/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-06-29 19:53:48
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-04-22 21:26:20
 * @FilePath: /aaskins/src/api/login.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {request} from "./index";
async function userLoginToken(data){
    let {stast,verify} = data
    if(stast == 'login' && verify == ''){
        return request({
            url: `/login`,
            method:'post',
            data
        })
    }
    if(stast == 'register'){
        return request({
            url: `/register`,
            method:'post',
            data
        })
    }
    if(stast == 'forget'){
        return request({
            url: `/reset_password`,
            method:'post',
            data
        })
    }

}
function getSendSms(data) {
    return request({
        url: `/send_sms`,
        method:'post',
        data
    })
}
function getUsetInof() {
    return request({
        url: `/me`,
        method:'get'
    })
}
function getWebInfo() {
    return request({
        url: `/info`,
        method:'get'
    })
}

function getRefreshToken(){
    return request({
        url: `/auth/refresh`,
        method:'post'
    })
}
function get_bind_mobile(data){
    return request({
        url: `/bind_mobile`,
        method:'post',
        data
    })
}

function getCode(){
    return request({
        url: `/get_code`,
        method:'post',
    })
}

export default {
    userLoginToken,
    getSendSms,
    getUsetInof,
    getWebInfo,
    getRefreshToken,
    get_bind_mobile,
    getCode
}
