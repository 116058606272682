<template>
    <div class="free mb-5 content">
        <!-- content -->
        <div class="h4 text-white font-weight-bold text-center my-3">
            <div><img src="@/assets/newImg/g1fltit.png" /></div>
            <div class="cursor-pointer text-center color-yellow" style="font-size: 16px"
            @click="playingMethod = true">活动规则</div>
        </div>

        <div class="row mt-5 justify-content-around mfllist">
            <router-link
                    :to="'/active/outOfTheFree/' + item.box_id+'?id='+item.id + '&type='+item.type"
                    v-for="(item,index) in box_list" :key="index"
                    class="mt-4 text-center free-content bg-size-100 bg-repeat col-lg-2 col-md-3 col-sm-4 col-6">
                <div class="">
                    <div><img class="maswrapimg" width="100%" :src="item.box.intact_cover" alt=""></div>
                    <div class="h6 mt-3 mTitlefl">{{item.name}}</div>
                    <div class="title_qian mt-3" style="line-height:20px">{{item.description}}<br/></div>
                </div>
            </router-link>

            <vacancy v-if="box_list.length == 0"></vacancy>
        </div>
        <!-- 玩法介绍 -->
        <div class="" v-if="playingMethod">
            <div style="width: 700px;left: calc(50% - 350px);top:15vh"
                 class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">
                <div class="text-white p-md-4 p-2" style="line-height: 2.0">

                    <p>1.达到宝箱条件即可开箱，充值达到一定金额免费开启宝箱。</p>

                    <p>2.咨询客服，新人注册，其他福利可获取cdk即可开启箱子。</p>

                </div>
                <div @click="playingMethod = false" class="position-absolute" style="right: 20px;top: 30px">
                    <div>
                        <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
                    </div>
                </div>

            </div>
            <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
        </div>

    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import $api from '@/api/outOfTheFree/index'
    export default {
        name: "indexV2",
        data() {
            return {
                blocks:[
                    { padding: '12px 19px', background: '#312414' },
                ],
                prizes:[],
                buttons: [{}],
                buttonsRepetition:true,
                activity_rules:false,
                box_list:[],
                cdk:'',
                box_gun_active:{},
                box_gun_active_is_show:false,
                playingMethod:false,
            }
        },
        computed:{
            ...mapState(['user_info'])
        },
        mounted() {
            this.init()
        },
        methods:{
            async init(){
                await this.getWelfare()

            },
            // 获取宝箱数据
            async getWelfare(){
                try {
                    let {data} = await $api.getWelfare()
                    data.forEach((item,index) =>{
                        if(item.box == null || item.box == undefined || !item.box){
                            data.splice(index,1)
                        }
                    })


                    this.box_list = data

                }catch (e) {
                    console.log(e)
                }
            },
            welfareDataPrizes(resData){
                let data = JSON.parse(JSON.stringify(resData))
                let obj =data.box_list.map((item,index) =>{
                    let obj =  {
                        skin_id:item.skins.id,
                        borderRadius:1,
                        x: index>=4? index-4>=4?index-8:index-4 : index,
                        y: index<4? 0 : index<8 ? 1:2,
                        fonts: [
                            { text: '金币 ' + item.skins.bean, fontSize: '12px', top: 5,fontColor:'#FFFFFF' },
                        ],
                        imgs:[{
                            src: item.skins.cover,
                            width:80,
                            height:60,
                            top:25,
                        }],
                        background:'rgba(246,175,24,.5)',
                    }
                    return obj
                })
                console.log(obj)
                return obj
            },

            async startCallBack(item,index){

                if(this.buttonsRepetition){
                    this.buttonsRepetition = false
                }
                let {data} = await $api.get_welfare_open({id:item.id,cdk:item.cdk})
                let i = this.box_list[index].box_list.findIndex(t =>{
                    return t.skin_id == data.skin_id
                })

                let luck_grid = this.$refs[`LuckyGridClick${index}`]
                luck_grid[0].play()
                // let sum = Math.random() * 12
                setTimeout(() => {
                    luck_grid[0].stop(i)
                }, 2000)
            },
            endCallBack (prize) {
                this.buttonsRepetition = true
                this.box_gun_active_is_show = true
                this.box_gun_active = prize
                this.$notify({
                    title: '恭喜你获得',
                    message: `${prize.fonts[0].text}，已放入您的仓库`,
                    type: 'success',
                    position: 'top-left'
                });

                console.log(prize)

            }
        }
    }
</script>

<style lang="scss" scoped>

@media (max-width:600px) {
    .maswrapimg{
        transform: scale(1.4);
        margin-bottom: 20px;
        margin-top: 20px;
    }
}


.mTitlefl{

box-sizing: border-box;
border: 2px solid ;
width: 184px;
height: 40px;
margin: 0 auto;
line-height: 40px;
border: solid 1px #1f384f;
background-color: #1f2f4a;
}

.mfllist{
    display: flex;
    
    > a{
        flex: 0 0 20% !important;
    width: 400px;
    display: table-cell;
    max-width: inherit !important;
    margin: 0 20px;
    }
}
.content{
    width: 1600px;
    margin: 0 auto;
    @media (max-width:600px) {
        width: 100%;
    }
    &-box{
        height: 100vh;
    }
}

@media (max-width:600px) {
    .free{
        > div{
            > div{
                img{
                    height: 70px;
                }
            }
        }
    }
}
</style>
