<template>
  <div class="d-flex overflow-hidden justify-content-between content-item"
    :class="resData.status == 1 ? 'bg-danger' : ''">

    <div class="d-flex" :class="resData.status == 2 ? '' : ''">
      <div class="content-user  d-flex justify-content-around align-items-center
            flex-wrap content-pk-list-item-user px-md-3" style="border-bottom: 1px solid #1a1a27 ">
        <div class="text-center mb-1 w-100" style="width:50px;height: 80px" v-for="(user, i) in resDataUser"
          :key="i + 'b'">
          <img v-if="user" class="rounded-circle border" width="80px" height="80px"
            :src="user.user.avatar || require('@/assets/img/pk/user.png')" alt="">
          <img v-else :src="require('@/assets/newImg/yuan.png')" class="rounded-circle" width="50px" height="50px"
            alt="">
        </div>
      </div>
    </div>


    <div ref="box" :class="resData.status == 2 ? '' : ''" class="text-center content-pk-list-item-gun px-3"
      style="border-bottom: 1px solid #1a1a27 ">

      <div ref="widthBox" class="content-pk-list-item-gun-content d-flex overflow-auto">
        <div style="border-right: 1px solid #1a1a27 " v-for="(box, bi) in resData.game_arena_box "
          :class="resData.status == 1 && resData.ceil == bi ? 'active' : ''" :key="bi + 'box'"
          class="content-pk-list-item-gun-item">
          <img width="100px" :src="box.box ? box.box.intact_cover : require('@/assets/img/f_new/case-bg.png')" alt="">
        </div>
        <div style="clear: both;"></div>
      </div>
    </div>
    <div class="" :class="resData.status == 2 ? '' : ''">

      <div style="width: 100%" class="content-user m-auto d-flex justify-content-center align-items-center
            flex-wrap content-pk-list-item-user px-md-3">
        <b class="text-center m-auto title_qian">
          <money /> {{ resData.total_bean }} | {{ resData.game_arena_box.length }} 回 合
        </b>
      </div>


      <div class="mmmbtnleftright">
        <div class="d-flex font-weight-bold">
        <div style="color: #438453;border-bottom: 1px solid #1a1a27 " class="text-center m-auto pt-2"
          v-if="resData.status == 0">
          <span class="" style="">
            <i class="el-icon-loading mb-2"></i>正在等待
          </span>
        </div>
        <div class="text-center m-auto pt-2" v-if="resData.status == 1">
          <span class="color: rgb(51, 204, 102)" style="">正在进行中</span>
        </div>
        <div class="text-center m-auto pt-2" v-if="resData.status == 2">
          <span class="" style="">对战结束</span>
        </div>
      </div>

      <div :class="resData.status == 2 ? '' : ''" class="d-flex justify-content-center align-items-center py-2 active"
        >
        <router-link v-if="resData.status == 2" :to="'/active/mysteryBox/' + resData.id"
          :active-class="'pk-conter-active'" class=" text-center  w-100 ">

          对战结束
        </router-link>
        <router-link v-if="resData.status == 0" :to="'/active/mysteryBox/' + resData.id + '?underway=0&round=0'"
          :active-class="'pk-conter-active'" class=" text-center  w-100">
         <img src="@/assets/newImg/ljpk.png" height="60" />
        </router-link>
        <router-link v-if="resData.status == 1"
          :to="'/active/mysteryBox/' + resData.id + '?underway=1' + '&round=' + resData.ceil"
          :active-class="'pk-conter-active'" class=" text-center f_btn  w-100">
          进行观战
        </router-link>
      </div>
      </div>

    </div>



  </div>
</template>

<script>

export default {
  name: "indexPKListPhone",
  props: {
    resData: { type: Object, default() { return {} } },
    PKarenaListIndex: { type: Number, default: 0 },
  },
  data() {
    return {
    }
  },
  computed: {
    PKarenaListIndexLinde() {
      return this.PKarenaListIndex
    },
    resDataUser() {
      let arrIndex = []
      arrIndex.length = this.resData.user_num
      for (let index = 0; index < this.resData.game_arena_player.length; index++) {
        let arrDataIndex = this.resData.game_arena_player[index]
        arrIndex[arrDataIndex.seat] = arrDataIndex
      }
      return arrIndex
    },
    resDataItem: {
      get() {
        return this.resDataItem.game_arena_box.map(item => {
          return item
        })
      },
      set(val) {
        return val
      }

    }
  },
  watch: {
    zizeng: {
      handler(newVal) {
        this.zizeng = newVal
      },
      immediate: true,//页面监听开始的时候就直接调用：见上文解释
      deep: true,//见上文解释
    },
    resData: {
      handler(newVal) {
        this.resData = newVal
      },
      immediate: true,//页面监听开始的时候就直接调用：见上文解释
      deep: true,//见上文解释
    },

  },
  mounted() {
  },
  created() {

  },
  beforeDestroy() {
    this.zizeng = 0
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">


@media (max-width:600px) {
  .mmmbtnleftright{
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    padding-bottom: 20px;
    > div{
      > div{
        border-bottom: none !important;
      }
    }
  }
}

.content-pk-list-item-gun-content>.active {
  transform: scale(1.1);
  animation: flash 1s infinite ease-in-out;
  animation-direction: alternate;
  color: var(--main-yellow);
}

.active:hover>a {
  display: block;
  /*color:var(--main-yellow) !important;*/
}

.content {
  &-item {
    @media (max-width:1200px) {
      display: block !important;
      // background:var(--f-main);
      left: 2%;
      border-radius: 10px;
      opacity: 1;
      background: linear-gradient(270deg, rgba(19, 108, 172, 0.5) 0%, rgba(0, 123, 255, 0.35) 50%, rgba(66, 77, 181, 0.2) 100%);
      box-sizing: border-box;
      border: 1px solid;
      -o-border-image: linear-gradient(180deg, rgba(52, 184, 255, 0) 0%, #34B8FF 52%, rgba(52, 184, 255, 0) 100%) 1;
      border-image: linear-gradient(180deg, rgba(52, 184, 255, 0) 0%, #34B8FF 52%, rgba(52, 184, 255, 0) 100%) 1;


      margin: 0 auto;
    }
  }

  &-pk {
    &-list {
      &-item {
        &-user {
          width: 100%;
          background-color: rgba(40, 40, 59, 1);

          @media (max-width:600px) {
            background-color: inherit;
          }

          &:hover {
            /*color: var(--main-yellow);*/
          }

          ;

          & .active {
            position: relative;
            background-color: rgba(40, 40, 59, 1);

            &::before {
              content: url("../../../assets/img/success_pk_list.png");
              width: 20px;
              height: 20px;
              position: absolute;
              top: -10px;
              left: -20px;
              transform: rotate(-45deg);
            }
          }

          @media (max-width: 1200px) {
            width: 100%;
            justify-content: start !important;

            & div {
              width: 60px !important;
              height: 60px !important;
              margin-left: 20px
            }

            & div img {
              width: 60px;
              height: 60px;
            }
          }
        }

        &-gun {
          width: 1366px;
          position: relative;
          padding: 0;
          overflow: auto;
          background-color: rgba(40, 40, 59, 1);

          @media (max-width: 1600px) {
            width: 1366px;
            background-color: inherit;
          }

          @media (max-width: 1366px) {
            width: 1100px;
          }

          @media (max-width: 1200px) {
            width: 768px;
            border-bottom: 2px solid var(--main-bg);

            & div {
              margin: 0 !important;
            }
          }

          @media (max-width: 960px) {
            width: 100%;

            & div {
              margin: 0 !important;
            }
          }

          & div {
            margin: 0 5px;
          }

          &-left,
          &-right {
            width: 10%;
            font-size: 50px;
            color: #FFFFFF;
          }

          &-left {
            transform: rotate(90deg)
          }

          &-content {

            &>div {
              float: left;
            }
          }

          &-right {
            transform: rotate(-90deg)
          }

          &-item {
            width: 112px;

            @media (max-width: 1600px) {
              width: 112px;
            }

            @media (max-width: 1366px) {
              width: 100px;
            }

            @media (max-width: 1200px) {
              width: 100px;
            }

            @media (max-width: 960px) {
              width: 100px;
            }
          }
        }
      }
    }


    &-conter-active {
      width: 230px;
      background-color: #191926;

      @media (max-width: 1200px) {
        margin-left: auto;
        width: 100px;
        font-size: 12px !important;
        border-left: 3px solid var(--main-bg);

        &>a {
          padding-right: 5px !important;
          padding-left: 5px !important;
        }
      }
    }
  }

  &-user {
    position: relative;
    padding: 20px 0;

    @media (max-width: 1200px) {
      display: flex !important;
      flex-wrap: nowrap !important;
      border-right: 0;
      border-left: 0;
    }

    &>div {
      @media (max-width: 1200px) {}
    }
  }

  @media (max-width: 1200px) {
    display: block !important;
    padding-bottom: 10px;
  }
}

@keyframes flash {
  to {
    opacity: .7;
    transform: scale(.94);
  }

  from {
    opacity: 1;
    transform: scale(1);
  }

}
</style>
