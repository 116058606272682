<template>
<!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
    <div class=" overflow-hidden" :style="'width:100%'" >
            <div class="py-2 d-md-flex d-block flex-column justify-content-around align-items-center position-relative bg-size-100 flex-wrap"
                 :style="`background-image:url(${resData.level_image_url});background-size:100% 100%`"
                 style="width: 100%;" >
                <!-- header -->
                <div class="px-3 text-center text-overflow-1 w-100 d-flex justify-content-between"
                     style="line-height: 24px;font-size: 12px">

                    <div class="text-overflow-1">{{resData.skins.dura_alias || '暂无'}}</div>
                    <div class="text-overflow-1">{{resData.odds_percent}}</div>

                </div>
                <img height="100px" class="position-relative my-md-3 ml-md-0 ml-3"
                     :src="resData.skins.cover" alt="">
                <div class="text-center">
                    <div class="color-yellow font-weight-bold"><money /> {{resData.skins.bean}}</div>

                    <div class="text-overflow-1 font-size-16px-12px">{{resData.skins.name}}</div>
                </div>
            </div>
        <slot>

        </slot>
    </div>
</template>

<script>
    export default {
        name: "weapon",
        props:{
            width:{
                type:String,
                default:"14%"
            },
            heightWeapon:{
                type:String,
                default:"150px"
            },
            bgSize:{
                type:Object,
                default(){
                    return {
                        height: '102px'
                    }
                }
            },
            weaponSize:{
                type:Object,
                default(){
                    return {
                        width: '115px',
                        marginTop:'25px'
                    }
                }
            },
        //    传输的item
            resData:{
                type:Object,
                default(){return {}}
            }
        },
    }
</script>

<style scoped lang="scss">
    .cover-content{
    }
    .cover-cover{
      & img{
        transition: all .5s;
      }
      &:hover img{
        transform: translateY(-20px)
      }
    }
    .weapon{
        &-name{
             padding: 10px 0;
         }
    }
</style>
