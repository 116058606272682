<template>
  <div class="px-md-5 px-1 mt-5 mInfo">
    <div class="d-flex flex-wrap mwarpflexno">
      <div class="content-left col-lg-3 col-sm-12" style="">
        <div style="" class="d-flex">
          <div class="position-relative mAbox" @click="avatarStatus = true,getRandAvatar(),currava=null">
            <img :src="user_info.avatar" width="100px" height="100px" alt="" />
            <!-- <div class="mAvatar bg_dark_blue p-2 text-center position-absolute bottom-0 right-0 left-0 cursor-pointer">
              <el-upload class="" :action="`*`" :before-upload="beforeUpload">
                <div size="small" type="primary" style="font-size: 12px">
                  点击更换头像
                </div>
              </el-upload>
            </div> -->
          </div>
          <div class="d-flex flex-column justify-content-around align-items-end">
            <div class="muser">
              <h4>{{ user_info.name }}</h4>
              <p>用户ID：{{ user_info.id }}</p>

              <div class="mmczck">
                <div class="mmy" style="cursor: pointer;" @click="$router.push('/user/recharge')">
                  <img src="@/assets/newImg/b2.png" /> 充值
                </div>
                <div class="mmy" style="cursor: pointer;" @click="$router.push('/user/info')">
                  <img src="@/assets/newImg/b1.png" /> 个人仓库
                </div>
              </div>
            </div>

            <!-- <div class="d-flex align-items-center justify-content-between mb-3">
                            <div class="mr-5"><span class="text-overflow-1"><money/>{{user_info.bean}}</span><span style="font-size: 12px" class="op-6">余额</span></div>
                            <div class=""><span  class="text-overflow-1">{{user_info.integral}}</span><span style="font-size: 12px" class="op-6">积分</span></div>
                        </div> -->
            <!-- <div class="ml-auto  " style="position: relative;z-index: 999">
                            <div class="mr-3 d-none">
                                <Task-in :bottom-right="false">
                                    <div class="f_btn3 px-3 py-1 rounded cursor-pointer">
                                        <div>
                                            签到
                                        </div>
                                    </div>
                                </Task-in>
                            </div>
                            <router-link to="/user/recharge" class="f_btn3 px-md-5 px-3 py-1 rounded cursor-pointer">
                                充值
                            </router-link>
                        </div> -->
          </div>
        </div>
        <div class="mt-3">
          <div class="mmoney">
            <p>
              <span>余额</span> <img src="@/assets/newImg/b.png" />
              {{ user_info.bean }}
            </p>
            <p>
              <span>积分</span> <img src="@/assets/newImg/z.png" />
              {{ user_info.integral }}
            </p>
          </div>

          <div class="mmoney mmoney1" style="cursor: pointer;" @click="$router.push('/user/recharge')">
            <img src="@/assets/newImg/b2.png" /> 充值
          </div>
          <div class="mmoney mmoney1" style="cursor: pointer;" @click="$router.push('/user/info')">
            <img src="@/assets/newImg/b1.png" /> 个人仓库
          </div>
        </div>
      </div>

      <div class="ml-auto content-right px-2 col-lg-9 col-sm-12" style="">
        <div class="mrightBox">
          <div class="mtab">
            <a :class="{ 'active': mTabActive == 0 }" @click="mTabActive = 0">个人资料</a>
            <a :class="{ 'active': mTabActive == 1 }" @click="mTabActive = 1">交易报价链接</a>
            <a :class="{ 'active': mTabActive == 2 }" @click="mTabActive = 2">快速帮助</a>
            <a :class="{ 'active': mTabActive == 3 }" @click="mTabActive = 3">交易明细</a>
          </div>
          <div class="mtabItem" v-if="mTabActive == 0">
            <div>
              <span>修改昵称</span>
              <input class="mtwoipt text-white" style="width: 150px" v-model="name" type="text" placeholder="输入昵称" />
              <a @click="set_name">保存</a>
            </div>
            <div>
              <span>邀请码</span>
              <input class="mtwoipt" v-if="user_info.inviter != null" style="width: 150px" v-model="name" type="text"
                placeholder="输入邀请码" />
              <input class="mtwoipt" v-else style="width: 150px" v-model="invite_code" type="text"
                placeholder="输入邀请码" />

              <a @click="getSet_inviter">绑定</a>
            </div>
            <div>
              <span>账户手机</span>
              <span class="mtwoipt">{{ user_info.mobile }}</span>
              <a @click="changePassword">更新</a>
            </div>
            <div>
              <span>账户控制</span>
              <span class="mtwoipt">已登录</span>
              <a @click="loginOut">登出</a>
            </div>
          </div>
          <div class="itemCard" v-if="mTabActive == 0">
            <label v-if="user_info.id_number === null">姓名:</label>
            <input v-if="user_info.id_number === null" class="mtwoipt1 text-white" style="width: 150px" v-model="name1" type="text" placeholder="请输入真实姓名" />
            <label v-if="user_info.id_number === null">身份证:</label>
            <input v-if="user_info.id_number === null" class="mtwoipt text-white" style="width: 150px" v-model="idcard" type="text" placeholder="请输入身份证号码" />
            <a v-if="user_info.id_number === null" @click="idcardCheck()">认证</a>
            <a v-else>√ 身份已认证</a>
          </div>
          <div class="mtabItem" v-if="mTabActive == 1">

            <div class="mMoney">
              <span>链接</span>
              <input v-model="steam_url" type="text" placeholder="交易报价链接 ">
              <a href="javascript:void(0)" @click="getSteamLink">保存</a>
              <!-- <a href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url" target="_blank">保存</a> -->
            </div>
            <p class="mMoneyp"><a>绑定自己的STM链接</a></p>

          </div>
          <div class="mtabItem" v-if="mTabActive == 2">
            <div class="help">
              <span>网站客服</span>
              <p>该客服处理充值、提货问题 QQ (10:00-22:00)</p>
            </div>
            <div class="help">
              <span>一般问题</span>
              <p>该客服处理您在网站使用中的各种问题。QQ (10:00-22:00)</p>
            </div>
            <div class="help">
              <span>提货帮助</span>
              <p>如何提取您的装备和需要注意的事项？</p>
            </div>
          </div>
          <div class="mtabItem masdhiweif" v-if="mTabActive == 3">
            <income :table-data="records" :total="recordstotal" :page-size="recordspageSize" ></income>
          </div>
        </div>
        <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="账户设置" name="user">
                        <div class="h5 mt-3 font-weight-bold d-md-block d-none">账户设置</div>
                        <div class="d-md-flex row mt-3">
                            <div class=" mr-0 col-md-3 col-12">
                                <div @click="user.info = 1" :class="user.info == 1? 'f-yellow-color' :''"
                                     class=" px-4 py-3 bg-bg cursor-pointer" style="margin-bottom: 1px">个人资料
                                </div>
                                <div @click="user.info = 2" :class="user.info == 2? 'f-yellow-color' :''"
                                     class=" px-4 py-3 bg-bg cursor-pointer" style="margin-bottom: 1px">
                                    交易报价链接
                                </div>
                                <div @click="user.info = 4" :class="user.info == 4? 'f-yellow-color' :''"
                                     class=" px-4 py-3 bg-bg cursor-pointer" style="margin-bottom: 1px">快速帮助
                                </div>
                            </div>
                            <div v-if="user.info == 1" class="col-md-9 p-0 pb-5"
                                 style="border: 3px solid var(--f-main); background-color: var(--main-bg-2) " name="个人资料">
                                <div class="h5 mb-0 font-weight-bold p-3 w-100"
                                     style="background-color: var(--f-main) ">个人资料
                                </div>
                                <div class="w-100 px-3 " style="">
                                    <div class="d-flex mt-3 d-block justify-content-between align-items-center" style="height: 50px">
                                        <div>修改昵称</div>
                                        <div>
                                 <span class="mr-3 pb-2" style="border-bottom:1px dashed var(--main-blue);">
                        <input style="width: 150px" v-model="name" class="text-white"
                               type="text"
                               placeholder="输入昵称"></span>

                                            <span class="px-4 py-2 text-center rounded cursor-pointer"
                                                  style="border:1px solid var(--main-blue)"
                                                  @click="set_name">保存</span>
                                        </div>
                                    </div>


                                    <div class="d-flex mt-3 d-block justify-content-between align-items-center" style="height: 50px">
                                        <div>邀请码<span></span></div>
                                        <div v-if="user_info.inviter != null">
                                            <span class="mr-3 pb-2" style="border-bottom:1px dashed var(--main-blue);">
                                                <span style="width: 150px" class="text-white">{{user_info.inviter.invite_code}}</span>
                                                       <span class="px-4 py-2 text-center rounded cursor-pointer"
                                                      style="border:1px solid var(--main-blue);margin-left:30px">已绑定</span>
                                            </span>

                                        </div>
                                        <div v-else>
                                            <span class="mr-md-3 mr-1 pb-2" style="border-bottom:1px dashed var(--main-blue);">
                                                <input  style="width: 150px" v-model="invite_code" class="text-white"
                                                       type="text"
                                                       placeholder="输入邀请码">


                                                <span class="px-4 py-2 text-center rounded cursor-pointer"
                                                      style="border:1px solid var(--main-blue)"
                                                      @click="getSet_inviter">绑定</span>
                                            </span>
                                        </div>



                                    </div>




                                    <div class="align-items-center mt-3 d-flex  justify-content-between" style="height: 50px">
                                        <div>账户手机</div>
                                        <div class="">
                                            <span class="mr-3 pb-2" style=" width: 150px;border-bottom:1px dashed var(--main-blue);">
                                                {{user_info.mobile}}</span>
                                            <span class="px-4 py-2 text-center rounded cursor-pointer"
                                                  style="border:1px solid var(--main-blue)" @click="changePassword">更新</span>
                                        </div>
                                    </div>
                                    <div class="d-flex mt-3  justify-content-between align-items-center" style="height: 50px">
                                        <div>账户控制</div>
                                        <div class="text-right">
                                            <span class="px-4 py-2 text-center rounded cursor-pointer"
                                                  style="border:1px solid var(--main-blue)"
                                                  @click="loginOut">登出</span>
                                        </div>
                                    </div>






                                </div>
                            </div>
                            <div v-if="user.info == 2" class="col-md-9 p-0 pb-5"
                                 style="border: 3px solid var(--f-main); background-color: var(--main-bg-2) " name="交易报价链接">
                                <div class="h4 mb-0 font-weight-bold p-3 w-100 "
                                     style="background-color: var(--f-main)">
                                    交易报价链接

                                        <a href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url" style="font-size: 0.8rem; color: var(--main-blue)" target="_blank">点我获取交易链接</a>


                                </div>
                                <div class="p-4">
                                    <input class="input py-4 px-4 " v-model="steam_url"
                                           type="text" placeholder="交易报价链接 ">
                                </div>
                                <div class="d-flex justify-content-end align-items-center pb-3 px-3">


                                    <div>
                                        取消
                                    </div>
                                    <div class="f-main-bg px-5 py-2 text-center rounded ml-3"
                                         @click="getSteamLink">
                                        保存
                                    </div>
                                </div>
                                <div>

                                </div>
                            </div>
                            <div v-if="user.info == 4" class="col-md-9 p-0 pb-5"
                                 style="border: 3px solid var(--f-main); background-color: var(--main-bg-2) " name="社交资料">
                                <div class="h4 mb-0 font-weight-bold p-3 w-100 "
                                     style="background-color: var(--f-main)">
                                    快速帮助
                                </div>
                                <div class="px-4 d-flex justify-content-between mt-4 ">
                                    <div>网站客服</div>
                                    <div class="col-md-8 col-12">该客服处理充值、提货问题 QQ {{web_info.qq_group_number}} (10:00-22:00)</div>
                                </div>
                                <div class="px-4 d-flex justify-content-between mt-4">
                                    <div>一般问题</div>
                                    <div class="col-md-8 col-12">该客服处理您在网站使用中的各种问题。QQ {{web_info.qq_group_number}} (10:00-22:00)</div>
                                </div>
                                <div class="px-4 d-flex justify-content-between mt-4">
                                    <div>提货帮助</div>
                                    <div class="col-md-8 col-12"><a href="/active/help/5" style="text-decoration-line:underline">如何提取您的装备和需要注意的事项？</a></div>
                                </div>
                           
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="个人仓库" name="back"></el-tab-pane>
                    <el-tab-pane label="取回历史" name="old">
                        <storage-list  :Storage-list-list="StorageList" :storage-listpage-size="StorageListpageSize"
                                      :storage-listtotal="StorageListtotal"></storage-list>
                    </el-tab-pane>
                    <el-tab-pane label="充值历史" name="pay">
                        <pay :table-data="tableData" :total="total" :page-size="pageSize" ></pay>
                    </el-tab-pane>
                    <el-tab-pane label="收支明细" name="income">
                        <income :table-data="records" :total="recordstotal" :page-size="recordspageSize" ></income>
                    </el-tab-pane>
                </el-tabs> -->
      </div>
    </div>

    <div class="msubtype">
      <div>
        <h2>游戏准则</h2>
        <p>
          我们鼓励客户以负责任的方式以合理的方式使用我们的服务。
          您可以随时请求立即禁用您的帐户。
        </p>
      </div>
      <div>
        <h2>开箱前</h2>
        <p>
          确保您熟悉所有取回选项和限制：检查您的
          账户的交易能力，确保没有交易禁令或任何限制（否则我们的机器人将无法向您发送您的物品）。
        </p>
      </div>
      <div>
        <h2>当心</h2>
        <p>
          所有问题必须通过 https://www.g1csgo.com 或电子邮件解决。
          忽略在任何社交网络上向您发送信息或向您发送电子邮件的人，他们可能是冒充
          g1csgo 站点管理员
        </p>
      </div>
      <div>
        <h2>赚佣金！</h2>
        <p>
          最多可将您推荐人资金的 10% 存入您的余额！
          您可以在合作伙伴页面上找到有关合作伙伴的详细信息！
        </p>
      </div>
    </div>

    <!--  验证码需要登录的那个弹窗 -->
    <el-dialog custom-class="login_egister" :show-close="false" :close-on-press-escape="false"
      :close-on-click-modal="false" :visible.sync="passwordShow" width="500px">
      <div class="login d-flex py-5 px-3">
        <div class="login-right">
          <!-- 验证码 -->
          <div class="d-flex border-dark-brown align-items-center mt-3">
            <div class="bg-bg-2 px-3 py-2 login-right-input">
              <div>
                <i class="iconfont text-white icon-dunpai cursor-pointer" style="font-size: 26px"></i>
              </div>
            </div>
            <div class="ml-2">
              <input v-model="form.verify" class="text-white d-block password" style="font-size: 16px" type="text"
                placeholder="请输入验证码" />
            </div>
            <div @click="getCode" :class="getCodeSeond != '获取验证码' ? 'cursor-not' : ''"
              class="bg-bg-2 px-3 ml-auto color-yellow cursor-pointer" style="height: 42px; line-height: 40px">
              <div>
                {{ getCodeSeond }}
              </div>
            </div>
          </div>

          <!-- 密码 - 注册 -->
          <div class="d-flex border-dark-brown align-items-center mt-3">
            <div class="bg-bg-2 px-3 py-2 login-right-input">
              <div>
                <i class="iconfont text-white icon-mima cursor-pointer" style="font-size: 26px"></i>
              </div>
            </div>
            <div class="ml-2">
              <input v-model="form.password" class="text-white d-block" style="font-size: 16px; width: 200px"
                type="password" placeholder="请输入密码" />
            </div>
          </div>

          <!-- 用户登录和steam登录 -->
          <div class="d-flex justify-content-center align-items-center">
            <bottom-bg @click.native="changePasswordClick" :title="'确认修改'" class="mt-3 px-5"></bottom-bg>
          </div>
        </div>
        <div @click="passwordShow = false" class="position-absolute" style="right: 30px; top: 20px">
          <div>
            <i class="iconfont text-white icon-guanbi-02-02 cursor-pointer" style="font-size: 30px"></i>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- vueCropper 剪裁图片实现-->
    <el-dialog class="cropper-dialog" width="600px" :close-on-click-modal="false" :visible.sync="dialogVisible"
      append-to-body>
      <div class="cropper-content d-md-flex d-block justify-content-center align-items-center">
        <div class="cropper" style="text-align: center">
          <vueCropper @real-time="realTime" ref="cropper" :img="option.img" :outputSize="option.size"
            :outputType="option.outputType" :info="true" :full="option.full" :canMove="option.canMove"
            :canMoveBox="option.canMoveBox" :original="option.original" :autoCrop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth" :auto-crop-height="option.autoCropHeight" :fixed="option.fixed"
            :fixedNumber="option.fixedNumber" :centerBox="option.centerBox" :infoTrue="option.infoTrue"
            :fixedBox="option.fixedBox"></vueCropper>
        </div>
        <div class="cropper d-none justify-content-between align-items-center text-center">
          <div>
            <div class="show-preview" :style="{
              width: previews.w + 'px',
              height: previews.h + 'px',
              overflow: 'hidden',
              margin: '5px',
            }">
              <div :style="previews.div">
                <img width="100px" height="100px" :src="previews.url" :style="previews.img" />
              </div>
            </div>
          </div>
          <div>
            <div class="show-preview rounded-pill" :style="{
              width: previews.w + 'px',
              height: previews.h + 'px',
              overflow: 'hidden',
              margin: '5px',
            }">
              <div :style="previews.div">
                <img class="" width="100px" height="100px" :src="previews.url" :style="previews.img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer d-flex justify-content-center">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="finish" :loading="loading">确认</el-button>
      </div>
    </el-dialog>
    <div class="avatarMask" v-if="avatarStatus">
      <div class="model">
        <div class="lsBox">
          <div class="ls" @click="currava = item.k" v-for="(item,index) in avatarData">
            <img :src="item.url" />
            <div class="imgBox" v-if="currava == item.k">
              <img src="@/assets/newImg/gou.png" />
            </div>
          </div>
        </div>
        <div class="btnLs">
          <a @click="avatarStatus =false">取消</a>
          <a @click="setAvatarFn()">确定</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bottomBg from "@/components/bottomBg";
import pay from "./common/pay";
import income from "./common/income";
import { mapState } from "vuex";
import $api from "@/api/user/index";
import StorageList from "./common/StorageList";
import TaskIn from "@/views/TaskIn/index";
export default {
  name: "index",
  components: {
    StorageList,
    bottomBg,
    pay,
    income,
    TaskIn,
  },
  data() {
    return {
      currava: null,
      avatarStatus:false,
      avatarData:[],
      name1:'',
      idcard:'',
      mTabActive: 0,
      activeName: "user",
      tableData: [],
      StorageList: [],
      user: {
        info: 1,
      },
      passwordShow: false,
      getCodeSeond: "获取验证码",
      InvitationCode: "",
      steam_url: "",
      form: {
        mobile: "",
        password: "",
        verify: "",
      },
      invite_code: "",
      inviter: "",
      name: "",
      dialogVisible: false,
      loading: false,
      option: {
        canMove: true,
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 100, // 默认生成截图框宽度
        autoCropHeight: 100, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      previews: {},

      total: 0,
      StorageListtotal: 0,
      pageSize: 0,
      StorageListpageSize: 0,
      old: "",
      records: [],
      recordstotal: 0,
      recordspageSize: 0,
    };
  },
  computed: {
    money() {
      let benr = 0;
      this.StorageList.forEach((item) => {
        benr += item.bean * 1;
      });
      return benr.toFixed(2);
    },
    recyleMoney() {
      let benr = 0;
      this.StorageList.forEach((item) => {
        if (item.isShow == true) {
          benr += item.bean * 1;
        }
      });
      return benr.toFixed(2);
    },
    ...mapState(["user_info", "web_info"]),
  },
  created() {
    let { name } = this.$route.query;
    if (name) {
      this.activeName = name;
    }
    setTimeout(() => {
      this.name = this.user_info.name;
      this.steam_url = this.user_info.steam_url;
      this.InvitationCode = this.user_info.invite_code;
    }, 500);
  },
  mounted() {
    this.getRecharge_record();
    this.getUsetChange_records();
  },
  methods: {
    async setAvatarFn(){
      if( this.currava === null ){
        this.$notify({
            title: "提示",
            message: '请选择头像',
            type: "error",
            position: "top-left",
          });
        return false;
      }
      let { data, code } = await $api.setAvatar({
        k: this.currava
      });
      if( code == 200 ){
        this.$notify({
            title: "成功",
            message: '修改成功',
            type: "success",
            position: "top-left",
          });
        this.avatarStatus=false;
        this.$store.dispatch("userInfo");
        
      }
    },
    async getRandAvatar(){
      let { data, code } = await $api.getRandAvatar();
      if( code == 200 ){
        this.avatarData = data.list
        // this.avatarStatus=false;
      }
    },
    async idcardCheck(){
      if( !this.name1 ){
        this.$notify({
          title: "提示",
            message: '请输入姓名',
            type: "error",
            position: "top-left",
          });
        return false
      }
      if( !this.idcard ){
        this.$notify({
            title: "提示",
            message: '请输入身份证',
            type: "error",
            position: "top-left",
          });
        return false
      }
      let { data, code } = await $api.idcardCheck({
        name: this.name1,
        idcard: this.idcard
      });
      if( code == 200 ){
        this.$notify({
                        message: `认证成功`,
                        position: 'top-left'
                    });
      }
    },
    tabClickBack() {
      console.log("asdsada");
    },
    loginOut() {
      this.$store.commit("LOGIN_IS_SHOW", true);
      this.$store.dispatch("removeInfo");
      this.$router.push("/");
    },
    handleClick(tab, event) {
      console.log("tab, event");
      console.log(tab, event);
      if (tab.name == "back") {
        this.$router.push("/user/info");
      }
      if (tab.name == "old") {
        this.getStorage();
      }
    },
    async getStorage(page = 1, status = 1) {
      try {
        let { data, code } = await $api.getStorage(page, status);
        if (code == 500) return;
        data.data.forEach((item) => {
          if (item.back_message != "") {
            console.log(123);
          }
          item.isShow = false;
        });
        this.StorageList = data.data;
        this.StorageListtotal = data.total;
        this.StorageListpageSize = data.per_page;
      } catch (e) {
        console.log(e);
      }
    },
    async getRecharge_record(page = 1) {
      try {
        let { data, code } = await $api.getRecharge_record(page);
        if (code == 500) return;
        this.tableData = data.data;
        this.total = data.total;
        this.pageSize = data.per_page;
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async getUsetChange_records(page = 1) {
      try {
        let { data, code } = await $api.getUsetChange_records(page);
        if (code == 500) return;
        this.records = data.data;
        this.recordstotal = data.total;
        this.recordspageSize = data.per_page;
      } catch (e) {
        console.log(e);
        return;
      }
    },
    currentChangeIncome(val) {
      this.getUsetChange_records(val);
      window.scrollTo(0, 0);
    },
    currentChange(val) {
      this.getStorage(val);
      window.scrollTo(0, 0);
    },
    async recycle() {
      let arr = [];
      try {
        this.StorageList.forEach((item) => {
          if (item.isShow) {
            arr.push(item.id);
          }
        });
        let { message, code } = await $api.getCash({ data: arr });
        if (code == 500) return;
        if (code == 200) {
          this.$notify({
            title: "成功",
            message: message,
            type: "success",
            position: "top-left",
          });
        }

        this.getStorage();
        this.$store.dispatch("userInfo");
      } catch (e) {
        console.log(e);
      }
    },
    async extract() {
      let arr = [];
      this.StorageList.forEach((item) => {
        if (item.isShow) {
          arr.push(item.id);
        }
      });
      try {
        let { message, code } = await $api.getExtract({ data: arr });
        if (code == 500) return;
        if (code == 200) {
          this.$notify({
            title: "成功",
            message: message,
            type: "success",
            position: "top-left",
          });
        }
        this.getStorage();
        this.$store.dispatch("userInfo");
      } catch (e) {
        console.log(e);
      }
    },
    itemOne(item) {
      if (item.back_message != "") {
        this.ReturnReason = true;
        this.back_message = item.back_message;
      }
      item.isShow = !item.isShow;
    },
    changePassword() {
      this.passwordShow = true;
    },
    async changePasswordClick() {
      try {
        this.form.mobile = this.user_info.mobile;
        let { message } = await $api.getpassword(this.form);
        this.$notify({
          message: message,
          position: "top-left",
        });
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async getSet_inviter() {
      try {
        let { message, code } = await $api.getSet_inviter({
          invite_code: this.invite_code,
        });
        if (code == 500) return;
        if (code == 200) {
          this.$notify({
            title: "成功",
            message: message,
            type: "success",
            position: "top-left",
          });
        }
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async getCode() {
      let data = await $api.getSendSms({
        mobile: this.user_info.mobile,
        is_use: 2,
      });
      if (data.code == 500) return;
      this.$notify({
        title: "成功",
        message: data.message,
        type: "success",
        position: "top-left",
      });
      this.getCodeSeond = 60;
      let time = setInterval(() => {
        this.getCodeSeond -= 1;
        if (this.getCodeSeond == 0) {
          this.getCodeSeond = "获取验证码";
          clearInterval(time);
        }
      }, 1000);
    },
    async userSrc(response) {
      try {
        this.$common.blobToDataURL(response, async (res) => {
          let { data } = await $api.getImageBase64({ data: res });
          let { message, code } = await $api.getSet_avatar({ path: data.url });
          if (code == 500) return;
          this.$notify({
            title: "成功",
            message: message,
            type: "success",
            position: "top-left",
          });
          this.$store.dispatch("userInfo");
        });
      } catch (e) {
        console.log(e);
        return;
      }
    },
    authCopy() {
      console.log(this.InvitationCode);
      this.$common.CopyText({ content: this.InvitationCode });
    },
    async getSteamLink() {
      try {
        let { message, code } = await $api.getSet_steam_url({
          steam_url: this.steam_url,
        });
        if (code == 500) return;
        this.$notify({
          title: "成功",
          message: message,
          type: "success",
          position: "top-left",
        });
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async set_name() {
      try {
        let { message, code } = await $api.getSet_name({ name: this.name });
        if (code == 500) return;
        this.$notify({
          title: "成功",
          message: message,
          type: "success",
          position: "top-left",
        });
        this.$store.dispatch("userInfo");
      } catch (e) {
        console.log(e);
      }
    },
    async beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      const isImage = file.type.indexOf("image");
      if (!isLt5M) {
        this.$notify({
          message: "图片大于5M,请压缩图片",
        });
        return false;
      }
      if (isImage === -1) {
        this.$notify({
          message: "请上传图片格式,jpg或者png",
          position: "top-left",
        });
        return false;
      }
      this.$common.blobToDataURL(file, async (res) => {
        this.$nextTick(() => {
          this.option.img = res;
          this.previews.url = res;
          this.dialogVisible = true;
        });
      });
      return false;
    },
    finish() {
      this.$refs.cropper.getCropData(async (data) => {
        let { data: url } = await $api.getImageBase64({ data: data });
        let { message } = await $api.getSet_avatar({ path: url.url });
        this.$notify({
          title: "成功",
          message: message,
          type: "success",
          position: "top-left",
        });
        this.$store.dispatch("userInfo");
        setTimeout(() => {
          this.dialogVisible = false;
          this.loading = false;
        }, 1000);
      });
    },
    realTime(data) {
      this.previews = data;
    },
  },
};
</script>

<style scoped lang="scss">
@media (max-width:600px) {
  .mwarpflexno {
    display: inherit !important;
  }
}

.avatarMask{
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #000000a8;
  left:0px;
  top: 0px;
  > .model{
    position: relative;
    width: 1000px;
    border: 1px solid;
    -o-border-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 19%, #34B8FF 55%, rgba(0, 0, 0, 0) 100%) 1;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 19%, #34B8FF 55%, rgba(0, 0, 0, 0) 100%) 1;
    left: 50%;
    top: 50%;
    margin-top: -400px;
    margin-left: -500px;
    padding: 20px 40px;
    background-color: #10214cc8;
    height: 760px;
    @media (max-width:600px) {
      width: 90%;
      margin-left: -45%;
      height: 520px;
      margin-top: -260px;
      padding: 10px;
    }
    > .lsBox{
      display: flex;
      flex-wrap: wrap;
      @media (max-width:600px) {
          justify-content: center;
        }
      > .ls{
        flex: 0 0 16%;
        margin: 2%;
        justify-content: center;
        position: relative;
        @media (max-width:600px) {
          margin: 3%;
        }
        > .imgBox{
        position: absolute;
        z-index: 99;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        display: flex;
        top: 0px;
        justify-content: center;
        align-items: center;
        background-color: #00000092;
        @media (max-width:600px) {
          flex: 0 0 16%;
          width: 60px;
          height: 60px;
          left: 0px;
        }
        > img{
          height: 60px;
          width: 60px;
          @media (max-width:600px) {
            height: 40px;
          width: 40px;
          }
        }
      }
        > img{
          border-radius: 50%;
        width: 120px;
        height: 120px;
        @media (max-width:600px) {
          width: 60px;
          height: 60px;
        }
        
      }
      }
      > .ls:nth-child(5n){
        margin-right: 0%;
      }
      @media (max-width:600px) {
        > .ls:nth-child(5n){
        margin-right: 2.8%;
      }> .ls:nth-child(4n){
        margin-right: 2.8%;
      }
      }
      
    }

    > .btnLs{
      margin: 20px auto;
      display: flex;
      justify-content: center;
      > a{
        background: linear-gradient(270deg, #085889 0%, #1F7EB9 100%);
    position: relative;
    border-radius: 4px;
    padding: 18px 20px;
    margin: 0 20px;
    width: 140px;
    display: inline-block;
    text-align: center;
    font-size: 22px;
    @media (max-width:600px) {
      font-size: 16px;
      padding: 10px 20px;
    margin: 0 20px;
    width: 120px;
    }
      }
      
    }
  }
}

.itemCard{
  background: rgba(0, 0, 0, 0.2);
  margin: 0 60px;
  margin-top: 20px;
  padding: 20px 20px;
  @media (max-width:600px) {
    margin: 0 0px;
    display: grid;
    > .mtwoipt1{
      margin-bottom: 20px;
    }
  }
  > label{
    color: #7ABEFF;
    margin-right: 20px;
    @media (max-width:600px) {
    font-size: 18px;
    font-weight: 300;
    padding-bottom: 6px;
  }
  }
  > input{
    margin-right: 20px;
    height: 50px;
    width: 200px !important;
    font-size: 18px;
    border-bottom: solid 2px #7abfff74;
    @media (max-width:600px) {
    width: 100% !important;
  }
  }
  > a{
    background: linear-gradient(90deg, rgba(19, 108, 172, 0.5) 0%, rgba(66, 77, 181, 0.2) 100%);
    box-sizing: border-box;
    border: 1px solid rgba(45, 116, 222, 0.3);
    border-radius: 4px;
    padding: 0px 20px !important;
    display: inline-block;
    text-align: center;
    line-height: 35px;
    height: 35px;
    @media (max-width:600px) {
      width: 100% !important;
      margin-top: 20px;
      height: 50px;
      font-size: 18px;
      line-height: 50px;
    }
  }
}

.masdhiweif{
  > div{
    display: inherit !important;
    align-items: inherit !important;
    > .record{
      margin-top: 0px !important;
    }
  }
}

.mmczck {
  display: none;

  @media (max-width:600px) {
    display: flex;
    align-items: center;

    >.mmy {
      border-radius: 4px;
      opacity: 1;
      background: linear-gradient(90deg, rgba(19, 108, 172, 0.5) 0%, rgba(66, 77, 181, 0.2) 100%);
      box-sizing: border-box;
      border: 1px solid rgba(45, 116, 222, 0.3);
      z-index: 0;
      padding: 6px 10px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      margin-top: 10px;
      > img{
        margin-right: 10px;
      }
    }
  }
}

.mtab {
  padding: 0 140px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width:600px) {
    padding: 0px;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  >a {
    font-size: 20px;
    font-weight: bold;
    color: #999;
    cursor: pointer;

    @media (max-width:600px) {
      width: 180px;
      text-align: center;
      margin-bottom: 30px;
      font-style: italic;
    }
  }

  >.active {
    color: #fff;
  }
}

.mtabItem {
  padding: 0 60px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width:600px) {
    padding: 0px;
    margin-bottom: 20px;
  }

  >.mMoney {
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    flex: 0 0 100%;
    align-items: center;
    border-radius: 2px;
    margin-bottom: 10px;
    width: 100%;

    >span {
      width: 160px !important;
      text-align: center;
    }

    >input {
      width: 600px;
      color: #fff;
    }
  }

  >.mMoneyp {
    display: flex;
    justify-content: right;
    width: 100%;
    margin-top: 20px;
    @media (max-width:600px) {
      justify-content: center;
    }

    >a {
      color: #7ABEFF;
    }
  }

  >.help {
    flex: 0 0 100%;
    margin-bottom: 14px !important;
    height: 60px;
    opacity: 1;

    background: rgba(7, 9, 58, 0.2);
    @media (max-width:600px) {
      display: flex;
    }

    >span {
      width: 160px !important;
      text-align: center;
      @media (max-width:600px) {
        flex: 0 0 120px !important;
      }
    }

    >p {
      text-decoration: underline;
      @media (max-width:600px) {
        
      }
    }

  }

  >div {
    height: 60px;
    border-radius: 2px;
    flex: 0 0 410px;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;

    >span:first-child {
      width: 120px;
      padding: 0 20px;
      color: #7ABEFF;
      @media (max-width:600px) {
        padding: 0 10px;
        width: 100px !important;
      }
    }

    >.mtwoipt {
      width: 200px !important;
    }

    >a {
      background: linear-gradient(90deg, rgba(19, 108, 172, 0.5) 0%, rgba(66, 77, 181, 0.2) 100%);

      box-sizing: border-box;
      border: 1px solid rgba(45, 116, 222, 0.3);
      border-radius: 4px;
      width: 65px;
      text-align: center;
      line-height: 35px;
      height: 35px;
      @media (max-width:600px) {
        width: 65px;
      }
    }
  }

  >div:nth-child(odd) {
    margin-right: 20px;
  }

  >div:first-child {
    margin-bottom: 2px;
  }

  >div:nth-child(2) {
    margin-bottom: 2px;
  }
}

.msubtype {
  display: flex;

  @media (max-width:600px) {
    flex-wrap: wrap;

    >div:nth-child(3) {
      width: 183px;
    }
  }

  >div {
    margin: 40px 14px;
    flex: 1;
    border-radius: 4px;
    opacity: 1;
    padding-bottom: 20px;

    @media (max-width:600px) {
      flex: 0 0 calc(50% - 20px);
      margin: 10px;
    }

    background: linear-gradient(90deg,
      rgba(19, 108, 172, 0.5) 0%,
      rgba(66, 77, 181, 0.2) 100%);

    box-sizing: border-box;
    border: 1px solid rgba(45, 116, 222, 0.3);

    >h2 {
      font-size: 24px;
      padding: 20px;
      padding-bottom: 10px;
      font-weight: bold;
    }

    >p {
      padding: 0 20px;
      color: #ddd;
      font-size: 12px;
    }
  }
}

.mmoney {
  width: 230px;
  padding: 5px 0;
  height: 110px;
  border-radius: 4px;
  opacity: 1;

  @media (max-width:600px) {
    display: flex;
    width: 100%;
    height: 90px;
    position: relative;
    border: 0px solid rgba(45, 116, 222, 0.3) !important;
  }

  background: linear-gradient(90deg,
    rgba(19, 108, 172, 0.5) 0%,
    rgba(66, 77, 181, 0.2) 100%);

  box-sizing: border-box;
  border: 1px solid rgba(45, 116, 222, 0.3);

  >p:first-child {
    border-bottom: 1px solid #426fa8;

    @media (max-width:600px) {
      border-bottom: 0px solid #426fa8;

      >span {
        position: absolute;
      }
    }
  }

  >p {
    display: flex;
    margin: 0 20px;

    @media (max-width:600px) {
      flex: 1;
      justify-content: center;

      >span {
        position: absolute;
        width: 50%;
        top: 50px;
        text-align: center;
      }
    }

    justify-content: left;

    height: 50px;
    align-items: center;

    >span {
      margin-right: 15px;
    }

    >img {
      margin-right: 6px;
    }
  }

  >p:nth-child(2) {
    @media (max-width:600px) {
      border-bottom: 0px solid #426fa8;

      >span {
        position: absolute;
        width: 50%;
        top: 50px;
        text-align: center;
      }
    }
  }
}

.mmoney1 {
  margin-top: 20px;
  height: 60px;
  display: flex;
  align-items: center;

  @media (max-width:600px) {
    display: none;
  }

  >img {
    margin: 0 30px;
  }
}

.content-left {
  border-right: solid 1px #416fa8;
  flex: 0 0 23% !important;
  height: 400px;

  @media (max-width:600px) {
    height: auto;
    border-right: none;
    margin-bottom: 40px;
  }
}

.muser {
  @media (max-width:600px) {
    margin-top: 0px;
  }

  >h4 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;

    @media (max-width:600px) {
      margin-bottom: 10px;
    }
  }

  >p {
    color: #ccc;
    font-size: 14px;
  }
}

.mAbox {
  border-radius: 10px;
  border: solid 2px #fff;
  overflow: hidden;
  margin-right: 20px;
  width: 100px;
  height: 100px;
}

.mInfo {
  width: 1400px;
  margin: 0 auto;
  margin-top: 40px !important;

  @media (max-width:600px) {
    margin-top: 0px !important;
    width: 100%;
  }
}

.mAvatar {
  background: linear-gradient(0deg, #d8d8d8 0%, rgba(216, 216, 216, 0) 100%);
}

.content-left {
  width: 500px;

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.content-right {
  width: calc(100% - 530px);

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.record {
  width: 100%;
  background-color: var(--main-bg-2);
  padding: 20px;
}

.user_bottom {
  color: var(--main-yellow);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: -1px;
    background-color: var(--main-yellow);
    left: 0;
    right: 0;
    height: 3px;
  }
}

.password {
  width: 200px;

  @media (max-width: 1200px) {
    width: 100px;
    font-size: 12px;
  }
}

.setam-link {
  width: 500px;

  @media (max-width: 1200px) {
    width: 300px;
  }

  @media (max-width: 960px) {
    width: 200px;
  }
}

.title-phone {
  @media (max-width: 1200px) {
    display: none;
  }
}

.cropper-content {
  & .cropper {
    width: 300px;
    height: 300px;
  }
}

.side-box {
  width: 204px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.content {
  width: 50%;
  margin: 0 auto;

  @media (max-width: 1200px) {
    width: 96%;
    font-size: 12px;
  }
}

.input-height {
  height: 38px;
  line-height: 38px;
  width: 150px;
  text-align: center;
}

.input-width {
  width: 300px;

  @media (max-width: 1200px) {
    width: 150px;
  }
}

.login {
  &-left {
    margin-left: 30px;
    width: 170px;
  }

  &-right {
    margin-right: 30px;
    width: 770px;

    &-input {
      color: #ffffff;
      font-size: 25px;
    }
  }
}
</style>
