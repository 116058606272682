<template>
  <div>
    <div class="row p-0 flex-wrap mwrapsmall" :class="!isShowSwiperOff ? '' : 'swiper'">
      <div class="swiper-list row m-0 p-0">
        <div class="swiper-list-content d-flex position-relative" @click="warp($event)">
          <vue-seamless-scroll :data="listData" :class-option="classOption" class="mwbbanerlunbo warp overflow-hidden"
            style="height: 80px">
            <ul class="" style="display: flex;">
              <!-- 循环 -->
              <li v-for="(item, index) in listData" :key="index + item"
                class="position-relative swiper-list-content-box">
                <a href="javascript:void(0)"
                  class="d-flex position-relative swiper-list-content-box-item overflow-hidden"
                  :style="`background-image: url(${item.lv_bg_image})`">
                  <div class="bg-size-100 bg-repeat w-100">
                    <div class="align-items-center px-1 d-flex position-relative justify-content-center">
                      <img style="z-index: 99;height: 50px" :src="item.cover" alt="" class="position-relative m-auto">
                    </div>
                    <div class="ml-2">
                      <div class=" px-2 text-overflow-1 text-center" style="font-size: 12px">
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                </a>


                <a href="javascript:void(0)" class="position-relative swiper-list-content-box-item
                 overflow-hidden d-flex">
                  <div
                    class="bg-size-100 bg-repeat w-100  d-flex justify-content-center align-items-center flex-column">
                    <div class="align-items-center d-flex position-relative mb-2">
                      <img class="rounded-pill" width="50px" height="50px" :src="item.get_user.avatar" alt="">
                    </div>
                    <div class="ml-2">
                      <div class=" fon14 text-overflow-1 ">{{ item.get_user.name }}</div>
                    </div>
                  </div>
                </a>


              </li>
              <!-- end循环 -->
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>

      <el-dialog custom-class="login_egister" :show-close="false" :close-on-press-escape="false"
        :close-on-click-modal="false" :visible.sync="login_is_show" :top="'0'" width="530px">
        <div class="login">
          <div class="text-center text-white font-weight-bold ">
            <img class="wefwefwefewrlogo" style="width: 150px;height: 100px" src="@/assets/newImg/logo.png" alt="">
          </div>
          <div class="pb-2" :style="`${tabLoginRF == 'login' ? '' : ''}`">
            <div class="text-center h6 mt-3 text-white d-flex justify-content-around w-50 m-auto mefekdwe">
              <span @click="loginUser('login')" class="cursor-pointer h5 text-white font-weight-bold"
                :class="tabLoginRF == 'login' ? 'mefeefefefe' : ''" style="margin-left: 2px">登录
              </span>
              <span @click="loginUser('register')" class="cursor-pointer h5 text-white font-weight-bold"
                :class="tabLoginRF == 'register' ? 'mefeefefefe' : ''" style="margin-left: 2px">注册
              </span>

            </div>
            <div class="mx-3">
              <div v-if="tabLoginRF == 'register'" class="d-flex align-items-center mt-3 mbgbgbgbg">
                <div class="px-3 py-2 login-right-input">

                  <div>
                    用户名
                  </div>

                </div>
                <div class="ml-2 w-100 pr-3">
                  <input v-model="form.name" class="text-white d-block" style="font-size: 16px;width:100%" type="text"
                    placeholder="请输入用户名">
                </div>
              </div>

              <div class="d-flex align-items-center mt-3 mbgbgbgbg">
                <div class="px-3 py-2 login-right-input o">
                  <div>
                    手机号
                  </div>

                </div>
                <div class="ml-2 w-100 pr-3">
                  <input v-model="form.mobile" class="text-white d-block w-100" style="font-size: 16px;" type="text"
                    placeholder="请输入手机号">
                </div>
              </div>

              

              <div v-if="tabLoginRF != 'login' || form.steam_data != '' || code == true"
                class="d-flex align-items-center mt-3 mbgbgbgbg">
                <div class="px-3 py-2 login-right-input">
                  <div>
                    验证码
                  </div>
                </div>
                <div class="ml-2 w-100 pr-3">
                  <input v-model="form.verify" class="text-white d-block code-phone w-100" style="font-size: 16px;"
                    type="text" placeholder="请输入验证码">
                </div>
                <div @click="getCode" :class="getCodeSeond != '获取验证码' ? 'cursor-not' : 'cursor-pointer'"
                  class="text-center px-1 ml-auto color-yellow  code-phone-size mmsdbox"
                  style="height: 42px;line-height: 40px;width: 200px">
                  <div class="">
                    {{ getCodeSeond }}
                  </div>
                </div>
              </div>

              

              <div v-if="form.steam_data == '' && code == false" class="d-flex align-items-center mt-3 mbgbgbgbg">
                <div class="px-3 py-2 login-right-input">
                  <div>
                    密码
                  </div>

                </div>
                <div class="ml-2 w-100 pr-3">
                  <input @keyup.enter="login" v-model="form.pass" class="text-white d-block w-100"
                    style="font-size: 16px;" type="password" placeholder="请输入密码">
                </div>
              </div>

              <div class="d-flex align-items-center mt-3 mbgbgbgbg">
                <div class="px-3 py-2 login-right-input o">
                  <div>
                    验证码
                  </div>

                </div>
                <div class="ml-2 w-100 pr-3" style="display: flex;">
                  <input v-model="form.code" class="text-white d-block w-100" style="font-size: 16px;" type="text"
                    placeholder="图形验证码">

                  <img :src="imgCodeUrl" class="erihgeirghourer23wed" style="cursor: pointer;" @click="getCodes()" />
                </div>
              </div>

              <div v-if="tabLoginRF == 'register'" :class="tabLoginRF == 'register' ? 'w-100' : ''"
                class="d-flex align-items-center mt-3 mbgbgbgbg">
                <div class="px-3 py-2 login-right-input">
                  <div>
                    邀请码
                  </div>
                </div>
                <div class="ml-2 w-100 pr-3">
                  <input v-model="form.invite" class="text-white d-block w-100" style="font-size: 16px;" type="text"
                    placeholder="请输入邀请码（没有可以不填）">
                </div>
              </div>
              <div class="d-none">
                <input v-model="form.steam_data" type="text">
              </div>

            </div>

            <!-- <div @click="loginUser('forget')" class="text-right mx-3 mt-3 h6 text-white cursor-pointer">
              忘记密码
            </div> -->
            <div v-if="tabLoginRF == 'login'" class="mold18" @click="moldToggle()">
              <span :class="{ 'active': oldStatus }"></span>我已满18岁,并阅读和同意<a
                @click="() => window.location.href = '/active/help/3'">《用户协议》</a><a
                @click="() => window.location.href = '/active/help/4'">《协议与条款》</a>,承诺理性消费。
            </div>
            <div class="mwemfmwefwefwbg d-flex justify-content-between mx-3">
              <div v-if="tabLoginRF == 'register'" class="h6">
                已有帐号！<br />
                <span class="text-white cursor-pointer" @click="loginUser('login')">登录</span>
              </div>
              <div style="width: 200px" @click="login" class="mbtnbtnendd">
                {{ tabLoginRFName }}
              </div>
            </div>

            <div @click="loginUser('forget')" class="text-right mx-3 mt-3 h6 text-white cursor-pointer mmmrexrrugt">
              忘记密码?
            </div>


            <div @click="off_login" class="position-absolute" style="right: 30px;top: 20px">
              <div>
                <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:28px"></i>
              </div>

            </div>
          </div>
        </div>
      </el-dialog>


    </div>

  </div>

</template>
<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import { setToken, setExpires } from '@/utils/auth'
import $api from '@/api/login.js'
import $api_history from '@/api/CSGO/index.js'
import { mapState } from 'vuex'

export default {
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      scrollamount: 6,
      listData: [],
      classOption: {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 15, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      },
      loginRegisterShow: false,
      tabLoginRF: 'login',
      getCodeSeond: '获取验证码',
      code: false,
      agreement: true,
      form: {
        mobile: '',
        pass: "",
        // name: '',
        verify: '',
        invite: '',
        steam_data: '',
        password: '',
        bd_vid: '',
        code: '',
        code_key: ''
      },
      checked: false,
      isShowSwiperOff: false,
      oldStatus: false,
      imgCodeUrl:''
    }
  },
  filters: {
    openUrl(ty, item) {
      switch (ty) {
        case 1: // 开箱
          return '/active/' + item.box_id;
        case 2:// 福利箱
          return '/active/outOfTheFree/' + item.box_id;
        case 3: // 盲盒对战
          return '/mysteryBox'
        case 4: // 幸运开箱
          return '/lucky';
        case 5: // 饰品商城
          return '/shopping';
        case 6: // 夺宝
          return '/';
        case 7: // 装备合成
          return '/compound';
      }
    }
  },
  created() {
    let invite = this.$route.query.ref_code
    if (invite) {
      this.form.invite = invite
      if (this.user_info) {
        this.loginRegisterShow = false
      } else {
        this.loginRegisterShow = true
      }
    }
    this.init()
  },
  computed: {
    ...mapState(['user_info', 'steam_handle', 'login_top', 'web_info']),
    tabLoginRFName: function () {
      if (this.tabLoginRF == 'login') {
        return '登录'
      }
      if (this.tabLoginRF == 'register') {
        return '注册'
      }
      return '找回密码'
    },
    login_is_show() {
      return this.loginRegisterShow || this.login_top
    },

  },
  mounted() {
    let codeRef = window.sessionStorage.getItem('codeRef')
    if (codeRef) {
      this.form.invite = codeRef
    }
    let ua = window.sessionStorage.getItem('ua')
    if (ua == 'phone') {
      this.scrollamount = 2
    }

  },
  methods: {
    moldToggle() {
      this.oldStatus = !this.oldStatus
    },
    isShowSwiper() {
      this.isShowSwiperOff = !this.isShowSwiperOff

    },
    async init() {
      this.form.steam_data = this.steam_handle
      await this.getBoxHistory()

      await this.getCodes()

    },

    async getCodes(){
      let data = await $api.getCode()
      console.log(data);
      if( data.code == 200 ){
        this.imgCodeUrl = data.data.img;
        this.form.code_key = data.data.key
      }
    },
    warp(event) {
      console.log(event)
      return

    },
    loginRegister() {
      this.loginRegisterShow = true
      this.$store.commit("LOGIN_TOP", true)
    },
    off_login() {
      this.tabLoginRF = 'login'
      this.loginRegisterShow = false
      this.$store.commit("LOGIN_TOP", false)
      this.$store.commit("LOGIN_TOP_IS_XIE_YI", false)

      if (this.$route.name == 'nologin') {
        this.$router.push('/')
      }
    },
    loginUser(start) {
      this.tabLoginRF = start
      if (start == 'register') {
        this.agreement = false
      } else {
        this.code = false
        this.agreement = true
      }
    },
    async getCode() {
      let is_use = 1
      if (this.tabLoginRF == 'register') {
        is_use = 1
      } else {
        is_use = 2
      }
      this.getCodeSeond = 60
      let data = await $api.getSendSms({ mobile: this.form.mobile, is_use })
      if (data.success) {
        this.$notify({
          message: data.message,
          position: 'top-left'
        });
      } else {
        this.$notify({
          message: data.message,
          position: 'top-left'
        });
      }
      let time = setInterval(() => {
        this.getCodeSeond -= 1
        if (this.getCodeSeond == 0) {
          this.getCodeSeond = '获取验证码'
          clearInterval(time)
        }
      }, 1000)

    },
    agreementClick() {
      this.agreement = !this.agreement
    },
    async login() {
      if (this.tabLoginRF == 'login') {
        if (!this.oldStatus) {
          this.$notify({
            message: '请阅读协议与条款',
            type: 'error',
            position: 'top-left'
          });
          return
        }
      }
      this.$store.commit("LOGIN_TOP_IS_XIE_YI", false)
      this.form.stast = this.tabLoginRF
      this.form.password = this.form.pass
      this.form.bd_vid = window.location.href
      window.localStorage.setItem('popularize', window.location.href); // 耿哥写好之后 将这个删除
      let { code, data, message } = await $api.userLoginToken(this.form)

      if (code != 200) {
        this.getCodes();
        return
      }
      if (this.tabLoginRF == 'forget') {
        this.tabLoginRF = 'login'
        this.form.verify = ''
        this.$notify({
          message: message,
          type: 'success',
          position: 'top-left'
        });
        return
      }

      setToken(data.access_token)
      setExpires(data.expires_in)
      this.$store.dispatch('userInfo')

      this.off_login()
      this.$store.commit("LOGIN_IS_SHOW", false)

      if (this.$route.name == 'CSGOActive') {
        this.$router.go(0)
      }
      if (this.$route.name == 'nologin') {
        this.$router.go(0)
      }
    },
    async getBoxHistory() {
      try {
        let { data } = await $api_history.getBoxHistory()
        this.listData = data
        setInterval(() => {
          $api_history.getBoxHistory().then(res => {
            let listData = this.listData.findIndex((item) => {
              return res.data.findIndex(it => {
                return item.id == it.id
              })

            })
            this.listData.push(...res.data.slice(0, listData + 5))
            this.listData.splice(0, listData + 5)

          })
        }, 5000)
      } catch (e) {
        console.log(e)
      }

    },
    ligin_zhuce() {
      this.loginRegisterShow = false
      this.$store.commit("LOGIN_TOP", false)
      setTimeout(() => {
        this.$router.push('/active/help/2')
      }, 500)

    }
  }
}
</script>
<style lang="scss" scoped>
@media (max-width:600px) {
  .mwrapsmall {
    >div {
      // margin-top: 54px !important;
    }
  }

  .mwemfmwefwefwbg{
    display: inherit !important;
    > .h6{
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      margin-top: 16px;
    }
  }

  .wefwefwefewrlogo{
    height: 60px !important;
    width: 100px !important;
  }
  .erihgeirghourer23wed{
    height: 30px;
  }

  .mwbbanerlunbo{
    margin-top: 64px;
    height: 100px !important;
  }
}

.mold18 {
  color: #ddd;
  padding-left: 20px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width:600px) {
    display: inherit;
  }

  >span {
    height: 14px;
    width: 14px;
    border: solid 1px #888;
    border-radius: 4px;
    margin-right: 4px;
    display: inline-block;
  }

  >a {
    color: #7ABEFF;
  }

  >.active {
    background-color: #7ABEFF
  }
}

.mmmrexrrugt {
  text-align: center !important;
  font-weight: 400;
  text-decoration: underline !important;
  color: #ccc !important;
}

.mbtnbtnendd {
  width: 230px;
  height: 60px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 1;

  background: linear-gradient(90deg, rgba(19, 108, 172, 0.5) 0%, rgba(66, 77, 181, 0.2) 100%);

  box-sizing: border-box;
  border: 1px solid rgba(45, 116, 222, 0.3);
  text-align: center;
  line-height: 60px;
  color: #fff;
  font-size: 24px;
  margin: 20px auto;
  margin-top: 40px;

  @media (max-width:600px) {
    margin-top: 10px;
    font-size: 20px;
    height: 50px;
    line-height: 50px;
  }
}

.mbgbgbgbg {
  padding: 10px 0;
  background: rgba(0, 0, 0, 0.2);
  @media (max-width:600px) {
    margin-top: 8px !important;
    padding: 8px 0;
  }

  >div {
    >div {
      font-size: 18px;
      width: 80px;
      text-align: center;
      color: #7ABEFF;
      @media (max-width:600px) {
        font-size: 14px;
      }
    }

    >input {
      font-size: 18px !important;
      @media (max-width:600px) {
        font-size: 14px !important;
      }
    }
  }
}

.mefeefefefe {
  background: linear-gradient(180deg, rgba(0, 174, 255, 0.3) 0%, rgba(0, 30, 182, 0) 100%);
}

.mefekdwe {
  width: 496px !important;

  @media (max-width:600px) {
    width: auto !important;
    transform: scale(.9);
  }

  >span {
    width: 50%;
    padding: 14px 0;
    @media (max-width:600px) {
      padding: 10px 0;
    }
  }
}

.heaght_0 {
  height: 0 !important;
}

.info {
  border-top: 1px solid var(--f-main);
}

.mmsdbox {
  width: 120px;

  @media (max-width:600px) {
    height: auto !important;
    line-height: inherit !important;
  }

  >div {
    width: 120px !important;
  }
}

.info-swiper {

  margin: 0 auto;
  width: 150px;
  padding: 2px 34px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .9rem;
  line-height: 1.3rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--f-main);
  clip-path: polygon(0 0, 100% 0, 85% 100%, 15% 100%)
}

.content-hove:hover {
  color: #FFFFFF;
}

.f_login {
  background-color: #e94d8b;
  color: #ffffff;
}

.code-phone {
  @media (max-width: 1200px) {
    width: 100px !important;
  }

  &-size {
    @media (max-width: 1200px) {
      font-size: 12px;
    }
  }
}

.login_egister {
  @media (max-width: 1200px) {
    width: 90% !important;
  }
}

.login {
  &-right {
    width: 100%;

    &-input {
      color: #FFFFFF;
      font-size: 25px;
      @media (max-width:600px) {
        padding: 4px 0px !important;
      }
    }
  }
}

.swiper {
  width: 100%;
  height: auto !important;

  @media (max-width: 1000px) {
    height: 80px !important;
    margin-top: 30px !important;
  }

  &-top {
    width: 80%;
    margin: 5px auto;
    padding: 5px 10px;
    background-color: var(--main-bg-2);
    border-radius: 15px;
    display: flex;
    align-content: center;
    font-size: 14px;

    @media (max-width:1000px) {
      width: 100%;
    }
  }

  &-user {
    width: 200px;
    height: 75%;
    font-size: 16px;

    & svg {
      font-size: 40px;
    }

    &-box {
      text-align: center;
      padding: 4px 0;
    }
  }

  &-list {
    width: 100%;

    @media (max-width:1000px) {
      transform: scale(1, .7) translateY(-15%);
    }

    &-content {
      width: 100%;

      &-box {
        top: 0;
        transition: all .5s;
        border-left: 2px solid var(--f-main);
        margin: 0 2px;
        @media (max-width:600px) {
          margin: 0px;
        }

        &:hover {
          top: -80px;
        }

        &-item {
          transition: all .5s;
          width: 120px;
          background-size: 120px 80px;
          height: 80px;
          flex-direction: column;
          justify-content: center;

          @media (max-width:1000px) {
            width: 100px;
            height: 100px;
            background-size: 100px 100px;
            background-position: center 2px;
          }

          /*background-color: var(--main-bg-2);*/
          /*&:nth-child(1)::before {*/
          /*  content: '';*/
          /*  width: 30px;*/
          /*  height: 30px;*/
          /*  position: absolute;*/
          /*  top: 0;*/
          /*  right: 0;*/
          /*  z-index: 9999;*/
          /*  background-size: 100% 100%;*/
          /*  background-image: url("../assets/img/f_new/jiangli.png");*/
          /*}*/
        }

        &-itemnone {
          opacity: 0;
          transition: all .5s;
          width: 170px;
          height: 80px;

          &-title {
            text-align: center;
            padding: 10px 0;
          }

          &-img {
            transition: all 1s;
          }
        }

        &:hover &-item {
          /*transform:rotateY(360deg);*/
        }

        &:hover &-itemnone {
          // opacity: 1;
        }

        &:hover &-itemnone-img {
          // transform:rotateY(180deg);
        }
      }
    }
  }
}

.fon14 {
  font-size: 14px;
}


.swiper {
  height: 500px;
  width: 100%;
  border: 1px transparent solid;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    background-color: rgb(98, 168, 200);
  }
}

.ceshi {
  transform-origin: 100% 50% 0;
  -webkit-animation: scrollAn .65s ease;
  animation: scrollAn .65s ease;
}

@keyframes scrollAn {
  0% {
    margin-left: -130px;
    opacity: 0;
    transform: perspective(800px) rotateY(-90deg) translateZ(0);
  }

  100% {
    margin-left: 1px;
    opacity: 1;
    transform: perspective(800px) rotateY(0deg) translateZ(0);
  }
}
</style>
