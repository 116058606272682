<template>
<div>
  <div class="d-flex align-items-center MSDIWHIFWEKF">
      <div class="d-flex flex-wrap">
          <div @click="bill('1')" :class="status=='1'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
              提取完成
          </div>
          <div @click="bill('4')" :class="status=='4'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
              已申请提货
          </div>

          <div @click="bill('2')" :class="status=='2'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
              已兑换
          </div>
          <div @click="bill('6')" :class="status=='6'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
              正在发货
          </div>
          <a target="_blank" href="/active/help/5" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
        如何取货？
            </a>
      </div>
  </div>
  <div class="flex-wrap mt-3 row d-flex" >
    <div @click="shoppinng(item)" class="back-gun col-lg-3 col-md-3 col-sm-6 col-6  mb-3"
    v-for="(item,index) in StorageList || StorageListList" :key="index">
        <weapon class="position-relative pt-4 border-bottom" :res-data="item" :class="item == 2? 'border-yellow' :''"
        style="border-radius: 10px; box-shadow: 1px 1px 1px var(--main-bg); color: #ddd ">
            <div class="position-absolute  text-center py-1 " :class="`back-color-${item.status}`"
                 style="top: 0px;font-size: 12px">
                {{item.status==4 || item.status == 5 ? '暂未发货' : item.status_alias}}{{item.updated_at}}
            </div>

        </weapon>
    </div>
    <vacancy  v-show="StorageList.length == 0"> </vacancy>
  </div>
  <el-pagination
    background
    layout="prev, pager, next"
    :total="StorageListtotal"
    :page-size="StorageListpageSize"
    @current-change="currentChange">
  </el-pagination>
</div>
</template>

<script>
  import $api from '@/api/user/index'
  import weapon from "./weapon";
  export default {
    name: "StorageList",
    props:{
      StorageListList:{
        type:Array,
        default(){return []}
      }
    },
    data(){
      return{
        ReturnReason:false,
        status:1,
        StorageListtotal:0,
        StorageListpageSize:0,
        StorageList:[],
        back_message:''
      }
    },
    components:{weapon},
    mounted() {
      this.getStorage(1,1)
      },
    methods:{
      shoppinng(item){
        console.log(item.back_message)
        if(item.back_message != ''){
          this.ReturnReason = true
          this.back_message = item.back_message
        }
      },
      // 获取仓库的信息
      async getStorage(page = 1,status = 1){
        try {
          let {data,code} = await $api.getStorage(page,status)
          if(code == 500)return
          data.data.forEach(item =>{
            if(item.back_message != ''){
              console.log(123)
            }
            item.isShow = false
          })
          this.StorageList = data.data
          this.StorageListtotal = data.total
          this.StorageListpageSize = data.per_page
          if(status == 1){
            let {data,code} = await $api.getStorage(1)
            console.log(data,code)
            data.data.forEach(item =>{
              if(item.back_message != ''){
                this.StorageList.unshift(item)
              }
            })
          }
        }catch (e) {
          console.log(e)
        }
      },

      currentChange(val){
        this.getStorage(val,this.status)
        window.scrollTo(0,0);
      },
      bill(val){
        this.status = val
        let loading = this.$loading({lock:true,text: '正在加载',  spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'})
        setTimeout(() => {

          this.getStorage(1,val)
          setTimeout(async()=>{
            if(val == 4){
              let {data:data5} = await $api.getStorage(1,5)
              this.StorageList.unshift(...data5.data)
              let {data} = await $api.getStorage(1,6)
              this.StorageList.unshift(...data.data)
            }
          },100)
          loading.close();
        }, 500);

      },
    }
  }
</script>

<style scoped>

.MSDIWHIFWEKF{
  margin-top: 20px;
  @media (max-width:600px) {
    margin-top: 20px;
  }
}
  .active{
    background: var(--main-yellow);
    color: var(--main-brown);
  }
</style>
