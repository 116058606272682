<template>
    <div class="d-flex mt-5 bg-bg-bg">
        <div class="content mt-5">
            <div class="h3 text-white font-weight-bold text-center mb-md-5 mb-1 content-top">
                {{ box_data.name }}
            </div>
            <!-- 动画开关 声音开关 手机端 -->
            <div class="off-open-phone" style="display: none;">
                <div class="col-md-4 d-flex col-12 text-right py-3  justify-content-center">
                    <!-- 动画开关 - 定位 -->
                    <div class=" col-6">
                        <el-switch class="mr-3" v-model="animationSwitch" active-color="rgb(67, 132, 83)"
                            inactive-color="#979797">
                        </el-switch>{{ animationSwitch ? '打开动画' : '关闭动画' }}
                    </div>
                    <!-- 声音开关 - 定位 -->
                    <div class="col-6">
                        <el-switch class="mr-3" v-model="voiceSwitch" active-color="rgb(67, 132, 83)"
                            inactive-color="#979797">
                        </el-switch>{{ voiceSwitch ? '打开声音' : '关闭声音' }}
                    </div>
                </div>
            </div>

            <div class="content-open-box row flex-wrap">
                <!-- left -->
                <div class="col-md-4 col-12 content-open-box-left py-3 mkleft">

                    <div class="d-flex align-items-center mt-4 merhjwfoe">
                        <div v-for="(item, index) in openBoxSum" :key="index" @click="openAddSum(item, index)" class="position-relative bg-size-100 bg-repeat d-flex justify-content-center
                                align-items-center mx-2 rounded content-open-box-sum cursor-pointer"
                            :class="index == num ? 'bg-yellow' : 'bg-brown'" style="width: 40px;height: 40px">
                            <div>
                                {{ index + 1 }}
                            </div>
                        </div>
                        <!-- <div>
                            |
                            <money />{{ (box_data.bean * openBoxNumber).toFixed(2) }}
                        </div> -->
                    </div>


                </div>

                <!-- 中间 -->
                <div class="col-md-4  position-relative content-open-box-bg d-flex  align-items-center  justify-content-center
                    mx-auto bg-repeat">
                    <!-- 定位 -->
                    <img src="@/assets/newImg/k1.png" class="mk1 mk2" height="80" />
                    <img src="@/assets/newImg/k2.png" class="mk1" height="80" />
                    <img class="mcover" style="bottom: 20px" :src="box_data.cover" alt="" />
                </div>

                <!-- right -->
                <div
                    class="col-md-4 col-12 content-open-box-right text-right py-3 d-md-flex flex-column justify-content-center">
                    <!-- 动画开关 - 定位 -->
                    <div class="mb-3" style="right: 0;top:-30px">
                        <el-switch class="mr-3" v-model="animationSwitch" active-color="rgb(67, 132, 83)"
                            inactive-color="#979797">
                        </el-switch>{{ animationSwitch ? '打开动画' : '关闭动画' }}
                    </div>
                    <!-- 声音开关 - 定位 -->
                    <div class="" style="right: 150px;top:-30px">
                        <el-switch class="mr-3" v-model="voiceSwitch" active-color="rgb(67, 132, 83)"
                            inactive-color="#979797">
                        </el-switch>{{ voiceSwitch ? '打开声音' : '关闭声音' }}
                    </div>
                </div>
            </div>
            <!-- 点击开箱 -->
            <div class="d-flex justify-content-center align-items-center mt-5 mbtnlsldsd">
                <div>
                    <bottom-bg v-if="getToken != undefined" class="px-5" @click.native="open">
                        <div class="py-2">
                            <div class="d-flex">
                                
                                <span style="    display: flex;
    align-items: center;
">(
                                    <money />{{ (box_data.bean * openBoxNumber).toFixed(2) }})
                                </span>
                                <span>打开 {{ openBoxNumberData.length }} 个箱子</span>
                            </div>
                        </div>
                    </bottom-bg>
                    <bottom-bg v-else @click.native="open" class="px-5">
                        <div class="py-2">
                            <div class="d-flex" style=";color:#ffffff">
                                <span>试玩</span>
                            </div>
                        </div>
                    </bottom-bg>
                </div>
            </div>



            <!-- 最近掉落 -->
            <div class="mt-5 mb-3 text-center font-weight-bold h4 mktitilesdsds">
                最近掉落
            </div>
            <div style="width:100%" class="zuijindiaoluo">
                <div class="px-2 d-md-block d-flex">
                    <div class="mb-3 px-2 float-left" style="width: 100px" v-for="(item, index) in historyListData"
                        :key="index">
                        <div style="width: 95%">
                            <weapon-hist :res-data="item"></weapon-hist>
                        </div>
                    </div>
                </div>
            </div>


            <!-- 分割线 -->
            <div style="opacity: .5">
                <el-divider></el-divider>
            </div>

            <!--  箱子包含  -->
            <div class="mt-5 mb-3 text-center font-weight-bold h4 ">
                箱子包含
            </div>
            <div class="w-100 d-flex px-3">
                <div class="d-flex w-100 flex-wrap mmwefelfjbdody">
                    <div class="mb-3 col-xl-2 col-lg-3 col-md-4 col-6 px-1" style=""
                        v-for="(item, index) in box_data.contains" :key="index">
                        <div style="width: 95%">
                            <weapon :res-data="item"></weapon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 老虎机中将弹窗 -->
        <div v-show="awardListPop">
            <div style="" class=" awardListPop position-fixed pop-up-open z-index-100 py-5 px-4">
                <div>
                    <div class="awardListPop-box d-flex justify-content-center pt-5 flex-wrap align-content-start">
                        <!-- awardListData.length<=2?'height:fit-content':'height:fit-content' -->
                        <div style=""
                            :class="`ggbg${item.lv} ${awardListData.length == 1 ? `awardListData1` : ''}
                             ${awardListData.length == 2 ? `awardListData2` : ''} ${awardListData.length >= 3 ? `awardListData3` : ''} `"
                            v-for="(item, index) in awardListData" :key="index"
                            class="text-center award-box overflow-hidden">
                            <!--                            <div  :id="'open_q_' + index" style="width: 200px;height: 200px"></div>-->
                            <div class="font-size-16px-12px pt-md-2 pt-1">{{ item.dura_alias }}</div>
                            <img v-lazy="item.cover" alt="" style="width: 80%;">
                            <div class="my-1 text-overflow-1 font-size-16px-12px">{{ item.name }}</div>
                            <div class="d-flex justify-content-between  "
                                style="font-size: 12px;border-radius: 0!important;">
                                <div
                                    class="text-center w-50 p-md-2 py-1 d-flex align-content-center justify-content-center">
                                    <money class="money-phone" />{{ item.bean }}
                                </div>
                                <div class="text-center w-50 p-md-2 py-1 cursor-pointer " @click="recycle(item, index)">
                                    兑换</div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex px-md-5 justify-content-between mt-2">
                        <div @click="offAward">
                            <bottom-bg>
                                <div style="color: #FFFFFF;font-size: 16px" class="px-md-5 px-2">
                                    继续开箱
                                </div>
                            </bottom-bg>
                        </div>
                        <div @click="recycleAll" class="cursor-pointer">
                            <bottom-bg>
                                <div style="color: #FFFFFF;font-size: 16px" class="px-md-5 px-2">
                                    <span v-if="moneyAll.length < 5">兑换</span>
                                    <money color="#FFFFFF" font-size="12px" />
                                    {{ moneyAll.toFixed(2) }}
                                </div>
                            </bottom-bg>
                        </div>
                    </div>
                    <div @click="offAward" class="position-absolute" style="right: 20px;top: 30px">
                        <div>
                            <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
                        </div>
                    </div>

                </div>
            </div>
            <div class="position-fixed op-9 z-index-1"
                style="width: 100vw;height: 100vh;left: 0;top: 0;background: #000"></div>
        </div>
        <!-- 开箱动画 -->
        <el-dialog custom-class="open_tage_box" :show-close="false" :close-on-press-escape="false"
            :close-on-click-modal="false" :top="`0vh`" :visible.sync="openIsShow">
            <div>
                <!-- 电脑端 弹窗老虎机开始页面 -->
                <div v-if="sUserAgent != 'phone'" :class="animateGo ? 'an' : ''" class="open-slot">
                    <div class="open-slot-list overflow-hidden" v-for="(item, index) in openBoxNumberData" :key="index">
                        <div class="an-item d-flex align-items-center" style="height: 100%">

                            <div class="text-center" :name="t.skins.a - 123456" v-for="(t, i) in item.solt_List"
                                :key="i" style="padding:0 5px;background-position: center;background-size: 100% 100%"
                                :style="`background-image:url(${t.skins.lv_bg_image || t.level_image_url})`">
                                <img width="120px" style="width: 120px;height: 90px" :src="t.skins.cover" :alt="t">

                                <div style="font-size: 12px" class="text-overflow-1">
                                    {{ t.skins.name }}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- 手机端 弹窗老虎机开始页面 -->
                <div v-else :class="animateGo ? 'an' : ''" class="open-slot ">
                    <div class="open-slot-list-phone overflow-hidden" v-for="(item, index) in openBoxNumberData"
                        :key="index">
                        <div class="an-item d-flex align-items-center" style="height: 100%">
                            <div class="text-center bg-size-100" :name="t.skins.a - 123456"
                                v-for="(t, i) in item.solt_List"
                                :style="`background-image:url(${t.skins.lv_bg_image || t.level_image_url}) `" :key="i"
                                style="padding:0 5px; background-size: 100% 100%">
                                <img width="65px" style="width: 65px;height: 50px" :src="t.skins.cover" alt="">
                                <div style="font-size: 10px;line-height: 20px" class="text-overflow-1">
                                    {{ t.skins.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import bottomBg from "@/components/bottomBg";
import weapon from "./common/weapon";
import weaponHist from "./common/weaponhist";
import $api from '@/api/CSGO/index'
import { getToken } from '@/utils/auth'
import sUserAgent from '@/assets/js/common.js'
export default {
    name: "active",
    components: {
        bottomBg,
        weapon,
        weaponHist
    },
    data() {
        return {
            voiceSwitch: true,
            box_id: '',
            box_data: {},
            historyListData: [],
            box_slot_list: [],
            animationSwitch: true,
            openBoxSum: [{ num: 1 }, { num: 0 }, { num: 0 }, { num: 0 }, { num: 0 },],
            openBoxNumber: 1,
            openBoxNumberData: [{ solt_List: [] }],
            lightning: false,
            openFrontAnim: false,
            opentow: false,
            openFive: false,
            animateGo: false,
            svgaIsShow: false,
            awardListPop: false,
            awardListData: [],
            num: 0,
            shifoukaishi: false,
            openIsShow: false,
            time: null,
            getToken: getToken(),

        }
    },
    computed: {
        moneyAll() {
            let bean = 0
            this.awardListData.forEach(item => {
                bean += (item.bean * 1)
            })
            return bean
        },
        historyListDataSide() {
            let arr = []
            if (this.historyListData.length > 10) {
                arr = this.historyListData.slice(0, 10)
            } else {
                arr = this.historyListData
            }
            return arr
        },
        sUserAgent() {
            return sUserAgent.sUserAgent()
        }

    },
    watch: {
        lightning: {
            handler(newVal) {
                this.lightning = newVal;
                setTimeout(() => {
                    this.lightning = false
                }, 1000)
            },
            immediate: true,
            deep: true,
        },
    },
    mounted() {
        this.voiceSwitch = true
        this.init()
        window.localStorage.setItem('demoOpen', 1)
    },
    beforeDestroy() {
        console.log('页面销毁的时候')
        clearInterval(this.tiem)
        this.voiceSwitch = false
        document.getElementById('open').pause()
        document.getElementById('openLeftBox').pause()
        document.getElementById('openTopAwardList').pause()
    },
    methods: {
        async init() {
            this.box_id = this.$route.params.id
            await this.getBoxDetail()
            await this.getBoxHistory()
        },
        async getBoxDetail() {
            try {
                let { data, code } = await $api.getBoxDetail(this.box_id)
                if (code == 500) return
                this.box_data = data
                let arrData = JSON.parse(JSON.stringify(data.contains))
                let arr = []
                for (let i = 0; i < 70; i++) {
                    arr.push(arrData[Math.floor(Math.random() * arrData.length)])
                }
                this.openBoxNumberData[0].solt_List = arr
                this.box_slot_list = arr
            } catch (e) {
                console.log(e)
            }
        },
        async getBoxHistory() {
            try {
                let { data, code } = await $api.getBoxHistory(this.box_id, 1)
                if (code == 500) return
                this.historyListData = data.slice(0, 15)
            } catch (e) {
                console.log(e)
            }

        },
        svgaInit() { },
        openAddSum(item, index) {
            let token = getToken()
            if (!token) {
                this.$store.commit("LOGIN_TOP", true)
                return
            }
            if (this.shifoukaishi) {
                this.$notify({
                    message: '正在开箱，请稍后点击',
                    type: 'warning',
                    position: 'top-left'
                });
                return
            }
            this.num = index
            this.openBoxNumber = index + 1
            let data = JSON.parse(JSON.stringify(this.openBoxNumberData[0]))
            this.openBoxNumberData = []
            for (let i = 0; i < index + 1; i++) {
                this.openBoxNumberData.push(data)
            }
        },
        async open() {
            try {
                let token = getToken()
                if (token == undefined) {
                    window.localStorage.setItem('demoOpen', 0)
                    this.demoOpen()
                    return
                }
                let { data, code } = await $api.getBoxOpen({ id: this.box_id, num: this.openBoxNumber })
                if (code == 500) return
                if (this.shifoukaishi && token) {
                    this.$notify({
                        message: '正在开箱，请稍后点击',
                        type: 'warning',
                        position: 'top-left'
                    });
                    return
                }
                await this.tageLoginAn()
                this.shifoukaishi = true
                this.awardListData = JSON.parse(JSON.stringify(data))
                let arr = []
                for (let i = 0; i < data.length; i++) {
                    arr[i] = { solt_List: this.box_slot_list }
                }
                arr = arr.map((item, index) => {
                    data[index].a = index
                    let arr = []
                    let arrData = JSON.parse(JSON.stringify(item.solt_List))
                    for (let i = 0; i < 70; i++) {
                        arr.push(arrData[Math.floor(Math.random() * arrData.length)])
                    }
                    let dataArr = {
                        ...arr[0],
                        skins: data[index]
                    }
                    arr.splice(50, 0, dataArr)
                    item.solt_List = [...arr]
                    return item
                })
                this.openBoxNumberData = [...arr]
                this.$store.dispatch('userInfo')
            } catch (e) {
                console.log(e)
                return
            }
            if (this.animationSwitch) {
                this.openIsShow = true
                this.svgaIsShow = true
                this.svgaInit()

                setTimeout(() => {
                    this.openBoxSoltGo()
                }, 10)
                this.time = setTimeout(() => {
                    if (this.voiceSwitch) {
                        document.getElementById('succeed').play()
                    }
                    this.shifoukaishi = false
                    this.opentow = true
                    this.openFive = false
                    this.svgaIsShow = false
                    this.awardListPop = true
                    this.openIsShow = false
                    this.awardListAn()
                }, 7500)
            } else {
                if (this.voiceSwitch) {
                    document.getElementById('succeed').play()
                }
                this.awardListPop = true
                setTimeout(() => {
                    this.awardListAn()
                }, 100)
            }
            this.$store.dispatch('userInfo')
        },
        async demoOpen() {
            this.openIsShow = true
            let data = []
            let sum = Math.floor(Math.random() * this.openBoxNumberData.length * 10)
            data.push(this.box_slot_list[sum])
            if (this.shifoukaishi) {
                this.$notify({
                    message: '正在开箱，请稍后点击',
                    type: 'warning',
                    position: 'top-left'
                });
                return
            }
            await this.tageLoginAn()
            this.shifoukaishi = true
            let awardData = [data[0].skins]
            this.awardListData = JSON.parse(JSON.stringify(awardData))
            let arr = []
            for (let i = 0; i < data.length; i++) {
                arr[i] = { solt_List: this.box_slot_list }
            }
            arr = arr.map((item, index) => {
                data[index].a = index
                let arr = []
                let arrData = JSON.parse(JSON.stringify(item.solt_List))
                for (let i = 0; i < 70; i++) {
                    arr.push(arrData[Math.floor(Math.random() * arrData.length)])
                }
                let dataArr = {
                    ...arr[0],
                    skins: data[index].skins
                }
                arr.splice(50, 0, dataArr)
                item.solt_List = [...arr]
                return item
            })
            this.openBoxNumberData = [...arr]
            this.$store.dispatch('userInfo')
            this.svgaIsShow = true
            this.openBoxSoltGo()
            this.time = setTimeout(() => {
                if (this.voiceSwitch) {
                    document.getElementById('succeed').play()
                }
                this.shifoukaishi = false
                this.opentow = true
                this.openFive = false
                this.awardListPop = true
                this.awardListAn()
                this.openIsShow = false
            }, 7000)
        },

        tageLoginAn() {
            return new Promise(res => {
                this.animateGo = true
                setTimeout(() => {
                    this.animateGo = false
                    res(this.animateGo)
                }, 100)

            })
        },
        openBoxSoltGo() {
            this.opentow = true
            setTimeout(() => {
                if (this.voiceSwitch) {
                    document.getElementById('open').play()
                }
                this.animateGo = true
            }, 10)
        },
        awardListAn() { },
        offAward() {

            if (!this.animationSwitch) {
                this.awardListPop = false
                this.shifoukaishi = false
                return
            }
            this.openFrontAnim = false
            this.opentow = true
            this.openFive = false
            this.svgaIsShow = false
            this.awardListPop = false
            this.awardListData = []
            this.getBoxHistory()
        },
        async recycle(item, index) {
            let arr = []
            arr.push(item.id)
            let { message, code } = await $api.getRecycle({ data: arr })
            if (code == 500) return
            if (code == 200) {
                this.awardListData.splice(index, 1)
            }
            if (this.awardListData.length == 0) {
                this.offAward()
            }
            this.$notify({
                message: message,
                type: 'success',
                position: 'top-left'
            });
            this.$store.dispatch('userInfo')
            this.getBoxHistory()
        },
        async recycleAll() {
            let token = getToken()
            if (token == undefined) {
                this.$store.commit("LOGIN_TOP", true)
                return
            }
            let arr = []
            this.awardListData.forEach(item => {
                arr.push(item.id)
            })
            let { message, code } = await $api.getRecycle({ data: arr })
            if (code == 500) return
            this.$notify({
                message: message,
                type: 'success',
                position: 'top-left'
            });
            if (code == 200) {
                this.offAward()
            }
            this.$store.dispatch('userInfo')
            this.getBoxHistory()
        },

    }
}
</script>

<style scoped lang="scss">

.mmwefelfjbdody{
    width: 1600px !important;
    margin: 0 auto;
}

@media (max-width:600px) {
    .merhjwfoe{
        margin-top: 20px !important;
    }
}


.mktitilesdsds{
    margin: 40px 0 !important;
}
.f_btn3{
    background-image: url(../../assets/newImg/btnbg.png);
    border: none !important;
    background-size: 304px 56px;
    background-repeat: no-repeat;
}

.bg-yellow{
    border: solid 2px #29C0D0 !important;
    background-color: #224C7D !important;
}
.mkleft {
    position: absolute;
    left: 50%;
    margin-left: -342.66px;
    display: flex;
    justify-content: center;
    top: 667px;
    width: 680px;
    flex: inherit;
    max-width: inherit;
    position: absolute;
    @media (max-width:600px) {
        margin-left: -210px;
        top: 550px;
        width: inherit;
    }
}


.mbtnlsldsd {
    margin-top: 160px !important;
    @media (max-width:600px) {
        margin-top: 140px !important;
    }
}

.mk1 {
    position: absolute;
    bottom: 0px;
}

.mk2 {
    position: absolute;
    width: 600px;
    bottom: -40px;
    height: auto;
}

.mcover {
    height: 300px;
    position: absolute;
    z-index: 999;
    @media (max-width:600px) {
        height: 260px;
    }
}

.pop-up-open {
    background-image: url("../../assets/img/openIndex/k.png");
    background-size: 100% 100%;
}

.awardListData1 {
    width: 50%;

    @media (max-width: 1000px) {
        width: 70%;
        margin: 70px 0 !important;
    }
}

.awardListData2 {
    width: 46%;

    @media (max-width: 1000px) {
        width: 50%;
        margin: 70px 0 !important;
    }
}

.awardListData3 {
    width: 30%;

    @media (max-width: 1000px) {
        width: 50%;
    }
}

.content-top {
    // background-image: url("../../assets/imgV2/open-title.png");
    background-position: center;
    background-size: cover;
    font-weight: bold;
    font-size: 38px;
    font-style: italic;
    color: rgba(255, 255, 255, 0.2) !important;
}

.off-open-phone {
    display: none;

    @media (max-width: 960px) {
        display: block;
    }
}

.zuijindiaoluo {
    overflow-x: scroll;
    width: 1600px !important;
    margin: 0 auto;
    @media (max-width:600px) {
        width: 100% !important
    }

    &::-webkit-scrollbar {
        height: 2px;
        
    }
}

.money-phone {
    @media (max-width: 1200px) {
        display: none;
    }
}

.award-box {
    box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, .5);
    margin: 10px 0;
}

.awardListPop {
    width: 700px;
    left: calc(50% - 350px);
    top: 100px;
    z-index: 1001;
    position: relative;

    @media (max-width:1200px) {
        width: 98%;
        left: 1%;
    }

    &-box {
        @media (max-width:1200px) {
            height: 350px;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none
            }
        }
    }
}

.list-box {
    width: 20%;

    @media (max-width: 960px) {
        width: 25%;
    }

    @media (max-width: 1200px) {
        width: 33%;
    }

    @media (max-width: 960px) {
        width: 50%;
    }
}

.scroll {
    & ::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 10px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
        display: none;
    }
}

.anZhuan {
    transition: all 7s;
    transform: rotate(360000deg)
}

.side-box {
    width: 200px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5)
}

.bg-bg-bg {
    @media (max-width:1366px) {
        background-position: center top;
        margin-top: 0px !important;
        // background-image: url("../../assets/img/openIndex/bg.png");
    }
}

.content {
    margin: 0 auto;
    // background-image: url("../../assets/img/openIndex/bg.png");
    background-size: 100%;
    background-repeat: no-repeat;

    @media (max-width:1366px) {
        background-image: none;
        width: 95%;

        .content-open-box-right {
            display: none;
        }

        .content-open-box-left {
            clip-path: none;
        }

        .content-open-box-bg {
            margin-top: 0;
        }
    }

    &-open {
        &-box {
            width: 1000px;
            margin: 0 auto;

            @media (max-width:1200px) {
                width: 100%;
            }

            &-bg {
                height: 250px;
                background-position: bottom;
                background-size: 250px;
                margin-top: 30px !important;
                left: 50%;
                margin-left: -166.665px !important;

                @media (max-width:1200px) {
                    background-size: 250px;
                    margin-top: 40px !important;
                    margin-left: -190px !important;
                }
            }

            &-left,
            &-right {
                margin-top: 50px;
                height: 100px;

                // background-image: -webkit-gradient(linear, left top, left bottom, from(#1d1b4b), to(#0f0e2b));
                @media (max-width:960px) {
                    margin-top: 0;
                }
            }

            &-left {
                clip-path: polygon(0 0, 86% 0, 100% 100%, 0% 100%);
            }

            &-sum {
                width: 80px;
                height: 80px;
                background: linear-gradient(180deg, rgba(65, 69, 255, 0.45) 0%, rgba(0, 255, 246, 0) 100%);

                box-sizing: border-box;
                border: 2px solid;
                border-image: linear-gradient(180deg, rgba(109, 250, 255, 0.2) 0%, rgba(109, 250, 255, 0) 22%, rgba(35, 193, 211, 0.2) 69%, rgba(255, 255, 255, 0) 100%);
                border-radius: 50% !important;
            }

            &-right {
                clip-path: polygon(14% 0, 100% 0, 100% 100%, 0% 100%);
                margin-top: -100px;
            }

        }

        &-add {
            width: 860px;
        }

        &-left {
            @media (max-width: 1366px) {
                display: none;
            }
        }

        &-center {
            @media (max-width: 1366px) {
                width: 70%;
            }

            @media (max-width: 960px) {
                width: 80%;
            }

            @media (max-width: 768px) {
                width: 100%;
            }
        }

        &-right {
            @media (max-width: 1366px) {
                display: none;
            }
        }

        &-gun {
            @media (max-width: 1366px) {
                width: 30%;
            }

            @media (max-width: 960px) {
                width: 40%;
            }

            @media (max-width: 768px) {
                width: 50%;
            }
        }

        &-box2 {
            @media (max-width: 1366px) {
                width: 30%;
            }

            @media (max-width: 960px) {
                width: 40%;
            }

            @media (max-width: 768px) {
                width: 50%;
            }
        }
    }

    &-add {}
}

.opentow-bg {
    width: 100vw;
    height: 150vh;
    position: fixed;
    background: #000000;
    opacity: .8;
    z-index: 3;
    top: 0;
}

.open-slot {
    & ::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 10px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
        display: none;
    }

    &-list {
        width: 1000px;
        height: 120px;
        left: 0;
        margin: 0 auto;
        position: relative;
        box-shadow: inset 0 0 3px var(--main-blue);

        &:before {
            content: "";
            width: 20px;
            height: 120px;
            background-image: url("../../assets/img/openIndex/zhishi.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            position: absolute;
            left: calc(50% - 10px);
            bottom: 0;
            z-index: 9;
            /*z-index: 99999;*/
        }

        @media (max-width: 960px) {
            display: none;
        }
    }

    &-list-phone {
        width: 500px;
        height: 100px;
        left: calc(50% - 250px);
        position: relative;
        box-shadow: inset 0 0 10px #000;
        background-color: rgba(0, 0, 0, .8);
        color: #fff;
        display: none;

        &:before {
            content: "";
            width: 20px;
            height: 100px;
            background-image: url("../../assets/img/openIndex/zhishi.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: absolute;
            left: calc(50% - 6px);
            bottom: 0;
            /*z-index: 99999;*/
        }

        @media (max-width: 960px) {
            display: block;
        }
    }

}

.an-multi {
    z-index: 1000;

    &-list {}
}

/* 动画 */
.an .open-slot-list:nth-child(1) .an-item {
    transition: all 5.5s cubic-bezier(.2, .3, .14, .92) 0s;
    transform: translateX(-6065px)
}

.an .open-slot-list:nth-child(2) .an-item {
    transition: all 5.5s cubic-bezier(.2, .32, .14, .98) 0s;
    transform: translateX(-6065px)
}

.an .open-slot-list:nth-child(3) .an-item {
    transition: all 5.5s cubic-bezier(.2, .3, .14, .92) 0s;
    transform: translateX(-6065px)
}

.an .open-slot-list:nth-child(4) .an-item {
    transition: all 5.5s cubic-bezier(0, 0, .2, 1) 0s;
    transform: translateX(-6065px)
}

.an .open-slot-list:nth-child(5) .an-item {
    transition: all 5.5s cubic-bezier(.2, .32, .14, .98) 0s;
    transform: translateX(-6065px)
}

.an .open-slot-list:nth-child(6) .an-item {
    transition: all 5.5s cubic-bezier(0, 0, .1, 1.01) 0s;
    transform: translateX(-6065px)
}

.an .open-slot-list:nth-child(7) .an-item {
    transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0s;
    transform: translateX(-6065px)
}







/* 手机端动画 */

.an .open-slot-list-phone:nth-child(1) .an-item {
    transition: all 5.5s cubic-bezier(.2, .32, .14, .98) 0s;
    transform: translateX(-3530px)
}

.an .open-slot-list-phone:nth-child(2) .an-item {
    transition: all 5.5s cubic-bezier(.2, .3, .14, .92) 0s;
    transform: translateX(-3520px)
}

.an .open-slot-list-phone:nth-child(3) .an-item {
    transition: all 5.5s cubic-bezier(.2, .32, .14, .98) 0s;
    transform: translateX(-3530px)
}

.an .open-slot-list-phone:nth-child(4) .an-item {
    transition: all 5.5s cubic-bezier(.2, .32, .14, .98) 0s;
    transform: translateX(-3510px)
}

.an .open-slot-list-phone:nth-child(5) .an-item {
    transition: all 5.5s cubic-bezier(.2, .32, .14, .98) 0s;
    transform: translateX(-3530px)
}

.an .open-slot-list-phone:nth-child(6) .an-item {
    transition: all 5.5s cubic-bezier(0, 0, .1, 1.01) 0s;
    transform: translateX(-3520px)
}

.an .open-slot-list-phone:nth-child(7) .an-item {
    transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0s;
    transform: translateX(-5790px)
}


.an-multi .open-multi-list .an-multi-item:nth-child(1) {
    transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0s;
    transform: translateY(-6980px)
}

.an-multi .open-multi-list .an-multi-item:nth-child(2) {
    transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0s;
    transform: translateY(-7000px)
}

.an-multi .open-multi-list .an-multi-item:nth-child(3) {
    transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0s;
    transform: translateY(-7000px)
}

.an-multi .open-multi-list .an-multi-item:nth-child(4) {
    transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0s;
    transform: translateY(-7000px)
}

.an-multi .open-multi-list .an-multi-item:nth-child(5) {
    transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0s;
    transform: translateY(-7000px)
}
</style>
