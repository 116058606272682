<template>
  <div>
      <div class="content mt-3 position-relative mjierofef">
          <div class="h3 text-white font-weight-bold text-center my-5">
              <img src="@/assets/newImg/jjc.png" style="margin-top:40px" />
          </div>

          <div class="position-absolute d-flex" style="right:0;top:20px">
            <div class="cursor-pointer mr-2 px-4 py-2" @click="$router.push({name:'mysteryBox'})">
              <img src="@/assets/newImg/back.png" />
            </div>

          </div>

          <div class="d-flex justify-content-between align-items-center py-3 px-4 mpktopheader">
              <div class="font-weight-bold">总共价格 <img src="@/assets/newImg/b.png" />{{detailsData.total_bean}}</div>

              <div class="" style="font-size: 12px">{{detailsData.box_num}}轮<span v-if="detailsData.status == 2"><a href="?underway=1&round=0"> | 重播对战</a></span></div>
              <div class="cursor-pointer" v-if="detailsData.status == 2" @click="$router.push({name:'mysteryBox'})">完成</div>
              <div v-else>等待中...</div>

          </div>

          <div class="w-100 pt-4 history_bg msdoreiguier" style="margin-bottom: 0px;">
              <div class="content-bout-box  d-flex justify-content-center align-items-center" :class="detailsData.status == 2?'':''">
                  <div v-for="(item,index) in detailsData.game_arena_box" :key="index"
                       @click="lockBoxGun(item,index)"
                       class="text-center content-bout-box-item "
                       :class="index == indexBox? detailsData.status == 2?'':'active':''">
                      <div class="content-bout-box-item-index m-auto ">{{index+1}}</div>
                      <img width="130px" class="" :src="item.box.intact_cover"
                           alt="">

                  </div>
              </div>
          </div>


          <div class="d-none">
              <div class="h4">对战回合</div>
              <div class="d-flex content-bout history_bg align-items-center">
                  <div class="content-bout-sum h4">
                      <span class="color-yellow h1">{{detailsData.status == 2? detailsData.box_num:indexBox + 1}}</span>
                      / {{detailsData.box_num}}
                  </div>
                  <div class="content-bout-box d-flex" :class="detailsData.status == 2?'':''">
                      <div v-for="(item,index) in detailsData.game_arena_box" :key="index"
                           class="text-center content-bout-box-item "
                           :class="index == indexBox? detailsData.status == 2?'':'active':''">
                          <img width="100px" :src="item.box.intact_cover"
                               alt="">
                          <div class="mt-2">{{item.box.name || ''}}</div>
                      </div>
                  </div>
                  <div class="ml-auto content-bout-add-pk">
                      <div v-show="detailsData.status == 2" class="px-5 py-4 bg-yellow cursor-pointer rounded">
                          对战结束 <money color="var(--main-brown)" />{{detailsData.total_bean}}
                      </div>
                      <div v-show="detailsData.status == 0" @click="addPk(item,index)" class="px-5 py-4 bg-yellow cursor-pointer rounded">
                          加入对战 <money color="var(--main-brown)" />{{detailsData.total_bean}}
                      </div>
                      <div v-show="detailsData.status == 1" class="bg-dark-brown cursor-pointer">
                      </div>
                  </div>
              </div>
          </div>
          <div class="content-pk mb-5 d-flex flex-wrap row ml-0 mr-0">
              <div :class="`col-${(12/(detailsData.user_num*1))} `"
                   class="content-pk-item  content-pk-item-active-bg overflow-hidden pl-0 pr-0 pk_pk_pk"
                   v-for="(item,index) in detailsData.game_arena_player" :key="index">

                  <div v-if="item" :class="item.success ? '': detailsData.status == 2 ? '':''"
                       class="overflow-hidden">
                      <div class="content-pk-item-pk d-fle "
                           :class="item.game_gun?'active_light content-pk-item-pk-bg' : ''">
                          <div class="content-pk-item-pk-box" :class="openAn?'an':''">
                              <div class="text-center content-pk-item-pk-box-item an-item position-relative " :name="t.a"
                                   v-for="(t,index) in item.game_arena_box[indexBox].box.contains"
                                   :key="index">
                                  <div class="position-absolute" style="width: 90%; height: 90%; background: rgba(80,71,121,.08)"></div>
                                  <div v-if="detailsData.status == 2">
                                      <div v-if="item.success">
                                          <div class="pk_ready">
                                              <svg class="pk_ready_svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                  <path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" class=""></path>
                                              </svg>

                                          </div>

                                          <svg viewBox="0 0 293.5 236.1" class="pk_stat_winbg">
                                              <path d="M34.6 194.8L171.9 0l121.6 136.1-258.9 58.7z" fill-opacity=".039"></path>
                                              <path d="M173.9 236.1L59.5 119.8l153.6-12.2-39.2 128.5z" fill-opacity=".129"></path>
                                              <path d="M200.9 219.3l-52-88.9 98.4 10.1-46.4 78.8z" fill-opacity=".051"></path>
                                              <path d="M87.7 215.1L0 150.5l108.2 46.6-20.5 18z" fill-opacity=".2"></path>
                                              <path d="M233.2 104.6l35.3-59.5 10.7 58.7-46 .8z" fill-opacity=".071"></path>
                                          </svg>

                                          <div class="m-auto d-flex justify-content-center align-items-center">
                                          </div>
                                          <div style="font-size: 20px; font-weight: 700; color: #3c6">
                                              胜利$<countTo :decimals="2" :startVal='0' :endVal='(item.sum)*1' :duration='2000'></countTo>
                                          </div>
                                      </div>

                                      <div v-else>


                                          <svg viewBox="0 0 293.5 236.1" class="pk_stat_losebg">
                                              <path d="M34.6 194.8L171.9 0l121.6 136.1-258.9 58.7z" fill-opacity=".039"></path>
                                              <path d="M173.9 236.1L59.5 119.8l153.6-12.2-39.2 128.5z" fill-opacity=".129"></path>
                                              <path d="M200.9 219.3l-52-88.9 98.4 10.1-46.4 78.8z" fill-opacity=".051"></path>
                                              <path d="M87.7 215.1L0 150.5l108.2 46.6-20.5 18z" fill-opacity=".2"></path>
                                              <path d="M233.2 104.6l35.3-59.5 10.7 58.7-46 .8z" fill-opacity=".071"></path>
                                          </svg>

                                          <div style="font-size: 20px;font-weight:700;">
                                              奖励$0.01
                                          </div>
                                      </div>

                                  </div>

                                  <div v-if="detailsData.status == 0">
                                      <div class="pk_ready">
                                          <svg class="pk_ready_svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                              <path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" class=""></path>
                                          </svg>
                                      </div>

                                      <svg viewBox="0 0 293.5 236.1" class="pk_stat_winbg">
                                          <path d="M34.6 194.8L171.9 0l121.6 136.1-258.9 58.7z" fill-opacity=".039"></path>
                                          <path d="M173.9 236.1L59.5 119.8l153.6-12.2-39.2 128.5z" fill-opacity=".129"></path>
                                          <path d="M200.9 219.3l-52-88.9 98.4 10.1-46.4 78.8z" fill-opacity=".051"></path>
                                          <path d="M87.7 215.1L0 150.5l108.2 46.6-20.5 18z" fill-opacity=".2"></path>
                                          <path d="M233.2 104.6l35.3-59.5 10.7 58.7-46 .8z" fill-opacity=".071"></path>
                                      </svg>
                                  </div>

                                  <div v-if="detailsData.status == 1">
                                      <div v-if="moneyJieShu" style="font-size: 50px"
                                           class="d-flex justify-content-center align-items-center">
                                          <money :font-size="'40px'" />
                                          <countTo :decimals="2" :startVal='0'
                                                   :endVal='(item.money)*1'
                                                   :duration='1000'>
                                          </countTo>
                                      </div>
                                      <div v-else>

                                          <img class="content-pk-item-pk-box-item-gun " :name="t.a"
                                               :src="t.skins.cover" alt="dura_alias">
                                          <div class="mt-1" style="font-size: 12px;line-height: 16px">
                                              {{t.skins.name}}<span style="font-size: 14px; color: #2ac88a"><money />{{t.skins.bean}}</span>
                                          </div>
                                      </div>
                                  </div>


                              </div>
                          </div>
                      </div>

                      <div :class="item.success ? ' pk_win_bg': detailsData.status == 2 ? 'pk_lose_bg':''"
                           style=""
                           class="d-md-flex  pk_win_bg align-items-center
                  justify-content-between border-left-0 border-right-0">
                          <div class="d-flex align-items-center">
                              <div class="ml-3 text-overflow-1">{{item.user.name}}</div>

                          </div>
                          <div>
                              <img width="60px" height="60px" class="rounded-pill"
                                   :src="item.user.avatar" alt="">
                          </div>
                          <div v-if="detailsData.status == 0" class="d-md-block d-none">

                  <money /> {{detailsData.total_bean}}
                          </div>
                          <div v-if="detailsData.status == 1" class="d-flex">
                              <span class="d-md-flex d-none">{{detailsData.status_alias}} </span><money class="ml-2"></money>
                              <countTo :decimals="2" :startVal='0' :endVal='(item.money)*1' :duration='1000'></countTo>

                          </div>
                          <div v-if="detailsData.status == 2" class="text-right d-flex">
                              <span class="d-md-block d-none">获取的金币</span> <money class="ml-2"></money> {{item.sum || '0.01'}}
                          </div>
                      </div>


                      <div v-if="detailsData.status == 1" class="d-flex pk_gun_bg flex-wrap w-12 mmmgdijhier">
                          <div class="content-award col-lg-4 col-md-4 col-sm-6 col-12 animate__animated "
                               v-for="(t,i) in item.game_award_pk" :key="i">
                              <img class="content-award-img" :src="t.skins.cover" alt="">
                              <div class="text-overflow-1 text-center" style="font-size: 12px;width: 100px">
                                  {{t.skins.name}}
                              </div>
                              <div class="mt-2 d-flex align-items-center justify-content-center"
                                   style="font-size: 16px; line-height: 16px; color: var(--pk-green) ">
                                  <money font-size="12px"></money>
                                  {{t.skins.bean}}
                              </div>
                          </div>
                      </div>


                      <div v-if="detailsData.status == 2" class="d-flex pk_gun_bg
                    flex-wrap w-12 ">
                          <div class="content-award col-lg-4 col-md-4 col-sm-6 col-12"
                               v-for="(t,i) in item.game_award" :key="i">
                              <div data-v-72eb0163="" class="position-absolute bglogo-e" style="width: 80px; height: 80px; z-index: 2; left: 30%; top: 20%;"></div>
                              <img class="content-award-img " :src="t.skins.cover" alt="">
                              <div class="text-overflow-1 text-center m-auto" style="font-size: 12px;width: 80%">
                                  {{t.skins.name}}
                              </div>
                              <div class="mt-2 d-flex align-items-center justify-content-center"
                                   style="font-size: 16px; line-height: 16px; color: var(--pk-green) ">
                                  <money font-size="12px" style=""></money>
                                  {{t.skins.bean || (fildErr/yessSum).toFixed(2) }}
                              </div>
                          </div>
                      </div>

                  </div>



                  <template v-if="!item">
                      <div :name="index" class="overflow-hidden content-pk-item  pl-0 pr-0 pk_pk_pk">
                          <div class="content-pk-item-pk-box-item w-100 p-0 ">
                              <div style=""></div>
                              <div style="" @click="addPk(index)" class="text-center ">
                                  <button style="--content: '点击加入'; font-size: 24px; background-color: #e94d8b!important;color:#fff"
                                   class="sefl_pk_button">
                                      <div class="self_pk_right"></div>
                                      点击加入
                                      <div class="self_pk_right"></div>
                                  </button>
                              </div>
                          </div>
                      </div>
                  </template>

              </div>
          </div>


          <div v-show="lockBoxGunIsShow">
              <div style=""
                   class="position-fixed pop-up content-pop z-index-100">
                  <div>
                      <div class="h5 mt-3 ml-3">包含以下奖励</div>
                      <div class="d-flex flex-wrap content-pop-lock align-content-start">

                          <div v-for="(item,index) in boxGunListContains" :key="index"
                               class="content-pop-lock-item mb-3 col-lg-3 col-md-3 col-6 ">
                              <img width="100%" class="mx-auto d-block mt-3" :src="item.skins.cover" alt="">
                              <div class="text-center px-3 text-overflow-1">
                                  <div class="gunboxtext ">{{item.skins.name}}</div>
                              </div>
                              <div class="mx-auto d-flex justify-content-between align-items-center  text-center p-2  mt-2 cursor-pointer"
                                   style="">
                                  <div style="font-size: 12px;color:#ddd ">{{item.skins.dura_alias}}</div>
                                  <div style="font-size: 12px; color: #75dc9e; font-weight: 900; ">
                                        <money /> {{item.skins.bean}}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div @click=" lockBoxGunIsShow= false" class="position-absolute" style="right: 20px;top: 30px">
                      <div>
                        <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
                      </div>
                  </div>
              </div>
              <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
          </div>
      </div>
  </div>

</template>

<script>
// 能不动就不要动 
// 能不动就不要动 
// 能不动就不要动 
  import $api from '@/api/pk/index'
  import countTo from 'vue-count-to';

  export default {
    name: "pkweb",
    components:{countTo},
    data() {
      return {
        voiceSwitch:true,
        game_arena_id:'',
        detailsData:{},
        box_num:0,
        indexBox:0,
        openAn:false,
        status:0,
        Against_the_end:false,
        sum:0,
        fildErr:0,
        yessSum:1,
        full_house:0,
        successAndErr:false,
        moneyJieShu:false,
        underway:0,
        round:null,
        detailsDataUnderway:{},
        box_huihe:0,
        time1:null,
        tiem2:null,
        time:null,
        huihe_gun:true,
        game_gun:false,
        settime:null,
        boxGunListContains:[],
        lockBoxGunIsShow:false,
      }
    },
    computed:{
      user_num:{
        get(){
            return this.detailsData.user_num - this.detailsData.game_arena_player.length
        },

      },
    },
    mounted() {
      document.getElementById('open_pk').load()
      this.init()
    },
    created() {
      this.game_arena_id = this.$route.params.id
      this.sum = this.$route.query.sum
      this.underway = this.$route.query.underway
      this.round = this.$route.query.round
      this.initWebSocket()
    },
    beforeDestroy(){
      clearInterval(this.time1)
      clearInterval(this.tiem)
      clearInterval(this.tiem2)
      clearTimeout(this.settime)
      document.getElementById('open_pk').pause()
      this.$router.go(0)
    },
    watch:{
      detailsData: {
        handler(newVal) {
          this.detailsData = newVal
        },
        immediate: true,
        deep: true,
      },

    },
    methods:{
      // 观战的时候显示
      async underwayPk(){
        let {data} = await $api.getPKarenaDetail(this.game_arena_id)
        let resData = JSON.parse(JSON.stringify(data))

        if(this.round>resData.box_num){
          this.round = resData.box_num - 1
        }
        resData.status = 1
        resData.status_alias = '进行中'
        let box_huihe =  this.round*1
        this.box_huihe = JSON.parse(JSON.stringify(box_huihe))
        this.indexBox = box_huihe

        await this.disposalData(resData)
        await this.turn()

      },
      async init(){
        if(this.underway == 1){
          await this.underwayPk()
        }else {
          await this.getPKarenaDetail()
        }
      },
      async getPKarenaDetail(){
        try {
          let {data,code} = await $api.getPKarenaDetail(this.game_arena_id)
          if(code == 500)return
          let obj = this.disposalData(data)
          this.detailsData = obj
          let game_arena_player = JSON.parse(JSON.stringify(data))

          if(this.detailsData.status == 2){
            
            if(obj.win_user_id.length>=2){

              this.yessSum = obj.win_user_id.length
              let sum = 0
              this.detailsData.game_arena_player.forEach((item) => {
                item.game_award.forEach(a => {
                  sum = sum + (a.skins.bean * 1)
                })
              })

              

              this.detailsData.game_arena_player.forEach((item,index) =>{
                item.success = false
                obj.win_user_id.forEach(t =>{
                  if(item.user_id == t){
                    item.success = true
                    item.sum = (sum /  obj.win_user_id.length).toFixed(2) ;
                  }
                })
                if(!item.success){
                  item.game_award.map(a =>{
                    this.fildErr = this.fildErr + (a.skins.bean * 1)
                  })
                  item.game_award = [
                    {
                      skins:{
                        cover:require('@/assets/img/liangliang.png'),
                        name:'凉凉',
                        bean:0.01
                      }
                    }
                  ]
                }else { 
                  item.game_award.push({
                    skins:{
                      cover:require('@/assets/img/money.png'),
                      name:'钱袋子',
                      bean:0
                    }
                  })
                }
                item = game_arena_player.game_arena_player[index]
              })

              
            }else {
              let arrAllList = []
              this.detailsData.game_arena_player.forEach(item =>{
                let arrList = JSON.stringify(item.game_award)
                arrAllList.push(...JSON.parse(arrList))
              })
              this.detailsData.game_arena_player.forEach(item =>{
                item.game_award = arrAllList
                obj.win_user_id.map(t =>{
                  if(item.user_id == t){
                    item.success = true
                    let sum = 0
                    item.game_award.forEach(m =>{
                      sum = sum + (m.skins.bean*1)
                    })
                    item.sum = sum.toFixed(2)

                    return item.game_award
                  }else {
                    item.game_award = [
                      {
                        skins:{
                          cover:require('@/assets/img/liangliang.png'),
                          name:'凉凉',
                          bean:0.01
                        }
                      }
                    ]
                  }
                })
              })
            }
          }
        }catch (e) {
          console.log(e)
        }
        setTimeout(()=>{
          this.successAndErr = true
        },2000)
      },
      disposalData(res){
        let data = JSON.parse(JSON.stringify(res))
        if(this.detailsData.status == 0){
          this.detailsData = data
          return
        }
        data.game_arena_player.forEach((item) =>{
          item.game_arena_box = JSON.parse(JSON.stringify(data.game_arena_box))
          item.game_award_pk = []
          item.money = 0
          item.game_gun = false
          let awar_id = JSON.parse(JSON.stringify(item.game_award))
          let awar_id_arr = []
          awar_id.forEach(i =>{
            awar_id_arr.push(i)
          })
          item.game_arena_box.forEach((e) =>{
            let obj = awar_id_arr.find(t=>{
              if(e.box_id == t.box_id){
                t.box_id = ''
                return e
              }
            })
            let contains = []
            for (let i=0;i<70;i++){
              let index = Math.floor(Math.random()*e.box.contains.length)
              contains.push(e.box.contains[index])
            }
            e.box.contains = [...contains]
            if(obj != undefined){
              obj.level = 1
              e.box.contains.forEach((item) =>{
                if(item.skin_id == obj.skins.id){
                  obj.level = item.level
                }
              })
              obj.a = '1'
              e.box.contains.splice(60,1,obj)
            }
          })
        })
        let arrData = JSON.parse(JSON.stringify(data))

        let arrIndex = []
        arrIndex.length = arrData.user_num
        for(let index = 0;index<arrData.game_arena_player.length;index++){
          let arrDataIndex = arrData.game_arena_player[index]
          arrIndex[arrDataIndex.seat] = arrDataIndex
        }
        arrData.game_arena_player = arrIndex

        this.detailsData = arrData
        return arrData
      },
      anwe_sort(arr){
        let sort = []
        for(let i = 0;i<arr.length;i++){
          let item = arr[Math.floor(Math.random()*arr.length)]
          sort.push(item)
        }
        return sort
      },
      async addPk(item){
        try {
          let obj = {
            game_arena_id: this.game_arena_id,
            seat:item
          }
          let {message,code} = await $api.getPKarenaJoin(obj)
          if(code == 500)return
          if(code == 200){
            this.$notify({
              message: message,
              position: 'top-left'
            });
            this.$store.dispatch('userInfo')
          }

        }catch (e) {
          console.log(e)
        }

      },
      turn(){
        if(this.round == this.detailsData.box_num){
          this.$router.push('/active/mysteryBox/' + this.game_arena_id + '?underway=0&round=' + 0)
          this.$router.go(0)
          return
        }
        if(this.round>0){
          this.indexBox =  this.round*1
          this.$router.push('/active/mysteryBox/' + this.game_arena_id + '?underway=1&round=' + this.round)
        }
        this.settime = setTimeout(()=>{
          this.openAn = true
          if(this.voiceSwitch){
            document.getElementById('open_pk').play()
          }
        },100)
        if(this.detailsData.status == 1){
          this.tiem = setInterval(()=>{
            this.detailsData.game_arena_player.forEach(item =>{
              item.money = ((item.money*1) + (item.game_award[this.indexBox].skins.bean*1)).toFixed(2)
              item.game_award_pk = item.game_award.slice(0,this.indexBox+1)
              item.game_gun = item.game_award[this.indexBox].skins.game_gun
              setTimeout(()=>{
                item.game_gun =false
              },2000)
            })
          },8900)
          this.time1 = setInterval(async ()=>{
            this.indexBox += 1
            if(this.indexBox  >= this.detailsData.box_num){
              try {
                document.getElementById('open_pk').pause()
                this.indexBox = 0
                clearInterval(this.time1)
                clearInterval(this.tiem)
                this.openAn = false
                this.moneyJieShu = true
                this.detailsData.game_arena_player.map(item =>{
                  item.money = 0
                  item.success = false
                  item.gun = false
                  item.game_award_pk.forEach(it =>{
                    it.gun = false
                  })
                  this.detailsData.win_user_id.forEach(t =>{
                    if(item.user_id == t){
                      item.success = true
                    }
                  })
                  return item
                })
                let i = 0
                this.tiem2 = setInterval(()=>{
                  if(i > this.detailsData.game_arena_player.length + 1){
                    this.getPKarenaDetail() 
                    this.detailsData.status = 2
                    clearInterval(this.tiem2)
                  }
                  try {
                    this.detailsData.game_arena_player.forEach(item =>{
                      item.money = ((item.money*1) + (item.game_award[i].skins.bean*1)).toFixed(2)
                    })
                  }catch (e) {
                    i = this.detailsData.game_arena_player.length+2
                    this.getPKarenaDetail()
                    this.detailsData.status = 2
                    clearInterval(this.tiem2)
                  }
                  i++

                },2000)
                if(this.$route.name == 'mysteryBox' ){
                  this.$router.push('/active/mysteryBox/' + this.game_arena_id + '?underway=0&round=' + this.indexBox)
                }
              }catch (e) {
                console.log(e)
              }
            }else {
              this.openAn = false
              setTimeout(()=>{
                this.openAn = true
              },100)
              if(this.$route.name == 'mysteryBox' ){
                this.$router.push('/active/mysteryBox/' + this.game_arena_id + '?underway=1&round=' + this.indexBox)
              }
              if(this.voiceSwitch){
                document.getElementById('open_pk').play()
              }
            }
          },9000)

        }
      },
      timeCeil(Data){
        setInterval(()=>{
          var time1 = Date.parse(Data.updated_at) / 1000
          var time2 = Date.parse(new Date()) / 1000
          let ceil = Math.ceil((time1 + Data.box_num * 10 - time2) / 10)
          Data.ceil = Data.box_num - ceil

        },3000)
      },
      lockBoxGun(item){
        this.lockBoxGunIsShow = true
        this.boxGunListContains = item.box.contains

      },
      initWebSocket(){
        let _this = this
        this.$ws.addEventListener('message',(event) => {
          let data = event.data
          let {Data,MsgType} = JSON.parse(data)
          if(MsgType == "joinGroup" ){
            if(Data.game_arena_id ==_this.game_arena_id){
              let data = JSON.parse(JSON.stringify(_this.detailsData.game_arena_player[0]))
              let {game_arena_box,game_award,game_award_pk,money} = data
              let obj = {
                seat:Data.seat,
                game_arena_id:Data.game_arena_id,
                user:{
                  id:Data.user_id,
                  name:Data.name,
                  avatar:Data.avatar,
                },
                game_arena_box,
                game_award,
                game_award_pk,
                money
              }
              _this.detailsData.game_arena_player.splice(obj.seat,1,obj)
            }
          }
          if(MsgType == "startGroup" && Data.game_arena_id ==_this.game_arena_id){
            _this.detailsData.status = Data.status
            if(this.$route.name == 'mysteryBox' && this.game_arena_id == Data.id){
              this.$router.push('/active/mysteryBox/' + this.game_arena_id + '?underway='+0+'&round=0')
            }
          }
          if(MsgType == "endGroup"){
            if(Data.id ==_this.game_arena_id){
              _this.full_house = JSON.parse(JSON.stringify(Data.status))
              this.timeCeil(Data)
              Data.status = 1
              Data.status_alias = '进行中'
              _this.detailsData.status = 1
              _this.detailsData.status_alias = '进行中'
              if(this.$route.name == 'mysteryBox' && this.game_arena_id == Data.id){
                this.$router.push('/active/mysteryBox/' + this.game_arena_id + '?underway='+1+'&round=0')
                _this.disposalData(Data)
                _this.turn()
              }
            }

          }
        })
      }

    },
  }
</script>

<style scoped lang="scss">

.mmmgdijhier,.mjierofef{
  background: linear-gradient(0deg, rgba(0, 123, 255, 0.42) 0%, rgba(0, 157, 255, 0) 100%);

}

.msdoreiguier{
  background: #0c274781
}

.content-pk-item-pk-box-item{
  padding-bottom: 0px;
  background: linear-gradient(0deg, rgba(0, 123, 255, 0.42) 0%, rgba(0, 157, 255, 0) 100%);

}

.mpktopheader{
  background: linear-gradient(180deg, rgba(25, 132, 208, 0.5) 0%, rgba(66, 77, 181, 0.2) 100%);
    box-sizing: border-box;
    overflow: hidden;
    border: 1px solid;
    -o-border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 19%, #34B8FF 55%, rgba(0, 0, 0, 0) 100%) 1;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 19%, #34B8FF 55%, rgba(0, 0, 0, 0) 100%) 1;
  height: 60px;
  align-items: center;
  > .font-weight-bold{
    display: flex;
    align-items: center;
    > img{
      margin: 0 4px;
    }
  }
  > div:nth-child(2){
    font-size: 16px !important;
  }
}
    .gunboxtext {
        font-size: 12px;
        position: absolute;
        top: 10px;
        color: #ddd
    }
  .active_light{
    position: relative;
    &::before{
      content: '';
      /*background-image: url("../../assets/img/pk/100skins-yes.png");*/
      width: 100%;
      left: 0!important;
      position: absolute;
      top:100%;
      left: 0!important;
      z-index: -1;
      animation: light 1s;
      box-shadow: 0 0 50px 20px rgba(255 ,0 ,0 , .8);
      animation-iteration-count:2;
      opacity: 0;
    }
  }
  .active_light_err{
    position: relative;
    &::before{
      content: '';
      background-repeat: no-repeat;
      background-position: center;

      width: 100%;
      left: 0!important;
      position: absolute;
      top:100%;
      left: 0!important;
      z-index: -1;
      animation: light 1s;
      box-shadow: 0 0 50px 20px rgba(63 ,63 ,63 , .8);
      animation-iteration-count:2;
      opacity: 0;
    }
  }
  .successAndErr{
    display: none;
  }
  .loading-zhuan{
    animation:clockwise_rotation 2.5s linear infinite;
  }

  /* 定义动画 */

  .an .an-item{
    transition: all 7s cubic-bezier(0,0,0,1) .1s;
    transform: translateY(-21000px)
  }
    .content {
        width: 90%;
        margin: 0 auto;

        @media (max-width:1200px) {
            width: 96%;
            left: 2%;
        }

        &-bout {
            height: 150px;
            width: 100%;
            padding: 0 15px;

            &-sum {
                padding: 0 20px 0 0px;

                @media (max-width:1200px) {
                    display: none;
                }
            }

            &-box {
                &-item {
                    margin: 0 10px;

                    & img {
                        @media (max-width:1200px) {
                            width: 70px !important;
                        }
                    }

                    &-index {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 20px;
                        font-size: 12px;
                        background-color: rgb(114, 123, 140);
                    }
                }

                & .active img {
                    transform: scale(1.4);
                    transition: all .5s
                }
            }

            &-add-pk {
                @media (max-width:1200px) {
                    display: none;
                }
            }
        }

        &-pk {
            &-item {
                border-right: 2px solid #000;
                /*margin-top: 50px;*/
                &-sum {
                    height: 80px;
                    padding: 0 30px;
                }

                &-pk {
                    /*background-color: rgba(42,42,42,.2);*/
                    overflow: hidden;
                    height: 350px;
                    position: relative;
                    background-repeat: no-repeat;
                    background-position: center;

                    &::before {
                        /* 中将标识 */
                        /*content: url("../../assets/img/aysmk.png");*/

                    }

                    &-box {
                        height: 250px;

                        &-item {
                            background-color: rgba(47,39,66,.2);
                            /*border-top: 1px solid rgba(251,202,1,1);*/
                            /*background-image: linear-gradient(0deg,rgba(251,202,1,1), rgba(0,0,0,0));*/
                            justify-content: center;
                            align-items: center;
                            display: flex;
                            width: 100%;
                            padding: 10px 30px;
                            height: 350px;
                            /*margin: 0 5px;*/
                            &-gun {
                                height: 150px;

                                @media (max-width: 1200px) {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                &-pk-bg {
                }

                & .active {
                    /*background-image: url("../../assets/img/success.png");*/
                    background-size: 100%;
                }

                & .error {
                    /*background-image: url("../../assets/img/error.png");*/
                    background-size: 100%;
                }
            }
        }

        &-award {
            &-img {
                height: 80px;

                @media (max-width:1500px) {
                    width: 96%;
                    height: auto;
                }
            }

            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px 0;

            &-active {
                &::before {
                    content: '';
                    width: 50%;
                    left: 25%;
                    height: 1px;
                    background-size: 100%;
                    position: absolute;
                    top: -0px;
                    background-color: red;
                    z-index: -1;
                    animation: light 1s;
                    box-shadow: 0 0 10px 2px rgba(255,255,255,.5);
                    animation-iteration-count: 2;
                    opacity: 0;
                }
            }
        }
    }


    .pk-top-header {
        border-bottom: 2px solid #000000;
        background-color: rgba(40,40,59,1)
    }
  .pk_pk_pk{
    /*background: linear-gradient(rgba(15, 14, 43,1),rgba(31, 28, 79,1));*/
  }
  @keyframes light {
    0%{
      opacity: 0;
    }
    50%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  }


  .PK_gun_index{
    background-color: #0e0e1e;
    --gradient-color: 255,255,255;
    --gradient-opacity: 0.2;
    border-style: solid;
    border-width: 1px;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, rgba(var(--gradient-color), 0), rgba(var(--gradient-color), 0) 28%, rgba(var(--gradient-color), var(--gradient-opacity)) 50%, rgba(var(--gradient-color), 0) 74%, rgba(var(--gradient-color), 0) 0%);
  }
    .content-pop {
        width: 800px;
        left: calc(50% - 350px);
        top: 100px;
        height: 650px;

        &-box {
            height: 450px;
            width: 720px;
            margin: 0 auto;
            overflow: scroll;
            overflow-x: hidden;

            &::-webkit-scrollbar {
                display: none;
            }

            &-item {
                border: 1px solid #000000;
                width: 160px !important;
                background-color: #090827;

                &:hover &-add {
                    display: block;
                }

                &-add {
                    display: none;
                }
            }

            & .active {
                background-color: var(--main-yellow);

                &::before {
                }
            }
        }

        &-lock {
            margin-top: 20px;
            height: 550px;
            overflow: scroll;
            overflow-x: hidden;
            padding: 30px;

            &-item {
                position: relative;
                background-color: var(--f-main);
                border: 1px solid #000;
                /*border: 1px solid var(--main-brown);*/
            }
        }

        @media (max-width: 960px) {
            width: 100%;
            left: 0;
            height: 650px;
        }
    }
</style>
