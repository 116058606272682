
import {request} from "../index";
function getPKarenaList(page){
    return request({
        url: `/arena/list?page=${page}`,
        method:'get'
    })
}
function getPKarenaHistory(page){
    return request({
        url: `/arena/history?page=${page}`,
        method:'get'
    })
}

function getPKarenaMyHistory(page){
    return request({
        url: `/arena/me-history?page=${page}`,
        method:'get'
    })
}
function getPKarenaboxList(){
    return request({
        url: `/arena/box-list`,
        method:'get'
    })
}
function getPKarenaCreate(data){
    return request({
        url: `/arena/create`,
        method:'post',
        data
    })
}
function getPKarenaJoin(data){
    return request({
        url: `/arena/join`,
        method:'post',
        data
    })
}


function getPKarenaDetail(game_arena_id){
    return request({
        url: `/arena/detail?game_arena_id=${game_arena_id}`,
        method:'get'
    })
}

function getArenaJoinChannel(data){
    // if(Math.random()*10)return
    return request({
        url: `/arena/join-channel`,
        method:'POST',
        data
    })
}



export default {
    getPKarenaList,
    getArenaJoinChannel,
    getPKarenaHistory,
    getPKarenaMyHistory,
    getPKarenaboxList,
    getPKarenaCreate,
    getPKarenaDetail,
    getPKarenaJoin,

}
