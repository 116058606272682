<template>
    <div class="content py-3 mt-3">
        <div class="h3 text-white font-weight-bold text-center position-relative" style="margin-bottom: 30px;">
            <img src="@/assets/newImg/yzhy.png" height="80" />
        </div>
        <div class="content-box pb-5">
            <div class="d-flex vip m-auto flex-nowrap content-box-table flex-wrap" style="width:1470px">
                <div>
                    <div class="vip-title">VIP奖励等级</div>
                    <div class="vip-title">充值金额</div>
                    <div class="vip-title">升级红包</div>
                    <div class="vip-title border-gray-VIP">充值加成</div>
                    <div class="vip-title">红包领取</div>
                </div>
                <div id="content-scroll" class="content-box-scroll">
                    <div class="d-flex">
                        <div class="d-flex">
                            <div class="vip-con">
                                <img style="height: 20px" :src="require('@/assets/img/nav/wan_vip' + (0) + '.png')"
                                    alt="">
                            </div>
                        </div>
                        <div v-for="(item, index) in VipList" :key="index" class="d-flex">
                            <div class="vip-con" :class="index + 1 === $store.state.user_info.vip_level ? 'bg-bg' : ''">
                                <img style="height: 20px"
                                    :src="require('@/assets/img/nav/wan_vip' + (item.level) + '.png')" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="d-flex">
                            <div class="vip-con">
                                /
                            </div>
                        </div>
                        <div v-for="(item, index) in VipList" :key="index" class="d-flex">
                            <div class="vip-con" :class="index + 1 === $store.state.user_info.vip_level ? 'bg-bg' : ''">
                                {{ item.threshold }}
                            </div>
                        </div>
                    </div>
                    <div class="d-none">
                        <div class="d-flex">
                            <div class="vip-con">
                                /
                            </div>
                        </div>
                        <div class="w-100 d-flex vip-silde d-flex align-items-center pl-2">
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="d-flex">
                            <div class="vip-con">
                                /
                            </div>
                        </div>
                        <div v-for="(item, index) in VipList" :key="index" class="d-flex">
                            <div class="vip-con" :class="index + 1 === $store.state.user_info.vip_level ? 'bg-bg' : ''">
                                <money></money>
                                {{ item.packet }}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="d-flex">
                            <div class="vip-con border-gray-VIP">
                                /
                            </div>
                        </div>
                        <div v-for="(item, index) in VipList" :key="index" class="d-flex"
                            :class="index + 1 === $store.state.user_info.vip_level ? 'bg-bg' : ''">
                            <div class="vip-con" :class="index | borderColor">
                                {{ item.rebate }}%
                            </div>
                        </div>
                    </div>
                    <div class="d-flex souyouhongbao">
                        <div class="w-100">
                            <div class="vip-con-btn">
                                <div class=" w-100">
                                    所有充值红包系统自动发放，请在收支明细中查看
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="highlighted-message text-center ">
                    <div class="highlighted-message-body">
                        您距离升级{{ Viptips.next_level }} 还需充值
                        <money /> {{ Viptips.next_lack }}.将享受 {{ Viptips.next_rebate }} % 充值加成。
                    </div>
                </div>
        </div>

    </div>
</template>

<script>
import $api from '@/api/vip/index'
import Money from '../../common/money/money.vue';
export default {
    name: "index",
    components: { Money },
    data() {
        return {
            VipList: [],
            Viptips: {},
        }
    },
    filters: {
        borderColor(index) {
            if (index <= 1) {
                return 'border-gray-VIP';
            }
            if (index <= 4) {
                return 'border-blue-VIP'
            }
            if (index <= 7) {
                return 'border-purple-VIP'
            }
            if (index == 8) {
                return 'border-red-VIP'
            }
            if (index == 9) {
                return 'border-gold-VIP'
            }
        }
    },
    computed: {

    },
    created() {

    },
    async mounted() {
        await this.getVipList()


    },
    methods: {
        getVipList() {
            $api.getVipList().then(res => {
                this.VipList = res.data.list
                this.Viptips = res.data.tips
                document.getElementById('content-scroll').scrollLeft = this.$store.state.user_info.vip_level * 100
            })
        }
    }
}
</script>

<style scoped lang="scss">
.souyouhongbao {
    width: 100%;

    @media (max-width: 1200px) {
        width: 1100px;
    }
}

.bg-bg {
    background-color: #12588A !important;
}

.desc {
    width: 80%;
    margin: 50px auto;

    @media (max-width: 1200px) {
        justify-content: flex-start;
        width: 90%;
        font-size: 12px !important;
        line-height: 1.3;
    }

    & .item {
        margin-bottom: 50px;

        & .tilte {
            margin-bottom: 20px;
            font-size: 17px;
            color: rgb(255, 255, 255);
        }

        & .info {
            color: rgb(181, 187, 194);
            font-size: 17px;
        }
    }
}

.vip {
    width: auto !important;
    &-title {
        color: #c2c1cf;
        // background-color: #28283b;
        // border-bottom: 1px solid #222144;
        width: 147px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1200px) {
            width: 100px;
        }
    }

    &-silde {
        background-color: #2e2b5b;
        border-right: 1px solid #222144;
        border-bottom: 1px solid #222144
    }

    &-con {
        width: 120px;
        height: 100px;
        padding: 0 10px;
        font-size: 14px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        /*background-color: #30303f;*/
        color: #d4d3dd;
        border-right: 1px solid #222144;
        border-bottom: 1px solid #222144;

        &-btn {
            font-size: 14px;
            text-align: center;
            line-height: 100px;

            // background-color: #30303f;
        }

        &-award {
            width: 80%;
            height: 60%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #333160;
        }

        & .active {
            background-color: red;
            color: #ffffff;
        }
    }
}

.grade {
    @media (max-width: 1200px) {
        font-size: 12px;
    }
}

.input-code {
    @media (max-width: 1200px) {
        width: 150px !important;
    }
}

.tong-height {
    height: 38px;
    line-height: 38px;
}

.side-box {
    width: 204px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5)
}

.content {
    width: 90%;
    margin: 0 auto;

    // background-color: #191926;
    @media (max-width: 1200px) {
        width: 96%;
        font-size: 12px !important;
    }

    &-box {
        &-scroll {
            // background-color: #30303f;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                height: 2px !important;
            }
        }

        &-table {
            background: linear-gradient(180deg, rgba(19, 108, 172, 0.5) 0%, rgba(66, 77, 181, 0.2) 100%);
            box-sizing: border-box;
            border: 1px solid;
            border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 19%, #34B8FF 55%, rgba(0, 0, 0, 0) 100%) 1;
            justify-content: center;

            @media (max-width: 1200px) {
                justify-content: flex-start;

                font-size: 12px !important;
            }
        }
    }
}

.cell {
    padding: 20px;

    @media (max-width: 1200px) {
        padding: 5px;
    }
}

.highlighted-message {
    width: 83%;
    margin: 10px auto;
    padding: 19px 5px 19px 16px;
    // border: 1px solid #fada62;
    border-radius: 8px;
    // background-color: rgba(250, 218, 98, 0.12);

    @media (max-width: 1200px) {
        width: 100%;
    }
}


.border-gray-VIP {
    border-bottom: 3px solid #d2d6ff;
}

.border-blue-VIP {
    border-bottom: 3px solid #5968ff;
}

.border-purple-VIP {
    border-bottom: 3px solid #9e6dff;
}

.border-red-VIP {
    border-bottom: 3px solid #af416e;
}

.border-gold-VIP {
    border-bottom: 3px solid #ffba6b;
}
</style>
