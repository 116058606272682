<template>
  <!-- 组件内的  v-mode 怎么导出  -->
  <!-- 暂时只是接收数据  没有往外导出数据 -->
  <div>
    <div style="display: none">
      输入百分数（0 ~ 1.0）
      <br>
      <input v-model="unconfirmedValue" type="number" min="0" max="1">
      <button  @click="value = unconfirmedValue ">点击开始动画</button>
    </div>
    <!-- r:半径 stroke-width：小条的长度 -->
    <svg width="500" height="500" >
      <defs>
        <linearGradient data-v-e7d1c0c8="" id="greenGradient" x1="100%" y1="100%" x2="10%" y2="10%">
          <stop offset="0%" stop-color="#62cde1"></stop>
          <stop offset="33%" stop-color="#62cde1"></stop>
          <stop offset="66%" stop-color="#62cde1"></stop>
          <stop offset="75%" stop-color="#61ce89"></stop>
          <stop offset="100%" stop-color="#61ce89"></stop>
        </linearGradient>
      </defs>
      <circle
        ref="circle"
        :r="r"
        cx="0"
        cy="0"
        stroke-width="9"
        fill="none"
        stroke="url(#greenGradient)"
        transform="translate(250, 250) rotate(-90)"
        :stroke-dasharray="strokeDasharray"
        class="upgrade__main__circle"></circle>
    </svg>
  </div>
</template>

<script>

  export default {
    props:{
      // 半径
      r:{
        type:Number,
        default:180
      },
      // 未确认的百分比
      unconfirmedValue:{
        type:Number,
        default:0
      },
      // 确认的百分比
      value:{
        type:Number,
        default:0
      },

    },
    data () {

      return {
        // circle 元素
        circleEl: null,
        animation: false
      }
    },
    computed: {
      strokeDasharray: function () {
        let result = ''
        // 圆形周长计算公式为：C = 2 * π * r = π * d 其中r为半径，d为直径。
        let c = 2 * Math.PI * this.r
        // 有值部分的周长
        let valueC = c * this.value
        // 有值部分到结尾部分的周长
        let endC = c - valueC
        // 小短线宽度
        let lineWidth = 2
        // 小短线之间的空隙宽度
        let spaceWidth = 2
        // 计算填充有值部分
        let strokeDasharrayByValueC = (function () {
           result = ''
          var _valueC = 0

          while (_valueC < valueC) {
            // 该属性值可以用空格区分不同的值，多几个空格没关系
            result += ` ${ lineWidth }  ${ spaceWidth } `
            _valueC += (lineWidth + spaceWidth)
          }

          // 最后需要用 "小短线" 进行结尾
          if (result) {
            result += ` ${ spaceWidth } `
          }

          return result
        })()

        return strokeDasharrayByValueC ?
          `${ strokeDasharrayByValueC } ${ endC }` :
          `0 ${ endC }`
      },
    },
    methods: {
      init: function () {
        this.circleEl = this.$refs.circle
      }
    },
    mounted: function () {
      this.init()

    }
  }
</script>

<style scoped>
  circle {
    transition: stroke-dasharray .8s;
  }
</style>
