<template>
  <div id="app">
    <div v-if="wxpage" >
      <img width="100%" height="100%" src="@/assets/img/index/wxpage.png" alt="">
    </div>
    <router-view v-else></router-view>

    <audio preload="auto"  id="mysound" :src="require('@/assets/music/click.mp3')"></audio>
    <audio preload="auto"  id="mouseOverBox" :src="require('@/assets/music/mouseOverBox.mp3')"></audio>
    <audio preload="auto"  id="open" :src="require('@/assets/music/open.mp3')"></audio>
    <audio preload="auto"  id="openLeftBox" :src="require('@/assets/music/openLeftBox.mp3')"></audio>
    <audio preload="auto"  id="openTopAwardList" :src="require('@/assets/music/openTopAwardList.mp3')"></audio>
    <audio preload="auto"  id="error" :src="require('@/assets/music/error.mp3')"></audio>
    <audio preload="auto"  id="succeed" :src="require('@/assets/music/succeed.mp3')"></audio>
    <audio preload="auto"  id="luckey" :src="require('@/assets/music/bgm_zhuanbei.mp3')"></audio>
    <audio preload="auto"  id="open_pk" :src="require('@/assets/music/open-pk.mp3')"></audio>
  </div>
</template>
<script>
  export default {
    data(){
      return{
        wxpage:false
      }
    },
    mounted() {
      // 判断是否是微信中打开的
      if(this.isWeiXin()){
        this.wxpage = true
      }
    },
    methods:{
      isWeiXin() {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
      }
    },
    sockets: {
      connect: function () {
      },
      customEmit: function () {
      }
    },
  }
</script>

<style lang="scss">
  @import "assets/css/bootstrap.min.css";
  @import "assets/css/common.css";
  @import "assets/css/element.css";
  @import "assets/iconfont/iconfont.css";

#app {
  color: #FFFFFF;
}

  .icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }


</style>
