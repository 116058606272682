<template>
    <!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
    <div class=" rounded overflow-hidden cursor-pointer" :style="'width:100%'" >
        <div class="bg-size  bg-repeat position-relative d-flex flex-wrap
        justify-content-center align-items-center" >

            <!-- header -->
            <div class="px-3 text-center text-overflow-1 w-100 d-flex justify-content-between"
                 style="line-height: 24px;font-size: 12px;height: 24px;">

                <div class="text-overflow-1">{{resData.name}}</div>
<!--                <div class="text-overflow-1">{{resData.id || '暂无'}}</div>-->
                <div class="text-overflow-1">{{resData.odds_percent}}</div>

            </div>


            <div class="d-flex justify-content-around align-items-center position-relative"
                 style="height: 100px;width: 100%;" :class="'ggbg2'">
                <div class="position-absolute bglogo-e" style="width: 80px; height: 80px;z-index:2;left:20%;top:20%"></div>
                <img  class="position-relative rounded-img my-md-3"
                     :src="resData.cover" alt="">
                <div class="px-md-0 px-2">
                    <div class="color-yellow font-weight-bold"><money /> {{resData.bean}}</div>
<!--                    <div style="font-size: 12px" class="mt-2 "><i class="el-icon-star-on mr-2"></i>{{resData.odds_percent}}</div>-->
                    <div style="font-size: 12px" class="mt-2 "><i class="el-icon-s-goods mr-2"></i>{{resData.dura_alias || '暂无'}}</div>
                </div>
            </div>
        </div>

        <slot>

        </slot>
    </div>
</template>

<script>
    export default {
        name: "weapon",
        props:{
            // 传输武器整体宽度
            width:{
                type:String,
                default:"14%"
            },
            // 武器显示的高度，宽度和整体宽度是一样的
            heightWeapon:{
                type:String,
                default:"150px"
            },
            // 传输背景 style 对象形式传输
            bgSize:{
                type:Object,
                default(){
                    return {
                        height: '102px'
                    }
                }
            },
            // 武器对应的宽度 高度 带单位 style 对象形式传输
            weaponSize:{
                type:Object,
                default(){
                    return {
                        width: '115px',
                        marginTop:'25px'
                    }
                }
            },
            //    传输的item
            resData:{
                type:Object,
                default(){return {}}
            }
        },
    }
</script>

<style scoped lang="scss">
    .rounded-img{
        height:80%;
        @media (max-width: 1000px) {
            height: 40%;
            width: 40%;
        }
    }
    .cover-content{
    }
    .cover-cover{
        & img{
            transition: all .5s;
        }
        &:hover img{
            transform: translateY(-20px)
        }
    }
    .weapon{
        &-name{
            padding: 10px 0;
        }
    }
</style>
