import { render, staticRenderFns } from "./activehot.vue?vue&type=template&id=d1ba556c&scoped=true"
import script from "./activehot.vue?vue&type=script&lang=js"
export * from "./activehot.vue?vue&type=script&lang=js"
import style0 from "./activehot.vue?vue&type=style&index=0&id=d1ba556c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1ba556c",
  null
  
)

export default component.exports