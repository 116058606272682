<template>
    <div class="mt-5 content ">
        <!-- title -->
        <div class="h3 text-white font-weight-bold text-center my-5 position-relative d-flex justify-content-between align-items-center">
            <div style="font-size: 16px" v-if="details.status != 1">等待中 <i class="el-icon-loading"></i></div>
            <div style="font-size: 16px" v-if="details.status == 1">已结束 </div>
            <div>礼物</div>
            <div style="font-size: 16px" class="px-3">{{details.type===0?'官方':'主播'}}</div>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
            <div class="d-flex col-md-4 py-4 justify-content-between f_ROLL_left"
                 style=";background-image: linear-gradient(rgb(29, 27, 75), rgb(15, 14, 43));">
                <div>
                    <div class="font-weight-bold">{{details.name}}</div>
                    <div class="mb-3 mt-1" style="font-size: 12px">房间名称</div>
                    <div class="text-break py-1 rounded-pill" style="font-size: 12px">
                        GW ID：{{details.id}}</div>
                </div>
                <div class="ROLL-user d-flex align-items-center justify-content-center " style="">
                    <img width="80" height="80" :src="require('@/assets/img/rollImg.png')" alt="">
                </div>
            </div>
            <!-- 中间 -->
            <div v-if="!carry_off_prize" class="col-md-4 my-md-0 my-2 text-center
             d-flex flex-column align-items-center justify-content-center" style="">
                <div style="font-size: 12px">开始派送赠品</div>
                <div class="h4 my-3">{{details.end_time?details.end_time.slice(10,details.end_time.length):'00:00:00'}} </div>
                <div class="font-weight-bold" style="font-size: 14px">{{details.end_time?details.end_time.slice(0,10):'00:00:00'}}</div>
            </div>

            <!-- 右侧 -->
            <div class="d-flex align-items-center col-md-4 f_ROLL_right"
                 style=";background-image: linear-gradient(rgb(29, 27, 75), rgb(15, 14, 43));">
                <div class="ml-md-5 pt-md-0 pt-3 w-100">
                   <div class="d-flex justify-content-around">
                       <div>
                           <div class="font-weight-bold color-blue"><money />{{details.award_bean}}</div>
                           <div class="mb-3 mt-1" style="font-size: 12px">箱子总价值</div>
                       </div>
                       <div>
                           <div class="font-weight-bold color-blue">{{details.awards?details.awards.length:0}}件</div>
                           <div class="mb-3 mt-1" style="font-size: 12px">装备数量</div>
                       </div>
                   </div>
                    <div class="text-center">
                        <div class="font-weight-bold color-blue text-overflow-2" style="font-size: 14px">{{details.describe}}</div>
                        <div class="mb-3 mt-1" style="font-size: 12px">加入条件</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 遍历假人 - 现在是武器 -->
        <div class="mb-5 position-relative d-flex align-items-center w-100 overflow-hidden ROLL-users" style="height: 150px">
            <div class="position-absolute ROLL-users-conter"></div>
            <div v-for="(item,index) in details.awards" :key="index" class="mx-2" style="height: 100px"
            :style="`background-image:url(${require('@/assets/img/f_new/ROLL_user.png')})`">
                <div style="width: 93px;margin-top: 16px;" class="d-flex justify-content-center">
                    <img style="width: 60px;height: 60px;border-radius: 50%;" :src="item.box_record.cover" alt="">
                </div>
            </div>
        </div>

        <!-- 开奖按钮 -->
        <div class="py-2 w-25 mx-auto mb-3" v-if="details.is_pwd == 1" style="border: 1px solid rgb(67, 132, 83); border-radius: 5px; ">
            <input style="width: 360px" class="text-white pl-3" type="password" v-model="pass"
                   placeholder="请输入密码">
        </div>
        <div v-if="token != undefined && details.status != 1 &&addRollHomeJR()" class="cursor-pointer px-4 py-3 f-main-bg font-weight-bold m-auto text-center
         rounded-pill" style="width: 200px" @click="AddRollHome">
            加入
        </div>
        <div v-if="details.status != 1 && !addRollHomeJR()" class="cursor-pointer px-4 py-3 bg-bg-2 font-weight-bold m-auto text-center
         rounded-pill" style="width: 200px" @click="AddRollHome">
            已加入
        </div>
        <div v-if="token == undefined && details.status != 1" class="cursor-pointer px-4 py-3 f-main-bg font-weight-bold m-auto text-center
         rounded-pill" style="width: 200px" @click="AddRollHome">
            请登录
        </div>
        <div v-if="details.status == 1" class="cursor-pointer px-4 py-3 f-main-bg font-weight-bold m-auto text-center
         rounded-pill" style="width: 200px" @click="AddRollHome">
            已结束
        </div>


        <div class="text-center mt-4 op-6" style="font-size: 14px">
            加入条件：{{details.describe}}

        </div>


        <!-- 中奖者 -->
        <div v-if="carry_off_prize || details.status == 1" class=" row mt-lg-4 mt-mt-2 mt-3">
            <div class="mt-5 mb-3 text-center font-weight-bold h4 w-100">
                获奖名单
            </div>
            <div class="col-lg-2 col-md-4 col-6 mb-4" v-for="(item,index) in details.awards" :key="index">
                <div  class="text-center d-flex flex-column justify-content-around align-items-center bg-size-100" style="width: 100%;height: 250px"
                     :style="`background-image: url(${require(`@/assets/img/f_new/ROLL_Z_J-1.png`)})`">
                    <div class="h6" v-if="details.user_id != item.box_record.user.id">{{item.box_record.user.name}}
                    </div>
                    <div style="width: 114px;height: 114px" class="bg-size bg-repeat cursor-pointer position-relative" :style="`background-image: url(${require('@/assets/img/f_new/ROLL_users_2.png')})`">
                        <img width="100%"  class="ROLL-users-user-img" :src="item.box_record.cover" alt="抢">
                        <img class="position-absolute ROLL-users-user-user ROLL-users-user-img" width="70px" height="70px"
                             v-if="details.user_id != item.box_record.user.id"
                             :src="item.box_record.user.avatar" alt="头象">
                    </div>
                    <div class="mb-2" style="font-size: 16px">
                        {{ item.box_record.name}}
                    </div>
                    <div style="font-size: 14px">
                        {{ item.box_record.dura_alias}}
                    </div>
                    <div class="" style="font-size: 12px">
                        价值：<money />{{ item.bean}}
                    </div>
                </div>
            </div>

        </div>

        <div class="mt-5 mb-3 text-center font-weight-bold h3 ">
            箱子包含
        </div>
        <div class="w-100 d-flex px-3">
            <div class="d-flex flex-wrap">
                <div class="mb-3 list-box " style="" v-for="(item,index) in details.awards" :key="index">
                    <div style="width: 95%">
                        <weapon :res-data="item"></weapon>
                    </div>
                </div>
            </div>
        </div>


        <!-- 参与人数 -->
        <div  class="h3 text-white font-weight-bold text-center my-5
        position-relative d-flex justify-content-center align-items-center">
            <div>已加入用户</div>
        </div>
        <div class="d-flex flex-wrap">
            <div class="mb-3 f-Registered-user" style="" v-for="(item,index) in details.users " :key="index">
                <div class="ROLL-users-user d-flex flex-column justify-content-center align-items-center py-4">
                    <div class="d-flex justify-content-center align-items-center"
                    style="width: 114px;height: 114px;background-size: 100%"
                    :style="`background-image: url(${require('@/assets/img/f_new/ROLL_users.png')})`">
                        <img width="100" class="ROLL-users-user-img" :src="item.user.avatar" alt="">
                    </div>
                    <div class="text-center mt-3">
                        {{item.user.name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $api from '@/api/rollHome/index'
    import weapon from './common/weapon'
    import { getToken } from '@/utils/auth'
    export default {
        name: "active",
        components:{
            weapon
        },
        data(){
            return {
                id:'',
                details:{},
                state:'pond',
                pass:'',
                roll:'',
                dataFUQ:0
            }
        },
        created() {
            this.id = this.$route.params.id

        },
        async mounted() {
            let roll = this.$route.query.roll
            this.roll = roll
            this.init()
        
        },
        computed:{
            token(){
                return getToken()
            },
            carry_off_prize(){
                return this.details.status == 1&&this.state != 'pond'&&this.state != 'num'
            }
        },
        methods:{
            async init(){
                await this.getRoomsDetail()

            },
            // 立即加入按钮
            async AddRollHome(){
                try {
                    let {message,code} = await $api.getRoomsJoin({id:this.id,password:this.pass})
                    if(code == 500)return
                    if(code == 200){
                        this.$notify({
                            message: message,
                            type: 'success',
                            position: 'top-left'
                        });
                    }
                    this.init()
                    this.pass = ''
                }catch (e) {
                    this.$store.commit("LOGIN_TOP", true)
                }
            },
            async getRoomsDetail(){
                try {
                    let {data,code,timestamp} =await $api.getRoomsDetail(this.id)
                    if(code == 500)return
                    this.dataFUQ = timestamp*1000
                    if(Date.parse(data.end_time)/1000 - timestamp < 0){
                        data.status = 1
                    }
                    this.details = data
                }catch (e) {
                    console.log(e)
                }
            },
            tabClick(state){
                this.state = state
                this.getRoomsDetail()
            },

          addRollHomeJR(){
                let userId = this.$store.state.user_info
               let user =  this.details.users.findIndex(item =>{
                    return item.user_id == userId.id
                })
                return user === -1 ? true : false
            }
        }
    }
</script>

<style scoped lang="scss">
    .f_ROLL_left{
        clip-path: polygon(0 0, calc(100% - 14%) 0, 100% 100%, 0% 100%)
    }
    .f_ROLL_right{
        clip-path: polygon(14% 0, 100% 0, 100% 100%, 0% 100%);
    }
    @media (max-width:1200px) {
        .f_ROLL_left,.f_ROLL_right{
            clip-path: initial;
        }
    }

    .f-Registered-user{
        width: 20%;
        @media (max-width:1200px) {
            width: 50%
        }
        @media (max-width:1366px) {
            width: 30%
        }
        @media (max-width:960px) {
            width: 50%
        }
    }
    .list-box{
        width: calc(100% / 5);
        @media (max-width: 1600px) {
            width: calc(100% / 5);
        }
        @media (max-width: 1200px) {
            width: calc(100% / 3);
        }
        @media (max-width: 960px) {
            width: calc(100% / 2);
        }
    }
    .ROLL-gun{
        width: 80px;
        height: 80px;
        margin-left: 14%;
    }
    .ROLL-users{
        background-color: #0f0e2b;
        border-style: solid;
        border-width: 1px;
        border-image-slice: 1;
        border-image-source: linear-gradient(to right, rgba(255,255,255, 0), rgba(255,255,255, 0) 28%, rgba(255,255,255,1) 50%, rgba(255,255,255, 0) 74%, rgba(255,255,255, 0) 0%);
        &-user{
            width: 98%;
            background-color: #0f0e2b;
            border-style: solid;
            border-width: 1px;
            border-image-slice: 1;
            border-image-source: linear-gradient(to right, rgba(255,255,255, 0), rgba(255,255,255, 0) 28%, rgba(255,255,255,1) 50%, rgba(255,255,255, 0) 74%, rgba(255,255,255, 0) 0%);
            &-user{
                right: -20px;
                top: 20%;
            }
            &-img{
                clip-path: polygon(25% 10%, 75% 10%, 100% 50%, 75% 90%, 25% 90%, 0% 49%);
            }


        }
        &-conter{

            width: 9.9%;
            position: absolute;
            top: -1px;
            bottom: -1px;
            left: 50%;
            z-index: 3;
            transform: translateX(-50%);
            background-image: linear-gradient(to right, rgba(233, 77, 139, 0.08), transparent 30%, transparent 70%, rgba(233, 77, 139, 0.08));
            border-top: 1px solid rgb(233, 77, 139);
            border-bottom: 1px solid rgb(233, 77, 139);
            border-image: initial;
            border-left: none;
            border-right: none;
            @media (max-width:1000px) {
                width: 48%;
            }
            &::before{
                top: -1px;
                border-width: 7px 8px 0 8px;
                border-color: #e94d8b transparent transparent transparent;
                content: "";
                width: 0px;
                height: 0px;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                z-index: 4;
                border-style: solid;
            }
            &::after{
                bottom: -1px;
                border-width: 0 8px 7px 8px;
                border-color: transparent transparent #e94d8b transparent;
                content: "";
                width: 0px;
                height: 0px;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                z-index: 4;
                border-style: solid;
            }
        }
    }
    .ROLL-user{
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 15px;
        overflow: hidden;
        margin-right: 14%;

        &::before{
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            opacity: 0.5;
            background: linear-gradient(144deg, rgba(57,85,232,0) -11%, rgba(57,85,232,0.6) 111%);

        }
        box-shadow: 0 4px 14px 0 rgba(0,0,0,.3);
    }
    .content{
        width: 80%;
        margin: 0 auto;
        &-top{
            width: 100%;
        }
        @media (max-width:1366px){
            width: 95%;
        }
    }
    .weapon-phone{
        width: 10%;
        @media (max-width:1600px){
            width: 20%;
        }
        @media (max-width:1366px){
            width: 25%;
        }
        @media (max-width:960px){
            width: 33.33%;
        }
        @media (max-width:760px){
            width: 50%;
        }
    }
</style>
