<template>
    <!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
    <div class=" rounded overflow-hidden" :style="'width:100%'" >

        <div class="bg-size  bg-repeat position-relative d-flex flex-wrap
        justify-content-center align-items-center" >

            <!-- header -->
            <div class="px-3 text-center text-overflow-1 w-100 d-flex justify-content-between"
                 style="line-height: 24px;font-size: 12px">
                <!--                {{resData.odds_percent}}-->
                <div class="text-overflow-1" style="font-size: 12px">{{resData.box_record.name}}</div>
                <div class="text-overflow-1">{{resData.box_record.dura_alias}}</div>
            </div>


            <div class="wefwevcwefvoeref d-md-flex pb-3 justify-content-around align-items-center cover-content cover-cover"
                 style="height: 140px;width: 100%;background-size: 100% 100%;"
                 :style="`background-image:url(${require('@/assets/img/f_new/open_bg.png')})`">
                <img height="70%"  class="position-relative my-3"
                     v-lazy="resData.box_record.cover" alt="">

                <div class="d-md-block d-flex align-items-center justify-content-between px-md-0 px-2">
                    <div class="color-yellow font-weight-bold weoifhweofwefwoncj"><money /> {{resData.bean}}</div>
                </div>

            </div>
            <div class="w-100 d-none">
                <div class="text-overflow-1" style="font-size: 12px">{{resData.box_record.name}}</div>
            </div>
        </div>

        <slot>

        </slot>
    </div>
</template>

<script>
    export default {
        name: "weapon",
        props:{
            width:{
                type:String,
                default:"14%"
            },
            heightWeapon:{
                type:String,
                default:"150px"
            },
            bgSize:{
                type:Object,
                default(){
                    return {
                        height: '102px'
                    }
                }
            },
            weaponSize:{
                type:Object,
                default(){
                    return {
                        width: '115px',
                        marginTop:'25px'
                    }
                }
            },
            resData:{
                type:Object,
                default(){return {}}
            }
        },
    }
</script>

<style scoped lang="scss">
    .cover-content{
    }

    @media (max-width:600px) {
        .weoifhweofwefwoncj{
            display: flex;
            align-items: center;
            margin:  0 auto;
            margin-top: -10px;
        }
        .wefwevcwefvoeref{
            text-align: center;
        }
    }
    .cover-cover{
        & img{
            transition: all .5s;
        }
        &:hover img{
            transform: translateY(-20px)
        }
    }
    .weapon{
        &-name{
            padding: 10px 0;
        }
    }
</style>
