<template>
    <div class="d-flex">

        <!-- 内容区域 -->
        <div class="content mt-4">
            <div class="d-flex align-items-center">
                <div class="d-flex ">
                    <div class="bg-yellow px-lg-4 px-2 mr-2 py-1 cursor-pointer" @click="allList">
                        全选
                    </div>
                    <div class="bg-dark-brown px-lg-4 px-2 mr-2 py-1 cursor-pointer" @click="recycle">
                        回收{{recyleMoney}}
                    </div>
                    <div class="bg-brown px-lg-4 px-2 mr-2 py-1 cursor-pointer" @click="extract">
                        提取
                    </div>
                    <div class=" px-lg-4 px-2 mr-2 py-1 cursor-pointer border-yellow">
                        武器总件数：{{total}}
                    </div>
                    <div class=" px-lg-4 mr-2 py-1 d-sm-block d-none cursor-pointer border-yellow">
                        当前页武器总价：{{money || 0}}
                    </div>
                </div>
            </div>
            <div class="flex-wrap row mt-3 d-flex muserBox" >
                <div class="content-hover col-lg-2 col-md-2 col-sm-3 col-4 mb-3 px-1" @click="itemOne(item,index)"
                     v-for="(item,index) in StorageList" :key="index">
                    <weapon class="position-relative" :class="item.isShow ? 'active' :''"
                            :res-data="item"> </weapon>
                </div>
                <!-- 为空的时候显示 -->
                <vacancy v-if="StorageList.length == 0" />

            </div>
            <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="total"
                    :page-size="pageSize"
                    @current-change="currentChange"
            >
            </el-pagination>
        </div>


        <!-- 背包物品 - 显示退回原因 -->
        <el-dialog
                custom-class="pop-up"
                :show-close="false"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                :visible.sync="ReturnReason"
                width="700px">
            <div class="p-4 text-white">
                <div>
                    <p>
                        {{back_message}}
                    </p>
                </div>
                <div class=" ml-auto color-dark-brown" style="width: 180px">
                    <bottom-bg @click.native="ReturnReason = false" title="确认"></bottom-bg>
                </div>
                <div @click="ReturnReason = false" class="position-absolute" style="right: 20px;top: 30px">
                    <div>
                        <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
                    </div>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import bottomBg from "@/components/bottomBg";
    import weapon from "./common/weapon";
    import $api from '@/api/user/index'
    export default {
        name: "index",
        components:{
            bottomBg,
            weapon,

        },
        data(){
            return {
                ReturnReason:false,
                StorageList:[],
                total:0,
                pageSize:0,
                back_message:''
            }
        },
        computed:{
            money(){
                let benr = 0
                this.StorageList.forEach(item =>{
                    benr += (item.bean)*1
                })
                return benr.toFixed(2)
            },
            recyleMoney(){
                let benr = 0
                this.StorageList.forEach(item =>{
                   if(item.isShow== true){
                       benr += (item.bean)*1
                   }
                })
                return benr.toFixed(2)
            }

        },
        created() {
        },
        mounted() {
            this.init()
        },
        methods:{
            init(){
                this.getStorage()
            },
            shoppinng(item,index){
                this.ReturnReason = true
                console.log(item,index)
            },
            async getStorage(page = 1){
                try {
                    let {data,code} = await $api.getStorage(page)
                    if(code == 500)return
                    data.data.forEach(item =>{
                        if(item.back_message != ''){
                            console.log(123)
                        }
                        item.isShow = false
                    })
                    this.StorageList = data.data
                    this.total = data.total
                    this.pageSize = data.per_page
                }catch (e) {
                    console.log(e)
                }
            },
            currentChange(val){
                this.getStorage(val)
                window.scrollTo(0,0);
            },
            allList(){
                this.StorageList.forEach(item =>{
                    item.isShow = !item.isShow
                })
            },
            itemOne(item){
                if(item.back_message != ''){
                    this.ReturnReason = true
                    this.back_message = item.back_message
                }
                item.isShow = !item.isShow
            },
            async recycle(){
                let arr = []
                try {
                    this.StorageList.forEach(item=>{
                        if(item.isShow){
                            arr.push(item.id)
                        }
                    })
                    let {message,code} = await $api.getCash({data:arr})
                    if(code == 500)return
                    if(code ==200){
                        this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success', position: 'top-left'
                        });
                    }

                    this.getStorage()
                    this.$store.dispatch('userInfo')
                }catch (e) {
                    console.log(e)
                }

            },
            async extract(){
                let arr = []
                this.StorageList.forEach(item=>{
                    if(item.isShow){
                        arr.push(item.id)
                    }
                })
                try {
                    let {message ,code} = await $api.getExtract({data:arr})
                    if(code == 500)return
                    if(code ==200){
                        this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success', position: 'top-left'
                        });
                    }
                    this.getStorage()
                    this.$store.dispatch('userInfo')
                }catch (e) {
                    console.log(e)
                }

            },
        }
    }
</script>

<style scoped lang="scss">

.muserBox{
    background: linear-gradient(180deg, rgba(19, 108, 172, 0.1) 0%, rgba(66, 77, 181, 0.1) 100%);
}
    .side-box {
        width: 204px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,.5)
    }
    .content {
        width: 80%;
        margin: 0 auto;
        @media (max-width: 1200px) {
            width: 96%;
            font-size: 12px;
        }
        &-hover{
            & .active{
                position: relative;
                &::before{
                    content: url("../../assets/img/hover.png");
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    z-index: 999;
                }
            }
        }
    }

</style>
