import {request} from "../index";

function getRebBackList(){
    return request({
        url: `/red`,
        method:'get'
    })
}
function getOpenRebBack(data){
    return request({
        url: `/red/open`,
        method:'post',
        data
    })
}

function taskReceive1(){
    return request({
        url: `/task?tab=2`,
        method:'get',
    })
}

function taskReceiven(tab){
    return request({
        url: `/task?tab=${tab}`,
        method:'get',
    })
}



export default {
    getRebBackList,
    getOpenRebBack,
    taskReceive1,
    taskReceiven
}
