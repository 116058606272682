<template>
  <!-- :style="'background-image: url(' + require('@/assets/image/index/rollBg.png') + ');'" -->
  <router-link tag="div" :to="'/active/rollHome/' + item.id"
               class=" w-100 py-3"
               :class="item.status==0?'':'filter-grayscale-1 '">
<!-- V3 -->
    <div class="roll-item-wrap py-2 px-3 mrollitemwrap">
      <div class="d-flex align-items-center justify-content-start ">
        <div class="mimg">
          <img :src="item.user.avatar" alt="">
        </div>
        <div class="font-weight-bold roll-item-wrap-top text-overflow-1 ml-2 minfotit">
          <h2>{{item.name}}</h2>
          <p>{{item.end_time}}</p>
          <p class="mrollp">{{item.describe}}</p>
        </div>
      </div>
      <div class="roll-item-wrap-tow d-flex justify-content-around text-center mt-3">
        <div>
          <div class="roll-item-wrap-tow-title" >{{item.awards_count}}</div>
          <div class="mp2">饰品数量</div>
        </div>
        <div>
          <div class="roll-item-wrap-tow-title" > {{item.join_number}}/{{item.people_number}}</div>
          <div class="mp2">参与人数</div>
        </div>
        <div>
          <div class="roll-item-wrap-tow-title" >{{ item.award_bean }}</div>
          <div class="mp2">奖池价值</div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-3">
        <img src="@/assets/newImg/lmgo.png" height="60px" style="margin-top: 20px;" />
      </div>
      <!-- 枪支 -->
      <div class="w-100 mt-3 d-flex" >
        <div class="col-lg-4 col-4 px-1" v-for="(t,index) in item.awards" :key="index">
          <div slot="reference" class="roll-item-wrap-cover px-1">
            <img width="100%" style="height: 80px" class="rounded "
                 :src="t.box_record.cover" alt="">
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
  export default {
    name: "yoyoRollList",
    props:{
      item:Object,
    },
    data(){
      return {
        end_time:''
      }
    },
    async mounted(){
      this.showtime()
    },
    methods:{
     
    }
  }
</script>

<style scoped lang="scss">

.mp2{
  font-size: 12px;
}
.minfotit{
  height: 80px;
  > h2 {
    margin-bottom: 6px;
  }
  > p {
    font-size: 12px;
    color: #43FFEC;
  }
  > .mrollp{
    color: #ccc;
    margin-top: 4px;
  }
}
.mimg{
  height: 80px;
  width: 60px;
  margin-right: 10px;
  > img{
    height: 60px;
  width: 60px;
  }
}

  .roll-item-wrap{
    font-size: 12px;
    position: relative;
    // background: url('../../../assets/imgV2/roll/item_bg.png') center no-repeat;
    background-size: 100% 100%;
    &-top{
      font-size: 18px;
    }
    &-btn{
      width: 150px;
      height: 40px;
      color: #000;
      text-align: center;
      line-height: 40px;
      font-size: 16px;
      background-image: url("../../../assets/imgV2/roll/btn.png");
      background-size: 100% 100%;
    }
    &-tow{
      font-size: 18px;
      &-title{
        margin-bottom: 10px;
        color: #43FFEC;
        font-weight: bold;
        font-size: 16px;
      }
    }
    &-end{
      font-size: 14px;
    }
    &-cover{
      background: url('../../../assets/imgV2/roll/cover.png') center no-repeat;
      background-size: 100% 100%;
    }
  }

  .mrollitemwrap{
    border-radius: 10px;
opacity: 1;

background: linear-gradient(180deg, rgba(19, 108, 172, 0.5) 0%, rgba(66, 77, 181, 0.2) 100%);

box-sizing: border-box;
border: 1px solid ;
padding: 20px !important;
border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 19%, #34B8FF 55%, rgba(0, 0, 0, 0) 100%) 1;
  }

  /* 旧的 */
  .roll-add{
    background-color: var(--main-blue);
  }
  .content{
    &-con{
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url("../../../assets/imgV2/roll/index-roll-item-bg.png");
      border: 1px solid transparent;
      transition: all .5s;
      &:hover{
        border: 1px solid var(--main-blue);
      }
    }
    &-item{
      border: 1px solid transparent;
      transition:all .5s;
      overflow: hidden;
      &:hover{
        border: 1px solid var(--pan-main);
        border-radius: 10px;
      }
      &:hover a{
        border: 2px solid var(--pan-main);
        border-radius: 15px;
      }
      &:hover .content-roll-item-time{
        transition:all .5s;
        color: var(--pan-main);
      }
      .box_record{
        transition:all .5s;
      }
      &:hover .box_record{
        transform: scale(1.2);
      }
    }
    &-roll{
      width: 100%;
      @media (max-width: 1200px) {
        overflow-x: scroll;
      }
    }
    /* 新加 */
    .border-green{
      border: 2px solid #48e082;
      box-shadow: 0px 0px 10px 2px #48e082;
    }
    .border-red{
      border: 2px solid #ff4861;
      box-shadow: 0px 0px 10px 2px #ff4861;
    }
    .roll-btn{
      border: 1px solid #ff4861!important;
      background-color: #241118!important;
    }
    &-top{
      border-radius: 20px;
      padding: 20px 0;
      background-image: linear-gradient(0deg, #27272e, #08080a);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &-item{
      &-conter{
        width: 160px;
        height: 160px;
        background-color: #18181e;
        border-radius: 50%;
        //padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition:all .5s;
        @media (max-width: 960px) {
          width: 120px;
          height: 120px;

        }
        &-cover{
          width: 100%;
        }
        &-user{
          transition:all .5s;
          width: 60px;
          height: 60px;
          padding: 5px;
          position: absolute;
          left: -30px;
          bottom: 10px;
          border-radius: 50%;
          overflow: hidden;
          background-color: #131318;
          & img{
            width: 100%;
            height: 100%;
          }
        }
        &:hover &-user{
          transform: scale(1.5) rotate(-30deg);
        }
        &:hover{
          transform: rotate(30deg);
        }
      }
    }
    &-bottom{
      display: flex;
      padding: .7rem;
      &-left{
        padding: 10px;
        border-radius: 10px;
        background-color: #08080a;
      }
      &-right{
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        border: 1px solid #48e082;
        //background-color: rgba(17,35,25);

      }
    }
  }
  .roll-top{
  }
  .content-item-conte-cover-img{
    background-color: #101426 ;
    padding: 10px;
  }
  .roll-user{
    color: #ffffff;
    bottom: -5px;
    width: 70px;
    text-align: center;
    border-radius: 5px;
    padding: 4px 0;
    background-color: #f19919;
    @media (max-width: 960px) {
      padding: 1px 0;
      bottom: -8px;
    }
  }
</style>
