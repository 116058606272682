<template>
    <div class="content">
        <div class="mtoptitole h3 text-white font-weight-bold text-center my-5">
            <div>
                <img src="@/assets/newImg/thsj.jpg" class="kkksdnfmmmsdkstop" />
            </div>
            <!-- <div class="cursor-pointer color-yellow text-center" style="font-size: 16px" @click="playingMethod = true">玩法介绍
            </div> -->
        </div>

        <img width="1600" v-if="sUserAgent == 'web'" src="@/assets/newImg/thjs.png" />
        <img width="100%" class="mderfimg" v-if="sUserAgent != 'web'" src="@/assets/newImg/sjgz.png" />

        <div class="mzhbox">
            <div class="left">
                <h2>可兑换的饰品<span class="mregjiergjepor">(饰品相差价格以G币反到您的账户余额)</span></h2>

                <div class="d-flex flex-wrap px-md-4  px-14 pb-3 f-hechengwupinList nmboxxsd nmboxxsd1"
                    v-if="zh_list.length != 0">
                    <div class=" col-lg-4 col-md-4 col-4 mb-2 px-0 py-1 msdorewfe" v-for="(item, index) in zh_list"
                        :key="index + 'a'" style="padding:0px!important;text-align: center;"
                        @click="nmboxxsd1Click(item.id)">
                        <div :class="{ active: skins_id == item.id }"
                            class="bg-size-100 bg-position-center position-relative"
                            style="border: solid 1px #3A6E99;padding: 10px;border-radius: 4px;">
                            <div class="position-absolute bglogo-e"
                                style="width: 60px; height: 60px;z-index:98;position:absolute;top:25%;left:25%;">
                            </div>
                            <div class="d-none">
                                <el-checkbox v-model="checked"></el-checkbox>
                            </div>
                            <div class="" style="text-align: center">
                                <img class="w-100 mhimgimgs mhimgimgs1" :src="item.cover" alt="">
                                <p class="ptext" style="font-size: 12px;margin-bottom: 4px;">{{ item.name.replace(/\s+/g,
                                    '')}}</p>
                                <p class="pewfoijehirgegf" v-if="sUserAgent!='web'">{{ item.dura_alias }}</p>
                            </div>
                            <div class="d-flex justify-content-between mrefjeoirfefr"
                                style="font-size:12px;align-items: center; ">
                                <div class="mmoneymoen">
                                    <money />{{ item.bean }}
                                </div>
                                <div v-if="sUserAgent=='web'">
                                    {{ item.dura_alias }}
                                </div>
                            </div>

                            <div
                                class="position-absolute compound-hove-name top-0 left-0 right-0 bottom-0 px-3 cursor-pointer">
                                <div class="d-flex justify-content-center align-items-center flex-column w-100"
                                    style="height: 100%;font-size: 12px">
                                    <div style="font-size: 14px" class="mb-2 font-weight-bold">
                                        {{ item.dura_alias }}
                                    </div>
                                    <div style="line-height: 1.5">{{ item.name }}</div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <el-pagination v-if="zh_list.length != 0" class="merfhpage" background layout="prev, pager, next"
                    :total="total1" :page-size="pageSize1" @current-change="currentChange1">
                </el-pagination>

                <div v-if="zh_list.length == 0">
                    <img class="smiallimgsdsd" src="@/assets/newImg/zw.png" height="300" />
                </div>
            </div>
            <a @click="get_zh_list(1)" v-if="sUserAgent == 'web'">获取可兑换饰品</a>
            <div class="getsadjwioehf" v-if="sUserAgent != 'web'">
                <a @click="get_zh_list(1)">获取可兑换饰品</a>
            </div>
            <div class="right mrightajdw">
                <h2>已选中的饰品 <span>总计:{{ money.IntentionMoney.toFixed(2) }}</span></h2>

                <div class="d-flex flex-wrap px-md-4  px-14 pb-3 f-hechengwupinList nmboxxsd"
                    v-if="IntentionToSsynthetic.length != 0">
                    <div class=" col-lg-4 col-md-4 col-4 mb-2 px-0 py-1 msdorewfe msdorewfe1"
                        v-for="(item, index) in IntentionToSsynthetic" :key="index + 'a'"
                        @click="IntentionGun(item, index)" style="padding:0px!important;text-align: center;">
                        <div :class="`position-relative border-${item.lv} ggbg${item.lv} p-2`"
                            class="bg-size-100 bg-position-center">
                            <div class="position-absolute bglogo-e"
                                style="width: 60px; height: 60px;z-index:98;position:absolute;top:25%;left:25%;">
                            </div>
                            <div class="d-none">
                                <el-checkbox v-model="checked"></el-checkbox>
                            </div>
                            <div class="" style="text-align: center">
                                <img class="w-100 mhimgimgs" :src="item.cover" alt="">
                            </div>
                            <div class="pcsdeowfjwef">{{ item.name.replace(/\s+/g, '') }}</div>
                            <p class="pewfoijehirgegf" v-if="sUserAgent!='web'">{{ item.dura_alias }}</p>
                            
                            <div class="d-flex justify-content-between" style="font-size:12px ">
                                <div class="mmoneymoen">
                                    <money />{{ item.bean }}
                                </div>
                                <div v-if="sUserAgent=='web'">
                                    {{ item.dura_alias }}
                                </div>
                            </div>

                            <div v-if="sUserAgent=='web'"
                                class="position-absolute compound-hove-name top-0 left-0 right-0 bottom-0 px-3 cursor-pointer">
                                <div class="d-flex justify-content-center align-items-center flex-column w-100"
                                    style="height: 100%;font-size: 12px">
                                    <div style="font-size: 14px" class="mb-2 font-weight-bold">
                                        {{ item.dura_alias }}
                                    </div>
                                    <div style="line-height: 1.5">{{ item.name }}</div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


                <div v-if="IntentionToSsynthetic.length == 0">
                    <img class="smiallimgsdsd" src="@/assets/newImg/xz.png" height="300" />
                </div>

                <img src="@/assets/newImg/qrzh.png" @click="SyntheticItemsAdd" height="60px" />
            </div>
        </div>

        <div class="f-content-box d-flex flex-wrap p-md-3 mksdjrifejf">
            <!-- <div class="col-lg-4 col-md-6 col-12 mcombg" style="height: 100%">


                <div class="p-md-4 p-1">
                    <div class="d-flex justify-content-between flex-wrap" style="">
                        <div class="mtitcom">
                            <div>
                                <span style="font-size: 12px;margin-right: 10px;">
                                    件数
                                </span>
                                <span class="h5" style="margin-right:5px;font-size: 18px;">
                                    {{ IntentionToSsynthetic.length }}/9
                                </span>
                            </div>

                            <div class="mzjssd">
                                <span style="font-size: 12px;margin-right: 10px;">总价</span>
                                <span class="h5" style="margin-left:5px">
                                    <money />{{ money.IntentionMoney.toFixed(2) }}
                                </span>
                            </div>

                        </div>
                        
                    </div>
                </div>



                <div class="d-flex flex-wrap px-md-4  px-14 pb-3 f-hechengwupinList nmboxxsd">
                    <div class=" col-lg-4 col-md-4 col-4 mb-2 px-0 py-1 msdorewfe" v-for="(item, index) in IntentionToSsynthetic"
                        :key="index + 'a'" @click="IntentionGun(item, index)" style="padding:0px!important;text-align: center;">
                        <div class="m-auto" style="width: 100%;height: 145px">
                            <img :src="item.cover" alt="" height="80%" class="f-content-img w-100 mfconimg">
                            <div class="text-center">
                                <money />{{ item.bean }}
                            </div>
                        </div>
                    </div>
                    <template v-if="IntentionToSsynthetic.length < 9">
                        <div class="col-lg-4 col-md-4 col-4 mb-2 px-0 msdorewfe" style=""
                            v-for="(item, index) in (9 - IntentionToSsynthetic.length)" :key="index">
                            <div class="m-auto" style="width: 100%; height: 145px ">
                                
                            </div>
                        </div>
                    </template>
</div>

<div style="margin-bottom:15px">
    <div class="text-center my-3 mmmsdeettt">
        您将获得的皮肤价格区间
    </div>
    <div class="d-flex justify-content-between mjjdfwhf" style="font-size: 0.9rem">
        <div class="msdkeerrer" style="">
            最低 <span class="h6 molvse">
                <money />{{ (money.IntentionMoney * 0.5).toFixed(2) }}
            </span>
        </div>
        <div class="mmxian">

        </div>
        <div class="msdkeerrer">
            最高 <span class="h6 molvse">
                <money />{{ (money.IntentionMoney * 5).toFixed(2) }}
            </span>
        </div>
    </div>
</div>

<div class="mbtnsd">
    <img src="@/assets/newImg/hyzx.png" @click="SyntheticItemsAdd" />
</div>

</div> -->
            <div class="col-lg-8 col-md-6 col-12 mskdjeiwfhwif">
                <div class="">
                    <div class="px-md-4 px-1 mmsdsdere" style="">
                        <div class="py-3 d-flex justify-content-between align-items-end mtjsdep"
                            style="font-size: 20px;">
                            <div class="mh3">添加我的饰品</div>
                            <div class="mh3 mh1">您有{{ total }}个皮肤</div>
                        </div>
                        <!-- <div class="mb-2 mshdej">
                            您有{{ StorageList.length }}个皮肤
                        </div> -->
                        <div class="w-100 overflow-auto row p-md-3 p-1 m-auto position-relative"
                            :class="StorageList.length == 0 ? 'd-flex justify-content-center align-content-center' : ''"
                            :style="StorageList.length == 0 ? 'height:570px' : ''">
                            <div @click="weaponGun(item, index)" style=""
                                class="col-lg-2 col-md-3 col-4 col-4 px-1 compound-hove mb-2"
                                v-for="(item, index) in StorageList" :key="index">
                                <div :class="`position-relative border-${item.lv} ggbg${item.lv} p-2`"
                                    class="bg-size-100 bg-position-center">
                                    <div class="position-absolute bglogo-e"
                                        style="width: 60px; height: 60px;z-index:98;position:absolute;top:25%;left:25%;">
                                    </div>
                                    <div class="d-none">
                                        <el-checkbox v-model="checked"></el-checkbox>
                                    </div>
                                    <div class="" style="text-align: center">
                                        <img class="w-100 mhimgimgs" :src="item.cover" alt="">
                                    </div>
                                    <div class="pcsdeowfjwef">{{ item.name.replace(/\s+/g, '') }}</div>
                                    <p class="pewfoijehirgegf" v-if="sUserAgent!='web'">{{ item.dura_alias }}</p>
                                    <div class="meerferfritouhbr d-flex justify-content-between" style="font-size:12px ">
                                        <div class="mmoneymoen">
                                            <money />{{ item.bean }}
                                        </div>
                                        <div v-if="sUserAgent=='web'">
                                            {{ item.dura_alias }}
                                        </div>
                                    </div>

                                    <div
                                        class="position-absolute compound-hove-name top-0 left-0 right-0 bottom-0 px-3 cursor-pointer">
                                        <div class="d-flex justify-content-center align-items-center flex-column w-100"
                                            style="height: 100%;font-size: 12px">
                                            <div style="font-size: 14px" class="mb-2 font-weight-bold">
                                                {{ item.dura_alias }}
                                            </div>
                                            <div style="line-height: 1.5">{{ item.name }}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div v-if="StorageList.length == 0" class="position-absolute">
                                <div class="d-flex flex-column justify-content-center align-items-center w-100"
                                    style="height: 570px;position: relative;">
                                    <img class="filter-grayscale-1 smiallimgsdsd"
                                        :src="require('@/assets/newImg/mysp.png')" alt="">

                                    <router-link to="/" class="f-border px-5 py-3 text-center rounded-pill mbtnopenbox">
                                        去开箱
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize"
                            @current-change="currentChange">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- 玩法介绍 -->
        <div class="" v-if="playingMethod">
            <div style="width: 700px;left: calc(50% - 350px);top:15vh"
                class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">
                <div class="text-white p-md-4 p-2" style="line-height: 2.0">

                    <p>1.选择饰品，使用您以前的饰品或购买我们提供的材料饰品进行合成。</p>

                    <p>2.合成获得随机饰品，饰品价格是合成总价的50%-200%</p>


                </div>
                <div @click="playingMethod = false" class="position-absolute" style="right: 20px;top: 30px">
                    <div>
                        <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
                    </div>
                </div>

            </div>
            <div class="position-fixed op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
        </div>
        <!-- 合成弹窗 -->
        <div class="" v-if="svgaIsShow">
            <div style="width: 700px;left: calc(50% - 350px);top:15vh"
                class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">
                <div>
                    <div class="d-flex flex-column align-items-center justify-content-center py-5 flex-wrap">
                        <div>
                            <img :src="compoundSucceed.cover" style="width: 300px;" alt="">
                        </div>
                        <div class="text-center">
                            <div>{{ compoundSucceed.name }}</div>
                            <div class="mt-2">{{ compoundSucceed.dura_alias }}</div>
                            <div class="mt-2">
                                <money />{{ compoundSucceed.bean }}
                            </div>

                        </div>
                    </div>
                    <div class="d-flex px-5 justify-content-center mt-3">
                        <div @click="svgaIsShow = false" style="width: 250px"
                            class=" text-white text-center f_btn_green cursor-pointer">
                            放入仓库
                        </div>
                    </div>
                    <div @click="svgaIsShow = false" class="position-absolute" style="right: 20px;top: 30px">
                        <div>
                            <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
                        </div>
                    </div>

                </div>
            </div>
            <div class="position-fixed op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
        </div>
    </div>
</template>

<script>
import $api from '@/api/compound/index'
import sUserAgent from '@/assets/js/common.js'
export default {
    name: "index",
    components: {
    },
    data() {
        return {
            checked: false,
            SyntheticItems: false,
            svgaIsShow: false,
            pitchYou: false,
            sideBoxList: [],
            StorageList: [],
            IntentionToSsynthetic: [],
            synthesisList: [],
            total: 0,
            pageSize: 0,

            total1: 0,
            pageSize1: 0,

            totalSYN: 0,
            pageSizeSYN: 0,
            weaponGunCompound: '',
            money: {
                weaponMoney: 0,
                IntentionMoney: 0
            },
            IntentionWeponGun: {},
            compoundSucceed: {},
            svgaIsShowYuan: false,
            playingMethod: false,

            zh_list: [],
            skins_id: ''
        }
    },
    computed: {
        moneyIntentionMoney: {
            get() {
                let sum = this.money.IntentionMoney * 5
                return sum.toFixed(2)
            },
            set(vla) {
                return vla
            }
        },
        sUserAgent() {
            return sUserAgent.sUserAgent()
        }
    },
    watch: {},
    created() {
    },
    mounted() {
        this.init()
    },
    methods: {
        nmboxxsd1Click(id) {
            this.skins_id = id
        },
        async get_zh_list(page) {
            if (this.money.IntentionMoney.toFixed(2) == 0) {
                this.$notify({
                    title: '警告',
                    message: '请选择饰品',
                    type: 'warning',
                    position: 'top-left'
                });
                return false;
            }
            let { data, code } = await $api.zh_list(page, this.money.IntentionMoney.toFixed(2))
            if (code == 500) return
            this.zh_list = data.data;
            this.total1 = data.total
            this.pageSize1 = data.per_page
        },
        init() {
            this.get_box_hot()
            this.get_storage()
            this.get_synthesis_list()
        },
        // 初始化svga
        svgaInit(data) {
            let player = new this.$SVGA.Player('#SyntheticItems');
            let parser = new this.$SVGA.Parser('#SyntheticItems');
            parser.load('/svga/animated/SyntheticItems.svga', function (videoItem) {
                player.setVideoItem(videoItem);
                player.setImage(data.cover, 'img_22569')
                player.setText({
                    text: data.name,
                    family: 'Arial',
                    size: "24px",
                    color: "#ffe0a4",
                    offset: { x: 0.0, y: 0.0 }
                }, 'img_22569 ');
                player.loops = 1;
                player.clearsAfterStop = false;
                player.fillMode = 'Forward';
                player.startAnimation();
            })
        },
        SyntheticItemsList() {
            this.SyntheticItems = true

        },
        SyntheticItemsOff() {
            this.SyntheticItems = false
        },
        SyntheticItemsWeapon(item) {
            this.IntentionWeponGun = item
            this.weaponGunCompound = item.cover
            this.money.weaponMoney = item.bean
            this.pitchYou = true
            this.SyntheticItems = false
        },
        async SyntheticItemsAdd() {
            if (!this.skins_id) {
                this.$notify({
                    title: '警告',
                    message: '请选中可兑换的饰品',
                    type: 'warning',
                    position: 'top-left'
                });
                return false;
            }
            if (this.svgaIsShow == true) {
                this.svgaIsShowYuan = false
                this.IntentionToSsynthetic = []
                this.weaponGunCompound = ''
                this.money.IntentionMoney = 0
                this.money.weaponMoney = 0
                this.IntentionWeponGun.name = ''
                this.svgaIsShow = false
                return
            }
            try {
                let arr = []
                this.IntentionToSsynthetic.forEach(item => {
                    arr.push(item.id)
                })
                let compound = {
                    "in_ids": arr,
                    skins_id: this.skins_id
                }
                let data = await $api.zh_open(compound)
                if (data.code == 500) return
                if (data.message != "OK") {
                    this.svgaIsShowYuan = true
                    setTimeout(() => {

                        this.compoundSucceed = {
                            cover: require('@/assets/img/commone/ZZ.png'),
                            name: '合成失败'
                        }
                        document.getElementById('error').play()
                        this.svgaIsShow = true
                        this.svgaIsShowYuan = false
                        this.IntentionToSsynthetic = []
                        this.weaponGunCompound = ''
                        this.money.IntentionMoney = 0
                        this.money.weaponMoney = 0
                        this.IntentionWeponGun.name = ''
                    }, 1000)
                } else {
                    this.svgaIsShowYuan = true
                    setTimeout(() => {
                        this.svgaIsShow = true
                        this.compoundSucceed = data.data
                        this.svgaIsShowYuan = false
                        this.IntentionToSsynthetic = []
                        this.weaponGunCompound = ''
                        this.money.IntentionMoney = 0
                        this.money.weaponMoney = 0
                        this.IntentionWeponGun.name = ''
                        document.getElementById('succeed').play();
                        this.zh_list = [];
                        this.total1 = 0;
                        this.pageSize1 = 0;
                    }, 1000)
                }


                this.get_storage()

            } catch (e) {
                console.log(e)
            }

        },
        async get_box_hot() {
            try {
                let { data, code } = await $api.get_box_hot()
                if (code == 500) return
                this.sideBoxList = data
            } catch (e) {
                console.log(e)
            }
        },
        sideBox(item) {
            this.$router.push('/active/' + item.id)
        },
        async get_storage(page = 1) {
            try {
                let { data, code } = await $api.get_storage(page)
                if (code == 500) return
                this.StorageList = data.data
                this.total = data.total
                this.pageSize = data.per_page
            } catch (e) {
                console.log(e)
            }
        },
        currentChange(val) {
            this.get_storage(val)
            // window.scrollTo(0, 0);
        },

        currentChange1(val) {
            this.get_zh_list(val)

        },
        currentChangeSYN(val) {
            this.get_synthesis_list(val)
            window.scrollTo(0, 0);
        },
        // 点击个人仓库内的枪
        weaponGun(item, index) {
            if (this.IntentionToSsynthetic.length == 9) {
                this.$notify({
                    title: '警告',
                    message: '最多添加九件物品',
                    type: 'warning',
                    position: 'top-left'
                });
                return;
            }
            let indexGun = this.IntentionToSsynthetic.findIndex(i => {
                return i.id == item.id
            })
            if (indexGun != '-1') {
                this.$notify({
                    title: '警告',
                    message: '此物品已添加，请勿重复添加',
                    type: 'warning',
                    position: 'top-left'
                });
                this.StorageList.splice(index, 1)
                return
            }
            this.money.IntentionMoney += (item.bean) * 1
            this.IntentionToSsynthetic.push(item)
            this.StorageList.splice(index, 1)
            this.zh_list = [];
            this.total1 = 0
            this.pageSize1 = 0

        },
        IntentionGun(item, index) {
            this.money.IntentionMoney -= (item.bean) * 1
            this.StorageList.push(item)
            this.IntentionToSsynthetic.splice(index, 1)

            this.zh_list = [];
            this.total1 = 0
            this.pageSize1 = 0
        },
        async get_synthesis_list(val = 1) {
            try {
                let { data, code } = await $api.get_synthesis_list(val)
                if (code == 500) return
                this.synthesisList = data.data
                this.totalSYN = data.total
                this.pageSizeSYN = data.per_page
            } catch (e) {
                console.log(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.pcsdeowfjwef {
    font-size: 12px;
    margin-bottom: 8px;
    text-align: center;

    @media (max-width:600px) {
        white-space: nowrap;
        overflow: hidden;
        width: 85px;
        text-overflow: ellipsis;
    }
}

.mregjiergjepor {
    font-size: 16px;
    padding-left: 8px;
    color: rgb(255, 170, 0);

    @media (max-width:600px) {
        font-size: 12px;
    }
}

.merfhpage {
    height: 40px !important;
    margin-top: 30px !important;
    margin-bottom: 0px !important;
}

@media (max-width:600px) {
    .pewfoijehirgegf{
        font-size: 12px;
        font-weight: 100;
        margin-top: -6px;
        text-align: center;
    }
    .meerferfritouhbr{
        justify-content: center !important;
    }
    .mtoptitole {
        margin-top: -10px !important;
        margin-bottom: 10px;
        text-align: center;
    }

    .getsadjwioehf {
        background-color: inherit !important;
        border: none !important;
        height: 60px !important;
        display: flex;
        justify-content: center;
        margin-bottom: 6px !important;

        >a {
            background: linear-gradient(270deg, #085889 0%, #1F7EB9 100%);
            display: block;
            border-radius: 2px;
            padding: 0px 20px;
            line-height: 40px;
            height: 42px;
            width: 180px;
            text-align: center
        }

    }

    .mrightajdw {
        height: 580px !important;

        >div {
            >img {
                height: 200px;
                margin-top: -60px;
            }
        }
    }

    .mrefjeoirfefr {
        justify-content: center !important;
    }

    .mderfimg {
        margin: 0 12px;
        width: calc(100% - 24px);

    }
}


.filter-grayscale-1 {
    height: 480px;
    filter: inherit;
}

.mbtnopenbox {
    background: linear-gradient(270deg, #085889 0%, #1F7EB9 100%);
    height: 60px;
    bottom: 130px;
    line-height: 23px;
    font-weight: 100;
    font-size: 18px;
    border-radius: 4px !important;
    position: absolute;
}

@media (max-width:600px) {
    .ptext {
        white-space: nowrap;
        /* 确保文本不会换行 */
        overflow: hidden;
        /* 隐藏超出容器的文本 */
        text-overflow: ellipsis;
        /* 当文本超出容器时显示省略号 */
        width: 80px;
        margin: 0 auto;
    }

    .smiallimgsdsd {
        height: 220px;
    }
}

.mzhbox {
    position: relative;
    display: flex;
    margin-top: 40px;

    >a {
        background: linear-gradient(270deg, #085889 0%, #1F7EB9 100%);
        position: absolute;
        left: 572px;
        top: 36px;
        border-radius: 2px;
        padding: 12px 20px;
    }

    @media (max-width:600px) {
        display: inherit;
        margin: 10px;

        >div:first-child {
            margin-bottom: 0px !important;
        }
    }

    >div {
        position: relative;
        width: 48%;
        border: 1px solid;
        -o-border-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 19%, #34B8FF 55%, rgba(0, 0, 0, 0) 100%) 1;
        border-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 19%, #34B8FF 55%, rgba(0, 0, 0, 0) 100%) 1;
        margin-right: 4%;
        padding: 40px;
        background-color: #10214cc8;
        height: 690px;

        @media (max-width:600px) {
            width: 100%;
            padding: 12px;
            height: 640px;
            margin-bottom: 20px;

        }

        >h2 {
            font-weight: 100;
            font-size: 24px;
            color: #ddd;
            margin-bottom: 30px;

            @media (max-width:600px) {
                margin-top: 12px;
                margin-bottom: 20px;
                font-size: 18px;
            }
        }

        >div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 400px;
        }

        >.nmboxxsd {
            justify-content: left;
            align-content: flex-start;
        }

        >.nmboxxsd1 {
            height: 500px;
            overflow-y: scroll;
            @media (max-width:600px) {
                height: 470px;
            }

            .active {
                border: solid 1px red !important;
            }
        }
    }

    >div:last-child {
        margin-right: 0px;

        >img {
            position: absolute;
            bottom: 20px;
            left: 50%;
            margin-left: -115.4px;
        }
    }
}

.mksdjrifejf {
    width: 1600px;
    padding: 0 !important;
    margin: 0px;
    border-radius: 0px;
    background-color: #10214cc8;
    border: 1px solid;
    margin-top: 40px;
    -o-border-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 19%, #34B8FF 55%, rgba(0, 0, 0, 0) 100%) 1;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 19%, #34B8FF 55%, rgba(0, 0, 0, 0) 100%) 1;

    >.col-lg-8 {
        flex: 0 0 1600px;
        max-width: inherit;
        padding: 0px;

        @media (max-width:600px) {
            flex: 0 0 100%;
        }
    }
}

@media (max-width:600px) {
    .mksdjrifejf {
        width: calc(100% - 20px);
        padding: 0 10px;
        margin: 10px;

        >div {
            >div {
                margin-top: 10px;
            }
        }
    }

    .mskdjeiwfhwif {
        padding: 0 0px !important;
    }
}

.kkksdnfmmmsdkstop {
    margin-top: 40px !important;

    @media (max-width:600px) {
        margin-top: -80px !important;
        height: 40px;
    }
}

.mfconimg {
    height: 80px;
    width: auto !important;
}

.mhimgimgs {
    height: 98px;
    width: auto !important;

    @media (max-width:600px) {
        width: auto !important;
        height: 60px !important;
    }
}

.mhimgimgs1 {
    @media (max-width:600px) {
        height: 62px !important;
        width: auto !important;
    }
}

.mmoneymoen {
    display: flex;
    align-items: center;
    justify-content: center;
}


.el-pagination {
    margin-bottom: 40px !important;
}


.mh3 {
    font-weight: bold;
    font-style: italic;

    @media (max-width:600px) {
        font-size: 18px;
    }
}

.mtjsdep {
    display: flex;
    margin-top: 16px;
    justify-content: space-between;

    @media (max-width:600px) {
        padding: 0 10px;
    }

    >.mh1 {
        color: #ccc;
        font-style: normal;
    }
}

.mmsdsdere {
    // border-radius: 10px;
    opacity: 1;

    // background: linear-gradient(180deg, rgba(13, 78, 124, 0.5) 0%, rgba(36, 47, 146, 0.2) 100%);

    box-sizing: border-box;
    // border: 1px solid ;
    // border-image: linear-gradient(270deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 19%, #34B8FF 55%, rgba(0, 0, 0, 0) 100%) 1;
}

.mshdej {
    margin-bottom: 20px !important;
}

.mbtnsd {
    text-align: center;
    height: 90px;

    >img {
        height: 60px;
    }
}

.mjjdfwhf {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.mmxian {
    height: 4px;
    width: 69px;
    height: 3px;
    background: linear-gradient(90deg, #5A8CFF 0%, #53CAE2 100%);
}

.msdkeerrer {
    display: flex;
    align-items: center;

    >.molvse {
        margin-left: 12px;
        color: #fff;

        >.money {}
    }
}

.molvse {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
}

.mmmsdeettt {
    font-weight: bold;
    margin-bottom: 40px !important;
    margin-top: 40px !important;
}

.nmboxxsd {
    padding: 0px !important;

    >.msdorewfe {
        margin-bottom: 12px !important;
        margin-right: 12px;
        flex: 0 0 32%;

        @media (max-width:600px) {
            flex: 0 0 32%;
            margin-right: 2%;
        }
    }

    >.msdorewfe:nth-child(3n) {
        border-right: none;
        margin-right: 0px;
    }

    >.msdorewfe:nth-child(7),
    >.msdorewfe:nth-child(8),
    >.msdorewfe:nth-child(9) {
        border-bottom: none;
    }

    @media (max-width:600px) {
        >.msdorewfe1 {}
    }
}

.msdorewfe {
    margin-bottom: 0px !important;
}

.mzjssd {
    display: flex;
    align-items: center;

    >.h5 {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        font-size: 18px;
    }
}

.mtitcom {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.mcombg {
    background: linear-gradient(180deg, rgba(19, 108, 172, 0.5) 0%, rgba(66, 77, 181, 0.2) 100%);
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 19%, #34B8FF 55%, rgba(0, 0, 0, 0) 100%) 1;
}

.f_btn_green {
    padding: 10px 20px;
    border: 1px solid rgb(67, 132, 83);
    transition: all .5s;
    background-color: rgb(67, 132, 83);
    border-radius: 5px;

    @media (max-width:1000px) {
        margin: 8px 0;
    }

}

.f_btn_green:hover {
    background-color: #1a1a27;
    box-shadow: 0 0 5px rgba(67, 132, 83, .3);
    // border: 1px solid rgb(117 228 144);
}

@media (max-width:1200px) {
    .f-hechengwupinList {
        /*height: 180px;*/
        /*overflow-y: scroll;*/
    }
}

.f-commone-gun {
    width: 20% !important;
}

@media (max-width:1200px) {
    .f-commone-gun {
        width: 30% !important;
        height: auto !important;
    }
}

@media (max-width:960px) {
    .f-commone-gun {
        width: 50% !important;
        height: auto !important;
    }
}

.up_left,
.up_right {
    width: calc(50% - 125px)
}

@media (max-width: 1250px) {

    .up_left,
    .up_right {
        width: 100%;
    }

    .up_conter {
        margin: 0 auto;
    }
}

.compound-hove {
    &-name {
        background-color: rgba(9, 8, 39, .6);
        display: none;
    }

    &:hover &-name {
        display: block;
    }
}

.caiqiedingbu {
    clip-path: polygon(0 0, calc(100% - 300px) 0, calc(100% - (300px - 40px)) 47px, 100% 47px, 100% 100%, 0 100%);
}

.up-pifu:nth-child(1) {
    transform: scale(.7);
}

.up-pifu:nth-child(2) {
    transform: scale(.8);
}

.up-pifu:nth-child(3) {
    transform: scale(.7);
}

.compound_add_bg {
    background: linear-gradient(270.39deg, hsla(0, 0%, 100%, .05) .34%, rgba(254, 253, 253, 0) 99.67%);
    clip-path: polygon(0 0, 75% 0, 100% 50%, 75% 100%, 0 100%);
    width: 106px;
    height: 106px;
    margin: 10px 10px;

    @media (max-width: 960px) {
        width: 90px;
        height: 90px;
    }
}

.bg-brown-con {
    background-color: rgba(25, 34, 45, .2);
    border: 1px solid rgba(25, 34, 45, 1);
}

/* 中间那个圆 */
.yuan {
    /*animation: pitchYouBox 5s infinite alternate;*/
    transition: all 5.5s cubic-bezier(0, 0, 0, 1);
    transform: rotate(3600deg)
}

/* 两侧的块 */
.rotateX {
    /*transition:all 5.5s cubic-bezier(0,0,0,1);*/
    /*transform: rotateX(720deg)*/
    /*animation: pitchYouBox 1s infinite alternate;*/
}

.heightt {
    /*height: 400px;*/
    /*width: 107%;*/
    /*height: 100%;*/
    padding: 10px;
    overflow: auto;
}

.hecheng {
    width: 100px;
    text-align: center;
    z-index: 9;
    height: 50px;
    background: rgba(0, 0, 0, .6);
    border-radius: 5px;
    line-height: 50px;
    margin: 0 auto;
}

.side-box {
    width: 200px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .5)
}

.content-left {
    overflow: hidden;

    &-box {
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            /* Chrome去除滚动条 */
            display: none;
        }

        ;

        height: 360px;
        /*width: 107%;*/
        /*height: 100%;*/
        padding: 10px;
        overflow: auto;

        @media (max-width: 960px) {
            height: 150px;
        }
    }
}

.content-center {
    overflow: hidden;

    &-box {
        /*background:url("../../assets/img/commone/bg.png");*/
        height: 100%;
        width: 107%;
    }

    &-gun {
        width: 80%;
        top: 10%;
        left: 10%;
    }
}

.content-right {
    overflow: hidden;

    & div:nth-child(1) {
        width: 107%;
        height: 100%;
        padding: 10px;
        overflow: auto;
    }
}

.content {
    width: 1600px;
    margin: 0 auto;

    @media (max-width: 1200px) {
        width: 100%;
    }

    &-explain {
        position: relative;
        padding-left: 20px;
        line-height: 1.4;
        font-size: 14px;

        &::before {
            content: '';
            width: 4px;
            height: 78px;
            background-color: yellow;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &-place {
        &-left {
            width: 720px;
            height: 200px;
            padding: 10px;
        }

        &-righr {
            width: 700px;
        }

    }
}

.pitchYou {
    animation: pitchYouGun 1.8s infinite alternate;
}

.pitchYouBox {
    transform: rotate(5deg)
        /*animation: pitchYouBox 1s infinite alternate;*/
}

@keyframes pitchYouGun {
    to {
        transform: translateY(-60px);
    }

    from {
        transform: translateY(20px);
    }
}

@keyframes pitchYouBox {
    0% {
        transform: rotate(360deg)
    }

    25% {
        transform: rotate(180deg)
    }

    50% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(-360deg)
    }
}
</style>
