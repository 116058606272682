<template>
  <div class="content">
    <div class="h3 text-white font-weight-bold text-center my-5 mehwfiwhfwhef">
      <img src="@/assets/newImg/hhuor.png" height="80" />
    </div>
    <div class="mls">
      <div class="">
        <h2>{{ user.promotion_level }}</h2>
        <p>你的等级</p>
      </div>
      <div class="">
        <h2>{{ user_info.inviter != null ? '' + user_info.inviter.name : '暂无' }}</h2>
        <p>被引荐者</p>
      </div>
      <div class="">
        <h2>{{ user.people_invited_num }}人</h2>
        <p>邀请总人数</p>
      </div>
      <div class="">
        <h2>{{ user.promotion ? user.promotion.rebate : '0.00' }}%</h2>
        <p>当前返佣比例</p>
      </div>
      <div class="">
        <h2>
          <money /> {{ (user.invite_total_recharge) }}
        </h2>
        <p>下线总充值</p>
      </div>
    </div>
    
    <div class="row">
      <div class="col-md-6 pr-2">
        <div class="d-flex align-items-center px-2 input mhhr" style="">
          
          <div class=" px-3 pt-3" style="width: 80%">
            <div class="msdwfeewfefwe" style="font-size: 12px">你的合伙人链接</div>
            <div class="py-1">
              {{ invite_url }}{{ user.invite_code }}
            </div>
            <div style="font-size: 12px" class="cursor-pointer" @click="authCopy">
               <img src="@/assets/newImg/file-copy-2-fill.png" height="28px" />
            </div>
          </div>
          
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex align-items-center px-2 input mhhr" style="">
          <div class=" px-3 pt-3" style="width: 80%">
            <div class="msdwfeewfefwe" style="font-size: 12px">你的合伙人编号</div>
            <div>
              <input type="text" class="w-100 text-white" v-model="user.invite_code">
            </div>
            <div class="text-right cursor-pointer" style="font-size: 12px;width: 50px" @click="amendCopy">
              <img src="@/assets/newImg/customer-service-2-fill.png" height="28px" />
            </div>
          </div>
          
        </div>
      </div>
    </div>



    <!-- <div class="text-center mt-5">
      总返佣：
      <money /> {{ (user.invite_total_recharge * user.promotion.rebate) * (user.promotion.level) / 100 || '0' }}
    </div> -->
    <!-- <div class="highlighted-message text-center my-5">
      <p class="highlighted-message-body">主播合作请联系客服。请勿恶意推广，aaskins保留最终解释权。需要帮助请联系{{ web_info.qq_group_number }}</p>
    </div> -->
    <div class="h3 text-white font-weight-bold text-center my-5 mhhrdj">
      合伙人等级
    </div>
    <div class="mt-3  ">
      <table cellspacing="0" class="mtabss" cellpadding="0" border="0" style="width: 100%;">
        <colgroup>
          <col width="50">
          <col width="300">
          <col width="300">
          <col width="300">
        </colgroup>
        <thead class="text-danger">
          <tr class="grade">
            <th colspan="1" rowspan="1">
              <div class="cell">级别</div>
            </th>
            <th colspan="1" rowspan="1">
              <div class="cell">下级需要</div>
            </th>
            <th colspan="1" rowspan="1">
              <div class="cell">充值返佣</div>
            </th>
            <th colspan="1" rowspan="1">
              <div class="cell">说明</div>
            </th>
          </tr>
        </thead>
        <tr class="grade" v-for="(item, index) in list" :key="index">
          <td rowspan="1" colspan="1">
            <div class="cell d-flex align-items-center">
              <div class="mr-2 text-center rounded-circle"
                style="font-size: 12px;width: 30px;height: 30px;line-height: 30px;">
                {{ item.name }}</div>
            </div>
          </td>
          <td rowspan="1" colspan="1">
            <div class="cell" style="display: flex;align-self: center;">

              <money />{{ item.invite_total }}
            </div>
          </td>
          <td rowspan="1" colspan="1">
            <div class="cell">{{ item.rebate + "%" }}</div>
          </td>
          <td rowspan="1" colspan="1">
            {{ item.description }}
          </td>
        </tr>
      </table>
    </div>
    <p style="text-align: center;margin-top: 40px;">主播合作请联系客服。请勿恶意推广，g1csgo保留最终解释权。需要帮助请联系</p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import $api from '@/api/user/index'

export default {
  name: "index",
  components: {},
  data() {
    return {
      invite_url: '//www.aaskins.cn/?ref_code=',
      resData: {},
      list: [],
      user: {},


    }
  },
  computed: {
    invite_url_mode() {
      return this.invite_url + this.user.invite_code
    },
    ...mapState(['user_info', 'web_info'])
  },
  created() {
    setTimeout(() => {
      this.resData = this.user_info
    }, 500)
  },
  mounted() {
    this.init()
    setTimeout(() => {
      let host = window.location.host;
      this.invite_url = 'https://' + host + '/?ref_code='
    }, 100)
  },
  methods: {
    init() {
      this.getPromote()
    },
    authCopy() {
      this.$common.CopyText({ content: this.invite_url + this.resData.invite_code })
    },
    async getPromote() {
      try {
        let { data, code } = await $api.getPromote()
        if (code == 500) return
        this.list = data.list
        this.user = data.user
      } catch (e) {
        console.log(e)
      }
    },
    async amendCopy() {

    }
  }
}
</script>

<style scoped lang="scss">

.mehwfiwhfwhef{
  margin-top: 40px !important;
  margin-bottom: 20px !important;
  @media (max-width:600px) {
    margin-top: 0px !important;
  }
}

.msdwfeewfefwe{
  padding-right: 18px ;
}

@media (max-width:600px) {
  .col-md-6{
    padding-left: 0px;
  }

  .mb-2, .my-2{
    margin-bottom: 0px !important;
  }

  .msdwfeewfefwe{
    padding-left: 12px;
    padding-right: 8px;
  }
}

.mtabss{
  background: rgba(0, 0, 0, 0.1);

box-sizing: border-box;
border: 0px solid #0686FF;
}

.mhhrdj{
  text-align: left !important;
    margin-top: 40px !important;
    font-style: italic;
    @media (max-width:600px) {
      margin-top: 20px !important;
      font-weight: bold;
      text-align: center !important;
      margin-bottom: 20px !important;
    }
}

.mhhr{
  background: rgba(0, 0, 0, 0.2);

box-sizing: border-box;
border-width: 0px 0px 0px 4px;
border-style: solid;
border-color: #0686FF;
justify-content: space-between;
@media (max-width:600px) {
  border-color: transparent;
  padding-left: 0px !important;
}
 > div{
  display: flex;
  height: 60px;
  align-items: center;
  padding-top: 2px !important;
  @media (max-width:600px) {
    padding-left: 0px !important;
  }
  > div:first-child{
    color: #7ABEFF;
    font-size: 14px !important;
    // width: 160px;
    @media (max-width:600px) {
      flex: 0 0 120px; 
      
    }
  }
  > div:last-child{
    position: absolute;
    right: 40px;
  }
 }
}
.mls {
  display: flex;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.1);
  padding: 20px 40px;
  margin-bottom: 40px;
  flex-wrap: wrap;

  box-sizing: border-box;
  border-width: 0px 0px 2px 0px;
  border-style: solid;
  border-color: #0686FF;

  >div {
    text-align: center;
    @media (max-width:600px) {
      flex: 0 0 50%;
      margin-bottom:30px;
    }
    >p {
      
      font-size: 20px;
      font-weight: bold;
      color: #7ABEFF;
      font-size: 14px;
      @media (max-width:600px) {
        font-weight: 300;
      }
    }
    > h2 {
      color: #fff;
      margin-bottom: 20px;
      font-size: 30px;
    }
  }
}

.grade {
  @media (max-width: 1200px) {
    font-size: 12px;
  }
}

.input-code {
  @media (max-width: 1200px) {
    width: 150px !important;
  }
}

.tong-height {
  height: 38px;
  line-height: 38px;
}

.side-box {
  width: 204px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5)
}

.content {
  width: 90%;
  margin: 0 auto;

  @media (max-width: 1200px) {
    width: 96%;
    font-size: 12px !important;
  }
}

.cell {
  padding: 20px;

  @media (max-width: 1200px) {
    padding: 20px 10px;
    align-items: center;
  }
}

.highlighted-message {
  padding: 19px 5px 19px 16px;
  border: 1px solid #fada62;
  border-radius: 8px;
  background-color: rgba(250, 218, 98, 0.12);
}</style>
