<template>
    <div class="myDragon">
        <div class="back" @click="$router.go(-1)">返回首页</div>
        <div class="rule" @click="maskStatus = true">活动详情</div>
        <div class="mBox">
            <div class="left">
                <div class="topTitle">
                    粽子排名
                </div>
                <div class="ranking">
                    <div>
                        <div class="bg">
                            <img v-if="ranking[1]" :src="ranking[1].user.avatar" />
                            <img v-else src="@/assets/zz/default.png" />
                        </div>
                        <img src="@/assets/zz/two.png" />
                        <div class="name">{{ ranking[1] && ranking[1].user.name || '虚位以待' }}</div>
                        <div class="desc">获得{{ ranking[1] && ranking[1].num || 0 }}个粽子</div>
                    </div>
                    <div>
                        <div class="bg">
                            <img v-if="ranking[0]" :src="ranking[0].user.avatar" />
                            <img v-else src="@/assets/zz/default.png" />
                        </div>
                        <img src="@/assets/zz/one.png" />
                        <div class="name">{{ ranking[0] && ranking[0].user.name || '虚位以待' }}</div>
                        <div class="desc">获得{{ ranking[0] && ranking[0].num || 0 }}个粽子</div>
                    </div>
                    <div>
                        <div class="bg">
                            <img v-if="ranking[2]" :src="ranking[2].user.avatar" />
                            <img v-else src="@/assets/zz/default.png" />
                        </div>
                        <img src="@/assets/zz/three.png" />
                        <div class="name">{{ ranking[2] && ranking[2].user.name || '虚位以待' }}</div>
                        <div class="desc">获得{{ ranking[2] && ranking[2].num || 0 }}个粽子</div>
                    </div>
                </div>

            </div>
            <div class="right">
                <div class="rankList">
                    <div v-if="rankList.length" class="ls" v-for="(item, index) in rankList">
                        <div class="left">
                            <span>{{ index + 4 }}</span>
                            <div class="img">
                                <img :src="item.user.avatar" />
                            </div>
                            <span class="name">{{ item.user.name }}</span>
                        </div>
                        <div class="right">
                            <img src="@/assets/zz/zz.png" />
                            {{ item.num }}
                        </div>
                    </div>
                    <div v-if="!rankList.length" class="noData">- 暂无数据 -</div>
                </div>
                <div class="rankSelf">
                    <div class="left">
                        <div class="img">
                            <img :src="user_info.avatar" />
                        </div>
                        <span>{{ user_info.name }} <span>自己</span></span>
                    </div>
                    <div class="right">
                        <img src="@/assets/zz/zz.png" />
                        {{ myNum }}
                    </div>
                </div>
            </div>
        </div>
        <div class="topTitle">
                    奖 品
                </div>
        <div class="prizeBox">
            <!-- skinsList -->
            <div class="ls" v-for="(item, index) in skinsList">
                <img class="bg" src="@/assets/zz/zzlsbg.png" />
                <img class="weap" :src="item.cover" height="40px" width="40px" />
                <div class="money">
                    <img src="@/assets/img/shandian_ceshi.png" />{{ item.bean }}
                </div>
                <div class="pized" v-if="(index + 1) != 31 && (index + 1) != 32">{{ index + 1 }}奖</div>
                <div class="pized" v-if="(index + 1) == 31">31-40奖</div>
                <div class="pized" v-if="(index + 1) == 32">41-50奖</div>
                <div class="name">{{ item.name.replace(/\s+/g, '') }}</div>
                <div class="desc">({{ item.dura_alias }})</div>
            </div>
            <div class="cyPrize">
            <img src="@/assets/zz/cyj.png" />
            <p>用户参与奖,1-2个CDK,随机发放。</p>
        </div>
        </div>
        

        <div class="mask" v-if="maskStatus">
            <div>
                <div class="model">
                    <h4>G1CSGO祝大家端午安康！！！</h4>
                    <p>&nbsp;&nbsp;为了回馈大家一直对G1CSGO的支持，给大家带来端午特别福利，只要参与就有奖励！
                        粽叶飘香情系端午活动详情：
                    </p>
                    <p>&nbsp;&nbsp;开箱“端午”系列箱子，可获得对应的粽子数量，粽子的排名对应相应的饰品
                    </p>
                    <p>&nbsp;&nbsp;开箱“粽叶飘香”宝箱1次可获得5个粽子</p>
                    <p>&nbsp;&nbsp;开箱“龙舟竞渡”宝箱1次可获得11个粽子
                    </p>
                    <p>&nbsp;&nbsp;开箱“五月端阳”宝箱1次可获得23个粽子

                    </p>
                    <p>&nbsp;&nbsp;开箱“阖家团圆”宝箱1次可获得47个粽子


                    </p>
                    <p>&nbsp;&nbsp;开箱“端午安康”宝箱1次可获得95个粽子



                    </p>
                    <p>&nbsp;&nbsp;此活动在6月30日晚上12点结束，届时统计排名获得的饰品将在7月1日晚上12点前发放到您在G1CSGO的仓库！



                    </p>
                </div>
                <img @click="maskStatus = false" src="@/assets/zz/close.png" />
            </div>
        </div>
    </div>
</template>

<script>
import $api from '@/api/help/index'
import { mapState } from "vuex";
export default {
    name: "index",
    data() {
        return {
            ranking: [],
            myNum: 0,
            skinsList: [],
            maskStatus: false
        }
    },
    computed: {
        rankList() {
            return this.ranking.slice(3);
        },
        ...mapState(["user_info", "web_info"]),
    },
    mounted() {
        this.getDwSkins()
        this.getDwList()
    },
    methods: {
        async getDwSkins() {
            let { data, code } = await $api.dwSkins()
            if (code == 500) return
            this.skinsList = data;
        },
        async getDwList() {
            let { data, code } = await $api.dwList()
            if (code == 500) return
            this.ranking = data.data.data
            this.myNum = data.my_num;
        }
    }
}
</script>

<style scoped lang="scss">
.mask {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #00000097;
    z-index: 999;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    >div {
        width: 80%;
        text-align: center;

        >img {
            height: 80px;
            width: 80px;
            margin: 40px auto;
        }

        >.model {
            border-top: solid 20px #008C61;
            background-color: #ffffffe3;
            text-align: left;
            padding: 20px 30px;
            color: #444;
            margin: 0 auto;
            width: 800px;
            height: 600px;

            >h4 {
                text-align: center;
                font-size: 34px;
                font-weight: bold;
                margin: 30px 10px;
            }

            >p {
                margin-bottom: 8px;
                color: #666;
                font-size: 20px;
                line-height: 35px !important;
            }

        }


    }
}

#app {
    text-align: center
}

.myDragon {
    margin: 0 auto;

    position: relative;
    background-image: url('../../assets/zz/bgPc.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    height: 2200px;
    background-color: #CEFEEF;

    @media (max-width:600px) {
        width: auto;
    }

    >.topTitle{
        top: 300px;
        
                color: #0E5846;
                font-size: 28px;
                font-weight: bold;
                text-align: center;
                width: 1400px;
                margin: 0 auto;
                margin-top: 40px;
                padding: 20px 40px;
                border-radius: 40px;
                font-weight: bold;
                background-color: #ffffff9a;
    }

    >.back {
        position: absolute;
        left: 0px;
        border-radius: 0 20px 20px 0;
        top: 45px;
        padding: 8px 20px;
        font-weight: 100;
        font-size: 22px;
        color: #fff;
        background-color: #5DB18F;
    }

    >.rule {
        position: absolute;
        left: 50%;
        border-radius: 20px;
        top: 20%;
        padding: 8px 20px;
        font-weight: 100;
        margin-left: -64px;
        font-size: 22px;
        color: #fff;
        background-size: cover;
        background-color: #5DB18F;
    }

    .mBox {
        display: flex;
        margin: 0 auto;
        padding-top: 550px;
width: 1400px;


        >.left {
            flex: 1;

            >.topTitle {
                top: 300px;
                color: #0E5846;
                font-size: 28px;
                font-weight: bold;
                text-align: center;
                width: 650px;

                padding: 20px 40px;
                border-radius: 40px;
                font-weight: bold;
                background-color: #ffffff9a;
            }

            >.ranking {
                font-size: 22px;
                text-align: center;
                display: flex;
                align-items: end;
                width: 650px;
                margin-top: 100px;

                >div {
                    flex: 1;
                    position: relative;

                    >.bg {
                        background: linear-gradient(to right, #FCA837, #E76528);
                        height: 100px;
                        width: 100px;
                        border-radius: 50%;
                        position: absolute;
                        left: 50%;
                        margin-left: -50px;
                        top: -65px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        >img {
                            height: 94px;
                            width: 94px;
                            border-radius: 50%;
                            background-color: #fff;
                        }
                    }

                    >img {
                        height: 45px;
                        width: 45px;
                        position: absolute;
                        left: 50%;
                        top: 8px;
                        margin-left: -22.5px;
                    }

                    >.name {
                        width: 100%;
                        text-align: center;
                        position: absolute;
                        color: #444;
                        font-size: 20px;
                        top: 70px;
                        left: 50%;
                        margin-left: -45%;
                        white-space: nowrap;
                        /* 确保文本不会换行 */
                        overflow: hidden;
                        /* 隐藏超出容器的文本 */
                        text-overflow: ellipsis;
                        /* 当文本超出容器时显示省略号 */
                        width: calc(100% - 10px);
                    }

                    >.desc {
                        text-align: center;
                        position: absolute;
                        font-weight: 100;
                        color: #E76528;
                        font-size: 18px;
                        text-align: center;
                        top: 105px;
                        left: 50%;
                        margin-left: -45%;
                        white-space: nowrap;
                        /* 确保文本不会换行 */
                        overflow: hidden;
                        /* 隐藏超出容器的文本 */
                        text-overflow: ellipsis;
                        /* 当文本超出容器时显示省略号 */

                        width: calc(100% - 10px);
                    }
                }

                >div:first-child {
                    height: 180px;

                    background-color: #fff;
                    border-radius: 25px 0 0 25px;
                }

                >div:nth-child(2) {
                    height: 260px;

                    background-color: #5DB18F;
                    border-radius: 25px 25px 0 0;

                    >.name {
                        color: #fff;
                    }

                    >.desc {
                        margin-top: 40px;
                        color: #fed39a;
                    }
                }

                >div:nth-child(3) {
                    height: 180px;

                    background-color: #fff;
                    border-radius: 0 25px 25px 0;
                }
            }
        }

        >.right {
            flex: 0 0 620px;

            >.rankList {
                height: 427px;
                border-radius: 10px;
                overflow-y: scroll;
                padding: 30px;
                background-color: #fff;

                >.ls {
                    display: flex;
                    margin-bottom: 22px;
                    justify-content: space-between;

                    >.left {
                        color: #444;
                        display: flex;
                        align-items: center;

                        >span:first-child {
                            margin-right: 10px;
                            width: 30px;
                            font-size: 20px;
                            font-weight: bold;
                        }

                        >.img {
                            height: 50px;
                            width: 50px;
                            margin-right: 14px;

                            >img {
                                height: 50px;
                                width: 50px;
                                border-radius: 50%;
                            }
                        }

                        >.name {
                            white-space: nowrap;
                            /* 确保文本不会换行 */
                            overflow: hidden;
                            /* 隐藏超出容器的文本 */
                            text-overflow: ellipsis;
                            width: 260px;
                            font-size: 18px;
                        }
                    }

                    >.right {
                        color: #444;
                        display: flex;
                        align-items: center;
                        font-size: 18px;
                        >img {
                            margin-right: 6px;
                            height: 45px;
                            width: 45px;
                        }
                    }
                }

                >.noData {
                    text-align: center;
                    color: #888;
                    font-weight: 100;
                    margin-top: 60px;
                    font-size: 28px;

                }
            }

            >.rankSelf {
                margin-top: 20px;
                padding: 20px 20px;
                background-color: #fff;
                display: flex;
                align-items: center;
                color: #444;
                justify-content: space-between;
                border: solid 2px #F9B222;
                border-radius: 10px;

                >.left {
                    display: flex;
                    align-items: center;

                    >.img {
                        margin-right: 10px;
                        border-radius: 50%;
                        height: 60px;
                        width: 60px;

                        img {
                            border-radius: 50%;
                            height: 60px;
                            width: 60px;
                        }
                    }

                    >span {
                        font-size: 18px;
                        margin: 0 10px;
                        >span {
                            background-color: #008C61;
                            color: #fff;
                            font-size: 18px;
                            font-weight: 100;
                            border-radius: 2px;
                            padding: 4px 10px;
                        }
                    }
                }

                >.right {
                    display: flex;
                    align-items: center;

                    font-size: 18px;
                    >img {
                        height: auto;
                        padding-right: 10px;
                        width: 60px;
                    }
                }
            }
        }
    }







    >.prizeBox {
        display: flex;
        width: 1400px;
        margin: 0 auto;
        margin-top: 40px;
        height: 750px;
        align-items: center;
        flex-wrap: wrap;
        overflow-y: scroll;

        >.ls {
            flex: 0 0 25%;
            margin-bottom: 20px;
            position: relative;

            >.pized {
                position: absolute;
                left: 50%;
                margin-left: -35px;
                width: 70px;
                font-size: 18px;
                top: 172px;
                text-shadow: 1px 1px 1px #999;
                text-align: center;
            }

            >.bg {
                width: 100%;
            }

            >.money {
                position: absolute;
                display: flex;
                color: #fff;
                justify-content: center;
                align-items: center;
                top: 20px;
                font-size: 16px;
                width: 100%;

                >img {
                    width: 18px;
                    height: 18px;
                    margin-right: 5px;
                }
            }

            >.weap {
                position: absolute;
                width: 160px;
                left: 50%;
                margin-left: -80px;
                height: 120px;
                top: 30px;
            }

            >.name {
                color: #444;
                font-size: 20px;
                font-weight: bold;
                text-align: center;
                background-color: #CFFDF3;
                margin-bottom: 12px;
            }

            >.desc {
                font-size: 18px;
                color: #444;
                text-align: center;
                font-weight: 100;
                background-color: #CFFDF3;
            }
        }

    >.cyPrize {
        flex: 0 0 25%;
        position: relative;
        text-align: center;

        >img {
            height: 220px;
        }

        >p {
            font-size: 20px;
            font-weight: 100;
            color: #444;
            font-weight: bold;
            background-color: #CFFDF3;
        }
    }
    }

}
</style>
