<template>
  <!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
  <div class="d-flex align-items-center flex-column mggbb pb-2 bg-size-100 bg-position-center"
        style="width:100%;border-bottom:1px solid #ffd800" >
    
  <div style="height: 120px;padding: 10px 0;">
    <img height="100%" class="position-relative z-index-1"
         :src="resData.cover" alt="">
  </div>

    <div class="px-2 text-center align-items-center d-flex mmboxzss"
         style="line-height: 24px;font-size: 12px "><div class="text-white mzsss"><img src="@/assets/img/zs.png" height="16" /> {{resData.mall_integral}}</div>  {{resData.dura_alias}} </div>
    <div class="px-2 text-center  border-top-0  text-overflow-1"
         style="line-height: 24px;font-size:12px">
    {{resData.name}}
    </div>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "weapon",
    props:{
      // 传输武器整体宽度
      width:{
        type:String,
        default:"180px"
      },
      // 武器显示的高度，宽度和整体宽度是一样的
      heightWeapon:{
        type:String,
        default:"150px"
      },
      // 传输背景 style 对象形式传输
      bgSize:{
        type:Object,
        default(){
          return {
            height: '102px'
          }
        }
      },
      // 武器对应的宽度 高度 带单位 style 对象形式传输
      weaponSize:{
        type:Object,
        default(){
          return {
            width: '115px',
            marginTop:'25px'
          }
        }
      },
      //    传输的item
      resData:{
        type:Object,
        default(){return {}}
      }
    },
  }
</script>

<style scoped lang="scss">


.mmboxzss{
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
}

.mzsss{
  display: flex;
  color: #8CE2FF !important;
  align-items: center;
  > img{
    margin-right:4px;
  }
}
.mggbb{
  background: rgba(0, 0, 0, 0.45);
  border-radius: 10px;

box-sizing: border-box;
border-bottom: solid 2px #C88C00 !important;

backdrop-filter: blur(5px);
}
  .weapon{
    &-name{
      padding: 10px 0;
    }
  }
</style>
