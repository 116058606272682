<template>
    <!-- 用户点击的按钮，基本公共样式 -->
    <div class="cursor-pointer f_btn_green f-main-bg " style="">
        <div :style="size" class="text-center">
            <slot>
                <!-- 默认返回这个，如果外面有插槽显示插槽内的内容 -->
                <div class="text" :style="location">
                    {{title}}
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "bottomBg2",
        props:{
            // 按钮样式
            size:{type:Object,default(){return{}}},
            // 按钮文字
            title:{type: String,default:'文字title'},
            // 按钮位置
            location:{type: Object,default(){return {}}}
        },
        data() {
            return {}
        },
    }
</script>

<style scoped>

    .text{
        text-align: center;
        color: #FFFFFF;
        line-height: 40px;
    }
    .btn{
    }
</style>
