import {request} from "../index";

function get_box_hot() {
    return request({
        url: `/box/hot`,
        method:'get',
    })
}
function get_storage(page) {
    return request({
        url: `/storage?page=${page}&sort=1`,
        method:'get',
    })
}

function get_synthesis_list(page) {
    return request({
        url: `/synthesis/list?page=${page}`,
        method:'get',
    })
}
function get_synthesis_compound(data) {
    return request({
        url: `/synthesis`,
        method:'post',
        data
    })
}

function zh_open(data){
    return request({
        url: `/zh_open`,
        method:'POST',
        data
    })
}

function zh_list(page,total_bean){
    return request({
        url: `/zh_list?sort=1&page=${page}&total_bean=${total_bean}`,
        method:'GET',
    })
}     

export default {
    get_box_hot,
    get_storage,
    get_synthesis_list,
    get_synthesis_compound,
    zh_open,
    zh_list
}
