<template>
  <div>
    <div class="footer bg-bg-2 text-center">
      <div class="d-md-block">
        <img
          style="padding: 10px 0"
          width="160px"
          :src="require('@/assets/newImg/logo.png')"
          alt=""
        />
      </div>
      <div class="d-flex pt-2">
        <div class="col-md-6 col-12 d-flex text-left m-auto">
          <div class="d-flex justify-content-center w-100">
            <div
              class="d-flex justify-content-between mt-2 w-100 ewjfkhwekfj"
              style="margin-bottom: 50px"
            >
              <a target="_blank" href="/active/help/2" class="mb-2">
                <img src="@/assets/newImg/foot/1.png" />
              </a>
              <a target="_blank" href="/active/help/3" class="mb-2">
                <img src="@/assets/newImg/foot/2.png" />
              </a>
              <a target="_blank" href="/active/help/4" class="mb-2">
                <img src="@/assets/newImg/foot/3.png" />
              </a>
              <a target="_blank" href="/help" class="">
                <img src="@/assets/newImg/foot/4.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <div class="px-3 d-md-flex d-block justify-content-around align-items-center mt-3 pb-3">
              <div class=" w-100">
                <div class="f_mean mt-1" style="text-align: center;">
                  <a target="_blank" href="https://beian.miit.gov.cn/" style="font-size:12px;">{{`湘ICP备2023013782号`}}</a>
                </div>
                <div class=" mt-1" style="text-align: center;" >
                  <img src="@/assets/img/GA.png" class="mr-1" alt="">
                  <a target="_blank" href="https://beian.miit.gov.cn/" style="font-size:12px;">湘公网安备 43010402001318号</a>
                </div>

              </div>
            </div>
  </div>
</template>
    </div>

</template>
<script>
export default {
  name: "footerBotton",
  data() {
    return {
      data: true,
      webInfo: {},
    };
  },
  mounted() {
    this.webInfo = this.$store.state.web_info;
  },
  methods: {},
};
</script>

<style scoped lang="scss">

.ewjfkhwekfj{
  padding: 0 40px;
  > a{
    > img{
      height: 60px;
    }
  }
}
.footer {
  width: 100%;
  margin-left: auto;
  font-size: 12px;
  margin-top: 80px;
  position: relative;
  z-index: 0;
  background-image: url(../assets/newImg/footbg.png);
  background-size: 100% 285px;
  background-color: #192636;

  &-statistics {
    margin-bottom: 35px;
    padding: 40px 0;
    background-color: #191926;
    --gradient-color: 255, 255, 255;
    --gradient-opacity: 0.2;
    border-style: solid;
    border-width: 1px;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      to right,
      rgba(var(--gradient-color), 0),
      rgba(var(--gradient-color), 0) 28%,
      rgba(var(--gradient-color), var(--gradient-opacity)) 50%,
      rgba(var(--gradient-color), 0) 74%,
      rgba(var(--gradient-color), 0) 0%
    );
    justify-content: space-around;

    &-item {
      text-shadow: 0 0 60px #e94d8b;
    }

    & svg {
      font-size: 40px;
    }

    @media (max-width: 1200px) {
      width: 100%;
      margin: 15px auto;
    }
  }

  &-title {
    width: 600px;
    margin: 0 auto;
    padding: 10px 0;

    @media (max-width: 1200px) {
      width: 90%;
      margin: 15px auto;
    }
  }
}
</style>
