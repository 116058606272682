<template>
  <div class="d-flex">
    <div class="content">
      <div class="Phone">
        <div class="content-roll d-flex justify-content-between">
          <swiper class="swiper" :options="options_phone">
            <swiper-slide class="position-relative" v-for="(item, index) in banners" :key="index">
              <div class="swiper-slide swiper-roll">
                <a index-phone-item:href="item.href">
                  <img class="swiper-slide-img" :src="item.image" style="width: 100%; height: 100%" />
                </a>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>

      <!--  公告  -->
      <div class="swiper-top">
        <div class="pr-1">
          <img style="width: 12px" src="../../assets/newImg/laba.png" alt="" />
        </div>
        <marquee :scrollamount="scrollamount" class="swiper-top-marquee" width="100%" behavior="scroll">
          {{ web_info.site_bulletin }}
        </marquee>
      </div>

      <div class="sreach" v-if="sUserAgent == 'web'">
        <div class="left">
          <div>
            <input v-model="box_name" placeholder="请输入箱子名称" />
            <!-- <a><img src="@/assets/newImg/sreach.png" height="22" /></a> -->
          </div>

          <div class="qjianmmm">
            <input v-model="min_price" placeholder="¥0" />
            <span></span>
            <input v-model="max_price" placeholder="¥1600" />
            <a class="btnsdwnweofwf" @click="sreach"><img src="@/assets/newImg/sreach.png" height="22" /></a>
          </div>

        </div>
        <div class="right">
          <a @click="clear"><img src="@/assets/newImg/clear.png" height="20" /> 清除</a>
        </div>
      </div>

      <!-- 导航 -->
      <div class="index-phone mt-3">
        <div class="d-flex justify-content-around flex-wrap">
          <div @click="$router.push({ name: 'outOfTheFree' })">
            <template>
              <div class="index-phone-item">
                <img style="width: 64px" class="m-auto" src="@/assets/newImg/warpmenu/1.png" alt="#" />
                <!-- <div class="mt-1" style="font-size: 12px">积分商城</div> -->
              </div>
            </template>
          </div>
          <div @click="$router.push({ name: 'rollHome' })">
            <template>
              <div class="index-phone-item">
                <img style="width: 64px" class="m-auto" src="@/assets/newImg/warpmenu/2.png" alt="#" />
                <!-- <div class="mt-1" style="font-size: 12px">充值</div> -->
              </div>
            </template>
          </div>
          <div @click="$router.push({ name: 'recharge' })">
            <template>
              <div class="index-phone-item">
                <img style="width: 64px" class="m-auto" src="@/assets/newImg/warpmenu/3.png" alt="#" />
                <!-- <div class="mt-1" style="font-size: 12px">合伙人</div> -->
              </div>
            </template>
          </div>
          <div @click="$router.push({ name: 'shopping' })">
            <template>
              <div class="index-phone-item">
                <img style="width: 64px" class="m-auto" src="@/assets/newImg/warpmenu/4.png" alt="#" />
                <!-- <div class="mt-1" style="font-size: 12px">客服</div> -->
              </div>
            </template>
          </div>
          <div @click="keFu()">
            <template>
              <div class="index-phone-item">
                <img style="width: 64px" class="m-auto" src="@/assets/newImg/kficon.png" alt="#" />
                <!-- <div class="mt-1" style="font-size: 12px">客服</div> -->
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="sreach sreach1" v-if="sUserAgent != 'web'">
        <div class="left">
          <div>
            <input v-model="box_name" placeholder="箱子名称" />
            <!-- <a><img src="@/assets/newImg/sreach.png" height="22" /></a> -->
          </div>

          <div class="qjianmmm">
            <input v-model="min_price" placeholder="¥0" />
            <span></span>
            <input v-model="max_price" placeholder="¥1600" />
            
          </div>

        </div>
        <div class="right">
          <a class="btnsdwnweofwf" @click="sreach"><img src="@/assets/newImg/sreach.png" height="22" /></a>
          <a @click="clear"><img src="@/assets/newImg/clear.png" height="20" /> 清除</a>
        </div>
      </div>

      <!-- 宝箱 -->
      <div class="content-box mt-5" v-if="sreachData.length == 0">
        <div v-for="(t, i) in boxList" :key="i">
          <div class="content-box-title" v-if="sUserAgent != 'web'">
            <div class="text-center content-box-title-top" :style="`background-image:url(${t.src});`"></div>
          </div>
          <div class="content-box-title" v-if="sUserAgent == 'web'">
            <div class="text-center content-box-title-top" :style="`background-image:url(${t.src});`"></div>
          </div>
          <div class="content-box-list d-flex flex-wrap justify-content-center">
            <div v-for="(item, index) in t.box" :key="index" class="content-box-list-item mb-3">
              <router-link :to="'/active/' + item.id" class=" ">
                <div @click="openBox"
                  class="text-center d-flex flex-column align-items-center m-auto py-2 overflow-hidden content-box-list-item-f"
                  style="width: 95%">
                  <div
                    class="col-md-8 col-lg-10 col-12 px-lg-3 px-1 content-box-list-item-img position-relative d-flex justify-content-center align-items-center">
                    <img class="position-relative" width="120%" :src="item.cover" alt="底部箱子" />
                    <img class="position-absolute p-4 z-index-2" width="100%" :src="item.weapon_cover" alt="抢" />
                  </div>
                  <div class="col-12 position-relative mlabefoewf" style="width: 90%; margin: 0 auto">



                    <div
                      class="col-lg-6 col-12 mx-auto justify-content-center title_qian font-weight-bold d-flex align-items-center">
                      {{ item.name }}
                    </div>
                    <div class="font-weight-bold content-box-list-item-money">
                      {{ item.bean }}
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="content-box mt-5" v-if="sreachData.length != 0">

        <div>
          
          <div class="content-box-list d-flex flex-wrap justify-content-center">
            <div v-for="(item, index) in sreachData" :key="index" class="content-box-list-item mb-3">
              <router-link :to="'/active/' + item.id" class=" ">
                <div @click="openBox"
                  class="text-center d-flex flex-column align-items-center m-auto py-2 overflow-hidden content-box-list-item-f"
                  style="width: 95%">
                  <div
                    class="col-md-8 col-lg-10 col-12 px-lg-3 px-1 content-box-list-item-img position-relative d-flex justify-content-center align-items-center">
                    <img class="position-relative" width="120%" :src="item.intact_cover" alt="底部箱子" />
                    <!-- <img class="position-absolute p-4 z-index-2" width="100%" :src="item.weapon_cover" alt="抢" /> -->
                  </div>
                  <div class="col-12 position-relative mlabefoewf" style="width: 90%; margin: 0 auto">



                    <div
                      class="col-lg-6 col-12 mx-auto justify-content-center title_qian font-weight-bold d-flex align-items-center">
                      {{ item.name }}
                    </div>
                    <div class="font-weight-bold content-box-list-item-money">
                      {{ item.bean }}
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="indexPup d-flex justify-content-center align-items-center" v-if="reminder">
      <div class="position-relative">
        <img class="mnewrfheurgerouig" src="@/assets/newImg/bgindex.jpg" height="900px" alt="" @click="reminderClick" />
      </div>
    </div>
  </div>
</template>


<script>
import $api from "@/api/CSGO/index";
import { mapState } from "vuex";
import sUserAgent from '@/assets/js/common.js'
import { getToken } from "@/utils/auth";

import {
  Swiper,
  SwiperSlide,
} from "vue-awesome-swiper/dist/vue-awesome-swiper.esm";
import "swiper/css/swiper.css";
import { getWebInfo } from "@/utils/auth";

export default {
  name: "index",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      sreachData:[],
      box_name: '',
      min_price: '',
      max_price: '',
      routers: [],
      reminder: true,
      boxList: [],
      sideBoxList: [],
      ROLLList: [],
      options: {
        watchOverflow: true,
        slidesPerView: 2,
        loop: true,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
      options_phone: {
        watchOverflow: true,
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
      },
      webInfo: {},
      openBoxState: {
        openBox: 0,
        userSum: 0,
        onLine: 0,
        pkSum: 0,
      },
      time: null,
      banners: [],
      StorageListpageSize: 0,
    };
  },
  created() {

  },
  computed: {
    ...mapState(["web_info"]),
    sUserAgent() {
      return sUserAgent.sUserAgent()
    }
  },
  mounted() {
    this.routers = this.$router.options.routes[0].children;
    this.init();
    let codeRef = this.$route.query.ref_code;
    if (codeRef != undefined) {
      window.sessionStorage.setItem("codeRef", codeRef);
    }
    setTimeout(() => {
      this.swiper = new Swiper(".swiper");
    }, 100);
    if (getToken()) {
      this.reminder = false;
    } else {
      this.reminder = true;
    }
  },
  beforeDestroy() {
    clearInterval(this.time);
    this.time = null;
  },
  methods: {
    clear(){
      this.box_name = '';
      this.max_price = '';
      this.min_price = '';
      this.sreachData = [];
    },
    async sreach() {
      if( !this.box_name && !this.min_price && !this.max_price ){
          this.$notify({
          title: '警告',
          message: '搜索条件不能为空',
          type: 'warning',
          position: 'top-left'
        });
        return false;
      }
      if( !this.box_name && !this.min_price && this.max_price ){
          this.$notify({
          title: '警告',
          message: '请输入开始金额',
          type: 'warning',
          position: 'top-left'
        });
        return false;
      }
      if( !this.box_name && this.min_price && !this.max_price ){
          this.$notify({
          title: '警告',
          message: '请输入结束金额',
          type: 'warning',
          position: 'top-left'
        });
        return false;
      }
      // if (!this.box_name) {
      //   this.$notify({
      //     title: '警告',
      //     message: '请输入箱子名称',
      //     type: 'warning',
      //     position: 'top-left'
      //   });
      //   return false;
      // }
      let { data, code } = await $api.sreach(this.box_name, this.min_price, this.max_price);
      if (code == 500) return;
      this.sreachData = data;
    },
    async getStorage(page = 1, status = 1) {
      try {
        let { data, code } = await $api.getStorage(page, status);
        if (code == 500) return;
        data.data.forEach((item) => {
          if (item.back_message != "") {
            console.log(123);
          }
          item.isShow = false;
        });
        this.StorageList = data.data;
        this.StorageListtotal = data.total;
        this.StorageListpageSize = data.per_page;
      } catch (e) {
        console.log(e);
      }
    },

    get_banners() {
      $api.get_banners().then((res) => {
        this.banners = res.data;
      });
    },
    // 客服
    keFu() {
      window.open(
        "https://www30.53kf.com/webCompany.php?arg=10838531&kf_sign=zg4NjMTcxMc3MDE0MDAwNzY1MTc4MDAyNzI4Mzg1MzE%253D&style=1",
        "_blank"
      );
    },
    onSwiper() { },
    onSlideChange() { },
    async init() {
      this.getBoxList();
      this.get_banners();
      this.getROllListthree();
      this.get_box_hot();
      this.sumState();
      this.webInfo = JSON.parse(getWebInfo());
    },
    sumState() {
      this.openBoxState.openBox = 1500 + Math.floor(Math.random() * 100);
      this.openBoxState.userSum = 1050 + Math.floor(Math.random() * 100);
      this.openBoxState.onLine = 1000 + Math.floor(Math.random() * 100);
      this.openBoxState.pkSum = 1000 + Math.floor(Math.random() * 100);
      let i = 0;
      this.time = setInterval(() => {
        if (Math.floor(Math.random() * 10) > 3) {
          setTimeout(() => {
            this.openBoxState.openBox += Math.floor(Math.random() * 50);
            this.openBoxState.onLine += Math.floor(Math.random() * 50);
          }, 500);
          this.openBoxState.userSum += Math.floor(Math.random() * 100);
          this.openBoxState.pkSum += Math.floor(Math.random() * 100);
        } else {
          setTimeout(() => {
            this.openBoxState.openBox -= Math.floor(Math.random() * 50);
            this.openBoxState.onLine -= Math.floor(Math.random() * 50);
          }, 500);
          this.openBoxState.userSum -= Math.floor(Math.random() * 50);

          this.openBoxState.pkSum -= Math.floor(Math.random() * 50);
        }
        i++;
        if (i === 50) {
          clearInterval(this.time);
          this.sumState();
        }
      }, 1000);
    },
    openBox() {
      document.getElementById("mysound").play();
    },
    mouseOverOpenBox() {
      document.getElementById("mouseOverBox").play();
    },
    mouseLeaveOpenBox() {
      document.getElementById("mouseOverBox").load();
    },
    reminderClick() {
      if (getToken()) {
        this.reminder = false;
        return;
      }
      this.reminder = false;
      this.$store.commit("LOGIN_TOP", true);
    },
    sideBox(item) {
      this.$router.push("/active/" + item.id);
    },
    async getBoxList() {
      try {
        let { data, code } = await $api.getBoxList();
        if (code == 500) return;
        this.boxList = data;
        this.sideBoxList = data[0].box;
      } catch (e) {
        console.log(e);
      }
    },
    async get_box_hot() {
      try {
        let { data, code } = await $api.get_box_hot();
        if (code == 500) return;
        this.sideBoxList = data;
      } catch (e) {
        console.log(e);
      }
    },
    async getROllListthree() {
      try {
        let {
          data: { data },
          code,
        } = await $api.getROllListthree();
        if (code == 500) return;
        this.ROLLList = data.slice(0, 3);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media (max-width:600px) {
  .mnewrfheurgerouig {
    height: 440px;
  }

  .mlabefoewf {
    margin-top: -20px !important;
  }

  .content-roll {
    margin-top: -20px;
  }
}

.content-box {
  width: 1600px;
  margin: 0 auto;

  @media (max-width:600px) {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .h1mwaorp {
    font-weight: bold;
    text-align: center;
    padding: 20px 0;
    font-size: 24px;
  }
}

.title_qian {
  margin-bottom: 20px;

  @media (max-width:600px) {
    margin-bottom: 10px;
  }
}

.sreach {
  width: 1600px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  @media (max-width:600px) {
    width: 100%;
  }

  .left {
    display: flex;

    @media (max-width:600px) {
      flex: 4;
    }

    >.qjianmmm {
      width: 260px;

      >input:first-child {
        // border-right: solid .5px #ffffffab;
        width: 80px;
        text-align: center;
        margin-right: 20px
      }

      >input:nth-child(3) {
        width: 120px;
        text-align: center;
      }

      >span {
        display: block;
        flex: 0 0 40px;
        height: .5px;
        background-color: #ffffffab;
      }

      >.btnsdwnweofwf {
        height: 45px !important;
        margin-left: 15px;
      }
    }

    >div {
      margin-right: 15px;
      background-color: #00000043;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 300px;
      padding-right: 6px;

      @media (max-width:600px) {
        width: 100px;
        margin-right: 5px;
      }

      >input {
        color: #fff;
        text-indent: 16px;
      }

      >input::placeholder {
        font-size: 16px;
      }

      >a {
        height: 40px;
        line-height: 40px;
        display: flex;
        align-items: center;
        border-radius: 2px;
        padding: 0 20px;
        background: linear-gradient(270deg, #085889 0%, #1F7EB9 100%);
      }
    }
  }

  .right {
    @media (max-width:600px) {
      flex: 1;
    }
    >a {
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      padding: 0 30px;
      background: linear-gradient(270deg, #085889 0%, #1F7EB9 100%);

      >img {
        margin-right: 4px;
      }
    }
  }
}

@media (max-width:600px) {
  .sreach1{
    display: inherit;
    margin: 0 12px;
    margin-top: 4px;
    width: calc(100% - 24px);
    > .left{
      margin-bottom: 10px;
      > div{
        height: 45px;
      }
      > div:first-child{
        flex: 0 0 40%;
      }
      > .qjianmmm{
        flex: 0 0 calc(60% - 4px);
        > input{
          width: 80px !important;
        }
      }
    }
    > .right{
      display: flex;
      > a:first-child{
        margin-right: 10px;
      }
      > a{
        flex: 1;
      }
    }
  }
}

.swiper-top {
  display: flex;
  align-items: center;
  width: 1600px;
  justify-content: center;
  margin: 0 auto;
  height: 44px;
  background: linear-gradient(270deg,
      rgba(115, 250, 200, 0.07) 2%,
      rgba(19, 64, 131, 0.39) 57%,
      rgba(134, 14, 141, 0.1) 100%);

  @media (max-width:600px) {
    margin-top: 10px;
    height: 36px;
    width: 100%;
  }

  backdrop-filter: blur(10px);

  >div {
    >img {
      width: 22px !important;
      margin: 0 14px;

      @media (max-width:600px) {
        width: 14px !important;
      }
    }
  }
}

.swiper-slide-img {
  @media (max-width: 1000px) {
    transform-origin: top;
    transform: scaleX(1.6);
  }
}

.indexPup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);

  @media (max-width: 1000px) {
    top: 0px;

    & img {
      width: 100%;
    }
  }
}

// 新写的手机端
.index-phone {
  @media (min-width: 600px) {
    display: none !important;


  }

  // background-image: url("../../assets/img/phone-nav/navbg.png");
  background-size: 100% 100%;
  padding: 20px 10px;

  @media (max-width:600px) {
    padding-top: 0px !important;
    padding-bottom: 0px;
  }

  &-item {
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
  }
}

.price_bottom_bg {
  display: inline-block;
  padding: 20px 20px;
  font-weight: 700;
  font-size: 14px;
  background-image: url("../../assets/img/phone-nav/item.png");
  background-size: 100% 100%;
  // background-color: #f3a45d;
  border-radius: 3px;
  margin-top: 8px;
  font-family: Roboto;

  @media (max-width:600px) {
    margin-top: -20px;
    margin-bottom: 10px;
    color: #fff;
  }
}

.sum-stast {
  font-size: 30px;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
}

.top-sum {
  font-size: 70px;

  @media (max-width: 1000px) {
    font-size: 24px;
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 90%;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 40%;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 40%;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 16px;
}

.swiper {
  height: 560px;
  margin-bottom: 40px;

  @media (max-width: 1000px) {
    height: 180px;
    margin-bottom: 0px;
  }

  &-roll {
    height: 100%;
    transition: all 0.5s;

    &:hover {
      background-size: 110% 110%;
    }

    &-user {
      width: 86px;
      height: 86px;
      left: -40px;
      overflow: hidden;
      background-color: #090827;
      border-radius: 14px;
      box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.3);

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0.5;
        background: linear-gradient(144deg,
            rgba(57, 85, 232, 0) -11%,
            rgba(57, 85, 232, 0.6) 111%);
      }
    }
  }

  &-cover {
    width: 130px;
    left: calc(50% - 60px);
    top: calc(50% - 40px);
    height: 100px;
  }
}

.side-box {
  width: 200px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.content {
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1366px) {
    width: 95%;
    margin-top: -20px;

    .swiper-top-marquee {
      font-size: 12px;
    }
  }

  &-roll {
    width: 100%;

    @media (max-width: 1366px) {
      &-item:nth-child(3) {
        display: none !important;
      }
    }

    &-item {
      width: 31%;
      transition: all 0.5s;
      overflow: hidden;

      @media (max-width: 1600px) {
        width: 32%;
      }

      @media (max-width: 1366px) {
        width: 48%;
      }

      @media (max-width: 760px) {
        width: 48%;
      }

      &:hover {
        transform: translateY(-20px);
      }

      &-bg {
        left: 20%;
        z-index: 0;
      }
    }
  }

  &-activity {
    margin-left: 20px;
  }

  &-box {
    &-title {
      &-top {
        height: 120px;
        // border-top: 1px solid var(--main-blue);
        // border-bottom: 1px solid var(--main-blue);
        background-size: initial;
        background-position: center;
        background-repeat: no-repeat;

        // background-size: 100% 100%;
        @media (max-width: 1000px) {
          height: 30px;
          background-size: auto 100%;
        }
      }

      &-name {
        font-size: 28px !important;
        border-top: 1px solid rgba(var(--main-blue), 1);
        border-bottom: 1px solid rgba(var(--main-blue), 1);
        // background-color: #0d101a;
        height: 70px;
        line-height: 70px;

        @media (max-width: 1000px) {
          height: 40px;
          line-height: 40px;
        }
      }
    }

    &-list {
      width: 100%;
      display: flex;
      justify-content: left !important;

      &-item {
        width: 20%;

        @media (max-width: 1600px) {
          width: 20%;
        }

        @media (max-width: 1366px) {
          width: 33.33%;
        }

        @media (max-width: 760px) {
          width: 50%;
        }

        &-img img {
          transition: all 0.5s;

          @media (max-width:600px) {
            transform: scale(.8);
            transform-origin: top;
          }
        }

        &-open {
          width: 100px;
          margin: 0 auto;
          opacity: 0;
          transform: scale(5, 5);
          transition: all 0.5s;

          &>div {
            border-radius: 4px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
          }
        }

        .f_title {
          transition: all 0.5s;
          font-weight: 600;
        }

        &-f {
          background-size: 150% 150%;
          background-position: center;
          transition: all 0.5s;
        }

        &:hover &-f {
          background-size: 100% 100%;
        }

        &:hover .f_title {}

        &:hover &-img img:nth-child(2) {
          transform: translateY(-20px);
          // transform: scale(1.2)
        }

        &:hover &-img img:nth-child(1) {
          // transform: scale(1.2)
        }

        &-money {
          color: #ccc;
          font-size: 18px;
          display: flex;
          background-image: url(../../assets/newImg/moneybg.png);
          justify-content: center;
          height: 40px;
          background-size: 180px 50px;
          background-repeat: no-repeat;
          background-position: center;
          line-height: 40px;

          @media (max-width:600px) {
            background-size: 160px 40px;
            transform: scale(.8);
            padding-left: 20px;
          }
        }

        &:hover &-open {
          opacity: 1;
        }
      }
    }
  }
}

@keyframes boxBg {
  to {
    transform: scale(0.8);
  }

  from {
    transform: scale(1.15);
  }
}

@keyframes boxBox {
  to {
    transform: rotate(3deg);
  }

  from {
    transform: rotate(-10deg);
  }
}
</style>
