<template>
    <div class="d-flex content-list-left-item align-items-center flex-wrap" >
        <div class="col-lg-1 col-md-1 col-3 d-md-block d-none content-list-left-item-sum">
            <div class=" d-flex align-items-center justify-content-around msoabsdwdjw">

              <!-- <span v-if="resData.type == 0">玩家</span>
              <span style="color: #f1bd09" v-if="resData.type == 1">主播</span>
              <span v-if="resData.type == 2">AI</span>
              <span >{{resData.user_num}}</span> -->
              <span></span>{{ resData.status_alias }}
            </div>
        </div>
        <div class="mavatarifme col-lg-2 px-1 col-md-5 col-12 content-list-left-item-user">
            <div class="content-list-left-item-user-list">
                <div  v-for="(item,index) in resData.game_arena_player" :key="index+ 'a'">
                    <div class="content-list-left-item-user-list-item">
                        <img style="width: 100%" :src="item.user.avatar" alt="#">
                    </div>
                </div>
                <div  v-for="(item,index) in (resData.user_num - resData.game_arena_player.length)" :key="index + 'b'">
                    <div class="content-list-left-item-user-list-item">
                        <img style="width: 100%" src="@/assets/img/pk/pk-add.png" :alt="item">
                    </div>
                </div>
                <div v-for="(item,index) in (4 - resData.user_num)" :key="index + 'c'">
                    <div class="content-list-left-item-user-list-item">
                        <img style="width: 100%" src="@/assets/img/pk/suo.png" :alt="item">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-10 col-12 d-md-flex d-none align-items-center">
            <!-- <div class="content-list-left-item-left">
                <i class="el-icon-arrow-left"></i>
            </div> -->
            <div class="content-list-left-item-content">
                <div v-for="(item,index) in resData.game_arena_box" :key="index + 'z'">
                    <div :class="resData.status == 1 && resData.ceil == index ? 'active' : '' "
                         class="d-flex flex-column justify-content-center align-items-center content-list-left-item-content-box" v-if="item.box">
                        <img  :src="item.box.intact_cover" alt="#">
                        <div class="text-overflow-1 px-3">
                            {{item.box.name}}
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="content-list-left-item-right">
                <i class="el-icon-arrow-right"></i>
            </div> -->
        </div>
        <div class="col-lg-1 px-0 col-md-3 col-12 content-list-left-item-money">
            <div class="d-flex align-items-center font-weight-bold">
                <money class="mr-2"></money>
                {{resData.total_bean}}
            </div>
        </div>
        
        <div class="col-lg-2 col-md-3 col-12 content-list-left-item-caozuo">
            <router-link tag="div"
                         v-if="resData.status == 0" :to="'/active/mysteryBox/' + resData.id + '?underway=0&round=0'"
                         class="d-inline-block mjr w-100 cursor-pointer">
                <img src="@/assets/newImg/ljpk.png" />
            </router-link>
            <router-link tag="div"  v-if="resData.status == 2" :to="'/active/mysteryBox/' + resData.id"
                         class="d-inline-block border-pk2 w-100 cursor-pointer">
                已结束
            </router-link>
            <router-link tag="div" v-if="resData.status == 1" :to="'/active/mysteryBox/' + resData.id + '?underway=1' + '&round=' +resData.ceil"
                         class="d-inline-block mjxz w-100 cursor-pointer">
                <img src="@/assets/newImg/gz.png" />
            </router-link>
        </div>
    </div>
    <!-- <div class="d-flex overflow-hidden justify-content-between content-item"
         :class="resData.status == 1?'bg-danger':''">
        <div  class="d-flex font-weight-bold">
            <div style="color: #7e2dc8" class="d-flex align-items-center justify-content-center flex-column text-center" v-if="resData.status == 0">
                <i class="el-icon-loading mb-2"></i>
                <span class="px-3" style="display: inline-flex;">
                    正在等待
                </span>
            </div>
            <div class="d-flex align-items-center justify-content-center flex-column text-center"
                 v-if="resData.status == 1">
                <span class="" style="display: inline-flex;">进行中</span>
            </div>
            <div class="d-flex align-items-center justify-content-center flex-column text-center"
                 v-if="resData.status == 2">
                <span class="px-3" style="display: inline-flex;">结束</span>
            </div>
        </div>
        <div class="d-flex" :class="resData.status ==2 ? '':''">
            <div class="content-user  d-flex justify-content-center align-items-center
            flex-wrap content-pk-list-item-user px-md-3"
                 style="">
                <div class="text-center mb-1 mx-2" style="width:50px;height: 50px"
                     v-for="(user,i) in resData.game_arena_player" :key="i + 'b'"
                     :class="user.success? 'active ' : ''">
                    <img  class="rounded-circle border" width="50px" height="50px"
                          :src="user.user.avatar || require('@/assets/img/pk/user.png')" alt="">
                   <div class="mt-2 text-overflow-1" style="font-size: 12px">{{user.user.name}}</div>
                </div>
                <div class="text-center mb-1 mx-md-2" style="width:50px;height: 50px"
                     v-for="(user,i) in (resData.user_num - resData.game_arena_player.length)" :key="i + 'a'"
                     :class="user.success? 'active ' : ''">
                    <img  class="rounded-circle" width="50px" height="50px"
                          :src="require('@/assets/img/add_home.png')" alt="">
                </div>
            </div>
        </div>

        <div ref="box" :class="resData.status ==2 ? '':''"
             class="text-center content-pk-list-item-gun">
            <div class="text-center" style="font-size: 12px">
                {{resData.game_arena_box.length}}回合
            </div>
            <div ref="widthBox" class="content-pk-list-item-gun-content d-flex overflow-auto">
                <div style="" v-for="(box,bi) in resData.game_arena_box "
                     :class="resData.status == 1 && resData.ceil == bi ? 'active' : '' "
                     :key="bi + 'box'" class="content-pk-list-item-gun-item">
                    <img width="100px"  :src="box.box ? box.box.intact_cover:require('@/assets/img/f_new/case-bg.png')" alt="">
                </div>
                <div style="clear: both;"></div>
            </div>
        </div>

        <div class="d-flex" :class="resData.status ==2 ? '':''">

            <div style="width: 150px" class="content-user  d-flex justify-content-center align-items-center
            flex-wrap content-pk-list-item-user px-md-3">
                <money class="ml-2" color="#FFFFFF"/>
                {{resData.total_bean}}
            </div>


            <div :class="resData.status ==2 ? '':''"
                 class="d-flex justify-content-center align-items-center content-pk-conter-active active">
                <router-link v-if="resData.status == 2" :to="'/active/mysteryBox/' + resData.id"
                             :active-class="'pk-conter-active'"
                             class=" text-center f_btn ">
                    对战结束
                </router-link>
                <router-link v-if="resData.status == 0" :to="'/active/mysteryBox/' + resData.id + '?underway=0&round=0'"
                             :active-class="'pk-conter-active'"
                             class=" text-center f_btn rounded-pill">
                    打开
                </router-link>
                <router-link v-if="resData.status == 1"
                             :to="'/active/mysteryBox/' + resData.id + '?underway=1' + '&round=' +resData.ceil"
                             :active-class="'pk-conter-active'" class=" text-center f_btn rounded-pill">
                    进行观战
                </router-link>
            </div>

        </div>



    </div> -->
</template>

<script>


    export default {
        name: "indexPkList",
        props:{
          resData:{type:Object,default(){return{}}},
            PKarenaListIndex:{type:Number,default:0},
        },
        data() {
            return {
            }
        },
        computed:{
            PKarenaListIndexLinde(){
                return this.PKarenaListIndex
            },

        },
        watch:{
            zizeng: {
                handler(newVal) {
                    this.zizeng = newVal
                },
                immediate: true,
                deep: true,
            },
            resData: {
                handler(newVal) {
                    this.resData = newVal
                },
                immediate: true,
                deep: true,
            },

        },
        mounted() {
        },
        created() {

        },
        beforeDestroy(){
            this.zizeng = 0
        },
        methods:{
            leftUp(){
               
            },
        }
    }
</script>


<style scoped lang="scss">
.el-pagination{
    text-align: center !important;
}
.msoabsdwdjw{
    font-weight: bold;
    color: #18A6E8;
    width: 80px !important;
    font-size: 16px;
    > span {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: #18A6E8;
    }
}

.mavatarifme{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.border-pk0{
  background-color: #222b1c;
  border-radius: 8px;
  border: 1px solid #7fd919;
}
.border-pk1{
  background-color: rgb(106,109,129);
  border-radius: 8px;
  border: 1px solid rgb(106,109,129);
}
.border-pk2{
  background-color: rgb(106,109,129);
  border-radius: 8px;
  border: 1px solid #6a6d81;
}
    .active{
        background-color:#242933!important; ;
    }

    .content{
        width: 100%;
        &-list{
            justify-content: center;
            display: flex;
            &-left{
                width: 100%;
                margin-right: 10px;
                &-title{
                    color: #666e80;
                    padding: 30px;
                }
                &-item{
                    border: 1px solid #1f232b;

                    border-radius: 5px;
                    &-sum{
                        &>div{
                            width: 50px;
                            height:50px;
                            border-radius: 5px;
                        }

                    }
                    &-content::-webkit-scrollbar{
                        display: none;
                    }
                    &-content{
                        padding: 0 10px;
                        display: flex;
                        align-items: center;
                        overflow-x: scroll;
                        &-box{
                            width: 70px;
                            height: 80px;
                            border-radius: 5px;
                            // background-color: #1d2129;
                            font-size: 12px;
                            line-height: 1.5;
                            margin-right: 1rem;
                        }
                        & img{
                            padding: 0 10px;
                            width: 80px!important;
                        }
                    }
                    &-money{
                        &>div{
                            padding: 20px;
                            // background-color:#1d2129 ;
                            border-radius: 5px;
                            @media (max-width: 1000px) {
                                border-radius: 0;
                            }
                        }
                    }
                    &-user{
                        &-list{
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-around;
                            /*width: 250px;*/
                            // background-color: #1d2129;
                            padding:0px;
                            border-radius: 5px;
                            margin-right: 5px;
                            @media (max-width: 1000px) {
                                border-radius: 0;
                            }
                            &-item{
                                width: 50px;
                                height: 50px;
                                margin-bottom: 5px;
                                border-radius: 50%;
                                overflow: hidden;
                            }
                        }
                    }
                    &-caozuo{
                        & div{
                            padding: 20px 30px;
                            border-radius: 5px;
                            text-align: center;
                            @media (max-width: 1000px) {
                                border-radius: 0;
                            }
                        }
                    }
                    &-left,&-right{
                        border: 1px solid #5e6576;
                        border-radius: 50%;
                        padding: 6px;
                    }
                }
            }

        }

    }
</style>
