<template>
    <!-- 侧边栏 -->
    <div class="position-fixed silde" :style="`right:${silde}px`">
        <div class="position-absolute silde-off" @click="sildeOff" v-if="sUserAgent == 'phone'">
            <span>
                <img src="@/assets/newImg/ksdjjoeijrf.png" height="100px" />
            </span>
        </div>
        <div :class="isShowSidle ? ' ' : ''" class="silde-box">
            <div @click="openUrlDragon" class="position-relative cursor-pointer
              silde-serve silde-W d-md-flex flex-column justify-content-center align-items-center">
                <img width="100%" :src="require('@/assets/zz/sideZZ.png')" alt="">
                <!-- <div style="font-size: 12px">
                    客服
                </div> -->
            </div>
            <div @click="QQQqun" class="position-relative cursor-pointer
              silde-serve silde-W d-flex  flex-column justify-content-center align-items-center">
                <img width="100%" :src="require('@/assets/newImg/side/1.png')" alt="">
                <!-- <div>
                    QQ
                </div>
                <div  class="position-absolute silde-weixin">
                    <img width="200px" :src="web_info.qq_group_qr_code" alt="">
                </div> -->
            </div>
            <div @click="csactive" class="position-relative cursor-pointer
              silde-serve silde-W d-md-flex flex-column justify-content-center align-items-center">
                <img width="100%" :src="require('@/assets/newImg/WechatIMG22.jpg')" alt="">
                
            </div>
            <div @click="keFu" class="position-relative cursor-pointer
              silde-serve silde-W d-md-flex d-none flex-column justify-content-center align-items-center">
                <img width="100%" :src="require('@/assets/newImg/side/2.png')" alt="">
                <!-- <div style="font-size: 12px">
                    客服
                </div> -->
            </div>
            <div @click="memberVip" class="position-relative cursor-pointer silde-serve silde-W d-md-flex d-flex flex-column justify-content-center
              align-items-center">
                <img width="100%" :src="require('@/assets/newImg/side/3.png')" alt="">
                <!-- <div>
                    vip
                </div> -->
            </div>

            
            
            <div @click="$router.push('/user/welfare')" class="position-relative cursor-pointer
              silde-serve silde-W d-md-flex flex-column justify-content-center align-items-center">
                <img width="100%" :src="require('@/assets/newImg/WechatIMG1227.jpg')" alt="">
                <!-- <div style="font-size: 12px">
                    客服
                </div> -->
            </div>

            <div @click="$router.push('/user/recharge')" class="position-relative cursor-pointer silde-serve silde-W d-md-flex d-flex flex-column justify-content-center
              align-items-center">
                <img width="100%" :src="require('@/assets/newImg/side/6.png')" alt="">
                <!-- <div>
                    充值
                </div> -->
            </div>

            <!-- <div @click="memberVip"
              class="position-relative cursor-pointer silde-serve silde-W d-md-flex d-flex flex-column justify-content-center
              align-items-center" >
              <img width="100%" :src="require('@/assets/newImg/side/7.png')" alt="">
                
            </div> -->

        </div>


        <div v-show="immediatelyShopping" class="position-fixed  z-index-1"
            style="width: 100vw;height: 100vh;left: 0;top: 0;background-color: rgba(0,0,0,.5)">

            <div v-show="immediatelyShopping" class="position-fixed z-index-100 immediatelyShopping">
                <div class="bg-size bg-repeat"
                    :style="`background-image: url(${require('@/assets/img/vd/red_top.png')})`">
                    <div class="immediatelyShopping-box px-2 pt-3">
                        <div class="d-flex ">
                        </div>
                        <div class="text-center mt-1">
                            加官群 {{ web_info.qq_group_number }}，抢更多福利！
                        </div>
                        <div class="d-flex justify-content-center px-3 immediatelyShopping-input">
                            <input placeholder="请输入红包口令" type="text" v-model="packet" class="d-block py-2">
                        </div>
                        <div class="d-flex justify-content-center">
                            <img @click="OpenRebBack(2)" width="60px" :src="require('@/assets/img/vd/kai.png')" alt="">
                        </div>
                        <div class="mt-4 text-center">
                            红包列表
                        </div>
                        <div class="immediatelyShopping-list mt-2">
                            <div :class="item.status != 2 ? '' : 'filter-grayscale-1'" v-for="(item, index) in redList"
                                :key="index" class="px-2 py-1 rounded mb-2" style="background-color: #ad252c">
                                <div class="d-flex mt-3">
                                    <div class="immediatelyShopping-item-img d-flex flex-column align-items-center justify-content-end bg-size-100 pb-md-2 pb-1"
                                        :style="`background-image: url(${require('@/assets/img/vd/red_money.png')})`"
                                        style="font-size: 12px">
                                        <div>剩余数量</div>
                                        <div>{{ item.num }} </div>
                                    </div>
                                    <div class="ml-2" style="font-size: 13px">
                                        <div class="mb-2">{{ item.title }}</div>
                                        <div class="mb-2">参与条件：{{ item.briefe }}</div>
                                        <div class="mb-2">结束时间：{{ item.end_time }}</div>
                                    </div>
                                </div>
                                <div @click="OpenRebBack(1, item)"
                                    class="cursor-pointer immediatelyShopping-pk text-center px-4">
                                    抢红包
                                </div>
                            </div>
                        </div>
                        <div @click="redPacket(0)" class="position-absolute" style="right: 0;top: 0">
                            <i class="iconfont icon-guanbi-02-02 text-white" style="font-size: 30px"></i>

                        </div>
                    </div>
                </div>
            </div>



        </div>

        <!-- 活动弹窗 -->
        <div class="ActivityCenter position-fixed z-index-333" v-show="ActiveShow" style="width: 100vw;
        height: 100vh;z-index:5;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.8);
      ">
            <div class="ActivityCenter1">
                <!-- title -->
                <div class="activeTitle"></div>
                <!-- 活动主页 -->
                <div class="ActivityCenter2">
                    <div class="active-left">
                        <div class="left-top">
                            <div class="left-top-button">
                                <button class="active-button tab_act" v-for="(item, index) in Activebutton" :key="index"
                                    :class="{ tab_act2: currentIndex === index }" @click="liClick(index)">
                                    <span class="wrapper">{{ item }}</span>
                                </button>
                            </div>
                        </div>
                        <div class="left-center">
                            <component :is="comName" ref="comName"></component>
                        </div>
                    </div>
                    <div class="active-right">
                        <commonMy @drawTap="drawTap" :tasksRecommendedList="tasksRecommendedList" :tasksRecommendedList3="tasksRecommendedList3" :tasksRecommendedList4="tasksRecommendedList4" ref="myName"></commonMy>
                    </div>
                </div>
                <div class="activeDel Btn_a" @click="activeDel">
                    <span class="activedel1">
                        
                    </span>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import {getToken} from '@/utils/auth'
import sUserAgent from '@/assets/js/common.js'
import { mapState } from "vuex";
import $api from '@/api/vd/index'
import TaskIn from "@/views/TaskIn/index";
import redenvelopes from "./common/Redenvelopes.vue";
import activehot from "./common/activehot.vue";
import freeskin from "./common/freeskin.vue";
import commonMy from "./common/my.vue";
import gradebenefits from "./common/gradebenefits.vue";
import taskcenter from "./common/taskcenter.vue";

export default {
    name: "silde",
    components: {
        TaskIn,
        commonMy,
    redenvelopes,
    activehot,
    freeskin,
    gradebenefits,
    taskcenter,
    },
    data() {
        return {
            ActiveShow: false,
            immediatelyShopping: false,
            QQ_qr_code: '',
            comName: "activehot",
            QQ_qr_code_is_show: false,
            qq_group_number: 123456789,
            packet: '',
            redList: [],
            silde: 0,
            isShowSidle: false,
            sidleSum: 0,
            //   按钮名称
            Activebutton: [
                "热门活动",
                "任务中心",
                "充值补给",
                "口令红包",
                // "等级福利",
            ],
            // 点击事件
            currentIndex: 0,
            tasksRecommendedList:[],
            tasksRecommendedList3:[],
            tasksRecommendedList4:[]
        }
    },
    computed: {
        ...mapState(['web_info']),
        sUserAgent() {
            return sUserAgent.sUserAgent()
        }
    },

    mounted() {
        this.init()
        this.QQ_qr_code = this.$store.state.web_info.qq_group_qr_code
        this.qq_group_number = this.$store.state.web_info.qq_group_number
        window.addEventListener("scroll", this.winScroll);
        if (this.sUserAgent == 'phone') {
            this.silde = -78
        }
    },
    watch: {
        sidleSum(n, l) {
            if (l < n) {
                this.isShowSidle = true
            } else {
                this.isShowSidle = false
            }
        }
    },
    methods: {
        openUrlDragon(){
            if( this.sUserAgent == 'web' ){
                this.$router.push('/dragonBoatFestivalPc')
            } else {
                this.$router.push('/dragonBoatFestival')
            }
        },
        liClick(index) {
        this.currentIndex = index;
        if (index == 0) {
            this.comName = activehot;
        }else if (index == 1) {
            this.comName = taskcenter;
        }else if (index == 2) {
            this.comName = freeskin;
        }else if (index == 3) {
            this.comName = redenvelopes;
        }else if (index == 4) {
            this.comName = gradebenefits;
        }
        },
        
        drawTap(){
            if(this.$refs.comName)this.$refs.comName.taskDataList()
        },
        // 跳转活动页面
    async csactive() {
      let token= await getToken()
      if (!token) {
         return this.$store.commit("LOGIN_TOP", true)
      }
      this.taskGetList1();
      this.taskGetList3(3);
      this.taskGetList4(4);
        this.ActiveShow = true;
        this.currentIndex=0
        this.comName='activehot'
        if(this.$refs.myName)this.$refs.myName.taskGetList()
        this.$store.state.mission_state = !this.$store.state.mission_state
    },
    activeDel() {
      this.ActiveShow = false;
    },
        winScroll() {
            let top = Math.floor(document.documentElement.scrollTop || document.body.scrollTop);
            this.sidleSum = top

        },
        recharge() {
            this.$router.push('/user/recharge')
        },
        init() {
            this.getredList()
            if (this.sUserAgent == 'phone') {
                this.silde = -55
                this.silde = 0
            } else {
                this.silde = 0
            }
        },
        keFu() {
            window.open('https://www30.53kf.com/webCompany.php?arg=10838531&kf_sign=zg4NjMTcxMc3MDE0MDAwNzY1MTc4MDAyNzI4Mzg1MzE%253D&style=1', "_blank")
        },
        QQQqun() {

            window.open('https://qm.qq.com/cgi-bin/qm/qr?k=MlfGL7CjlosuFM-7QjrRSk9zjVNs5N07&jump_from=webapi&authKey=CLEYZcvqkUbrvMDV7mUgL5+wFCFz/SGx1SaBGTfbrkeql/nJQfnaOsg1mbhtFz6b', "_blank")
        },
        // 跳转VIP
        memberVip() {
            this.$router.push({ name: 'memberVip' })
        },
        // 隐藏显示
        sildeOff() {
            if (this.silde === 0) {
                if (this.sUserAgent == 'phone') {
                    this.silde = -78
                } else {
                    this.silde = -50
                }

            } else {
                this.silde = 0
            }

        },
        // 返回顶部
        backTop() {
            document.getElementById('content').scrollTo(0, 0)
        },
        taskGetList1(){
            console.log($api)
            $api.taskReceive1().then(res =>{
                this.tasksRecommendedList=res.data
            })
        },
        taskGetList3(val){
            $api.taskReceiven(val).then(res =>{
                this.tasksRecommendedList3=res.data
            })
        },
        taskGetList4(val){
            $api.taskReceiven(val).then(res =>{
                this.tasksRecommendedList4=res.data
            })
        },
        // 打开红包
        redPacket(is) {
            if (is) {
                this.immediatelyShopping = true
            } else {
                this.immediatelyShopping = false
            }



        },
        QQ() {
            this.QQ_qr_code_is_show = !this.QQ_qr_code_is_show
            setTimeout(() => { this.QQ_qr_code_is_show = false }, 5000)
        },
        // 渲染列表
        async getredList() {
            let { data, code } = await $api.getRebBackList()
            if (code != 200) return
            this.redList = data
        },
        // 打开红包
        async OpenRebBack(sum, item) {

            let redOpen = {}
            if (sum == 1) {
                redOpen = {
                    type: sum,
                    id: item.id
                }
            }
            if (sum == 2) {
                redOpen = {
                    type: sum,
                    key: this.packet
                }
            }
            try {
                let { data, code } = await $api.getOpenRebBack(redOpen)
                if (code != 200) return
                this.$confirm('您领取了，价值' + data + '的红包', '领取', {
                    customClass: 'redPacket',
                    confirmButtonText: '确定',
                    type: 'success'
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '领取成功!'
                    });
                })

                this.$notify({
                    message: data,
                    type: 'success',
                    position: 'top-left'
                });
                this.$store.dispatch('userInfo')
            } catch (e) {
                console.log(e)
            }
        }
    },
}
</script>

<style scoped lang="scss">
@media (max-width:600px) {
    .silde-box {
        // background-color: #000;
    }
}

.ActivityCenter {
  outline: none;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #848492;

  .ActivityCenter1 {
    width: 1129px;
    height: 781px;
    left: calc(50% - 550px);
    top:  calc(50% - 390px);
    position: relative;
    border-right: 10px;
    background-size: 100% 100%;
    @media (max-width:600px) {
        width: 100%;
        left: inherit;
    top: inherit;
    }
    .activeTitle {
      width: auto;
      height: 80px;
      background: url("../assets/img/news/task_pc.png") no-repeat;
      background-size: auto 60px;
      background-position: center;
      @media (max-width:600px) {
        background-size: auto 40px;
        margin-top: 40px;
      }
    }
    .ActivityCenter2 {
      display: flex;
      // background: #22232E;
      padding: 20px;
      z-index: 999;
      height: 690px;
      .active-left {
        flex: 1;
        height: 100%;
        // background: linear-gradient(180deg, #1d492524 0%, #22300b 100%);
        background: url("../assets/img/news/bgrq.png") no-repeat;
        background-size: 801px 614px;
        border-radius: 5px;
        @media (max-width:600px) {
            width: 100%;
            background-size: 100% 614px;
        }
        .left-top {
          .left-top-button {
            white-space: nowrap;
            display: flex;
            overflow: hidden;
            .active-button {
              width: 100%;
              border: 0;
              max-width: 100%;
              min-width: auto;
              min-height: 44px;
              font-size: 14px !important;
              margin-left: 1px;
              padding-top: 14px;
              margin-right: 1px;
              padding-left: 12px;
              padding-right: 12px;
              padding-bottom: 6px;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              font-size: 18px;
              text-align: center;
              color: #c3c3e2;
            }
            .tab_act {
              // background: linear-gradient(0deg, #1B1C27, #313348);
              // border: 1px solid #000000;  
            }
            .tab_act2 {
              color: #fff;
              font-weight: bold;
              // background: #000;
            }
          }
          .left-center {
            width: 100%;
            height: calc(100% - 44px);
            background-color: yellow;
          }
        }
      }
      .active-right {
        width: 25%;
        background: url("../assets/img/news/rbg.png") no-repeat;
        height: 100%;
        margin-left: 14px;
        background-size: 272px 615px;
        @media (max-width:600px) {
            display: none;
        }
      }
    }
    .activeDel {
      position: absolute;
      top: 0;
      right: -20px;
      z-index: 9999;
      color: rgb(255, 183, 165);
      @media (max-width:600px) {
        right: 10px;
      }
      .activedel1 {
        padding:10px;
        color: #000;
        margin-top: 20px;
        font-weight: bold;
// background: #FFC67B;
        // border-radius: 50%;
        height: 80px;width: 80px !important;
        background-repeat: no-repeat;
        background-image: url('../assets/img/news/close.png');
        width: 100%;
        display: flex;
        align-items: inherit;
        justify-content: inherit;
      }
    }
  }
}

.silde {
    top: 30%;
    right: 0;
    width: 160px;
    z-index: 999;
    padding: 20px 0;
    background-image: url("../assets/img/silde/Rank.png");
    background-size: 100% 100%;
    transition: all .5s;

    @media (max-width:1000px) {
        top: 25%;
        font-size: 12px;
        width: 80px;
        /*display: none;*/
    }

    &-off {
        top: 35%;
        left: -20px;
        // background-color: var(--main-yellow);
        width: 20px;
        height: 50px;
        font-size: 24px;
        line-height: 50px;

    }

    &-serve {
        /*border:1px solid #81cdf8;*/
        border-radius: 50px;
        margin-bottom: 0px;
        width: 120px;
        height: 70px;

        @media (max-width:1200px) {
            width: 80px;
            height: 40px;
            background-size: 100% 100%;
        }
    }

    &-weixin {
        /*border: 1px solid #81cdf8;*/
        right: 100%;
        border-radius: 10px;
        padding-right: 10px;
        display: none;
    }

    &-W:hover &-weixin {
        display: block;
    }
}

.immediatelyShopping {

    width: 350px;
    left: calc(50% - 175px);
    top: calc(25%);
    z-index: 999;
    background-color: #c02932;
    border-radius: 10px;

    &-list {
        height: 300px;
        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &-input {
        width: 150px;
        margin: 10px auto;
        background-color: #fffad8;
        border-radius: 5px;

        & input {
            width: 140px;
        }
    }

    &-item {
        &-img {
            width: 150px;
            height: 80px;
        }
    }

    &-pk {
        width: 100%;
        margin: 5px auto;
        background-color: #bd3840;
        padding: 10px;
        border-radius: 5px;
    }

    @media (max-width:1200px) {
        width: 70%;
        left: 15%;
        top: calc(80px + 5%);

        &-box {

            &-con {
                &-img {
                    width: 50%;
                }

                & img {
                    width: 100%;
                }

                &-dis {
                    margin-left: 20px !important;
                    margin-top: 10px;
                }
            }
        }
    }

}
</style>
