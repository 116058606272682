

import {request} from "../index";

function getarticle(){
    return request({
        url: `/article`,
        method:'get'
    })
}
function getarticledetail(id){
    return request({
        url: `/article/detail?id=${id}`,
        method:'get'
    })
}

function dwSkins(){
    return request({
        url:`/dw/skins `,
        method:'post'
    })
} 

function dwList(){
    return request({
        url:`/dw/list`,
        method:'post'
    })
}

export default {
    getarticle,
    getarticledetail,
    dwSkins,
    dwList
}
