<template>
  <div class="d-flex">
    <!-- 手机端 内容区域 -->
    <div class="content mt-4 d-flex flex-wrap">
      <!--      支付方式 -->
      <div class="content-pay" style="border-radius:10px 0px 0px 10px">
        <div class="d-md-block d-none">
          <div class="d-flex justify-content-between w-100" style="font-size: 12px;flex-wrap: wrap;">
            <div v-for="(item,index) in zfb" class="mpaybtn d-flex align-items-center p-2 cursor-pointer  justify-content-center"
              :class="(payActive == item.pay_name&&payModeActive=='zfb') ? 'activeMpaybtn' : ''" style="flex: 0 0 50%;" @click="payModeAlipay('zfb',item.type,item.pay_name)">

              <div>
                <img src="@/assets/newImg/alipay-fill.png" />
              </div>
              <div class="mtyoe">支付宝充值({{index+1}})</div>
            </div>
            <div v-for="(item,index) in wx" class="mpaybtn d-flex align-items-center p-2 cursor-pointer  justify-content-center"
              :class="(payActive == item.pay_name&&payModeActive=='wx') ? 'activeMpaybtn' : ''" style="flex: 0 0 50%;" @click="payModeAlipay('wx',item.type,item.pay_name)">

              <div>
                <img src="@/assets/newImg/mwx.png" style="height: 20px;width: 20px;" />
              </div>
              <div class="mtyoe">微信充值({{index+1}})</div>
            </div>
            

          </div>
        </div>
        <div class="mt-5 d-sm-block d-none text-center" style="font-size: 18px">
          <div class="mb-3 mTitp">实付金额</div>
          <div style="font-size:16px">
            <span style="font-size:12px">RMB</span>{{ resData.price }}
          </div>


        </div>


        <div class="d-sm-block d-none">
          <div class="mt-5 d-flex justify-content-center">
            <img class="" width="200px" height="200px" v-lazy="codeIsShow || require('@/assets/img/code_pay.png')"
              alt="">
          </div>
        </div>


        <div class="text-center d-sm-block d-none mt-4" style="font-size: 12px;    color: #c2c2c2;
">
          请使用 <span class="color-yellow"
            style="color: #48FFFF !important;">”{{ payModeActive == 'zfb' ? '支付宝' : '微信' }}扫一扫“</span>确认支付
          <div class="mt-2">二维码只能使用一次，再次充值请重新选择金额</div>
        </div>

        <div class="mt-5 d-sm-block d-none text-center"
          style="margin-top: 60px !important; margin-bottom: 60px; font-size: 14px;color: #c2c2c2;">

          <div>
            <img src="@/assets/newImg/luj.png" height="30px" style="margin-bottom: 10px;" />
          </div>
          支付环境安全，请放心使用
        </div>
        <p class="mpaybot">如果充值遇到问题，请更换充值方式，或者联系客服</p>
      </div>
      <!--      选择金额 -->
      <div class="content-money p-sm-5 " style="border-radius:0px 10px 10px 0px">
        <div class="h5 text-center mt-2">
          <span class="h6 color-yellow"><!-- 暂时不添加 -->当前等级V{{ user_info.vip_level }} </span>在线充值
        </div>

        <div class="my-3 mInfoTip">
          如果充值遇到问题，请更换充值方式，或者联系客服

          <p style="color:#e94d8b;font-size:12px"> 客服QQ:394412544</p>
        </div>
        <!--    选择金额    -->
        <div style="margin-bottom: 30px;" class="content-money-list d-flex flex-wrap justify-content-between ">
          <div class="content-money-list-item cursor-pointer position-relative mt-3 rounded"
            v-for="(item, index) in beansList" :key="index" @click="beansClick(item, (index + 1))"
            :class="index == beansIndex - 1 ? 'active' : ''">
            <div class="d-md-flex d-block justify-content-center mt-md-0 mt-3">
              <div class="mr-2 h4">
                <money style="width: 20px;height: 20px" />
                {{ (item.bean * 1).toFixed(0) }}
              </div>
            </div>
            <div style="font-size:12px">RMB {{ item.price }}</div>
            <div class="position-absolute content-money-list-item-vip px-3 py-1 text-white"
              style="transform:rotate(-45deg);background-color: var(--main-blue)" v-if="user_info.is_recharge == 0">
              <span>{{ item.firdt.ratio }}.00%</span>
            </div>
            <div v-if="user_info.is_recharge == 0">
              {{ item.firdt.description }}
            </div>
          </div>
        </div>
        <!-- 卡密充值 -->
        <div class="d-flex mt-3 mkamiczhi">
          <div class="mbox">
            <span>卡密充值</span>
            <input placeholder="请输入卡密" style="border: 1px solid var(--main-blue)" class="text-white pl-3 kami rounded"
              type="text" v-model="CardRecharge">
            <div class=" f_btn3  cursor-pointer ml-2 mt-1" @click="getCardRecharge">
              充值
            </div>
          </div>

          <p>为了保证您能顺利的领取到奖品，请您再充值前确认本账号是否被
            Steam限制交易，请自行迁往Steam社区查看该账号的交易安全内容</p>
        </div>
        <!-- 手机端充值按钮 -->
        <div class="d-md-none d-block">
          <div v-for="(item,index) in zfb" class="d-flex justify-content-between w-100"
            style="font-size: 12px; border: 1px solid #ccc; margin-top: 10px; border-radius: 10px">
            <div class="d-flex align-items-center p-2 cursor-pointer col-12 justify-content-center"
              :class="payActive == item.pay_name ? 'active' : ''" @click="payModeAlipay('zfb',item.type,item.pay_name)">

              <div>
                <img src="@/assets/newImg/alipay-fill.png" style="margin-right: 4px;" height="24px" width="24px" />
              </div>
              <div class="">支付宝充值({{index+1}})</div>
            </div>

            

          </div>
          <div v-for="(item,index) in wx" class="d-flex justify-content-between w-100"
            style="font-size: 12px; border: 1px solid #ccc; margin-top: 10px; border-radius: 10px">
            

            <div class="d-flex align-items-center p-2 cursor-pointer col-12 justify-content-center"
            :class="payActive == item.pay_name ? 'active' : ''" @click="payModeAlipay('wx',item.type,item.pay_name)">

              <div>
                <img src="@/assets/newImg/mwx.png" style="margin-right: 4px;" height="24px" width="24px" />
              </div>
              <div class="">微信充值({{index+1}})</div>
            </div>

          </div>
        </div>

        <!-- 提示 -->
        <!-- <div class="mt-5 bg-size bg-repeat justify-content-center d-flex align-items-center">

              <div style="font-size: 12px;line-height: 28px" class="title_qian">
                  为了保证您能顺利的领取到奖品，请您再充值前确认本账号是否被
                  Steam限制交易，请自行迁往Steam社区查看该账号的交易安全内容
              </div>
          </div> -->
      </div>
    </div>
    <!-- 电脑端吗 -->

    <div class="maskwechat" v-if="maskwechatStatus">
      <img :src="wechatCode"  width="200px" height="200px" />
      <p>请截图,用微信扫一扫支付</p>
      <a @click="maskwechatStatus=false">关闭</a>
    </div>

  </div>
</template>


<script>

import $api from '@/api/user/index'
import $apiVip from '@/api/vip/index'
import sUserAgent from '@/assets/js/common.js'
import { mapState } from 'vuex'

export default {
  name: "recharge",
  data() {
    return {
      maskwechatStatus:false,
      wechatCode:'',
      isiOS: false,
      isiOSUrl: '',
      tableData: [],
      beansList: [],
      beansIndex: 100,
      resData: {
        price: ''
      },
      CardRecharge: '',
      payModeActive: 'zfb',
      payActive:'',
      payUrl: '',
      money: '',
      time: 'time',
      total: 0,
      pageSize: 0,
      firdtList: [],
      beansClickData: {
        id: 0
      },
      Vip: {},
      Viptips: {},
      zfb:[],
      wx:[]
    }
  },
  created() {
  },
  mounted() {
    this.init()
  },
  computed: {
    ...mapState(['user_info', 'web_info']),
    codeIsShow: {
      get() {
        return this.payUrl
      }
    },
    sUserAgent() {
      return sUserAgent.sUserAgent()
    }
  },
  beforeDestroy() {
    clearInterval(this.time)
  },
  methods: {
    async init() {
      this.getBeans()
      this.getRecharge_record()

      this.getPayList()

      setTimeout(() => {
        this.money = JSON.parse(JSON.stringify(this.$store.state.user_info.bean))
        this.getVipList()
      }, 500)
    },
    getPayList() {
       $api.getPayList().then(res => {
       this.zfb = res.data.zfb;
       this.wx = res.data.wx;
       this.payActive = res.data.zfb[0]?.pay_name
       
      })
    },
    async getBeans() {
      try {
        let { data } = await $api.getBeans()
        let { data: firdt } = await $api.getbeans_first()
        console.log('data,firdt')
        data.forEach((item) => {
          firdt.forEach(t => {
            if (t.bean == item.bean) {
              item.firdt = t
            }
          })

        })
        console.log(data)
        this.firdtList = firdt
        this.beansList = data
        this.resData.price = data[0].price
      } catch (e) {
        console.log(e)
        return
      }
    },
    getVipList() {
      $apiVip.getVipList().then(res => {
        this.Vip = res.data.list.find((item) => {
          return item.level == this.user_info.vip_level
        })

        this.Viptips = res.data.tips
      })
    },
    beansClick(item, index) {
      this.beansIndex = index
      this.resData.price = item.price
      let data = {
        id: item.id, pay_way: 1
      }
      this.beansClickData = data
      if (this.sUserAgent === 'web') {
        this.payModeAlipay(type)
      }

    },
    payModeAlipay(payType,type,active) {
      if( this.user_info.id_number == '' || this.user_info.id_number === null ){
        this.$message('当前未实名认证,请前去认证,跳转中...')
        setTimeout(() => {
          this.$router.push('/user/user')
        },1000)
        return false;
      }
      if (this.beansClickData.id == 0) {
        this.$message('选择充值金额')
        return
      }
      this.payActive = active;
      this.payModeActive = payType;
      this.getRecharge({ id: this.beansClickData.id, pay_way: type,pay_name: active})
      
      
    },

    async getCardRecharge() {
      try {
        let { code, message } = await $api.getCardRecharge({ card: this.CardRecharge })
        if (code == 500) return
        if (code == 200) {
          this.$message.success(message)
          this.CardRecharge = ''
        }
        this.init()
      } catch (e) {
        console.log(e)
        return
      }
    },
    async getRecharge_record(page = 1) {
      try {
        let { data, code } = await $api.getRecharge_record(page)
        if (code == 500) return
        this.tableData = data.data
        this.total = data.total
        this.pageSize = data.per_page
      } catch (e) {
        console.log(e)
        return
      }
    },
    currentChange(val) {
      this.getRecharge_record(val)

    },
    async getRecharge(data = { id: 1, pay_way: 6 }) {
      let iosUrl = null
      if (this.sUserAgent != 'web') {
        if( this.payModeActive == 'zfb' ){
          iosUrl = window.open('', '_blank ')
        }  
        
      }
      let loading = null
      try {
        loading = this.$loading({ lock: true, text: '正在加载', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' })
        let { data: resData, code, message } = await $api.getRecharge(data)

        if (code == 500) {
          this.$message('错误，' + message)
          loading.close();
          return
        }

        if (this.sUserAgent == 'web') {
          this.payUrl = resData.list.qr_url
        } else {
          if( this.payModeActive == 'zfb' ){
            iosUrl.location = resData.list.h5
          } else {
            this.wechatCode = resData.list.qr_url;
            this.maskwechatStatus = true;
          }
        }
        setTimeout(async () => {
          loading.close();
          this.$message('请稍后......')
          this.getUsetInof()
        }, 500);
      } catch (e) {
        loading.close();
        this.getUsetInof()
        this.$message('抱歉，请稍后......')
        let routeUrl = this.$router.resolve({
          path: "/user/Payment",
          query: {
            id: data.id,
            pay_way: data.pay_way
          }
        });
        
        window.open(routeUrl.href, '_self')
      }
    },
    async getUsetInof() {
      this.time = setInterval(async () => {
        try {
          let { data, code } = await $api.getUsetInof()
          if (code == 500) return
          if (data.bean > this.money) {
            this.$store.dispatch('userInfo')
            this.$message.success('充值成功')
            this.money = JSON.parse(JSON.stringify(this.$store.state.user_info.bean))
            clearTimeout(this.time)
          }
        } catch (e) {
          console.log(e)
        }
      }, 1000)
    },
  }
}
</script>


<style scoped lang="scss">

@media (max-width:600px) {
  .maskwechat{
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    background-color: #00000097;
    left: 0px;
    padding-top: 280px;
    text-align: center;
    > a,>p{
      display: block;
      width: 100%;
      margin-top: 10px;
    }
    > a{
      padding: 0px;
    width: 80px;
    justify-content: center;
    display: flex;
    margin: 20px auto;
    text-align: center;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: linear-gradient(90deg, rgba(19, 108, 172, 0.5) 0%, rgba(66, 77, 181, 0.2) 100%);
    box-sizing: border-box;
    border: 1px solid rgba(45, 116, 222, 0.3);
    }
  }
}

.activeMpaybtn{
  background-color: #1D283D !important;
}
.mpaybot {
  font-size: 12px;
  color: #c2c2c2;
  margin-top: 20px;
  padding: 0 10px;
  margin-bottom: 20px;
  text-align: center;
}

.mTitp {
  font-weight: bold;
  font-size: 22px;
}

.mpaybtn {
  padding: 10px 0 !important;
  background: linear-gradient(180deg, rgba(0, 174, 255, 0.3) 0%, rgba(0, 30, 182, 0) 100%);
  // margin-bottom: 20px;

  img {
    margin-right: 4px;
  }

  div {
    display: flex;
    align-items: center;
  }
}

.mtyoe {
  font-size: 16px;
  font-weight: bold;
}

.mkamiczhi {
  display: flex;
  @media (max-width:600px) {
    display: block !important;
  }

  >.mbox {
    display: flex;
    margin-right: 20px;
    width: 500px;
    background: rgba(0, 0, 0, 0.2);
    align-items: center;
    height: 60px;
    @media (max-width:600px) {
      margin-right: inherit;
      width: 100%;
    }

    >span {
      color: #7ABEFF;
      font-size: 16px;
      width: 130px;
      text-align: center;
    }

    >input {
      font-size: 16px;
      border: none !important;
      width: 280px;
      color: #9096a5;
      @media (max-width:600px) {
        width: 180px;
      }
    }

    >div {
      padding: 0px;
      width: 80px;
      margin: 0px !important;
      margin-right: 10px !important;
      height: 35px;
      line-height: 35px;
      text-align: center;
      background: linear-gradient(90deg, rgba(19, 108, 172, 0.5) 0%, rgba(66, 77, 181, 0.2) 100%);

      box-sizing: border-box;
      border: 1px solid rgba(45, 116, 222, 0.3);
    }
  }

  >p {
    font-size: 12px;
    color: #c2c2c2;
    @media (max-width:600px) {
      padding: 20px 0;
      text-align: center;
    }
  }
}

.mInfoTip {
  position: absolute;
  right: 14px;
  font-size: 12px;
  top: 8px;
  color: #c2c2c2;

  @media (max-width:600px) {
    display: none;
  }
}

.kami {
  width: 400px;

  @media (max-width: 1366px) {
    width: 200px;
  }

  @media (max-width: 1200px) {
    width: 200px;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.side-box {
  width: 204px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5)
}

.content {
  width: 1400px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    width: 98%;
    font-size: 12px;
    margin-top: 0px !important;
  }

  &-shou {
    background-color: var(--main-bg-2);
    margin-bottom: 20px;
  }

  &-money {
    width: 70%;

    position: relative;

    overflow: hidden;
    padding: 20px 60px !important;
    opacity: 1;

    background: linear-gradient(180deg, rgba(19, 108, 172, 0.5) 0%, rgba(66, 77, 181, 0.2) 100%);

    box-sizing: border-box;
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 19%, #34B8FF 55%, rgba(0, 0, 0, 0) 100%) 1;
    // background-color: var(--main-bg-2);

    @media (max-width: 1200px) {
      width: 100%;
      background: inherit;
      border: inherit;
      padding: 20px 10px !important;
      padding-top: 0px !important;
    }

    &-list {

      &-item {
        width: 32%;
        text-align: center;
        padding: 20px 0;
        margin-bottom: 0px;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.1);

        &-vip {
          color: var(--main-yellow);
          left: -20px;
          top: 7px;

          & img {
            width: 30px;
            height: 20px;
          }

          @media (max-width: 1000px) {
            width: 100px;
            left: -22px;
            top: 15px;
          }
        }
      }

      & .active {
        // background-color: var(--main-yellow);
        border-radius: 10px;
        opacity: 1;

        background: linear-gradient(180deg, rgba(19, 108, 172, 0.8) 0%, rgba(66, 77, 181, 0.5) 100%);

        box-sizing: border-box;
        border: 1px solid;
        border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 19%, #34B8FF 55%, rgba(0, 0, 0, 0) 100%) 1;
      }
    }

    &-Siberian {
      height: 38px;
      line-height: 38px;

      @media (max-width: 1200px) {
        font-size: 12px;
      }
    }
  }

  &-pay {
    margin-right: 2%;
    background: linear-gradient(180deg, rgba(19, 108, 172, 0.5) 0%, rgba(66, 77, 181, 0.2) 100%);

    box-sizing: border-box;
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 19%, #34B8FF 55%, rgba(0, 0, 0, 0) 100%) 1;
    width: 28%;
    // background-color: var(--f-main);

    @media (max-width: 1200px) {
      width: 100%;
      display: none;
    }

    & .icon {
      font-size: 50px;
    }

    & .active {
      // background-color: var(--f-main);
    }
  }
}

.record {
  width: 100%;
  background-color: var(--main-bg-2);
  padding: 20px;
}
</style>
