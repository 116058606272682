<template>
  <!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
  <div class=" rounded position-relative" :style="'width:100%'" >
    <!-- :class="'ggbg' +resData.lv" -->
    <div class="bg-size-100  bg-repeat position-relative d-flex flex-wrap
        justify-content-center align-items-center "
         :style="`background-image:url(${resData.lv_bg_image});background-size:100% 100%`"
          style="border-radius: 5px;">
        <div class="position-absolute bglogo-e" style="width: 30px; height: 30px;z-index:999"></div>
        <img height="60px" class="position-relative z-index-1"
             :src="resData.cover" alt="" style="opacity:0.6; ">

        </div>
    <div class="py-2 text-center text-overflow-2" style="font-size: 12px">
          <div class="mb-3"><money />{{resData.bean}}</div>
        </div>
    <div class="position-absolute get_user">
      <img width="100%" :src="resData.get_user.avatar" alt="">
    </div>
    <slot> </slot>
    </div>
</template>

<script>
    export default {
        name: "weaponhist",
        props:{
            //    传输的item
            resData:{
                type:Object,
                default(){return {}}
            }
        },
    }
</script>

<style scoped lang="scss">
  .get_user{
    top: calc(30% - 15px);
    right: -8px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    overflow: hidden;
    // clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  }
    .weapon{
        &-name{
            padding: 10px 0;
        }
    }
</style>
