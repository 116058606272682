<template>
  <div class="header p-0 m-0">
    
    <div class="header_PC">

      <div class="g1HeaderBox">
      <div class="g1Logo">
        <img src="@/assets/newImg/logo.png" />
      </div>
      <div class="g1Menu">
        <a @click="ma = 0,$router.push('/')" :class="{ 'active': ma == 0 }">
          <img v-if="ma == 0" src="@/assets/newImg/1_on.png" />
          <img v-else src="@/assets/newImg/1.png"/>高爆盲盒
        </a>
        <a @click="ma = 1,$router.push('/xsteryBox')" :class="{ 'active': ma == 1 }">
          <img v-if="ma == 1" src="@/assets/newImg/2_on.png" />
          <img v-else src="@/assets/newImg/2.png"/>竞技场
        </a>
        <a @click="ma = 2,$router.push('/lucky')" :class="{ 'active': ma == 2 }">
          <img v-if="ma == 2" src="@/assets/newImg/3_on.png" />
          <img v-else src="@/assets/newImg/3.png"/>追梦
        </a>
        <a @click="ma = 3,$router.push('/compound')" :class="{ 'active': ma == 3 }">
          <img v-if="ma == 3" src="@/assets/newImg/4_on.png" />
          <img v-else src="@/assets/newImg/4.png"/>置换合约
        </a>
        <a @click="ma = 4,$router.push('/rollHome')" :class="{ 'active': ma == 4 }">
          <img v-if="ma == 4" src="@/assets/newImg/5_on.png" />
          <img v-else src="@/assets/newImg/5.png"/>ROLL房
        </a>
        <a @click="ma = 5,$router.push('/shopping')" :class="{ 'active': ma == 5 }">
          <img v-if="ma == 5" src="@/assets/newImg/6_on.png" />
          <img v-else src="@/assets/newImg/6.png"/>积分商城
        </a>
        <a @click="ma = 6,$router.push('/outOfTheFree')" :class="{ 'active': ma == 6 }">
          <img v-if="ma == 6" src="@/assets/newImg/7_on.png" />
          <img v-else src="@/assets/newImg/7.png"/>福利宝箱
        </a>
        <a @click="ma = 7,$router.push('/user/welfare')" :class="{ 'active': ma == 7 }">
          <img v-if="ma == 7" src="@/assets/newImg/8_on.png" />
          <img v-else src="@/assets/newImg/8.png"/>合伙人
        </a>
      </div>
    </div>
      <div
        style=""
        class="header_PC position-fixed top-0 right-0 top d-flex align-items-center"
      >
        <div
          v-if="login_is_show"
          @click="login"
          class="ml-auto cursor-pointer mr-3 mhlogbtn"
        >
          <img src="@/assets/newImg/logres.png" height="45px" />
        </div>
        <div v-else>
          <div class="header-user mr-2">
            <div class="d-flex position-relative mr-5 w-100">
              <router-link
                to="/user/user"
                class="header-user-img position-relative"
              >
                <img
                  width="40px"
                  height="40px"
                  class="rounded-circle"
                  :src="user_info.avatar"
                  alt=""
                />
                <span class="header-user-name-vip position-absolute">
                  <img
                    :src="`${require(`@/assets/img/nav/${user_info.vip_level}.png`)}`"
                    alt=""
                /></span>
              </router-link>
              <div
                class="ml-1 px-2 d-flex flex-column justify-content-center header-user-name cursor-pointer"
                style="
                  font-size: 14px;
                  line-height: 16px;
                  border-right: 1px solid var(--main-dark-brown);
                "
              >
                <router-link
                  to="/user/user"
                  class="text-overflow-1 mb-1 d-flex"
                  style="font-weight: 700"
                >
                  {{ user_info.name }}
                </router-link>
                <div class="d-flex" style="">
                  <router-link
                    to="/user/info"
                    class="text-overflow-1 mr-1 f-yellow-color"
                    >个人背包
                  </router-link>
                  /
                  <router-link
                    to="/user/user"
                    class="text-overflow-1 ml-1 f-yellow-color"
                  >
                    资料设置</router-link
                  >
                </div>
              </div>
              <router-link
                to="/user/recharge"
                style="
                  border: 1px solid var(--main-dark-brown);
                  border-radius: 5px;
                "
                class="d-flex ml-3"
              >
                <div
                  class="px-1 cursor-pointer d-flex align-items-center pl-2"
                  style="height: 100%"
                >
                  <div>
                    <div class="d-flex">
                      <money color="#FFF" />
                      {{ user_info.bean || "0.00" }}
                      <br />
                    </div>
                    <div class="d-flex">
                      <money :integral="false" color="#FFF" />
                      {{ user_info.integral || "0.00" }}
                    </div>
                  </div>
                  <div
                    class="bgmolv text-center rounded-circle font-weight-bold ml-2"
                    style="line-height: 30px; width: 30px; height: 30px"
                  >
                    +
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <!-- <div class="mr-5" @click="laba_off_on">
          <img
            v-if="off_laba"
            width="25px"
            :src="require('@/assets/img/f_new/laba.png')"
            alt=""
          />
          <img
            v-else
            width="25px"
            :src="require('@/assets/img/f_new/laba_off.png')"
            alt=""
          />
        </div> -->
      </div>
    </div>

    <div class="header_PHONE">
      <!-- 手机下按钮 -->
      <div
        class="position-fixed top-0 d-flex justify-content-between align-items-center px-1 w-100 warpBg"
        style="height: 56px"
      >
        <!-- <div @click="mobile_click_nav = !mobile_click_nav">
          <i class="el-icon-s-unfold h2"></i>
        </div> -->
        <div class="">
          <router-link to="/">
            <img
              style="margin-left:10px;"
              class="py-1"
              :src="require('@/assets/newImg/logo.png')"
              height="70px"
              alt=""
            />
          </router-link>
        </div>

        <div
          v-if="login_is_show"
          @click="login"
          class="cursor-pointer mr-3"
        >
          <div class="">
            <img src="@/assets/newImg/logres.png" height="38" />
          </div>
        </div>
        <div v-if="!login_is_show" class="ml-md-auto d-flex align-items-center">
          <div
            class="position-relative d-none"
            style="margin-right: 10px"
            @click="$router.push({ path: '/user/user' })"
          >
            <img
              width="35px"
              height="35px"
              class="rounded-circle"
              :src="user_info.avatar"
              alt=""
            />
            <span class="header-user-name-vip position-absolute"
              ><img
                :src="`${require(`@/assets/img/nav/${user_info.vip_level}.png`)}`"
                alt=""
            /></span>
          </div>

          <div
            class="mx-2"
            style=""
            @click="$router.push({ path: '/user/recharge' })"
          >
            <div
              class="px-1 text-overflow-1 cursor-pointer d-flex align-items-center text-white py-2 px-3 mmmriroeger"
              style="font-size: 14px;"
            >
              <div>
                <div class="d-flex" style="font-size: 12px;align-items: center;margin-bottom: 5px;">
                  <img src="@/assets/img/shandian_ceshi.png" style="margin-right: 2px;" height="16" width="16" />
                  {{ user_info.bean || "0.00" }}
                </div>
                <div class="d-flex" style="font-size: 12px;align-items: center;">
                  <img src="@/assets/img/zs.png" style="margin-right: 2px;" height="16" width="16" />
                  {{ user_info.integral || "0.00" }}
                </div>
              </div>
            </div>
          </div>

          <div class="d-none">
            <i
              class="el-icon-s-unfold h2"
              @click="mobile_click_nav = !mobile_click_nav"
            ></i>
          </div>
        </div>
      </div>
      <!-- 点击之后显示的导肮 -->
      <div
        v-if="mobile_click_nav"
        class="position-fixed bg-bg"
        style="z-index: 99; top: 56px; left: 20px; right: 20px"
      >
        <div
          @click="mobile_click_nav_url(item, index)"
          v-for="(item, index) in routers"
          :key="index"
          :class="mobile_click_nav_index == index ? 'phone_nav_list' : ''"
        >
          <div
            class="d-flex py-3 pl-5 phone_nav px-3 border-t-b"
            v-if="item.meta.isShow"
            style=""
          >
            <div class="mr-4 color-yellow">
              <i
                class="iconfont"
                :class="item.meta.icon"
                style="font-size: 24px"
              ></i>
            </div>

            <div class="header-conter-item-text">
              {{ item.meta.title }}
            </div>
          </div>
        </div>

        <div class="">
          <div
            @click="loginOut"
            class="pl-2 d-flex align-items-center px-3 border-t-b"
            style="line-height: 50px"
          >
            <div class="mr-4">
              <i class="iconfont icon-jiahao" style="font-size: 24px"></i>
            </div>

            <div class="header-conter-item-text">退出登录</div>
          </div>
        </div>
      </div>
    </div>

    <div class="header-phone-left position-relative align-items-center">
      <div
        v-show="is_show_nav_phone ? 'd-block' : ''"
        class="position-absolute header-phone-left-nav"
      >
        <router-link
          v-for="(item, index) in routers"
          :key="index"
          :to="'/' + item.path"
          v-show="item.meta.isShow"
          class=""
          :active-class="'header-conter-active header-phone-left-active'"
        >
          <div class="pl-3 py-3" style="">
            <div class="">
              {{ item.meta.title }}
            </div>
          </div>
        </router-link>
      </div>
      <div
        @click="offNav"
        v-if="is_show_nav_phone ? 'd-block' : ''"
        class="header-phone-left-nav-bg"
      ></div>
    </div>

    <!-- pc下导航 -->
    <!-- <div class="pc animate__animated " :class="isShowNavActive?'d-none ':' animate__slideInLeft'">
            <div class="d-flex pc flex-column ">
                <div @click="isShowNav" class="position-absolute " style="left: 210px;top:20px">
                    <i class="el-icon-s-unfold h2"></i>
                </div>
                <router-link to="/" class="py-3 d-block">
                    <img height="160px" class="" style=""
                         :src="require('@/assets/img/logo.png')" alt="">
                </router-link>
                <div class="">
                    <router-link v-for="(item,index) in routers" :key="index" :to="{name:item.name}" v-show="item.meta.isShow"
                                 class="header-conter-item"
                                 :active-class="item.name == $route.name ? 'header-conter-active':''">
                        <div class="pl-5 d-flex align-items-center" style="line-height:50px; ">
                            <div class=" mr-4 color-yellow" >
                                <i class="iconfont" :class="item.meta.icon" style="font-size: 22px"></i>
                            </div>

                            <div class="header-conter-item-text">
                               {{item.meta.title}}
                            </div>
                        </div>
                    </router-link>
                  <a @click="loginOut"
                               class="header-conter-item cursor-pointer">
                    <div class="pl-5 d-flex align-items-center" style="line-height:50px; ">
                      <div class=" mr-4 color-yellow" >
                      </div>
                      <div class="header-conter-item-text">
                       退出登录
                      </div>
                    </div>
                  </a>

                </div>

                <div class="d-flex justify-content-between p-2">

            </div>
               
            </div>
        </div> -->
    <!-- 点击收缩 -  -->
    <div
      class="shrink animate__animated"
      :class="isShowNavActive ? 'animate__slideInLeft' : 'd-none'"
    >
      <!-- 收缩按钮 -->
      <div
        @click="isShowNav"
        class="position-absolute"
        style="left: 80px; top: 20px"
      >
        <i class="el-icon-s-unfold h2"></i>
      </div>
      <!-- 手机下logo -->
      <router-link to="/" class="header-phone-logo d-block">
        <img
          width="100%"
          class=""
          style=""
          :src="require('@/assets/img/nav/logo-y.png')"
          alt=""
        />
      </router-link>

      <div class="mt-5 mt-5">
        <router-link
          v-for="(item, index) in routers"
          :key="index"
          :to="'/' + item.path"
          v-show="item.meta.isShow"
          class=""
          :active-class="'header-conter-active'"
        >
          <div
            class="d-flex justify-content-center align-items-center py-2 position-relative shrink-con"
            style="height: 50px; width: 100%"
          >
            <div class="h5">
              <i class="iconfont icon-" :class="item.meta.icon"></i>
            </div>

            <div
              class="shrink-title position-absolute top-0 bg-bg border-bottom-blue"
              style="line-height: 50px"
            >
              {{ item.meta.title }}
            </div>
          </div>
        </router-link>
        <div
          @click="loginOut"
          class="d-flex justify-content-center align-items-center py-2 position-relative shrink-con"
          style="height: 50px; width: 100%"
        >
          <div class="h5">
            <i class="iconfont icon-guanbi-02-02"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- 点击之后显示的导肮 - 只关注手机端 -->
    <div class="position-fixed app-phone d-md-none d-block" style="z-index: 99">
      <div class="app-phone-nav">
        <div
          class="d-flex app-phone-nav-item flex-column align-items-center"
          @click="$router.push('/')"
          :class="`${$route.name === 'SCGOindex' ? 'nav-active' : ''}`"
        >
          <img
            v-if="$route.name === 'SCGOindex'"
            style="width: 35px"
            src="@/assets/newImg/warpbot/1.png"
            alt=""
          />
          <img
            v-else
            style="width: 35px"
            src="@/assets/newImg/warpbot/1.png"
            alt=""
          />
          <span style="font-size: 12px">首页</span>
        </div>
        <div
          class="d-flex app-phone-nav-item flex-column align-items-center"
          @click="$router.push({ name: 'mysteryBox' })"
          :class="`${$route.name === 'mysteryBox' ? 'nav-active' : ''}`"
        >
          <img
            v-if="$route.name === 'mysteryBox'"
            style="width: 35px"
            src="@/assets/newImg/warpbot/2.png"
            alt=""
          />
          <img
            v-else
            style="width: 35px"
            src="@/assets/newImg/warpbot/2.png"
            alt=""
          />
          <span style="font-size: 12px">竞技场</span>
        </div>
        <div
          class="d-flex app-phone-nav-item flex-column align-items-center"
          @click="userInfo"
          :class="`${$route.name === 'info' ? 'nav-active' : ''}`"
        >
          <img
            v-if="$route.name === 'info'"
            style="width: 35px"
            src="@/assets/newImg/warpbot/3.png"
            alt=""
          />
          <img
            v-else
            style="width: 35px"
            src="@/assets/newImg/warpbot/3.png"
            alt=""
          />
          <span style="font-size: 12px"> 我的 </span>
        </div>
        <div
          class="d-flex app-phone-nav-item flex-column align-items-center"
          @click="$router.push({ name: 'lucky' })"
          :class="`${$route.name === 'lucky' ? 'nav-active' : ''}`"
        >
          <img
            v-if="$route.name === 'lucky'"
            style="width: 35px"
            src="@/assets/newImg/warpbot/4.png"
            alt=""
          />
          <img
            v-else
            style="width: 35px"
            src="@/assets/newImg/warpbot/4.png"
            alt=""
          />
          <span style="font-size: 12px">追梦</span>
        </div>
        <div
          class="d-flex app-phone-nav-item flex-column align-items-center"
          @click="$router.push({ name: 'compound' })"
          :class="`${$route.name === 'compound' ? 'nav-active' : ''}`"
        >
          <img
            v-if="$route.name === 'compound'"
            style="width: 35px"
            src="@/assets/newImg/warpbot/5.png"
            alt=""
          />
          <img
            v-else
            style="width: 35px"
            src="@/assets/newImg/warpbot/5.png"
            alt=""
          />
          <span style="font-size: 12px">置换合约</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getToken } from "@/utils/auth";
import $api from "@/api/help/index";
import { mapState } from "vuex";

export default {
  name: "headerTop",
  data() {
    return {
      ma:0,
      routers: [],
      routersUser: [],
      isShowNavActive: false,
      isShowNavPc: false,
      mobile_click_nav: false,
      mobile_click_nav_index: 0,
      off_laba: true,
      // 文章列表
      hrlpList: [],
      help: false,

      // 登陆之前点击显示
      login_is_show_pup: this.login_top_is_xie_yi,
      checked01: false,
      checked02: false,
      checked03: false,
      checked04: false,
    };
  },
  mounted() {
    this.getarticle();
    this.routers = this.$router.options.routes[0].children;
    let rouer = this.$router.options.routes.find((item) => {
      return item.name == "user";
    });
    this.routersUser = rouer.children;
    if (getToken()) {
      setTimeout(() => {
        this.$store.commit("LOGIN_IS_SHOW", false);
      }, 500);
    }
  },
  computed: {
    ...mapState([
      "web_info",
      "user_info",
      "steam_handle",
      "login_top",
      "login_is_show",
      "is_show_nav_pc",
      "is_show_nav_phone",
      "login_top_is_xie_yi",
    ]),
  },
  methods: {
    userInfo() {
      if (getToken()) {
        this.$router.push({ name: "info" });
      } else {
        this.$store.commit("LOGIN_TOP", true);
      }
    },
    login_is_show_pup_off() {
      this.login_is_show_pup = false;
      this.$store.commit("LOGIN_TOP_IS_XIE_YI", false);
    },
    // 获取文章列表
    async getarticle() {
      let { data } = await $api.getarticle();
      this.hrlpList = data;
    },
    // 注册
    register() {
      this.$store.commit("LOGIN_TOP", true);
      this.$store.commit("REGISTER", "register");
    },
    // steam 提示登录
    steamLogin() {
      this.$confirm("打开加速器，跳转更快捷呦！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(() => {
          window.open("https://api.aaskins.cn/api/steam/login", "_blank");
          // window.open("https://api.9m17.chunblog.com/api/steam/login", '_blank')
        })
        .catch(() => {});
    },
    mobile_click_nav_url(item, index) {
      this.mobile_click_nav = false;
      this.mobile_click_nav_index = index;
      this.$router.push({ path: "/" + item.path });
    },
    laba_off_on() {
      this.off_laba = !this.off_laba;
      if (!this.off_laba) {
        document.querySelectorAll("video, audio").forEach((elem) => {
          elem.muted = true;
          elem.pause();
        });
      } else {
        document.querySelectorAll("video, audio").forEach((elem) => {
          elem.muted = false;
          // elem.play();
        });
      }
    },
    userNav() {
      this.isShowNavPc = !this.isShowNavPc;
      this.$store.commit("ISSHOWNAVPC", this.isShowNavPc);
    },
    login() {
      //this.$baidu.baiduApi(4); // 百度
      // if(!this.checked01||!this.checked02||!this.checked03||!this.checked04){
      //   this.$notify({
      //     title: '警告',
      //     message: '请同意以上条款',
      //     type: 'warning',
      //     position: 'top-left'
      //   });
      //   return
      // }
      this.login_is_show_pup = false;
      if (!getToken()) {
        this.$store.commit("LOGIN_TOP", true);
      } else {
        this.$store.commit("LOGIN_IS_SHOW", false);
      }
    },
    loginOut() {
      this.$confirm("您是否退出登录！", "提示", {
        confirmButtonText: "退出",
        cancelButtonText: "取消",
        type: "success",
        customClass: "pkClass",
      })
        .then(() => {
          this.$store.commit("LOGIN_IS_SHOW", true);
          this.$store.dispatch("removeInfo");
          this.$router.push("/");
        })
        .catch(() => {
          console.log(12);
        });
    },
    // 手机端点击显示导航
    isShowNav() {
      this.isShowNavActive = !this.isShowNavActive;
      // this.$store.commit("ISSHOWNAVPC",false)
      this.$store.commit("ISSHOWNAVPHONE", true);
    },
    // 关闭导航手机端
    offNav() {
      this.isShowNavActive = false;
      // this.$store.commit("ISSHOWNAVPC",false)
      this.$store.commit("ISSHOWNAVPHONE", false);
    },
  },
};
</script>
<style scoped lang="scss">


@media (max-width:600px) {
  .mmmriroeger{
    padding-right: 0px !important;
  }
}
.mhlogbtn{
  margin: 20px 10px;
  margin-top: 36px;
}
.warpBg{
  background-size: 1680px 130px;
  z-index: 999;
  background-repeat: no-repeat;
  background-image: url(../assets/newImg/topbg.png);
  background-position: -940px 0px;
}
.g1HeaderBox {
  height: 160px;
  width: 100%;
  margin: 0 auto;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url(../assets/newImg/topbg.png);
  > .g1Logo {
    width: 1680px;
    margin: 0 auto;
    text-align: center;
    > img {
      height: 70px;
      margin-top: -5px;
    }
  }
  > .g1Menu{
    width:1600px;
    margin: 0 auto;
    margin-top:25px;
    display: flex;
    height:65px;
    > a{
      display:flex;
      align-items: center;
      padding:0 38px;
      cursor: pointer;
      > img{
        margin-right:8px;
      }
    }
    > .active{
      border-bottom: solid 4px #0D92EF;
    }
  }
}
.app-phone {
  bottom: 0;
  left: 0;
  right: 0;
  // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  // /*background-image: url('../assets/img/phone-nav/navbg-bottom.png');*/
  // background-color: #191c23;
  // background-size: 100% 100%;
  background: rgba(0, 0, 0, 0.27);

backdrop-filter: blur(10px);
  &-nav {
    display: flex;
    align-items: center;
    &-item {
      display: flex;
      align-items: center;
      width: 20%;
      padding: 10px 0;
    }
    .nav-active {
      // background-color: #15151c;
    }
  }
}
.header_PC {
}
.header_PHONE {
  display: none;
}

@media (max-width: 960px) {
  .header_PHONE {
    display: block;
  }
  .header_PC {
    display: none;
  }
}
.border-t-b {
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}
.phone_nav_list > .phone_nav {
  background-size: 100% 100%;
  background-image: url("../assets/img/nav/nav-item-active.png");
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    /*background-color: var(--main-yellow);*/
  }
}
.pc {
  width: 250px;
  overflow: auto;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.shrink {
  width: 70px;

  &-title {
    position: absolute;
    z-index: -2;
    white-space: nowrap;
    text-align: center;
    width: 0;
    left: 100%;
    overflow: hidden;
    transition: all 0.5s;
  }

  &-con:hover {
    .shrink-title {
      width: 150px;
    }
  }
}
@media (max-width: 960px) {
  .pc {
    display: none !important;
  }
  .shrink {
    display: none !important;
  }
}

.top {
  height: 56px;
  z-index: 9999;
}

.header {
  // height: 100vh;
  // background-color: rgba(0, 0, 0, 0.3);
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);

  &-phone {
    &-left {
      display: none;
      height: 100%;
      @media (max-width: 960px) {
        display: none;
      }

      &-nav {
        top: 100%;
        position: relative;
        width: 300%;
        height: 100vh;
        background: var(--main-bg);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        z-index: 2;

        &-bg {
          position: absolute;
          width: 100vw;
          height: calc(100vh - 80px);
          background: #000000;
          opacity: 0.8;
          z-index: -2;
          top: 80px;
          left: 0;
        }
      }

      & span {
        display: block;
        width: 40px;
        height: 3px;
        border-radius: 2px;
        background: red;
        margin: 6px 20px;
        transition: all 0.5s;
      }

      &:hover {
        & span:nth-child(2) {
          display: none;
        }

        & span:nth-child(1) {
          transform: rotate(180deg);
        }

        & span:nth-child(3) {
          transform: rotate(-180deg);
        }

        &-nav {
          display: block;
        }
      }

      &-active > div {
        border-left: 4px solid;
        background-image: var(--main-yellow-gradual-x);
      }
    }

    &-logo {
      @media (max-width: 1200px) {
        display: block;
        margin: 0 auto;
      }
    }
  }

  &-left {
    width: 100%;
    height: 160px;

    & img {
      height: 100%;
    }

    @media (max-width: 1200px) {
      display: none;
    }
  }

  &-conter {
    width: 100%;
    height: 100%;
    margin-left: 40px;
    @media (max-width: 1200px) {
      display: none !important;
    }

    &-item {
      width: 11%;
      height: 100%;
      background-size: 100% 100%;
      filter: grayscale(1);
      font-size: 1.3rem;
      transition: all 0.3s;
      @media (max-width: 1600px) {
        width: 15%;
      }
      @media (max-width: 1366px) {
        width: 18%;
      }
      @media (max-width: 1200px) {
        width: 14%;
      }
      @media (max-width: 960px) {
        display: none;
      }

      &-text {
        margin-top: 5px;
        font-size: 0.9rem;
        @media (max-width: 1600px) {
          font-size: 0.8rem;
        }
      }

      &:hover {
        color: var(--main-yellow);
        filter: grayscale(0);
        background-image: url("../assets/img/nav/nav-item-active.png");
      }

      @media (max-width: 960px) {
        font-size: 12px;
      }
    }

    &-active {
      color: var(--main-yellow);
      filter: grayscale(0);
      &::before {
        content: "";
        width: 2px;
        height: 50px;
        background-color: var(--main-purple);
        position: absolute;
        left: 0;
      }
      & > div {
        background-size: 100% 100%;
        background-image: url("../assets/img/nav/nav-item-active.png");
      }
    }
  }

  &-login {
    /*background-image: url('../assets/img/nav/login.png');*/
    background-size: 100% 100%;
    font-size: 14px;
    width: 140px;
    background-color: #e94d8b;
    border: 1px solid #e94d8b;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    @media (max-width: 960px) {
      width: 80px;
      font-size: 12px;
    }
  }

  &-user {
    width: 370px;
    @media (max-width: 960px) {
      display: none;
    }

    &-phone {
      display: none;
      @media (max-width: 960px) {
        display: block;
        position: relative;
      }

      & .active2 {
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        top: 60px;
      }

      &-title {
        /*background: rgba(0, 0, 0, 0.5);*/
        /*border: 1px solid #000000;*/
        border-radius: 5px;
        padding: 5px 5px;
        line-height: 22px;
      }
    }
    & .active {
      display: block !important;
    }
    &-nav {
      display: none;
      width: 250px;
      position: absolute;
      top: 30px;
      left: 0;
      text-align: center;
      padding: 20px 0;
      z-index: -1;
      background: var(--main-bg);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);

      &-active {
        color: var(--main-yellow);
      }

      &-hove {
        transition: all 0.5s;
      }

      &-hove:hover {
        color: var(--main-yellow);
      }
    }

    &:hover &-nav {
      /*display: block;*/
    }

    &-img {
      right: 10px;
    }
    &-name {
      width: 230px;
      /*padding: 0 10px;*/
      overflow-y: hidden;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      &-vip {
        margin-left: 5px;
        width: 40px;
        height: 18px;
        bottom: 0px;
        right: -15px;
        & img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &-money {
      width: 250px;
      border: 3px solid #000000;
      border-left: 0;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }

    &-name,
    &-money {
      font-size: 14px;
      line-height: 30px;
    }
  }
}

.bonanza {
  position: relative;

  margin-bottom: 10px;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    background-size: 100% 100%;
  }
}
</style>
