import {request} from "./index";

function getConsume(data){
    return request({
        url: `/welfare_center/getConsume`,
        method:'post',
        data
    })
}
function recoList(){
    return request({
        url: `/welfare_center/getConsume/recoList`,
        method:'get'
    })
}
function supplyList(){
    return request({
        url: `/task?tab=5`,
        method:'get'
    })
}
function drawRecharge(data){
    return request({
        url: `/welfare_center/supply/post`,
        method:'post',
        data
    })
}
function consumePost(data){
    return request({
        url: `/task/receive `,
        method:'post',
        data
    })
}
function activityList(){
    return request({
        url: `/welfare_center/activity/list`,
        method:'get'
    })
}
function signIn(data){
    return request({
        url: `/task/receive`,
        method:'post',
        data
    })
}
function signUp(data){
    return request({
        url: `/task/sign_up`,
        method:'post',
        data
    })
}
function taskReceive2(){
    return request({
        url: `/task?tab=1`,
        method:'get',
    })
}
function taskReceiven(tab){
    return request({
        url: `/task?tab=${tab}`,
        method:'get',
    })
}
function taskReceive(){
    return request({
        url: `/task`,
        method:'get',
    })
}
function remindList(data){
    return request({
        url: `/welfare_center/getUserTaskReward`,
        method:'post',
        data
    })
}
export default {
    remindList,
    signIn,
    taskReceive,
    signUp,
    activityList,
    consumePost,
    drawRecharge,
    getConsume,
    recoList,
    supplyList,
    taskReceive2,
    taskReceiven
}
