<template>
    <div class="mt-5 content ">

        <!-- title -->
        <div class="h3 text-white font-weight-bold text-center my-2 con-titlem">
            <div>
                <img src="@/assets/newImg/roll.png" />
            </div>
            <!-- <div class="cursor-pointer text-center color-yellow" style="font-size: 16px" @click="playingMethod = true">游戏规则</div> -->
        </div>


        <div class="rollmenu">
            <a @click="navRoll('underway')" :class="navActive == 'underway'?'activeAA':'shabi'">进行中</a>
            <a @click="navRoll('finished')" :class="navActive == 'finished'?'activeAA':'shabi'">已结束</a>
            <a @click="navRoll('participate')" :class="navActive == 'participate'? 'activeAA' : 'shabi'">我参与的</a>
            <a @click="playingMethod = true">游戏规则</a>
        </div>



        
        <div class="rooms ">
            <!-- 进行中  已结束 -->
            <div class="row mx-0 w-100" style="justify-content: center;">
                <div class="rooms-item col-xl-3 px-1 col-md-4 col-12 mb-2" v-for="(item,index) in rollList" :key="index">
                    <rollListItem :item="item"></rollListItem>
                </div>
            </div>


            <!--我参与的-->
            <div v-for="(item,index) in rollListOver" :key="index">
                <div class="room">
                    <div class="head">
                        <div class="avatar" style="width: 85px; height: 85px; border-radius: 20%;">
                            <img width="100" height="100" v-lazy="item.user.avatar" alt="头像" style="border-radius: 20%;">
                        </div>
                        <div class="title">

                            <span class="name">{{item.name}}</span>
                            <span class="let">开奖时间:{{item.end_time.slice(5,11)}}{{item.end_time.slice(11,16)}} </span>


                            <div class="desc">
                                <div style="width: 100%;">
                                    <div class="ellipsis">要求:{{item.describe}}</div>
                                </div>
                                <span class="tooltip">{{item.describe}}</span>
                            </div>
                        </div>
                        <div class="position-absolute top-0 right-0">
                            <img v-if="item.type === 0" src="@/assets/img/G.png" alt="">
                            <img v-else src="@/assets/img/Z.png" alt="">
                        </div>
                    </div>

                    <div class="gifts">
                        <div class="item" v-for="(t,i) in (item.box_records.slice(0,3))" :key="i">
                            <img v-lazy="t.cover">
                        </div>
                    </div>
                    <div class="content">
                        <div class="content-item">
                            <span class="num">{{item.award_bean}}</span>
                            <span class="unit">价值</span>
                        </div>
                        <div class="content-item">
                            <span class="num">{{item.awards_count }}</span>
                            <span class="unit">件饰品</span>
                        </div>
                        <div class="content-item">
                            <span class="num">{{item.join_number}}</span>
                            <span class="unit">/{{item.people_number}}参加</span>
                        </div>
                    </div>


                    <router-link :to="'/active/rollHome/' + item.id + '?roll=1'">
                        <div class="join">
                            <button class="btn" v-if="status === 0"> 我要加入 </button>
                            <button class="btn" v-else> 查看结果 </button>
                        </div>
                    </router-link>

                </div>
            </div>

            <el-pagination
              background
              layout="prev, pager, next"
              :hide-on-single-page="total<19"
              :total="total"
              :page-size="pageSize"
              @current-change="currentChange"
            >
            </el-pagination>
        </div>


        <!-- 玩法介绍 -->
        <div class="" v-if="playingMethod">
            <div style="width: 700px;left: calc(50% - 350px);top:15vh"
                 class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">
                <div class="text-white p-md-4 p-2" style="line-height: 2.0">

                    <p>1.选择加入roll房间，根据房间规则可以加入房间。</p>
                    <p>2.达到加入条件后，即可加入房间。</p>
                    <p>2.加入房间，到达房间开奖日期后即可随机分配房间内的饰品。</p>

                </div>
                <div @click="playingMethod = false" class="position-absolute" style="right: 20px;top: 30px">
                    <div>
                        <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
                    </div>
                </div>

            </div>
            <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
        </div>
    </div>
</template>

<script>
  import rollListItem from "./common/rollListItemV2";

    import $api from '@/api/rollHome/index'

    export default {
        name: "index",
        components: {
            rollListItem

        },
        data() {
            return {
                total: 5,
                pageSize: 0,
                rollList: [],
                rollListOver: [],
                status: 0,
                navActive: 'underway',
                dataFUQ: 0,
                playingMethod:false
            }
        },
        computed: {
            dateMYD() {
                return this.dataFUQ * 1000
            }
        },
        created() {
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                this.getRoomsList()

            },
            rollType(type){
                console.log(type)
                this.getRoomsList(1,type)
            },
            async getRoomsList(page = 1,type = '') {
                try {
                    let { data, code, timestamp } = await $api.getRoomsList(page, this.status,type)
                    if (code == 500) return
                    this.rollList = data.data
                    this.dataFUQ = timestamp
                    this.total = data.total
                    this.pageSize = data.per_page
                } catch (e) {
                    console.log(e)
                }
            },
            currentChange(val) {
                this.getRoomsList(val)
            },
            navRoll(state) {
                this.navActive = state
                if (state == 'underway') {
                    let loading = this.$loading({
                        lock: true,
                        text: '正在加载',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    setTimeout(() => {
                        this.rollListOver = []
                        this.status = 0
                        this.getRoomsList(1)
                        loading.close();
                    }, 500);

                }
                if (state == 'finished') {
                    let loading = this.$loading({
                        lock: true,
                        text: '正在加载',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    setTimeout(() => {
                        this.rollListOver = []
                        this.status = 1
                        this.getRoomsList(1)
                        loading.close();
                    }, 500);

                }
                if (state == 'participate') {
                    let loading = this.$loading({
                        lock: true,
                        text: '正在加载',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    setTimeout(() => {
                        this.status = 2
                        $api.getUserRooms().then(res => {
                            this.rollList = []
                            this.rollListOver = res.data.data
                            this.total = res.data.total
                            this.pageSize = res.data.per_page
                        })
                        loading.close();
                    }, 500);

                }
            }
        }
    }
</script>

<style scoped lang="scss">


.rooms-item{
  margin: 0 20px !important;
}
.rollmenu{
    display: flex;
    justify-content: space-between;
    padding: 0 500px;
    margin-bottom: 40px;
    @media (max-width:600px) {
        padding: 0 30px;
    }
    > a{
        font-weight: bold;
        cursor: pointer;
        font-size: 18px;
        color: #9fa1a4;
        font-style: italic;
    }
}

.con-titlem{
    margin: 60px 0 !important;
    @media (max-width:600px) {
        margin: 20px 0 !important;
    }
}
.shabi{
}
.activeAA{
    color: #fff !important;
}
.roll_type{
        &:hover{
            color: var(--main-blue);
        }
    }
  .ROLL_pc{
    @media (max-width: 1200px) {
      display: none;
    }
  }
  .ROLL_phone{
    display: none;
    @media (max-width: 1200px) {
      display: block;
    }
  }


  .ROLL_user{
    border-radius: 10px;
    overflow: hidden;
    background-color: #090827;
    box-shadow: 0 4px 14px 0 rgba(0,0,0,.3);
    &::before{
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0.5;
      background: linear-gradient(144deg, rgba(57,85,232,0) -11%, rgba(57,85,232,0.6) 111%);
    }
  }
  .content {
    width: 90%;
    margin: 0 auto;
    @media (max-width: 1366px) {
      width: 95%;
    }
  }

    .rooms {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        &-item{
            @media (max-width: 1000px){
                width: 90%;
            }
            & .room{
                @media (max-width: 1000px){
                    width: auto;
                }
                & .name{
                    @media (max-width: 1000px){
                        -webkit-line-clamp: 1!important;
                    }
                }
            }
        }
    }
    @media screen and (min-width: 1440px) {
        .rooms {
            width: 100%;
        }
    }
    @media screen and (min-width: 1080px) and (max-width: 1440px) {
        .rooms {
            width: 790px;
        }
    }
    @media screen and (min-width: 720px) and (max-width: 1080px) {
        .rooms {
            width: 760px;
        }
    }
    @media screen and (min-width: 360px) and (max-width: 720px){
        .rooms {
            width: 100%;
        }
    }
    .room {
        width: 340px;
        height: 360px;
        padding: 10px 20px;
        margin: 15px;
        position: relative;
        box-shadow: 1px 1px 3px #111;
        background-color: hsla(0,0%,100%,.03);
        background-size: contain;
        color: #fff;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 1.5;
    }
    .room .head {
        width: 100%;
        height: 120px;
        display: flex;
        align-items: center;
    }
    .room .head .avatar {
        margin: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .room .head .title {
        width: calc(100% - 85px);
        height: 100px;
        padding-left: 20px;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 90;
        text-align: left;
    }
    .room .head .title .name {
        font-size: 18px;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .room .head .title .desc {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #b0b0b0;
        letter-spacing: 1px;
        position: absolute;
        top: 20%;
        padding: 0 10px 0 0px;
    }
    .room .head .title .desc .ellipsis {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .room .head .title .tooltip {
        z-index: 200;
        display: none;
        position: absolute;
        top: 40%;
        background: #fff679;
        border-radius: 2px;
        color: #000;
        font-size: 12px;
        padding: 10px;
        transition: all .3s ease;
    }

    .room .let {
        color: var(--main-blue);
        font-size: 12px;
        font-weight: 500;
        position: absolute;
        top: 14%;
    }
    .room .gifts {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .room .gifts .item{
        width: 80px;
        height: 70px;
        position: relative;
    }
    .room .content{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: Oxanium,sans-serif;
    }

    element.style {
    }

    .room .content-item{
        width: 80px;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .room .gifts .item > img {
        width: 90%;
        position: absolute;
        top: 10%;
        left: 0;
        z-index: 80;
    }
    .room .content-item .num {
        font-size: 18px;
        font-weight: 600;
        color: var(--main-blue);
        overflow-wrap: normal;
    }
    .room .content-item .unit {
        font-size: 12px;
    }


    .room .join:hover {
        //background: linear-gradient( 225deg,rgb(66 130 82),rgb(59 115 74));
    }
    .room .join{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        background: hsla(0,0%,100%,.05);
    }
    .room .join .btn {
        color: #fff;
        width: 100%;
    }
    .horn {
        background: linear-gradient(var(--main-blue), var(--main-blue)) left top, linear-gradient(var(--main-blue), var(--main-blue)) left top, linear-gradient(var(--main-blue), var(--main-blue)) right bottom, linear-gradient(var(--main-blue), var(--main-blue)) right bottom;
        background-repeat: no-repeat;
        background-size: 1px 20px, 20px 1px;
    }
</style>
