<template>
  <div class="">
    <div class="h3 text-white font-weight-bold text-center my-5">
      <div>
        <img src="@/assets/newImg/jfbg.png" class="kksdwrnfenrfrkne" />
      </div>
    </div>
    <div class="content">


      <div class="d-flex justify-content-between flex-wrap">
        <div :class="getluckyTypeActive == index ? 'active' : ''" @click="getluckyTypeClick(item, index)" class="text-center mb-md-4 mb-2  p-2
                     f_shopping_class" style="font-size: 12px" v-for="(item, index) in getluckyType" :key="index">
          <img width="100%" :src="item.cover" alt="">
          <div>{{ item.name }}</div>
        </div>
      </div>


      <div class="d-flex flex-wrap align-items-center mmmjifenbox">


        <div class="d-flex mb-1 mjifen">
          <div class="border-bg px-3 py-1 cursor-pointer">
            积分
          </div>
          <div class="bg-bg px-2 py-1">
            {{ user_info.integral || 0 }}
          </div>
        </div>
        <div class="d-flex ml-md-auto mb-1">
          <div class="d-md-flex d-none  mr-3 align-items-center mmipt" style="margin-right: 40px !important;">
            <div class="bg-bg px-2 input">
              <input placeholder="最小金额" v-model="min_bean" style="width: 100px;font-size: 12px;height: 40px" type="text"
                class="text-white">
            </div>
            <div style="width:60px;height: 2px" class="bg-yellow1 "></div>
            <div class="bg-bg  px-2 input">
              <input placeholder="最大金额" v-model="max_bean" style="width: 100px;font-size: 12px;height: 40px" type="text"
                class="text-white" @keyup.enter="filtrateMoney">
            </div>
            <div @click="filtrateMoney" class=" ml-3 rounded-pill f-main-bg cursor-pointer mmmbtn"
              style="height: 40px;line-height: 40px;width: 200px;text-align: center">
              筛选
            </div>
          </div>
          <div class=" d-flex ml-auto mmmiputbg" style="height: 40px">
            <input placeholder="搜索名称" v-model="search" style="width: 200px;font-size: 12px" type="text"
              class="text-white px-2 input">

            <div @click="searchName" class="px-md-5
             px-3 ml-3 rounded-pill f-main-bg cursor-pointer mmmbtn" style="line-height: 40px">搜索
            </div>
          </div>

        </div>

      </div>

      <div class="d-flex justify-content-between flex-wrap mmtype">
        <div class="w-100 mb-2">
          <div class="d-flex mb-1 mmmmdwewe" style="height: 40px;line-height: 40px">
            <div>价格排序: </div>
            <div class="bg-bg px-3 cursor-pointer" @click="sort('0')">
              <div class=" icon-size">
                升序
              </div>
            </div>
            <div class="border-bg px-3 cursor-pointer" @click="sort('1')">
              <div class=" icon-size mjianxu">
                降序
              </div>

            </div>
          </div>
          <div class="mt-4 d-flex">
            <div class="mtypetit" style="width: 70px;line-height: 16px;">外观：</div>
            <div class="d-md-block d-flex shaixuan"
              style="width: calc(100% - 70px);font-size: 8px;margin-top: -3px;padding-left: 20px;">

              <span class="mr-3 cursor-pointer px-4 d-inline-block rounded-pill  text-nowrap"
                style="line-height: 16px;font-size: 8px" @click="unlimited('', '')"
                :class="search_dura_value === '' ? ' f-main-bgm mmmsdsd' : 'mmmsdsd'">不限</span>
              <span class="mr-3 cursor-pointer px-3 py-md-2 mb-md-3  rounded-pill text-nowrap" style="line-height: 16px"
                @click="search_dura_value_click(item, index)"
                :class="index === search_dura_value ? ' f-main-bgm mmmsdsd' : 'mmmsdsd'"
                v-for="(item, index) in search_dura" :key="index">{{ item }}</span>
            </div>
          </div>

          <div class="d-flex mt-3">
            <div class="mtypetit" style="width: 70px;line-height: 16px;">品质：</div>
            <div class="d-md-block d-flex shaixuan flex-nowrap overflow-auto"
              style="width: calc(100% - 70px);font-size: 8px;margin-top: -3px;padding-left: 20px;">

              <span class="mr-3 cursor-pointer px-4 d-inline-block rounded-pill  text-nowrap"
                style="line-height: 16px;font-size: 8px" @click="unlimitedWG('', '')"
                :class="search_rarity_value === '' ? ' f-main-bgm mmmsdsd' : 'mmmsdsd'">不限</span>
              <span class="mr-3 cursor-pointer px-4 py-md-2 mb-md-3  d-inline-block rounded  text-nowrap"
                style="line-height: 16px" @click="search_rarity_value_click(item, index)"
                :class="index === search_rarity_value ? ' f-main-bgm mmmsdsd' : 'mmmsdsd'"
                v-for="(item, index) in search_rarity" :key="index">{{ item }}</span>
            </div>
          </div>
        </div>

      </div>


      <div class="content-list my-5">

        <div class="mb-2 weapon mweapon" style="" v-for="(item, index) in shoppingList" :key="index">
          <weapon style="width: 90%" @click.native="shoppinng(item, index)" :res-data="item">
          </weapon>
        </div>

        <vacancy class="d-flex justify-content-center" v-if="shoppingList.length == 0"></vacancy>
      </div>
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize"
        @current-change="currentChange">
      </el-pagination>
    </div>


    <!-- 立即购买 -->
    <div v-show="immediatelyShopping">
      <div style="" class="position-fixed pop-up z-index-100 py-5 immediatelyShopping f-border-top">
        <div class="pt-5 px-5 immediatelyShopping-box">
          <div class="d-md-flex immediatelyShopping-box-con">
            <div class="px-2 immediatelyShopping-box-con-img" :class="'ggbg' + Shopping.dura">
              <img width="250px" :src="Shopping.cover" alt="">
            </div>
            <div class="ml-5 immediatelyShopping-box-con-dis">
              <div class="mb-3" style="line-height: 24px">{{ Shopping.name }}</div>
              <div class="mt-3">
                {{ Shopping.dura_alias }}
              </div>
              <div class="mt-3">
                <el-input-number v-model="num" controls-position="right" :min="1"></el-input-number>
              </div>
              <div class="mt-3">
                积分：{{ (Shopping.mall_integral * num).toFixed(2) }}
              </div>


            </div>
          </div>
          <div class="d-flex mt-5 justify-content-center">
            <div @click="ShoppingMoney">
              <bottom-bg>
                <div class="" style="font-size: 16px;color: #ffffff">
                  积分购买
                </div>
              </bottom-bg>
            </div>
          </div>
          <div @click="immediatelyShopping = false" class="position-absolute" style="right: 20px;top: 20px">
            <div>
              <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
            </div>
          </div>

        </div>
      </div>
      <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;">

      </div>
    </div>
  </div>
</template>

<script>
import bottomBg from "@/components/bottomBg";
import weapon from "./common/weapon";
import $api from '@/api/shopping/index'
import $luckyType from '@/api/lucky/index'
import { mapState } from 'vuex'

export default {
  name: "index",
  components: {
    bottomBg,
    weapon,
  },
  data() {
    return {
      // 弹出框
      immediatelyShopping: false,
      type: 1,
      // 选择数量
      num: 1,
      // 渲染商城列表
      shoppingList: [],
      // 翻页
      total: 0,
      pageSize: 0,
      // 价格区间
      min_bean: '',
      max_bean: '',
      // 排序
      sortSum: 0,
      // 弹出的武器信息
      Shopping: {},
      // 更具名称搜索
      search: '',
      search_dura_value: '',
      search_dura: ['无', '崭新出厂', '略有磨损', '久经沙场', '破损不堪', '战痕累累', '无涂装'],
      search_rarity_value: '',
      search_rarity: ['无', '消费级', '工业级', '军规级', '受限', '保密', '隐秘', '普通级', '高级', '非凡', '奇异', '卓越', '违禁', '探员:高级', '探员:卓越', '探员:非凡', '探员:大师'],
      getluckyType: [],
      getluckyTypeActive: false,

    }
  },
  computed: {
    ...mapState(['user_info'])
  },
  created() {

  },
  mounted() {
    this.init()
  },
  methods: {
    // 点击分类
    async getluckyTypeClick(item, index) {
      this.getluckyTypeActive = index
      this.type = item.id
      let { data } = await $api.getShopListType(item.id)
      this.shoppingList = data.data
      this.total = data.total
      this.pageSize = data.per_page

    },
    // 增加搜索
    async searchName() {
      await this.getShopList(this.sortSum, this.min_bean, this.max_bean, this.num, this.search,)
    },
    async search_dura_value_click(item, index) {
      this.search_dura_value = index
      if (this.search_rarity_value !== '') {
        await this.getShopList(this.sortSum, this.min_bean, this.max_bean, this.num, '', index, this.search_rarity_value)
      } else {
        await this.getShopList(this.sortSum, this.min_bean, this.max_bean, this.num, '', index,)
      }
    },
    async search_rarity_value_click(item, index) {
      this.search_rarity_value = index
      if (this.search_dura_value !== '') {
        await this.getShopList(this.sortSum, this.min_bean, this.max_bean, this.num, '', this.search_dura_value, index)
      } else {
        await this.getShopList(this.sortSum, this.min_bean, this.max_bean, this.num, '', '', index,)
      }

    },
    unlimited(t) {
      this.search_rarity_value = t
      this.search_dura_value = t
      this.getShopList()
    },
    unlimitedWG(t) {
      this.search_rarity_value = t
      this.search_dura_value = t
      this.getShopList()
    },
    init() {
      this.getShopList()
      this.getluckyTypeList()
    },
    shoppinng(item) {
      document.getElementById('mysound').play()
      this.immediatelyShopping = true
      this.Shopping = item
    },
    async getShopList(sort = 0, min_bean = '', max_bean = '', page = 1, name = '', dura = '', rarity = '') {
      try {
        let { data, code } = await $api.getShopList(sort, min_bean, max_bean, page, name, dura, rarity, this.type)
        if (code == 500) return
        this.shoppingList = data.data
        this.total = data.total
        this.pageSize = data.per_page
      } catch (e) {
        console.log(e)
      }
    },
    async getluckyTypeList() {
      try {
        let { data } = await $luckyType.getluckyType()
        this.getluckyType = data
        this.type = data[0].id
      } catch (e) {
        console.log(e)
      }

    },
    sort(sum) {
      this.sortSum = sum
      this.getShopList(sum, this.min_bean, this.max_bean, 1)
    },
    filtrateMoney() {
      this.getShopList(this.sortSum, this.min_bean, this.max_bean, 1)
    },
    async ShoppingIntegral() {
      try {
        document.getElementById('mysound').play()
        let { code, message } = await $api.getShopBuy({ id: this.Shopping.id, num: this.num })
        if (code == 500) return
        if (code == 200) {
          this.$notify({
            title: message,
            type: 'success',
            position: 'top-left'
          });
          this.$store.dispatch('userInfo')
        }
      } catch (e) {
        console.log(e)
      }

      this.immediatelyShopping = false

    },
    async ShoppingMoney() {
      try {
        document.getElementById('mysound').play()
        let { code, message } = await $api.getShopexchange({ id: this.Shopping.id, num: this.num })
        if (code == 500) return
        if (code == 200) {
          this.$notify({
            title: message,
            type: 'success',
            position: 'top-left'
          });
          this.$store.dispatch('userInfo')
        }
      } catch (e) {
        console.log(e)
      }
      this.immediatelyShopping = false

    },
    currentChange(val) {
      this.getShopList(0, '', '', val)
    }
  }
}
</script>

<style scoped lang="scss">

.kksdwrnfenrfrkne{
  margin-top: 40px;
}
@media (max-width:600px) {
  .kksdwrnfenrfrkne{
    margin-top: 0px;
  }
}

.mmmiputbg {
  background-color: #000000;

  >input {
    border-bottom: none !important;
    text-indent: 20px !important;
  }
}

.mmmbtn {
  border-radius: 0px !important;
  text-align: center;
  width: 100px !important;
  background: linear-gradient(270deg, #085889 0%, #1F7EB9 100%);
  padding: 0 !important;
}

.bg-yellow1 {
  background-color: #484a4d;
}

.mmipt {
  border-radius: 6px;
  opacity: 1;

  background: rgba(0, 0, 0, 0.5);

  >.input {
    width: 100px;
    border-bottom: none !important;

    >input {
      text-align: center;
    }
  }
}

.mmmjifenbox {
  margin-bottom: 20px;
  @media (max-width:600px) {
    justify-content: center;
  }
}

.mjifen {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  height: 40px;
  line-height: 10px;
  font-weight: bold;
  padding: 10px 30px !important;
  text-align: center;
  @media (max-width:600px) {
    margin: 20px 0 !important;
  }
}

.mjianxu {
  background-color: #265785;
  padding: 0 20px;
  border-radius: 8px;
}

.mmmmdwewe {
  position: absolute;
  right: 20px;
  @media (max-width:600px) {
    left: 10px;
    top: 200px;
  }
}

.f-main-bgm {
  background-color: #265785;
  padding: 5px 0;
  border-radius: 4px !important;
}

.mmmsdsd {
  font-size: 14px !important;
  @media (max-width:600px) {
    height: 30px;
    padding: 0px;
    line-height: 30px !important;
  }
}

.mtypetit {
  font-weight: bold;
  margin-bottom: 30px;
  padding-left: 20px;
}

.mmtype {
  position: relative;
  border-radius: 10px;
  opacity: 1;
  padding: 20px;

  @media (max-width:600px) {
    position: relative;
  }

  background: rgba(0, 0, 0, 0.2);
}

.mweapon {
  margin-bottom: 20px !important;
}

.el-pagination {
  text-align: center;
}

.f_shopping_class {
  width: calc(100% / 8);

  @media (max-width: 960px) {
    width: 25%;
  }
}

.shaixuan {
  @media (max-width: 960px) {
    overflow-x: scroll;
  }

  &::-webkit-scrollbar {
    height: 2px;
  }
}

.immediatelyShopping {
  z-index: 3;
  width: 600px;
  left: calc(50% - 300px);
  top: calc(25%);

  @media (max-width: 1200px) {
    width: 95%;
    left: 2.5%;

    &-box {
      padding-left: 10px !important;
      padding-right: 10px !important;

      &-con {
        &-img {
          width: 50%;
          margin: 0 auto;
        }

        & img {
          width: 100%;
        }

        &-dis {
          margin-left: 20px !important;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
}

.weapon {
  width: calc(100% / 6);

  @media (max-width: 1600px) {
    width: calc(100% / 6);
  }

  @media (max-width: 1366px) {
    width: calc(100% / 5);
  }

  @media (max-width: 1200px) {
    width: calc(100% / 3);
  }

  @media (max-width: 960px) {
    width: 50%;
  }
}

.content {
  width: 90%;
  margin: auto;
  overflow: hidden;

  @media (max-width: 1366px) {
    width: 95%;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    @media (max-width:600px) {
      margin-top: 80px !important;
    }

    & .weapon {
      @media (max-width: 1200px) {
        width: 50% !important;
      }
    }
  }
}

.icon-size {
  font-weight: bold;
  font-size: 14px !important;
}

.icon-zhuan {
  font-weight: bold;
  transform: rotate(180deg);
}

.active {
  position: static;
  left: 0px;
  top: 0px;
  width: 162px;
  height: 100px;
  opacity: 1;

  background: radial-gradient(50% 50% at 50% 50%, rgba(86, 191, 255, 0.2) 0%, rgba(8, 88, 137, 0) 100%);

  @media (max-width:600px) {
    width: 25%;
  }
}
</style>
