<template>
    <div class="red-packet">
        <div class=" immediatelyShopping">
            <div class="bg-size bg-repeat" style="box-shadow: 1px 1px 10px #ff8607;" :style="`background-image: url(${require('@/assets/img/vd/red_top.png')})`">
                <div class="immediatelyShopping-box px-2 pt-3">
                    <div class="d-flex"></div>
                    <div class="text-center mt-1">
                        加官群 873385782，抢更多福利！
                    </div>
                    <div class="d-flex justify-content-center px-3 immediatelyShopping-input">
                        <input placeholder="请输入红包口令" type="text" v-model="packet" class="d-block py-2" />
                    </div>
                    <div class="d-flex justify-content-center">
                        <img @click="OpenRebBack(2)" width="60px" :src="require('@/assets/img/vd/kai.png')" alt="" />
                    </div>
                    <div class="mt-4 text-center">红包列表</div>
                    <div class="immediatelyShopping-list mt-2">
                    <div v-show="item.status == 1" :class="item.status != 2 ? '' : 'filter-grayscale-1'"
                            v-for="(item, index) in redList" :key="index" class="px-2 py-1 rounded mb-2"
                            style="background-color: #ad252c">
                            <div class="d-flex mt-3">
                                <div class="immediatelyShopping-item-img d-flex flex-column align-items-center justify-content-end bg-size-100 pb-md-2 pb-1"
                                    :style="`background-image: url(${require('@/assets/img/vd/red_money.png')})`"
                                    style="font-size: 12px">
                                    <div>总数量</div>
                                    <div>{{ item.num }}</div>
                                </div>
                                <div class="ml-2" style="font-size: 13px">
                                    <div class="mb-2">{{ item.title }}</div>
                                    <div class="mb-2">参与条件：{{ item.briefe }}</div>
                                    <div class="mb-2">结束时间：{{ item.end_time }}</div>
                                </div>
                            </div>
                            <div v-if="item.remainder ==1" @click="OpenRebBack(1, item)"
                                class="cursor-pointer immediatelyShopping-pk text-center px-4">
                                抢红包
                            </div>
                            <div v-if="item.remainder ==0"
                                class="cursor-pointer immediatelyShopping-pk text-center px-4">
                                已抢完
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <el-dialog custom-class="custom_dialog" title="" :close-on-click-modal="false" :visible.sync="dialogVisible" append-to-body center width="300px">
          <div class="text-center padding-tb text-white">恭喜您领取到了<span class="text-orange">{{muVal}}F币</span></div>
          <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span>
      </el-dialog>
    </div>
</template>
<script>
import { mapState } from "vuex";
import $api from "@/api/vd/index";
export default {
    computed: {
        ...mapState(["web_info"])
    },
    data() {
        return {
            dialogVisible:false,
            muVal:'',
            packet: '',
            redList: [],
            loading:false,
        };
    },
    mounted() {
        this.init();
        this.QQ_qr_code = this.$store.state.web_info.qq_group_qr_code;
        this.qq_group_number = this.$store.state.web_info.qq_group_number;
        window.addEventListener("scroll", this.winScroll);
    },
    methods: {
        // 渲染列表
        async getredList() {
            let { data, code } = await $api.getRebBackList();
            if (code != 200) return;
            this.redList = data;
        },
        init() {
            this.getredList();
            if (this.sUserAgent == "phone") {
                this.silde = -55; // 手机端默认隐藏
                this.silde = 0; // 手机端默认隐藏
            } else {
                this.silde = 0;
            }
        },
        // 打开红包
        async OpenRebBack(sum, item) {
            if(this.loading)return
            this.loading=true
            let redOpen = {};
            if (sum == 1) {
                redOpen = {
                    type: sum,
                    id: item.id,
                };
            }
            if (sum == 2) {
                redOpen = {
                    type: sum,
                    key: this.packet,
                };
            }
            setTimeout(() => {
                this.loading=false
            }, 1000);
            try {
                let { data, code } = await $api.getOpenRebBack(redOpen);
                if (code != 200) return;
                // this.dialogVisible=true
                this.muVal=data

                this.$notify({
                        message: `恭喜您领取到了${data}G币`,
                        position: 'top-left'
                    });

                this.$store.dispatch("userInfo");
            } catch (e) {
                console.log(e);
            }
        },
    }
}
</script>
<style scoped lang="scss">
.red-packet {
    padding-top: 40px;
    margin-top: 8px;
    width: 95%;
    margin-left: 20px;
    height: calc(100% - 120px);
    color: #fff;
    position: relative;
    background: url('../../static/media/red_bg.jpg') no-repeat;
    background-size: 100% 100%;

    @media (max-width:600px) {
        margin-left: 10px ;
    }

    .immediatelyShopping-list {
        height: 276px;
        overflow-y: scroll;
    }
}

.immediatelyShopping {
    width: 350px;
    margin: 0 auto;
    background-color: #c02932;
    border-radius: 10px;

    &-list {
        height: 300px;
        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &-input {
        width: 150px;
        margin: 10px auto;
        background-color: #fffad8;
        border-radius: 5px;

        & input {
            width: 140px;
        }
    }

    &-item {
        &-img {
            width: 87px;
            height: 80px;
        }
    }

    &-pk {
        width: 100%;
        margin: 5px auto;
        background-color: #bd3840;
        padding: 10px;
        border-radius: 5px;
    }

    @media (max-width: 1200px) {
        width: 70%;
        left: 15%;
        top: calc(80px + 5%);

        &-box {
            &-con {
                &-img {
                    width: 50%;
                }

                & img {
                    width: 100%;
                }

                &-dis {
                    margin-left: 20px !important;
                    margin-top: 10px;
                }
            }
        }
    }
}
</style>