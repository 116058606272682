<template>
    <div class="content">
        <div class="h3 my-5 font-weight-bold text-center">
            创建竞技场
        </div>
        <div class="d-flex justify-content-between">
            <div class="mt-3 ">
                <div class="mb-2">玩家</div>
                <div class="d-flex">
                    <div  class="cursor-pointer content-f_sum text-center rounded mr-3 px-3 py-1" @click="userSum(item)"
                          :class="item == sum ? 'bg-blue' :''"
                          style=""
                          v-for="(item,index) in 3" :key="index">{{item + 1}}</div>
                </div>

            </div>
            <div class="d-md-flex d-block align-items-center pr-5">
                <div class="mx-md-5">总金额：<span class="font-weight-bold">
                    {{money}}</span></div>
                <div class="mx-md-5 ">回合数：<span class="font-weight-bold">
                    {{boxGunListPitch.length}}</span></div>
                <div class=" ml-auto d-flex">
                    <div @click="getPKarenaCreate" style="font-size: 14px"
                         class=" text-white cursor-pointer px-4 py-2 f_btn ">
                        创建竞技场
                    </div>
                </div>
            </div>

        </div>

        <div class="content-box flex-wrap mt-3 d-flex">
            <div v-for="(item,index) in boxGunListPitch" :key="index + 'box'"
                 class="content-box-item d-flex justify-content-center align-items-center
                  flex-column position-relative mb-2">
                <div class="text-center">
                    <img width="" height="100px" :src="item.intact_cover" alt="">
                    <div class="my-2">{{item.name}}</div>
                    <div><money/>{{item.game_bean}}</div>
                    <div @click="deleteGun(item,index)" class="position-absolute  cursor-pointer" style="right: 10px;top: 10px;font-size: 20px">
                        <div>
                            <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
                        </div>
                    </div>
                </div>
            </div>
            <block v-if="boxGunListPitch.length < 6">
                <div v-for="(item,index) in 1" :key="index"
                     class="content-box-item d-flex justify-content-center align-items-center flex-column position-relative mb-2">
                    <div class="position-absolute d-flex flex-column justify-content-center align-items-center bg-size-100 w-100 h-100 bg-bluck"
                         style="width: 200px;height: 200px">
                        <div @click="ChooseWeapons"
                             class="content-box-item-add d-flex justify-content-center align-items-center cursor-pointer" >
                            <div class="h2 m-0">+</div>
                        </div>
                        <div class="mt-3">添加箱子</div>
                    </div>
                </div>
            </block>

        </div>
        <div v-show="boxGunAdd" class="content-popcon">

            <div  style=""
                  class="position-fixed pop-up content-pop z-index-100">
                <div class="text-center p-4 "
                     style="font-size: 25px;font-weight:
                     bold; border-top: 2px solid var(--f-border-yellow)">
                    增加箱子
                    ({{boxGunListPitch.length}}/6)
                </div>
                <div class="text-center">
                    <div >
                        <div>箱子正在被添加: <span class="color-yellow h4"> <money /> {{money}}</span></div>
                    </div>
                    <div class="" >

                        <div class="w-100 row content-pop-box p-3 " style="background-color:var(--f-main)">
                            <div v-for="(item,index) in boxGunList" :key="index"
                                 class="content-pop-box-item mb-3 position-relative col-lg-3 col-md-3 col-4"
                                 :class="item.active?'active':''"
                                  >
                                <img width="100%"  class="mx-auto d-block"
                                     :src="item.intact_cover" alt="">
                                <div class="text-center px-3 text-overflow-1">
                                    <div style="font-size: 12px">{{item.name}}</div>
                                </div>
                                <div class="mx-auto text-center p-2 mt-2 cursor-pointer"
                                     style="font-size: 12px">
                                    <money></money> {{item.game_bean}}
                                </div>
                                <div class="content-pop-box-item-add">
                                    <div class="d-flex justify-content-center align-items-center
                             w-100 position-absolute top-0 bottom-0 left-0 right-0 "
                                         :style="`background-image: url('${require('@/assets/img/f_new/add_box_creader.png')}')`"
                                    >
                                        <div @click="ChooseWeaponsClick(item,index)"
                                             class="rounded f_btn3 px-4 py-2 cursor-pointer"
                                             style="">增加</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div @click="ChooseWeaponsAdd" style="width: 150px "
                             class="f_btn3 rounded px-4 py-3 text-center mx-auto mt-3 cursor-pointer" >
                            完成
                        </div>
                    </div>

                </div>


                <div @click="ChooseWeaponsAdd" class="position-absolute" style="right: 20px;top: 30px">
                <div>
                    <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
                </div>
                </div>

            </div>
            <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
        </div>

    </div>
</template>

<script>
    import $api from '@/api/pk/index'
    export default {
        name: "creationHome",
        data() {
            return {
                sum:1,
                boxGunAdd:false,
                lockBoxGunIsShow:false,
                boxGunList:[],
                boxGunListPitch:[],
                boxGunListContains:[]
            }
        },
        mounted() {
        this.init()
            },
        computed:{
            addGunLingth(){
                return 6 - this.boxGunListPitch.length
            },
            money(){
                let bean = 0;
                this.boxGunListPitch.forEach(item =>{
                    bean += (item.game_bean)*1
                })
                return bean.toFixed(2)
            }
        },
        methods:{
            init(){
                this.getPKarenaboxList()
            },
            userSum(item){
                document.getElementById('mysound').play()
                this.sum = item
            },
            ChooseWeapons(){
                this.boxGunAdd = true
                document.getElementById('mysound').play()
            },
            async getPKarenaboxList(){
                try {
                    let {data,code} = await $api.getPKarenaboxList()
                    if(code == 500)return
                    this.boxGunList = data.map(item =>{
                        item.active = false
                        return item
                    })

                }catch (e) {
                    console.log(e)
                }
            },
            ChooseWeaponsClick(item){
                document.getElementById('mysound').play()
                if(this.boxGunListPitch.length>5){
                    this.$notify({
                        message:'最多添加6个箱子',
                        position: 'top-left'
                    })
                    return
                }
                this.boxGunList.forEach(t =>{
                    if(item.id == t.id ){
                        this.boxGunListPitch.push(item)
                    }
                })
            },
            ChooseWeaponsAdd(){
                document.getElementById('mysound').play()
                this.boxGunListPitch = JSON.parse(JSON.stringify(this.boxGunListPitch))
                this.boxGunAdd = false
            },
            deleteGun(item,index){
                document.getElementById('mysound').play()
                this.boxGunListPitch.splice(index,1)
            },
            async getPKarenaCreate(){
                document.getElementById('mysound').play()
                try {
                    let boxId = this.boxGunListPitch.map(item =>{
                        return item.id
                    })
                    let create = {
                        user_num:this.sum+1,
                        box:boxId
                    }
                    let {code,data} = await $api.getPKarenaCreate(create)
                    if(code == 500)return
                    if(code == 200){
                        this.$message.success('创建成功')
                        setTimeout(()=>{
                            this.$router.push('/active/mysteryBox/' + data.id)
                        },2000)
                    }
                }catch (e) {
                    console.log(e)
                    return
                }
            },
            lockBoxGun(item){
                this.lockBoxGunIsShow = true
                this.boxGunListContains = item.contains
            },

        }
    }
</script>

<style scoped lang="scss">
    .pop-up{
        @media (max-width: 1200px) {
            width: 96% !important;
            left:2%!important;
        }
    }
    .content {
        width: 90%;
        margin: 0 auto;

        @media (max-width: 1200px) {
            width: 96%;
            margin: 0 auto;
        }

        &-f_sum {
            border: 1px solid var(--f-main);
        }

        .f_active {
            background-color: var(--f-main)
        }

        &-top {
            border-top: 2px solid var(--f-border-yellow);
            background-color: #040319;

            @media (max-width: 1200px) {
                /*display: block!important;*/
            }

            &-sum {
                width: 450px;
                height: 140px;
                background-color: rgba(175,129,0,.10);

                @media (max-width: 1200px) {
                    width: 100%;
                    justify-content: left !important;
                    padding-left: 20px;
                    margin: 0 0 10px 0;
                }
            }
        }

        &-lockbox {
            &-list {
                margin-top: 20px;
                width: 100%;

                &-item {
                    width: 125px;
                    height: 125px;
                    margin-bottom: 10px;
                    text-align: center;
                    padding: 5px;

                    @media (max-width: 960px) {
                        width: 100px;
                        height: 100px;
                        padding: 0;
                        margin-bottom: 40px;
                    }
                }

                &-height {
                    height: 300px;
                    overflow: auto;

                    @media (max-width: 960px) {
                        height: 150px !important;
                    }
                }
            }

            & .active {
                background-color: var(--f-main);
            }
        }

        &-box {
            &-item {
                width: 200px;
                height: 220px;
                font-size: 14px;
                margin: 0 10px;
                border-radius: 5px;
                background-color: var(--f-main);

                @media (max-width: 1200px) {
                    width: 130px;
                    height: 130px;
                }

                @media (max-width: 960px) {
                    & img {
                        height: 70px;
                    }
                }

                &-add {
                    display: flex;
                    width: 100px;
                    height: 100px;
                    background-color: var(--main-dark-brown);
                    border-radius: 50%;
                    transition: all .5s;

                    &:hover {
                        background-color: var(--main-blue);
                    }
                }
            }
        }

        &-sum {
            height: 150px;

            &-item {
                background-color: var(--gray);
                padding: 20px 60px;
                margin: 0 20px;

                @media (max-width: 960px) {
                    padding: 10px;
                }
            }

            & .active {
                background-color: var(--main-brown);
            }
        }

        &-popcon {
            border-top: 2px solid var(--f-border-yellow);
        }

        &-pop {
            width: 800px;
            left: calc(50% - 350px);
            top: 100px;
            height: 650px;

            &-box {
                height: 450px;
                width: 720px;
                margin: 0 auto;
                overflow: scroll;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    display: none;
                }

                &-item {
                    border: 1px solid #000000;
                    width: 160px !important;
                    background-color: var(--f-main);

                    &:hover &-add {
                        display: block;
                    }

                    &-add {
                        display: none;
                    }
                }

                & .active {
                    background-color: var(--main-yellow);

                    &::before {
                    }
                }
            }

            &-lock {
                margin-top: 20px;
                height: 550px;
                overflow: scroll;
                overflow-x: hidden;

                &-item {
                    border-radius: 5px;
                    /*border: 1px solid var(--main-brown);*/
                    height: 167px;
                }
            }

            @media (max-width: 960px) {
                top: 10px;
            }
        }
    }
</style>
