<template>

  <div class="app">
    <header-top class="z-index-99"></header-top>
    <div  style="width: 100%" class="" id="content">
      <swiper-user></swiper-user>
      <transition name="fade-transform" mode="out-in">
        <router-view :key="key" />
      </transition>
      <footer-botton></footer-botton>
    </div>
    <div class="common_bg" @click="bodyCilkck" ></div>
    <silde class="d-md-block"></silde>

    <div class="position-fixed TaskIn d-none" >
      <Task-in :bottom-right="false">
        <div class="cursor-pointer silde-serve silde-W d-flex flex-column
                  justify-content-center align-items-center">
          <img width="50%" :src="require('@/assets/img/silde/QD.png')" alt="">
          <div>
            签到
          </div>
        </div>
      </Task-in>
    </div>
  </div>
</template>

<script>
  import headerTop from './headerTop.vue'
  import swiperUser from './swiperUser.vue'
  import footerBotton from "./footerBotton";
  import silde from "./silde";
  import TaskIn from '@/views/TaskIn/index'
  import $api from '@/api/login.js'
  import { setWebInfo } from '@/utils/auth'
  export default {
    name: "layout",
    components:{
      headerTop,
      swiperUser,
      footerBotton,
      silde,
      TaskIn
    },
    data(){
      return {
        routers:[],
        key:this.$route.path,
      }
    },

    computed:{
    },
    created() {
      this.routers = this.$router.options.routes[0].children
      },
    mounted() {
      $api.getWebInfo().then(res =>{
        setWebInfo(res.data)
        this.$store.commit('WEB_INFO',res.data)
      })
    },
    methods: {
      bodyCilkck(){
        this.$store.commit('ISSHOWNAVPC',false)
      }
    },
  }
</script>

<style scoped lang="scss">
  .TaskIn{
    right: 0;
    bottom:60%;
    z-index: 999;
    // display: none;
  }
  #content{
    /*margin-left: 250px;*/
    // height: calc(100vh - 70px);
    // overflow-y: scroll;s
    // overflow-x: hidden;

    /* 手机端放大效果 */
    @media (max-width: 1000px) {
      height: auto;
      overflow-y: auto;
      margin-bottom: 60px;
    }
    /*&::-webkit-scrollbar {display:none}*/
  }
  .common_bg{
    background-image: url("../assets/newImg/bgxx.png");
    width: 100vw;
    height: 100vh;
    background-size: 100% 100%;
    position: fixed;
    top: 0;
    z-index: -2;
    // opacity:0.2
  }

</style>
