<template>
    <div>
        <!-- 侧边栏  -->
        <div class="taskcenter-broadside ">
            <div class="MuiBox-root jss1965">
                <div class="MuiBox-root jss3449 " @click="taskSet = index"
                    :class="taskSet === index ? 'jss3443' : 'jss3442'" v-for="(item, index) in taskList" :key="index">{{
                    item.title }}</div>
            </div>
            <div class="MuiBox-root jss1968">

                <!-- <div class="MuiBox-root jss3129 jss3094" v-show="taskSet!==0">
                    <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover jss3096 MuiTypography-colorPrimary" style="cursor: pointer;"  @click="explainShow=true">规则说明<i class="el-icon-question" style="margin-left:6px"></i></a>
                    <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover jss3095 MuiTypography-colorPrimary" href="/user/user?name=income">点击进入 “收支明细”</a>
                </div> -->
                <div class="taskcenter-consume">
                    
                    <!-- <div class="MuiBox-award">
                        <div class="award-left">额外奖励</div>
                        <div class="award-main">
                            <div class="award-tip">完成所有子任务后可领取</div>
                            <div>
                                <img style="" src="https://img.zeroskins.com/upload/2022/11/15/1a3f14c7a47d7c02688033161ac8f841.png" class="jss606"/>
                            </div>
                            <div class="award-bottom">保底{{taskList[taskSet].finishAward}}F币</div>
                        </div>
                        <div class="award-right" :class="{'text-orange':taskList[taskSet].finishState}">{{taskList[taskSet].finishState?'已':'未'}}解锁</div>
                    </div> -->
                    <div class="sign-main" v-show="taskSet === 0">
                        <div class="text-center" style="color:#fff">
                            <div class="sign-txt">
                                <img src="./../../assets/newImg/good_present.19139697.png" />
                            </div>
                            <div style="font-size:24px" class="margin-tb-sm">开始签到吧 已连续签到<span class="margin-lr-xs">{{
                                    taskReceiveForm.days }}</span>天</div>
                            <div class="_a">
                                <img src="./../../assets/newImg/signIn0.6e0cfd34.png"
                                    v-if="!taskReceiveForm.today_signed" style="width:240px;cursor: pointer;"
                                    @click="topAttendance">
                                <img src="./../../assets/img/renwu/yiqiandao.png" v-else style="width:140px;">
                            </div>
                            <div v-if="taskReceiveForm.days == 7">
                                <img class="received-img" style="cursor: pointer;" src="@/assets/newImg/first.8d7c32e8.png" @click="tapDraw({type:1})" height="80px" />
                            </div>
                            <p>玩家连续签到7天,点击宝箱最高可获18
                                <money style="margin-left: 5px" />随机红包
                            </p>
                            <!-- <el-button class="margin-top-sm" type="primary"  :disabled="taskReceiveForm.today_signed" round @click="topAttendance">{{!taskReceiveForm.today_signed?'立即签到':'已签到'}}</el-button> -->
                        </div>
                        <div class="welfare-tips">
                            <el-row :gutter="20">
                                <el-col :span="8" v-for="(item, index) in taskReceiveForm.all_configs" :key="index">
                                    <div class="grid-content _a">
                                        <div v-if="index == 0">
                                            <img src="./../../assets/img/renwu/jiangli1.png" @click="receiveAward(item)"
                                                v-if="item.is_can === 1" class="received-img" />
                                            <div class="draw" v-else>
                                                <img src="./../../assets/img/renwu/jiangli1.png" />
                                                <img class="lingQu" src="./../../assets/img/renwu/img_ok.png"
                                                    v-if="item.is_can === 2" />
                                            </div>
                                            <div>累计{{ item.day }}天领取</div>
                                        </div>
                                        <div v-if="index == 1">
                                            <img src="./../../assets/img/renwu/jiangli2.png" @click="receiveAward(item)"
                                                v-if="item.is_can === 1" class="received-img" />
                                            <div class="draw" v-else>
                                                <img src="./../../assets/img/renwu/jiangli2.png" />
                                                <img class="lingQu" src="./../../assets/img/renwu/img_ok.png"
                                                    v-if="item.is_can === 2" />
                                            </div>
                                            <div>累计{{ item.day }}天领取</div>
                                        </div>
                                        <div v-if="index == 2">
                                            <img src="./../../assets/img/renwu/jiangli3.png" @click="receiveAward(item)"
                                                v-if="item.is_can === 1" class="received-img" />
                                            <div class="draw" v-else>
                                                <img src="./../../assets/img/renwu/jiangli3.png" />
                                                <img class="lingQu" src="./../../assets/img/renwu/img_ok.png"
                                                    v-if="item.is_can === 2" />
                                            </div>
                                            <div>累计{{ item.day }}天领取</div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>

                    </div>
                    <div class="MuiBox-root jss3130 jss3085" v-if="taskList.length && taskSet != 4">
                        <div class="task-list" v-for="(item, index) in taskList[taskSet].list" :key="index">
                            <div class="task-list-img "
                                :class="{ 'received-img': !(item.threshold > item.hasBean) && !item.has_get }"><img
                                    src="@/assets/newImg/first.8d7c32e8.png" height="20" /></div>
                            <div class="task-list-main">
                                <div class="span-h6">{{ item.txt }}{{ item.number }}({{ item.progress }}/{{ item.number }})
                                </div>
                                <!-- <div>{{ item.name }}（<span class="text-orange">{{ item.hasBean > item.threshold ?
                                        item.threshold : item.hasBean}}</span>/{{ item.threshold }}）</div> -->
                                <div class="flex" style="line-height:20px">
                                    <div class="margin-right-xs flex" style="line-height:20px" v-if="item.minMoney">
                                        <span class="text-orange">最低{{ item.minMoney }}</span>
                                        <money></money>
                                    </div>
                                    <!-- <div class="flex" style="line-height:20px" v-if="item.front_reward">
                                        <span class="text-orange">最高{{item.front_reward}}</span>
                                        <money></money>
                                    </div> -->
                                </div>
                            </div>
                            <div class="task-list-right ok _a" v-if="item.is_receive == 1" @click="tapDraw(item)"><img
                                    src="@/assets/img/renwu/lingqu.png" /></div>
                            <!--达到了数额但是没有领取-->
                            <div class="task-list-right main" v-if="item.is_receive == 2"><a href="javascript:void(0)">
                                    已领取
                                </a></div>
                            <div class="task-list-right main" v-if="item.is_receive == 0"><a
                                    :href="item.tab === 3 ? '/xsteryBox' : item.tab === 2 ? '/' : '/lucky'">
                                    前往
                                </a></div>
                        </div>
                    </div>

                    <div class="MuiBox-root jss3130 jss3085" v-if="taskSet == 4">
                        <div >
                                <img src="@/assets/newImg/fk.jpg" width="100%" />
                            </div>
                        <div class="task-list">
                            
                            <div class="task-list-img "><img style="cursor: pointer;" src="@/assets/newImg/first.8d7c32e8.png" height="20" />
                            </div>
                            <div class="task-list-main">
                                <div class="span-h6">您上周一至周日总充值: {{taskList[this.taskSet].list.progress}}</div>
                                <!-- <div>{{ item.name }}（<span class="text-orange">{{ item.hasBean > item.threshold ?
                                        item.threshold : item.hasBean}}</span>/{{ item.threshold }}）</div> -->
                                <div class="flex" style="line-height:20px">
                                    <div class="margin-right-xs flex"
                                        style="line-height:20px;display: flex;justify-content:space-between;">
                                        <span class="text-orange mmoney" style="display: flex;align-items: center;">
                                            <img src="@/assets/img/shandian_ceshi.png" height="16" width="16"
                                                style="margin-right: 4px;" /> {{taskList[this.taskSet].list.progress1}}</span>
                                        <div style="color: #fff;">每周一领取</div>
                                    </div>
                                    <!-- <div class="flex" style="line-height:20px" v-if="item.front_reward">
                                        <span class="text-orange">最高{{item.front_reward}}</span>
                                        <money></money>
                                    </div> -->
                                </div>
                            </div>
                            <div class="task-list-right main" @click="tapDraw(taskList[taskSet].list)"><a
                                    href="javascript:void(0)">
                                    领取
                                </a></div>
                            <!-- <div class="task-list-right main" v-if="item.is_receive == 0"><a
                                    :href="item.type ===24 ? '/FmysteryBox' : item.type === 23 ? '/' : '/Flucky'">
                                 前往
                                </a></div> -->
                        </div>
                    </div>
                </div>

                <!-- 规则说明 -->
                <!-- <div class="MuiBox-root jss3358 jss3162" v-show="explainShow">
                    <i class="el-icon-error jss3164 _a" @click="explainShow = false"></i>
                    <div class="MuiBox-root jss3359 jss3163" style="color: #e9b10e">任务系统规则说明</div>
                    <p>1.完成任务后即可领取饰品，F币，ROLL房资格奖励</p>
                    <p>2.完成一项类别中的所有子任务后更有丰厚额外奖励领取</p>
                    <p>3.任务进度会重置，日常任务每日24点重置进度，每周任务每周日24点重置进度</p>
                    <p>4.任务活动的最终解释权归FCSGO所有，恶意刷取奖励等行为，一经发现，系统将溶解奖励，并封禁帐号。</p>
                </div> -->
            </div>
        </div>
        <!-- <el-dialog custom-class="custom_dialog" title="" :close-on-click-modal="false" :visible.sync="dialogVisible"
            append-to-body center width="300px">
            <div class="text-center padding-tb text-white">恭喜您完成任务,领取到了<span class="text-orange">{{ muVal }}F币</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog> -->
    </div>
</template>
<script>
import $api from '@/api/activity.js'
import money from '../../common/money/money.vue';
export default {
    components: { money },
    data() {
        return {
            explainShow: false,
            dialogVisible: false,
            taskReceiveForm: {
                days: 0,
                all_configs: []
            },
            muVal: '',
            taskList: [{
                title: '每日签到',
                finishAward: 10,   //完成奖励
                finishState: false,   //完成奖励状态
                list: []
            }, {
                title: '盲盒任务',
                finishAward: 10,   //完成奖励
                finishState: false,   //完成奖励状态
                list: []
            }, {
                title: '对战任务',
                finishAward: 20,   //完成奖励
                finishState: true,   //完成奖励状态
                list: []
            },
            {
                title: '追梦任务',
                finishAward: 10,   //完成奖励
                finishState: false,   //完成奖励状态
                list: []
            },
            {
                title: '疯狂G1',
                finishAward: 10,   //完成奖励
                finishState: false,   //完成奖励状态
                list: []
            }
            ],
            inform: [
                {
                    timeTips: '12分钟之前',   //时间
                    name: "小李**",   //人名
                    task: '对战消耗任务',   //目标
                    award: '保底20任务目标'   //奖励
                },
                { timeTips: '2分钟之前', name: "猪猪**", task: '盲盒消耗任务', award: '保底120任务目标' },
                { timeTips: '32分钟之前', name: "不知名**", task: '火海', award: '保底120任务目标' },
            ],
            taskSet: 0
        };
    },
    watch: {
        taskSet: {
            handler(val) {
                if (val) this.taskDataList()
                else this.taskReceiveList()
            },
            deep: true,
            immediate: true,
        }
    },
    created() {
        //this.informationList()
        this.taskReceiveList1111();
    },
    methods: {
        taskDataList() {
            console.log(this.taskSet)
            $api.taskReceiven(this.taskSet == 1 ? 2 : this.taskSet == 2 ? 3 : this.taskSet == 3 ? 4 : 6).then(res => {
                if (res.code == 200) {
                    this.taskList[this.taskSet].list = res.data
                    this.$store.state.mission_state = !this.$store.state.mission_state
                }
            })

        },
        // 领取奖励
        tapDraw(data) {
            console.log(data);
            $api.consumePost({ type: data.type }).then(res => {
                if (res.code === 200) {
                    this.$notify({
                        message: `恭喜您完成任务,领取到了${res.data.amount}G币`,
                        position: 'top-left'
                    });
                    // this.dialogVisible = true
                    // this.muVal = res.data.reward_bean
                    // this.$store.state.mission_state = !this.$store.state.mission_state
                    this.taskDataList()
                    this.$store.dispatch('userInfo')
                } else {
                    this.taskDataList()
                }
            })
        },
        // 签到列表
        taskReceiveList1111() {
            $api.taskReceive2().then(res => {
                if (res.code == 200) {
                    this.taskReceiveForm = res.data
                }
            })
        },
        // 签到
        topAttendance() {
            $api.signUp().then(() => {
                this.taskReceiveList()
            })
        },
        // 消息列表
        informationList() {
            $api.remindList().then(res => {
                if (res.code === 200) {
                    this.inform = res.data
                }
            })
        },
        //领取签到奖励
        receiveAward(data) {
            $api.signIn({ day: data.day, type: 1 }).then(res => {
                if (res.code === 200) {
                    this.taskReceiveList()
                    this.muVal = res.data.amount
                    // this.dialogVisible = true
                    this.$notify({
                        message: `恭喜您完成任务,领取到了${res.data.amount}G币`,
                        position: 'top-left'
                    });
                    this.$store.dispatch('userInfo')
                }
            })
        }
    }
}
</script>
<style scoped lang="scss">
.sign-txt {
    >img {
        height: 200px;

        @media (max-width:600px) {
            height: 120px;
        }
    }
}

.jss3449 {
    padding-left: 20px !important;
    margin-bottom: 10px;

    @media (max-width:600px) {
        text-align: center;
        padding-left: 0px !important;
    }
}

@keyframes move {
    0% {
        transform: scale(1.2);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.2);
    }
}

@keyframes rock {
    10% {
        transform: rotate(15deg)
    }

    20% {
        transform: rotate(-10deg)
    }

    30% {
        transform: rotate(5deg)
    }

    40% {
        transform: rotate(-5deg)
    }

    50%,
    100% {
        transform: rotate(0)
    }
}

.taskcenter-broadside {
    .received-img {
        animation: move 1s 0.15s linear infinite;
    }

    .draw {
        position: relative;

        .lingQu {
            left: 55px;
            top: 0;
            position: absolute;
        }
    }

    .received-shade {
        background: black;
        opacity: 0.5;
        z-index: 100;
    }

    .sign-rule {
        img {
            width: 20px;
            vertical-align: middle;
            margin-right: 10px;
        }

        width: 400px;
        line-height: 1.5;
        padding-left:20px;
        padding-top:80px;
        height: 240px;
        font-size: 16px;
        color: #fff;
        background: url('../../static/media/qiandao/rule.png') no-repeat;
        background-size: 100% 100%;
    }

    width: 100%;
    height: 100%;
    display: flex;
    padding: 24px;
    position: relative;
    box-sizing: border-box;

    @media (max-width:600px) {
        display: inherit;
    }

    .taskcenter-consume {
        width: 100%;
        max-height: 420px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    a {
        color: #C3C3E2;
    }

    a:hover {
        color: #0056b3;
    }

    .taskcenter-inform {
        width: 100%;
        background-color: rgb(0, 0, 0, 0.3);
        border-radius: 6px;
        display: flex;
        margin-bottom: 10px;

        .medium {
            width: 100%;
            color: #848492;
            font-size: 14px;
            ;
            line-height: 30px !important;
        }
    }

    .jss1963 {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 16px;
        position: relative;
        box-sizing: border-box;
    }

    .jss1965 {
        width: 20%;
        height: 60%;
        margin-left: 20px;
        overflow-y: auto;

        @media (max-width:600px) {
            width: 100%;
            display: flex;
            margin-left: 0px;
        }
    }

    .jss3442 {
        color: #C3C3E2;
        width: 100%;
        cursor: pointer;
        height: 32px;
        font-size: 14px;
        box-sizing: border-box;
        line-height: 32px;
        padding-left: 5px;

        @media (max-width:600px) {
            width: 140px;
            padding-left: 0px !important;
        }
    }

    .MuiBox-award {
        display: flex;
        background: url("https://zeroskins.com/static/media/task_img.c33e057a.png") 0% 0% / 100% 100% no-repeat;

        .award-left {
            color: #E9B108;
            width: 150px;
            font-size: 20px;
            font-weight: 600;
            padding: 50px 20px;
        }

        .award-right {
            width: 20%;
            display: inherit;
            align-items: inherit;
            justify-content: inherit;
            line-height: 120px;
        }

        .award-main {
            width: 70%;
            text-align: center;

            .award-tip {
                color: #FFFFFF;
                font-size: 12px;
                margin-top: 10px;
            }

            img {
                height: 78px;
                animation: move 1.5s ease infinite;
            }

            .award-bottom {
                color: #E9B108;
                font-size: 14px;
                margin-bottom: 5px;
            }
        }
    }

    .jss1970 {
        color: #C3C3E2;
        font-size: 14px;
        margin-top: 20px;
        min-height: 200px;
        line-height: 20px;
    }

    .jss1964 {
        left: 0;
        bottom: 0;
        position: absolute;
    }

    .jss1971 {
        color: #E9B10E;
        width: 180px;
        border: 1px solid #e9b10e;
        height: 41px;
        margin: 10px auto;
        display: block;
        padding: 0;
        z-index: 9;
        font-size: 18px;
        line-height: 41px;
        border-radius: 50px;
    }

    .jss3443 {
        color: #e9b108 !important;
        border-radius: 4px;
        height: 32px;
        line-height: 32px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: linear-gradient(180deg, rgba(0, 174, 255, 0.38), rgba(0, 174, 255, 0.38));

        @media (max-width:600px) {
            width: 120px;
        }
    }

    .jss1969 img {
        width: 100%;
    }

    .jss1341 {
        width: 100%;
        height: 30px;
        margin: 10px auto;
        padding: 0 10px;
        position: relative;
        font-size: 14px;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: hidden;
        line-height: 30px;
        white-space: nowrap;
        padding-left: 60px;
        border-radius: 5px;
        text-overflow: ellipsis;
        background-color: rgb(0, 0, 0, 0.3);
    }

    .jss1336 {
        width: 80%;
        height: 100%;
    }

    .jss1342 {
        top: -3px;
        left: 5px;
        width: 30px;
        z-index: 999;
        position: absolute;
    }

    .welfare-tips {
        color: #fff;
        text-align: center;
        font-size: 12px;
        margin: 10px 0;

        img {
            width: 80px;
            height: 80px;
            margin-bottom: 10px;
        }
    }

    .home-activity-main-img {
        padding: 10px 10px 0 10px;

        img {
            width: 100%;
        }

        .activity-mian-text {
            color: #fff;
            width: 100%;
            padding: 15px;
            font-size: 14px;
            background: #333;
        }
    }

    .jss1346 {
        display: flex;
        text-align: center;
        margin-bottom: 10px;
        justify-content: space-between;
    }

    .jss1348 {
        color: #C3C3E2;
        cursor: pointer;
        font-size: 12px;
    }

    .jss1347 {
        color: #C3C3E2;
        cursor: pointer;
        font-size: 12px;
    }

    .jss1337 {
        width: 100%;
        height: 86%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .jss1377 {
        background: url("https://zeroskins.com/static/media/task_img.c33e057a.png") 0% 0% / 100% 100% no-repeat;
    }

    .jss1352 {
        width: 100%;
        height: 136px;
        display: flex;
        padding: 5px 20px;
        box-sizing: border-box;
        text-align: center;
        align-items: center;
        justify-content: space-between;
    }

    .jss1364 {
        width: 100%;
        margin: 10px 0;
        display: flex;
        padding: 10px;
        position: relative;
        background: #050732;
        box-sizing: border-box;
        min-height: 85px;
        padding-left: 5px;
        border-radius: 5px;
    }

    .jss1365 {
        width: 14%;
        position: relative;
        box-sizing: border-box;
    }

    .jss1366 {
        top: 50%;
        left: 50%;
        position: absolute;
        max-width: 70%;
        transform: translate(-50%, -50%);
    }

    .jss1367 {
        width: 64%;
        position: relative;
    }

    .jss1368 {
        color: #C3C3E2;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .jss1369 {
        color: #848492;
        margin: 5px 0;
        font-size: 14px;
    }

    .jss1372 {
        color: #848492;
        font-size: 12px;
    }

    .jss1367 {
        width: 64%;
        position: relative;
    }

    .text-orange {
        color: #fff;
    }

    .jss1373 {
        width: 24%;
        display: flex;
        position: relative;
        justify-content: center;
    }

    .task-list {
        width: 100%;
        margin: 10px 0;
        border-bottom: solid 1px #666;
        display: flex;
        position: relative;
        //  background: #3F4151;
        box-sizing: border-box;
        min-height: 85px;
        padding-left: 5px;
        border-radius: 5px;

        .task-list-img {
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width:600px) {
                width: 60px;
            }

            img {
                width: auto;
                height: 60px;

                @media (max-width:600px) {
                    height: 50px;
                }
            }
        }

        .task-list-main {
            width: 64%;
            padding: 10px 0px;
            margin: 0 10px;
            color: #848492;

            >div {
                margin-bottom: 10px;
                font-size: 14px;
            }

            .span-h6 {
                color: #fff;
                font-size: 16px;
                margin-top: 20px;

                @media (max-width:600px) {
                    font-size: 14px;
                }
            }
        }

        .task-list-right {
            width: calc(36% - 124px);
            padding: 10px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            text-align: center;

            @media (max-width:600px) {
                width: 80px;
                margin-top: 10px;
            }

            a {
                width: 100%;
                height: 100%;
                display: block;
            }

            img {
                line-height: 80px;
                vertical-align: middle;
            }
        }

        .task-list-right.ok {
            background: #53A228;

            img {
                margin-top: 14px;
            }
        }

        .task-list-right.main {
            // background: #FFC67B;

            img {
                margin-top: 28px;
            }

            >a {
                height: 30px;
                line-height: 30px;
                margin-top: 20px;
                border: 1px solid rgba(45, 116, 222, 0.3);
                background: linear-gradient(90deg, rgba(19, 108, 172, 0.5), rgba(66, 77, 181, 0.2));
            }
        }

        .task-list-right.accomplish {
            background: #494949;

            img {
                margin-top: 10px;
            }
        }
    }

    .text-orge {
        color: #E9B108;
    }

    .jss3089 {
        width: 100%;
        height: 30px;
        margin: 10px auto;
        padding: 0 10px;
        position: relative;
        font-size: 14px;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: hidden;
        line-height: 30px;
        white-space: nowrap;
        padding-left: 60px;
        border-radius: 5px;
        text-overflow: ellipsis;
        background-color: rgb(0, 0, 0, 0.3);
    }

    .jss1968 {
        width: 80%;
        height: 450px;

        @media (max-width:600px) {
            width: 100%;
        }
    }

    .jss3129 {
        display: flex;
        font-size: 12px;
        color: #C3C3E2;
        text-align: center;
        margin-bottom: 10px;
        justify-content: space-between;
    }

    // 规则说明
    .jss3162 {
        top: 100px;
        left: 50%;
        color: #fff;
        width: 300px;
        padding: 20px 30px;
        position: absolute;
        border-radius: 10px;
        font-size: 12px;
        transform: translateX(-50%);
        background: #333;
        border-radius: 5px;
        background-size: 100% 100%;

        .jss3164 {
            top: 10px;
            right: 10px;
            position: absolute;
            font-size: 16px;
        }

        .jss3163 {
            color: #C3C3E2;
            font-size: 16px;
            text-align: center;
            font-weight: 500;
            margin-bottom: 20px;
        }

        p {
            margin-bottom: 10px;
        }

        .MuiSvgIcon-root {
            fill: currentColor;
            width: 1em;
            height: 1em;
            display: inline-block;
            font-size: 1.5rem;
            transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            flex-shrink: 0;
            user-select: none;
            vertical-align: middle;
        }
    }
}
</style>