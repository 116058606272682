const axios = require('axios')
import { getToken } from '@/utils/auth'
import Notification from "element-ui/packages/notification/src/main";
import store from '../store'
import router from '../router'
import { removeToken } from '@/utils/auth'

export function request(config) {
    let apiurl = `https://api.g1csgo.com/api` //'http://api.demo628.aaskins.cn/api'

    const instance = axios.create({
        baseURL: apiurl,
    })

    instance.interceptors.request.use(config => {
        let token = getToken()
        config.headers = {
            'Authorization' : 'Bearer ' + token || ''
        }
        return config
    },err => {
        return err.data
    })

    instance.interceptors.response.use(res => {
        if(res.data.code == 500 ){
            Notification.closeAll()
            /* 500 服务器错误 */
            Notification.error({
                message: res.data.message,
                position: 'top-left'
            });

        }
        if(res.data.code == 401){

     
            removeToken()
            store.commit("LOGIN_IS_SHOW", true)
            store.commit('USER_INFO',{}) 
            router.push({name:'Z2nologin'}) 
            return res.data
        }
        return res.data
    },err => {
        return err.data
    })

    return instance(config)
}
