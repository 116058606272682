<template>
  <div class="content">
    <div
        class="h3 lucky-title-top text-white font-weight-bold text-center my-md-5 mt-5"
      >
        <div class="biaoti_img">
          <img src="@/assets/newImg/zm.png" height="80px" />
        </div>
        <div
          class="cursor-pointer text-center "
          style="font-size: 16px"
          @click="playingMethod = true"
        >
          活动规则
        </div>
        <div class="closeOpenewfwefw" style="font-size: 16px;text-align: right;">
              <el-switch
                class="animationOFF"
                v-model="animationOFF"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
              关闭动画
            </div>
      </div>
    <div class="mp-content">

      
      

      <div
        class="d-flex align-items-center justify-content-between lucky-fontSize"
      >
        <div class="d-md-flex d-block">
          <!-- <div
            v-if="SelectedPic"
            class="mr-2 p-3 cursor-pointer"
            @click="luckyHistoryQ"
          >
            最近掉落
          </div> -->
        </div>
        <div>
          <div class="d-flex">
            
          </div>
        </div>
      </div>
      <div class="content-top d-flex justify-content-around align-items-center">
        <div
          class="d-flex flex-column align-items-center justify-content-center content-top-left"
          @click="selectClose = true"
        >
          <img
            :src="
              SelectedPic
                ? SelectedPic.cover
                : require('@/assets/newImg/qz.png')
            "
            alt=""
            :class="SelectedPic ? 'content-top-active' : ''"
            :style="{width: !SelectedPic ? '420px' : '320px' }"
            class="content-top-left-img"
          />
          <div style="width: 220px" class="text-overflow-1 text-center">
            {{ SelectedPic ? SelectedPic.name : "" }}
          </div>
          <span class="mt-3 upDom">
            <span v-if="SelectedPic">
              <money></money>{{ SelectedPic ? SelectedPic.bean : "0.00" }}</span
            >
            <!-- <img
              v-else
              style=""
              class="content-top-left-img-ico"
              src="../../assets/img/lucky/upgrade__icon__arrow.png"
              alt=""
            /> -->
          </span>
        </div>
        <div
          class="d-flex justify-content-center align-items-center position-relative content-top-yuan"
        >
          <img src="../../assets/img/lucky/upgrade__rotary.png" alt="" />
          <img
            class="position-absolute anShanDian"
            src="../../assets/img/lucky/shengji_1.png"
            style=""
            alt=""
            v-if="btnStatus"
          />
          <img
            v-if="!oprnClickIsShow"
            class="position-absolute content-top-yuan-xian"
            name="1"
            :style="`transform: rotate(${360 * (successRate / 100)}deg);transition: all 0s;`"
            src="../../assets/img/lucky/upgrade__rotary__pointer.png"
            alt=""
          />
          <img
            v-else
            class="position-absolute content-top-yuan-kai-jiang"
            name="2"
            :style="`transform: rotate(${successRateTow}deg);`"
            src="../../assets/img/lucky/upgrade__rotary__pointer.png"
            alt=""
          />
          <!-- <img
            class="position-absolute"
            src="../../assets/img/lucky/upgrade__rotary__box.png"
            alt=""
          /> -->
          <div class="position-absolute">
            <linear-gradient :value="successRate2 / 100"></linear-gradient>
          </div>

          <div class="position-absolute text-center">
            <div v-if="!loing">
              <div class="font-weight-bold h1">
                {{ successRate }} <span class="h6">%</span>
              </div>
              <div>设置追梦概率</div>
            </div>

            <div
              v-else
              class="lucky-error-succeed animate__fadeInUp animate__animated"
            >
              <div v-if="resData.skin_id != SelectedPic.id" class="lucky-error">
                升级失败
              </div>
              <div v-else class="lucky-succeed">升级成功</div>
              <div class="font-weight-bold">
                {{ successRate }} <span class="">%</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="phone-therr d-flex flex-column align-items-center justify-content-center"
        >
          <img
            src="../../assets/newImg/sj.png"
            alt=""
            style="width: 420px"
          />
          <!-- <div style="width: 220px" class="text-overflow-1 text-center">
            随机得到的饰品
          </div> -->
          <div style="opacity: 0">随机得到的饰品</div>
          <div style="opacity: 0">随机得到的饰品</div>
        </div>
      </div>

      <div class="content-bottom">
        <div class="d-flex" style="justify-content: center;align-items: center;">
          <div>
            成功率<span style="color: #28eb6c"> ({{ successRate }}%) </span> /
            失败率<span style="color: #eb2727">
              ({{ 100 - successRate }}%)</span
            >
          </div>
          <div class="d-flex" style="align-items: center;
    display: flex;
    margin-left: 20px;">
            <div><money /> {{ money }}</div>
          </div>
        </div>
        <!-- 进度条 -->
        <div class="content-bottom-slide">
          <el-slider :show-tooltip="false"
            class="content-bottom-slide-slider"
            :min="0"
            :max="75"
            v-model="successRate"
          ></el-slider>
          <!-- <div>
            <img
              class="content-bottom-slide-slider-img"
              src="@/assets/img/lucky/jindutiao.png"
              alt=""
            />
          </div> -->
        </div>
        <!-- 按钮 -->
        <div
          class="content-bottom-btn"
          v-if="btnStatus || btnStatus==null"
          :class="
            successRate > 4 ? 'content-bottom-btn2' : 'content-bottom-btn1'
          "
          @click="openAward(SelectedPic, successRate)"
        >
          <div>立刻升级</div>
        </div>
        <div
          class="content-bottom-btn"
          v-else
          :class="
            successRate > 4 ? 'content-bottom-btn2' : 'content-bottom-btn1'
          "
          @click="btnStatus=null,oprnClickIsShow = false"
        >
          <div>继续追梦</div>
        </div>
        <div class="text-center color-yellow mt-3">
          * 升级概率范围为5% - 75%
        </div>
      </div>

      <!-- 奖品弹窗 -->
      <div class="" v-if="false">
        <div
          style="width: 700px; left: calc(50% - 350px); top: 50px"
          class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg"
        >
          <div>
            <div
              class="d-flex flex-column align-items-center justify-content-center py-5 flex-wrap"
            >
              <div>
                <img :src="resData.cover" width="300px" alt="" />
              </div>
              <div class="text-center">
                <div>{{ resData.name }}</div>
                <div class="mt-2">{{ resData.dura_alias }}</div>
                <div class="mt-2"><money />{{ resData.bean }}</div>
              </div>
            </div>
            <div class="d-flex px-5 justify-content-center mt-3">
              <div
                @click="svgaIsShowOff"
                style="width: 250px;"
                class="text-white text-center f_btn_green cursor-pointer"
              >
                放入仓库
              </div>
            </div>
            <div
              @click="svgaIsShowOff"
              class="position-absolute"
              style="right: 20px; top: 30px"
            >
              <div>
                <i
                  class="iconfont text-white icon-guanbi2 cursor-pointer"
                  style="font-size: 30px"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div
          class="position-fixed bg-bg-2 op-4 z-index-1"
          style="width: 100vw; height: 100vh; left: 0; top: 0"
        ></div>
      </div>

      <!-- 武器弹窗 -->
      <div class="select" v-if="selectClose">
        <div class="select-top d-flex justify-content-between p-md-4 p-2">
          <div>选择饰品</div>
          <div @click="selectClose = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="select-lucky">
          <lucky-pup @pitchOn="pitchOn"></lucky-pup>
        </div>
        <div
          class="position-fixed bg-bg-2 op-4 z-index-0"
          style="width: 100vw; height: 100vh; left: 0; top: 0"
        ></div>
      </div>

      <!-- 中将弹窗 -->
      <transition name="el-fade-in-linear">
        <div v-if="svgaIsShow">
          <div
            style=""
            class="f-border-top awardListPop position-fixed pop-up z-index-100 py-5 px-4 history_bg"
          >
            <div>
              <div class="h5 text-center">您获得了</div>
              <div
                class="awardListPop-box d-flex justify-content-center pt-5 flex-wrap"
              >
                <div
                  class="text-center rounded award-box position-relative ggbg2"
                  :class="resData.skin_id == SelectedPic.id ? 'ggbg2' : 'ggbg5'"
                  style="width:300px;"
                >
                  <div
                    class="my-1 text-overflow-1 title_qian"
                    style="line-height: 16px;"
                  >
                    {{ resData.name }}
                  </div>
                  <img :src="resData.cover" alt="" style="width: 80%; height: 90px;" />
                  <div
                    v-if="resData.skin_id != SelectedPic.id"
                    class="d-flex justify-content-between huishoubtn"
                    style="font-size: 14px"
                  >
                    <div class="text-center w-50 p-2">
                      <money />{{ resData.bean }}
                    </div>
                    <div
                      class="text-center w-50 p-2 cursor-pointer"
                      @click="recycle(resData)"
                    >
                      溶解
                    </div>
                  </div>
                  <div
                    v-else
                    class="d-flex justify-content-between huishoubtn"
                    style="font-size: 14px"
                  >
                    <div class="text-center w-100 p-2">
                      <money />{{ resData.bean }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex mt-2 justify-content-between">
                <div @click="svgaIsShowOff">
                  <bottom-bg2>
                    <div style="color: #ffffff; font-size: 16px" class="">
                      {{
                        resData.skin_id != SelectedPic.id
                          ? "继续开箱"
                          : "放入背包"
                      }}
                    </div>
                  </bottom-bg2>
                </div>
                <div @click="recycle(resData)" class="cursor-pointer">
                  <bottom-bg2>
                    <div style="color: #ffffff; font-size: 16px" class="">
                      <span style="margin-right: 10px; font-size: 14px"
                        >饰品溶解</span
                      >
                      <!-- <money color="#FFFFFF" font-size="16px" />
                      {{resData.bean}} -->
                    </div>
                  </bottom-bg2>
                </div>
              </div>

              <div
                @click="svgaIsShowOff"
                class="position-absolute"
                style="right: 20px; top: 30px"
              >
                <div>
                  <i
                    class="iconfont text-white icon-guanbi2 cursor-pointer"
                    style="font-size: 30px"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div
            class="position-fixed bg-bg-2 op-4 z-index-1"
            style="width: 100vw; height: 100vh; left: 0; top: 0"
          ></div>
        </div>
      </transition>
    </div>

    <!-- 中将记录 -->
    <div style="">
      <div class="content1">
    <div class="h3 text-white font-weight-bold text-center my-5 mshdwfiefewf">
      {{ '最近掉落'}}
    </div>
    <!-- <div @click="codeBack" class="p-2 bg-yellow cursor-pointer d-flex col-md-1 col-4 justify-content-center ml-auto">
      返回
    </div> -->

    <div class="mt-3  content-list">
      <table cellspacing="0" cellpadding="0" border="0" class="content-list-box" style="">
        <colgroup>
          <col width="100">
          <col v-if="id != 0" width="200">
          <col width="200">
          <col width="120">
          <col width="120">
          <col width="100">
         
          <col width="250">
          <col width="150">
        </colgroup>
        <thead class="text-danger">

        <tr class="grade">
          <th colspan="1" rowspan="1">
            <div class="cell">合成结果 </div>
          </th>
          <th v-if="id != 0" colspan="1" rowspan="1">
            <div class="cell">用户</div>
          </th>

          <th colspan="1" rowspan="1">
            <div class="cell">饰品图片</div>
          </th>

          <th colspan="1" rowspan="1">
            <div class="cell">获得饰品外观</div>
          </th>
          <th colspan="1" rowspan="1">
            <div class="cell">获得饰品价值</div>
          </th>

          <!-- <th colspan="1" rowspan="1">
            <div class="cell">花费</div>
          </th> -->
         <!-- <th colspan="1" rowspan="1">
           <div class="cell">拉取量 </div>
         </th> -->
          <th colspan="1" rowspan="1">
            <div class="cell">目标饰品名称</div>
          </th>
          <th colspan="1" rowspan="1">
            <div class="cell">目标饰品外观 </div>
          </th>
          <th colspan="1" rowspan="1">
            <div class="cell">时间 </div>
          </th>


        </tr>
        </thead>
        <tr class="grade lucky-H-bg" :class="item.award_id ==item.get_award.id?'color-yellow':''" v-for="(item,index) in historyList" :key="index">


          <td>
            <div v-if="item.award_id != item.get_award.id" class="cell flex-column align-items-center justify-content-center" >
              <!-- <div><img style="width:50px" src="@/assets/img/lucky/upgradeHistory__lose.png" alt=""></div> -->
              <div>失败</div>
            </div>
            <div v-else class="cell flex-column align-items-center justify-content-center" >
              <!-- <div><img style="width:50px" src="@/assets/img/lucky/upgradeHistory__succeed.png" alt=""></div> -->
              <div style="color: aqua;">成功</div>
            </div>
          </td>

          <td v-if="id != 0" >
            <div class="px-4 pb-1 text-center d-flex align-items-center">
              <img style="height: 50px" class="rounded-pill" v-lazy="item.user.avatar" alt="">
              <div class="text-overflow-1 ml-1">{{item.user.name}}</div>
            </div>
          </td>

          <td>
           <div class="px-4  text-center d-flex flex-column align-items-center">
             <img style="height: 50px" class="" v-lazy="item.get_award.cover" alt="">
             <div class="" style="font-size: 12px">{{item.get_award_name }}</div>
           </div>
          </td>

          <td >
            <div class="cell">{{item.get_award_dura_alias }}</div>
          </td>

          <td >
            <div class="cell">{{item.get_bean }}</div>
          </td>



          <!-- <td >
            <div class="cell">{{item.use_bean }}</div>
          </td> -->

          <!-- <td >
            <div class="cell">{{item.percent + '%' }}</div>
         </td> -->

          <td >
            <div class="cell">
              {{item.award_name}}
            </div>
          </td>

          <td >
            <div class="cell">{{item.award_dura_alias }}</div>
          </td>
          <td >
            <div class="cell">{{item.created_at }}</div>
          </td>




        </tr>
      </table>
     <div class="mt-3">
       <el-pagination
         background
         layout="prev, pager, next"
         :total="total"
         :page-size="pageSize"
         @current-change="currentChange"
       >
       </el-pagination>
     </div>
    </div>


  </div>
    </div>
    <!-- 玩法介绍 -->
    <div class="" v-if="playingMethod">
      <div
        style="width: 700px; left: calc(50% - 350px); top: 15vh"
        class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg"
      >
        <div class="text-white p-md-4 p-2" style="line-height: 2">
          <p>
            1.每次追梦皆为独立计算，通过随机函数得到某个数值，与当前饰品价值乘以玩家控制百分比范围得到的所占用范围区间进行比对，若随机得到的数值与所在道具区间一致，则获得该饰品，若失败则获得随机安慰饰品。
          </p>
          <p>&nbsp;</p>
          <p>
            2.由于每次追梦为独立计算，当您连续三次的30%概率开启追梦饰品时不意味着有90%几率获得该饰品。当您连续3次30%都未掉落饰品时，第四次修改为10%概率开启不意味着100%获得饰品，相比于之前的30%获得几率可能会面临更大的失败概率。
          </p>
          <p>&nbsp;</p>
          <p>3.概率具有随机性，在最近掉落统计中会存在波动。</p>
          <p>&nbsp;</p>
          <p>
            &nbsp;&nbsp;&nbsp;例如盲盒内有A、B两件饰品，获得概率都为50%，当连续开启该盲盒时，无法保证开启结果稳定为ABABAB。再例如某饰品掉落概率为10%，可能10次无掉落，也可能20次掉落一次。无法保证每开10次必定掉落1次。
          </p>
          <p>&nbsp;</p>
          <p style="color: red">&nbsp;&nbsp;&nbsp;请谨慎操作，理性消费！</p>
        </div>
        <div
          @click="playingMethod = false"
          class="position-absolute"
          style="right: 20px; top: 30px"
        >
          <div>
            <i
              class="iconfont text-white icon-guanbi2 cursor-pointer"
              style="font-size: 30px"
            ></i>
          </div>
        </div>
      </div>
      <div
        class="position-fixed bg-bg-2 op-4 z-index-1"
        style="width: 100vw; height: 100vh; left: 0; top: 0"
      ></div>
    </div>
  </div>
</template>

<script>
import $api from "@/api/lucky/index";
import $url from "@/api/CSGO/index";
import luckyPup from "./common/luckyPup";
import linearGradient from "./common/linearGradient";
import bottomBg2 from "@/components/bottomBg2";
import history from "./history.vue";
import sUserAgent from "@/assets/js/common.js";
export default {
  name: "luckyIndex",
  data() {
    return {
      selectClose: false,
      playingMethod: false,
      successRate: 0,
      successRate2: 0,
      oprnClickIsShow: false,
      SelectedPic: null,
      animationOFF: true,
      resData: {},
      svgaIsShow: false,
      successRateTow: 30,
      loing: false,
      ClickAgain: false,
      historyList: true,
      SelectedPicID: 0,
      btnStatus:null
    };
  },
  components: {
    luckyPup,
    linearGradient,
    bottomBg2,
    history,
  },
  created() {
    setInterval(() => {
      // console.log(this.successRate);
      this.successRate2 = this.successRate - 0.6;
    }, 1000);
  },
  computed: {
    money() {
      return (
        // 1.205
        (this.successRate / 100) *
        (this.SelectedPic ? this.SelectedPic.bean * 1 : 0)
      ).toFixed(2);
    },
    ua() {
      console.log("sUserAgent");
      console.log(sUserAgent);
      return sUserAgent.sUserAgent();
    },
  },
  methods: {
    codeBack(Bon) {
      this.historyList = Bon;
    },
    // 点击我的记录
    luckyHistory() {
      this.historyList = true;
      this.SelectedPicID =this.SelectedPic.id;
    },
    // 点击饰品显示的记录
    luckyHistoryQ() {
      this.historyList = true;
      this.SelectedPicID = this.SelectedPic.id;
    },
    // 点击溶解
    async recycle(item) {
      let arr = [];
      arr.push(item.id);
      let { message, code } = await $url.getRecycle({ data: arr });
      if (code == 500) return;
      this.$notify({
        message: message,
        type: "success",
        position: "top-left",
      });
      this.svgaIsShowOff();
      this.$store.dispatch("userInfo");
      this.ClickAgain = false;
    },
    // 两个数字之间的值
    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    // 点击开箱
    async openAward(SelectedPic = null, successRate) {
      this.$notify.closeAll()
      if (this.successRate < 5) {
        this.$notify({
          showClose: false,
          message: "成功率不得低于5%",
          type: "warning",
          position: "top-left",
        });
        return;
      }
      // 没有选择饰品的情况
      if (SelectedPic == null) {
        this.$notify({
          showClose: false,
          message: "请选择饰品",
          type: "warning",
          position: "top-left",
        });
        return;
      }
      if (this.ClickAgain) {
        this.$notify({
          showClose: false,
          message: "正在开箱，稍后......",
          type: "warning",
          position: "top-left",
        });
        return;
      }
      this.ClickAgain = true;
      // 发出请求
      let { data, code } = await $api.getluckyOpen({
        id: SelectedPic.id,
        percent: (successRate / 100).toFixed(2),
      });
      if (code == 500) {
        return;
      }
      // // 得到结果
      if (SelectedPic.id == data.skin_id) {
        // 暂时没用

        this.successRateTow =
          360 * 3 +
          360 * (this.successRate / 100) -
          Math.floor(Math.random() * (360 * (this.successRate / 100)));
      } else {
        let editVal= 360 * 3 +
          360 * (this.successRate / 100) +
          this.getRandomNumber(360 * (this.successRate / 100) + 10, 360) -
          360 * (this.successRate / 100);
        if( editVal <= 1442 && editVal >= 1432 ){
          this.successRateTow = editVal-10
        } else {
          this.successRateTow = editVal
        }
      }

      this.resData = data;
      // 更新金币
      this.$store.dispatch("userInfo");
      
      // 动画关闭的时候
      if (!this.animationOFF) {
        this.oprnClickIsShow = false;
        window.scrollTo(0, 1000);
        this.svgaIsShow = true;
        this.$store.dispatch("userInfo");
      } else {
        this.oprnClickIsShow = true;
        this.btnStatus=true;
        // 声音是六秒
        document.getElementById("luckey").play();
        setTimeout(() => {
          this.loing = true;
        }, 6000);
        setTimeout(() => {
          this.svgaIsShow = true;
          this.btnStatus = false;
          console.log('结束更新')
          this.getHistory()
        }, 8000);
      }
    },
    pitchOn(item) {
      console.log('点击了')
      this.SelectedPicID = item.item.id
      this.SelectedPic = item.item;
      this.getHistory()
      setTimeout(() => {
        this.selectClose = false;
      }, 500);
    },
    // 关闭结果
    svgaIsShowOff() {
      this.svgaIsShow = false;
      this.loing = false;
      this.ClickAgain = false;
    },
    // 翻页
    currentChange(val) {
          console.log(this.id)
        if(this.id == 0){
          this.getHistoryList(val)
        }else {
          this.getHistory()
        }
        window.scrollTo(0,0)
      },
      getHistory(){
        $api.getHistory(this.SelectedPicID).then(res => {
          console.log(res)
          this.historyList = res.data
          this.total = 0
          this.pageSize = 0
        })

      },
      codeBack(){
        this.$emit('codeBack',false)
      }
  },
};
</script>

<style scoped lang="scss">



.closeOpenewfwefw{
  width: 1400px;
  @media (max-width:600px) {
    width: 100%;
  }
}

@media (max-width:600px) {
  .content1{
    width: 100% !important;
  }
}

.content1{
  width: 1600px;
  margin: 0 auto
}
.mshdwfiefewf{
  text-align: left !important;
}
  .content-list{
    overflow-x: scroll;
    width: 100%;
    &-box{
      width: 100%;
      @media (max-width: 1366px) {
        width: 1200px;
      }
    }
  }
  .lucky-H-bg{
    // background-image: url("../../assets/img/lucky/upgradeHistory__item__bg.png") !important;
    background-size: 100% 100%;
    margin-bottom: 10px;
  }
  .lucky-H-bg:nth-child(odd){
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 52%, rgba(255, 255, 255, 0) 100%);
  }
  td{
    height: 100px;
    vertical-align: middle;
  }
  .grade {
    
background: rgba(0, 191, 255, 0.149);
    @media (max-width: 1200px) {
      font-size: 12px;
    }
  }

  .input-code {
    @media (max-width: 1200px) {
      width: 150px !important;
    }
  }

  .tong-height {
    height: 38px;
    line-height: 38px;
  }

  .side-box {
    width: 204px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5)
  }

  .content {
    
    margin: 0 auto;
    @media (max-width: 1200px) {
      width: 96%;
      font-size: 12px !important;
    }
  }

  .cell {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    font-size: 14px;
    @media (max-width: 1200px) {
      padding: 5px;
    }
  }
  .highlighted-message {
    padding: 19px 5px 19px 16px;
    border: 1px solid #fada62;
    border-radius: 8px;
    background-color: rgba(250,218,98,0.12);
  }
.biaoti_img{
  @media (max-width: 1000px) {
    img{
      width: 140px;
      height: inherit;
    }
  }
}
.lucky-title-top {
  @media (max-width: 1000px) {
    font-size: 1.25rem !important;
  }
}
.lucky-fontSize {
  @media (max-width: 1000px) {
    font-size: 0.8rem !important;
    position: relative;
    z-index: 9;
    .animationOFF {
      transform: scale(0.8);
    }
  }
}
.lucky-error-succeed {
  width: 100px;
  height: auto;
}
.lucky-error {
  background-image: url("../../assets/img/lucky/upgrade__bg__faild.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 30px;
}
.lucky-succeed {
  background-image: url("../../assets/img/lucky/upgrade__bg__succeed.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 30px;
}
.content {
  width: 96%;
  margin: 0 auto;
  @media (max-width:600px) {
    width: 96% !important;
  }
  .mp-content{
    margin-top:4rem;
    background-image: url("../../assets/newImg/bg.jpg");
    background-size: 1600px auto;
    background-position: center -30px;
    background-repeat: no-repeat;
    @media (max-width:600px) {
      background-image: none;
    }
  }
  &-top {
    width: 1600px;
    margin: 0 auto;
    height: 360px;
    background-size: 100% 100%;
    margin-top: 110px;
    @media (max-width:600px) {
      width: 100%;
      margin-top:0px;
    }
    > div{
      flex: 1;
    }
    > div:nth-child(2){
      transform: scale(1.2);
      @media (max-width:600px) {
        transform: scale(1.0);
      }
    }
    // background-image: url("../../assets/img/lucky/upgrade__skins.png");
    @media (max-width: 1200px) {
      height: auto;
      background-image: none;
      & .phone-therr {
        display: none !important;
      }
    }
    @media (max-width: 960px) {
      transform-origin: top;
      transform: scale(0.9);
    }
    @media (max-width: 750px) {
      transform-origin: top;
      transform: scale(0.9);
      flex-direction: column-reverse !important;
    }
    &-yuan {
      width: 500px;
      height: 360px;
      @media (max-width: 1000px) {
        transform: scale(0.8);
        transform-origin: top;
      }
      @media (max-width: 960px) {
        height: 210px;
      }
      &-xian {
        // transition: all 0.5s;
      }
      /* 这里动画多少秒就是多少秒 */
      &-kai-jiang {
        transition: all 6s;
      }
    }
    &-active {
      animation: animationName 3s infinite;
    }
    &-left {
      @media (max-width: 960px) {
        padding: 5px;
        margin-top: 50px;
        background-image: linear-gradient(0deg, #1d2027, #13161c);
        background-blend-mode: normal, normal;
        border-radius: 10px;
        border: 1px solid #1c2029;
        transform-origin: top;
        transform: scale(0.9);
        &-img {
          width: 120px !important;
        }
      }
    }
  }
  &-bottom {
    padding: 25px 100px 0;
    margin-top: 110px;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    height: 300px;
    // background-image: linear-gradient(0deg, #1d2027, #13161c);
    background-blend-mode: normal, normal;
    border-radius: 10px;
    border: 1px solid #1c2029;

    &-btn {
      margin: 0px auto 0;
      width: 360px;
      height: 72px;
      border-radius: 6px;
      background-size: contain;
      display: flex;
      font-size: 24px;
      background: rgba(0, 0, 0, 0);

box-sizing: border-box;
border: 1px solid #00BFFF;
background: linear-gradient(0deg, #00BFFF 0%, #7EFFEA 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
text-shadow: 0px 2px 3px rgba(0, 229, 255, 0.5);
box-shadow: 0px 0px 3px 0px #00E5FF;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-weight: bold;
      transition-duration: 0.3s;
      -webkit-transition-duration: 0.3s;
      cursor: pointer;
      @media (max-width: 1000px) {
        font-size: 1rem;
        width: 60%;
        margin-top: 10px;
        height: 50px;
        background-size: 100% 100%;
      }
    }
    &-btn1 {
      opacity: 0.2;
      // background-image: url("../../assets/img/lucky/upgrade__btn__bg.png");
    }
    &-btn2 {
      // background-image: url("../../assets/img/lucky/upgrade__btn__bg__active.png");
    }
    &-slide {
      width: 1000px;
      height: 88px;
     //  background-color: #1A1B25;
      border-radius: 4px;
      padding: 30px 200px;
      margin: 0 auto;
      // display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;
      margin-top: 10px;
      @media (max-width:600px) {
        width: auto;
      }
      &-slider-img {
        width: 103%;
        height: 40px;
        margin-left: -14px;
        @media (max-width: 1200px) {
          width: 101.5%;
          margin-left: 0;
          height: auto;
        }
      }
    }
    @media (max-width: 1200px) {
      margin-top: -40px;
      padding: 10px 10px 0;
      font-size: 0.8rem !important;
      height: 210px;
      &-slide {
        padding: 20px 20px;
        height: 55px;
      }
    }
  }
}
/* 弹窗列表也面 */
.select {
  position: fixed;
  top: 15vh;
  left: 20%;
  width: 60%;
  z-index: 999;
  &-top {
    background-color: #161721;
    border: 1px solid #454759;
    border-bottom: 0;
    /*width: 100%;// 由于下面列表是90  所以改成九十*/
    /*background-image: url("../../assets/img/lucky/shop_top.png");*/
    /*background-size: 100% 100%;*/
  }
  &-lucky {
    /*background-image: url("../../assets/img/lucky/img_tancuan.png");*/
    /*background-size: 100% 100%;*/
    background-color: #161721;
    border: 1px solid #454759;
    border-top: 0;
  }
  @media (max-width: 1200px) {
    top: 5vh;
    width: 90%;
    left: 5%;
    &-slide {
      padding: 10px 5px;
    }
  }
}

.upDom {
  animation: animationName 5s infinite;
}
.anShanDian {
  animation: animationShanDian 0.3s infinite;
}
.awardListPop {
  width: 700px;
  height: 500px;
  left: calc(50% - 350px);
  top: calc(50% - 250px);
  z-index: 1001;
  position: relative;


  @media (max-width: 1200px) {
    width: 98%;
    height:50%;
    left: 1%;
    
  }
  &-box {
    width: 50%;
    margin: 0 auto;
    @media (max-width: 1200px) {
      overflow: auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.awardListPop-box{
  margin-bottom: 30px;
}
@keyframes animationName {
  0% {
    transform: translateY(-20%);
  }
  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-20%);
  }
}
@keyframes animationShanDian {
  10% {
    transform: rotate(10deg) scale(0);
  }
  10% {
    transform: rotate(45deg) scale(0.5);
  }
  20% {
    transform: rotate(90deg) scale(0);
  }
  30% {
    transform: rotate(125deg) scale(0.6);
  }
  40% {
    transform: rotate(160deg) scale(0);
  }
  50% {
    transform: rotate(185deg) scale(0.8);
  }
  60% {
    transform: rotate(220deg) scale(0);
  }
  70% {
    transform: rotate(255deg) scale(0.4);
  }
  80% {
    transform: rotate(290deg) scale(0);
  }
  0% {
    transform: rotate(300deg) scale(0.3);
  }
  90% {
    transform: rotate(330deg) scale(0);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
</style>
