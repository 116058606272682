<template>
  <div class="px-md-5 px-1 mt-5">
    <div class="d-flex justify-content-between">
      <div class="h4 font-weight-bold mTitle">我的库存</div>
      <div class="mBack"
           @click="$router.go(-1)">返回
      </div>
    </div>
    <div class="d-flex w-100 px-3 mt-3" style="border-bottom: 1px solid var(--main-blue)">
      <div :class="{ 'user_bottom' : user_bottomStatus == 0 }" @click="user_bottomStatus=0" class="p-2 cursor-pointer">个人仓库</div>
      <a :class="{ 'user_bottom' : user_bottomStatus == 1 }" @click="user_bottomStatus=1,getStorage1()"  class="p-2 mx-3 cursor-pointer" >取回历史</a>
      <div class="p-2 d-md-block d-none" style="font-size:0.6rem">因饰品价格波动，请尽快提取您的装备。</div>
    </div>

    <div v-if="user_bottomStatus==0" class="d-flex justify-content-between align-items-center px-3 mt-2">
      <div class=" d-flex align-items-center">
        <span class="text-primary cursor-pointer cursor-pointer font-weight-bold" @click="allList">选择全部</span>
      </div>
      <div class="p-2 d-md-none d-block" style="font-size:0.6rem">因饰品价格波动，请尽快提取您的装备。</div>

    </div>
    <div v-if="user_bottomStatus==0" class="mbgbg w-100 px-5 py-3 d-flex flex-wrap justify-content-between align-items-center bg-size-100 bg-repeat mt-3"
         style="background:var(--f-main)">
      <div class="">
        <div class="h6 mb-0">
          <span >{{recyleMoney.index}}件物品</span><span class="f-yellow-color ml-2">${{recyleMoney.benr}}</span>
        </div>

      </div>
      <div class="d-flex align-items-center">
        <div class="px-md-5 px-3 py-2 rounded border-primary op-8 mr-3 cursor-pointer mt-md-0 mt-2 mQuhui" style="border:2px solid" @click="extract">取回</div>
        <div class="px-md-5 px-3 py-2 rounded border-primary op-8 cursor-pointer mt-md-0 mt-2 mHuis" style="border:2px solid" @click="recycle">回收</div>
      </div>
    </div>


    <div v-if="user_bottomStatus == 0">
      <!-- 渲染数据 -->
      <div class="flex-wrap mt-3 d-flex muserBox">
        <div class="content-hover col-lg-2 col-md-2 col-sm-3 col-4 mb-3 px-1" @click="itemOne(item,index)"
             v-for="(item,index) in StorageList" :key="index">
          <weapon class="position-relative" :class="item.isShow ? 'active' :''"
                  :res-data="item"></weapon>
        </div>
        <!-- 为空的时候显示 -->
        <vacancy v-if="StorageList.length == 0"/>

        

      </div>

      <div class="mpage">
        <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="currentChange"
      >
      </el-pagination>
      </div>
      
    </div>

    <div v-if="user_bottomStatus == 1">

      <storage-list  :Storage-list-list="StorageList" :storage-listpage-size="StorageListpageSize"
                                      :storage-listtotal="StorageListtotal"></storage-list>
    </div>
  </div>
</template>

<script>

  import weapon from "./common/weapon";
  import $api from '@/api/user/index'
  import StorageList from "./common/StorageList";
  export default {
    name: "index",
    components: {
      
    StorageList,
      weapon,

    },
    data() {
      return {

        user_bottomStatus:0,
        // 弹出框
        ReturnReason: false,
        // 仓库列表
        StorageList: [],
        // 翻页数量
        total: 0,
        pageSize: 0,
        back_message: '',
        // 排序
        StorageList: [],
        sequenceTab:false,
        StorageListpageSize:0,
        StorageListtotal: 0,
      }
    },
    computed: {
      money() {
        let benr = 0
        this.StorageList.forEach(item => {
          benr += (item.bean) * 1
        })
        return benr.toFixed(2)
      },
      recyleMoney() {
        let benr = 0
        let index = 0
        this.StorageList.forEach(item => {
          if (item.isShow == true) {
            benr += (item.bean) * 1
            index++
          }
        })
        return {benr:benr.toFixed(2),index}
      }

    },
    created() {
    },
    mounted() {
      this.init()
    },
    methods: {
      async getStorage1(page = 1, status = 1) {
      try {
        let { data, code } = await $api.getStorage(page, status);
        if (code == 500) return;
        data.data.forEach((item) => {
          if (item.back_message != "") {
            console.log(123);
          }
          item.isShow = false;
        });
        this.StorageList = data.data;
        this.StorageListtotal = data.total;
        this.StorageListpageSize = data.per_page;
      } catch (e) {
        console.log(e);
      }
    },
      init() {
        this.getStorage()
      },
      async sequence(){
        let sort = this.sequenceTab? '0':'1'

        await this.getStorage(1,sort)
        setTimeout(()=>{this.sequenceTab = !this.sequenceTab},100)

      },
      shoppinng(item, index) {
        this.ReturnReason = true
        console.log(item, index)
      },
      async getStorage(page = 1,sort) {
        console.log(sort)
        try {
          let {data, code} = await $api.getStorage(page,0 ,sort)
          if (code == 500) return
          data.data.forEach(item => {
            if (item.back_message != '') {
              console.log(123)
            }
            item.isShow = false
          })
          this.StorageList = data.data
          this.total = data.total
          this.pageSize = data.per_page
        } catch (e) {
          console.log(e)
        }
      },
      currentChange(val) {
        this.getStorage(val)
        window.scrollTo(0, 0);
      },
      allList() {
        this.StorageList.forEach(item => {
          item.isShow = !item.isShow
        })
      },
      itemOne(item) {
        if (item.back_message != '') {
          this.ReturnReason = true
          this.back_message = item.back_message
        }
        item.isShow = !item.isShow
      },
      async recycle() {
        let arr = []
        try {
          this.StorageList.forEach(item => {
            if (item.isShow) {
              arr.push(item.id)
            }
          })
          let {message, code} = await $api.getCash({data: arr})
          if (code == 500) return
          if (code == 200) {
            this.$notify.closeAll()
            this.$notify({
              message: message,
              type: 'success', position: 'top-left'
            });
          }

          this.getStorage()
          this.$store.dispatch('userInfo')
        } catch (e) {
          console.log(e)
        }

      },
      // 点击提取
      async extract() {
        let arr = []
        this.StorageList.forEach(item => {
          if (item.isShow) {
            arr.push(item.id)
          }
        })
        try {
          let {message, code} = await $api.getExtract({data: arr})
          if (code == 500) return
          if (code == 200) {
            this.$notify.closeAll()
            this.$notify({
              title: '成功',
              message: message,
              type: 'success', position: 'top-left'
            });
          }
          this.getStorage()
          this.$store.dispatch('userInfo')
        } catch (e) {
          console.log(e)
        }

      },
    }
  }
</script>

<style scoped lang="scss">


.mQuhui{

background: linear-gradient(90deg, rgba(19, 108, 172, 0.5) 0%, rgba(66, 77, 181, 0.2) 100%);

box-sizing: border-box;
height: 40px;
line-height: 20px !important;
border: 1px solid rgba(45, 116, 222, 0.3) !important;
}

.mHuis{
  background: linear-gradient(90deg, rgba(19, 108, 172, 0.5) 0%, rgba(66, 77, 181, 0.2) 100%);

box-sizing: border-box;
height: 40px;
line-height: 20px !important;
border: 1px solid rgba(45, 116, 222, 0.3) !important;
}

.mbgbg{
  background: linear-gradient(180deg, rgba(19, 108, 172, 0.1) 0%, rgba(66, 77, 181, 0.1) 100%) !important;
}
.mBack{
  background: linear-gradient(90deg, rgba(19, 108, 172, 0.5) 0%, rgba(66, 77, 181, 0.2) 100%);

box-sizing: border-box;
border: 1px solid rgba(45, 116, 222, 0.3);
position: absolute;
    width: 80px;
    height: 40px;
    right: 50px;
    line-height: 40px;
    text-align: center;
}

.mTitle{
  text-align: center;
  width: 100%;
  line-height: 50px;
}
.mpage{
  background: linear-gradient(180deg, rgba(19, 108, 172, 0.1) 0%, rgba(66, 77, 181, 0.1) 100%);
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}
.muserBox{
  padding: 40px;
    background: linear-gradient(180deg, rgba(19, 108, 172, 0.1) 0%, rgba(66, 77, 181, 0.1) 100%);
}
  .money{
    &-qian{
      width: 44px;
      background: #3b3027;
      color: #bababa;
      text-align: center;
    }
    &-icon_shenxu{
      transition:all .5s;
      width: 12px;
      height: 16px;
      background-size: 100% 100%;
      background-image: url("~@/assets/img/lucky/icon_shenxu.png");
    }
    & .active{
      transform: rotate(180deg)
    }
    @media (max-width:800px){
      & .money-s{
        display: none!important;
      }
    }
  }
  .side-box {
    width: 204px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,.5)
  }
  .content {
    width: 90%;
    margin: 0 auto;
    @media (max-width: 1200px) {
      width: 96%;
      font-size: 12px;
    }
    &-hover{
      & .active{
        position: relative;
        &::before{
          content: url("../../assets/img/hover.png");
          width: 20px;
          height: 20px;
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 999;
        }
      }
    }
  }

  .user_bottom {
    color: var(--main-yellow);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: -1px;
      background-color: var(--main-yellow);
      left: 0;
      right: 0;
      height: 3px;
    }
  }

  .password {
    width: 200px;
    @media (max-width: 1200px) {
      width: 100px;
      font-size: 12px;
    }
  }

  .setam-link {
    width: 500px;
    @media (max-width: 1200px) {
      width: 300px;
    }
    @media (max-width: 960px) {
      width: 200px;
    }
  }

  .title-phone {
    @media (max-width: 1200px) {
      display: none;
    }
  }

  .cropper-content {
    & .cropper {
      width: 300px;
      height: 300px;
    }
  }

  .side-box {
    width: 204px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5)
  }

  .content {
    width: 50%;
    margin: 0 auto;
    @media (max-width: 1200px) {
      width: 96%;
      font-size: 12px;
    }
  }

  .input-height {
    height: 38px;
    line-height: 38px;
    width: 150px;
    text-align: center;
  }

  .input-width {
    width: 300px;
    @media (max-width: 1200px) {
      width: 150px;
    }
  }

  .login {
    &-left {
      margin-left: 30px;
      width: 170px;
    }

    &-right {
      margin-right: 30px;
      width: 770px;

      &-input {
        color: #FFFFFF;
        font-size: 25px;
      }
    }
  }
</style>
